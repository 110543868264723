import { useQuery } from 'react-query';
import { getUserPreferences } from '../../services/apiClient/preferencesApi/preferencesApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'usePreferencesQuery';

/**  * Custom hook for retrieving data from preferences API  */
export const usePreferencesQuery = () => {
  const config = {
    // Setting stale time to 5 minutes because preferences alert data rarely changes.
    // This means that when any component mounts using this hook within the "fresh" time,
    // it will not fetch new data.
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'UserPreferences', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['UserPreferences'], getUserPreferences, config);
};
