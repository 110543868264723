import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FlightActionMenu from '../../FlightActionMenu/FlightActionMenu';
import './ClassicFlightPuck.css';
import { EntitlementNames, IrropsCode, Treatment } from '../../../../lib/constants';
import { displayFlightNumber } from '../../../../lib/displayUtils';
import { jsonEqual, filterFlightsForDoubleClick } from '../../../../lib/utils';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { getFlightsInBetweenForMultiSelectGroup } from '../../../../lib/swapUtil';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { getFlightPuckClassNames, getPuckRenderStat } from '../../GanttChart/ganttHelpers';
import { useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import {
  useMultiSelectedFlightGroupStore,
  useMultiSelectedFlightGroupDispatch,
} from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';
import { useDoubleClick } from '../../../../hooks/useDoubleClick/useDoubleClick';
import { getTimeDifference } from '../../../../lib/dateTimeUtils';
import {
  useFocusedFlightLegDispatch,
  useFocusedFlightLegStore,
} from '../../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { useFlightPuckRefsDispatch } from '../../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
/**
 * Classic Flight Puck component renders a puck to be displayed in the gantt chart
 * @param {Object} getFlightLineForFlightPuck - flight pucks along the line
 * @param {Object} data - a flight puck data object to be rendered
 * @param {function} openDetailPane - callback to open the detail pane
 * @param {bool} isPaneOpen - whether the detail panel is open
 * @param {string} summaryPanelMode - the mode of the summary panel
 */
const ClassicFlightPuck = ({
  getFlightLineForFlightPuck,
  data,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
}) => {
  //Get puck's display statistics
  const puckRenderStat = getPuckRenderStat(data);
  const { isSwapModeActive } = useSwapModeStore();
  const selectedFlightDetails = useSelectedItemStore();

  const { updateSelectedFlightDetails, useIsSelected } = useSelectedItemDispatch();
  const { updateMultiSelectedFlightGroup, removeMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
  const { flightLegGroup } = useMultiSelectedFlightGroupStore();
  const [showFlightNumber, setShowFlightNumber] = useState(true);
  const flightNumberRef = useRef(null);
  const [showFlightActionMenu, setShowFlightActionMenu] = useState(false);
  const [showOverLay, setShowOverLay] = useState(false);
  const { clearFocusedFlightLeg } = useFocusedFlightLegDispatch();
  const { addFlightPuckRef } = useFlightPuckRefsDispatch();
  const flightPuckRef = useRef(null);
  // feature flag
  useFeatureFlag();

  const { isReady } = useContext(SplitContext);
  const { showFeature } = useFeatureFlag();

  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const treatments = useTreatments([Treatment.SHORT_TURN_ALERTS, Treatment.FLIGHT_EQUIPMENT_CHANGE]);
  const shortTurnFlag = isReady && treatments[Treatment.SHORT_TURN_ALERTS]?.treatment === 'on';
  const equipmentChangeFlag = isReady && treatments[Treatment.FLIGHT_EQUIPMENT_CHANGE]?.treatment === 'on';
  const melcdlFlag = showFeature(Treatment.MEL_MX_MESSAGES);
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const hasMxMessageEntitlement = useAuthorizationAccess(null, [EntitlementNames.MXMESSAGES]);

  const { focusedFlightLeg } = useFocusedFlightLegStore();

  const handleClick = (event) => {
    clearFocusedFlightLeg();
    if (event.detail == 1) {
      if (selectedFlightDetails.data?.flightLegKey !== data.flightLegKey) {
        const newFlightDetails = {
          data: data,
          isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
          isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
        };
        updateSelectedFlightDetails(newFlightDetails);
      }
    }
  };

  const handleOnDoubleClick = useDoubleClick(() => {
    let flightPhase = filterFlightsForDoubleClick(data);
    if (isSwapModeActive && data.irropsCode !== IrropsCode.CANCELLED_FLIGHT && flightPhase) {
      if (
        flightLegGroup[data.aircraft] == undefined ||
        (flightLegGroup[data.aircraft].length > 1 &&
          flightLegGroup[data.aircraft][0]?.flightLegKey != data.flightLegKey)
      ) {
        updateMultiSelectedFlightGroup({ aircraftRegistration: data.aircraft, flightLegs: [data] });
      } else if (
        flightLegGroup[data.aircraft] != undefined &&
        flightLegGroup[data.aircraft][0]?.flightLegKey == data.flightLegKey &&
        flightLegGroup[data.aircraft].length >= 1
      ) {
        removeMultiSelectedFlightGroup({ aircraftRegistration: data?.aircraft });
      } else if (
        flightLegGroup[data.aircraft] != undefined &&
        flightLegGroup[data.aircraft][0]?.flightLegKey !== data.flightLegKey &&
        flightLegGroup[data.aircraft].length == 1
      ) {
        if (getTimeDifference(flightLegGroup[data.aircraft][0].departure, data.arrival) > 0) {
          let pucksInBetween = getFlightsInBetweenForMultiSelectGroup(
            flightLegGroup,
            getFlightLineForFlightPuck(null, true),
            data,
          );

          updateMultiSelectedFlightGroup({
            aircraftRegistration: data.aircraft,
            flightLegs: [...flightLegGroup[data.aircraft], ...pucksInBetween, data],
          });
        } else {
          updateMultiSelectedFlightGroup({
            aircraftRegistration: data.aircraft,
            flightLegs: [data],
          });
        }
      }
    }
  });

  const handleMouseEnter = () => {
    setShowFlightActionMenu(true);
  };

  const puckStyle = {
    width: `calc(${puckRenderStat.puckDuration} * var(--time-scale-minute))`,
  };

  const shortPuckFlag = !showFlightNumber ? 'short-puck-flag' : '';

  /**  flight action menu render */
  const flightActionMenuContainer = (
    <div className={`flight-action-menu ${shortPuckFlag}`}>
      <FlightActionMenu
        flightLeg={data}
        openDetailPane={openDetailPane}
        isPaneOpen={isPaneOpen}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        overLayHandler={(e) => setShowOverLay(e)}
        getFlightLineForFlightPuck={getFlightLineForFlightPuck}
      />
    </div>
  );

  React.useEffect(() => {
    // hide flight number if there's text overflow
    const timerRef = setTimeout(() => {
      const element = flightNumberRef?.current;
      if (element && element.scrollWidth > element.clientWidth) {
        setShowFlightNumber(false);
      } else {
        setShowFlightNumber(true);
      }
    }, 1000);
    return () => clearTimeout(timerRef);
  }, []);

  const isSelected = useIsSelected(data, 'classic');
  const mxMessageindicatorClass = melcdlFlag && hasMxMessageEntitlement ? 'mx-message-indicator-puck' : '';

  let selectLabel = '';
  if (isSelected) {
    selectLabel = 'selected-puck';
    if (isSelected && enableEnhancedScalingFlag) {
      selectLabel = 'enhanced-scaling-selected-puck';
    }
  }
  const selectedPuckThroughDoubleClick =
    isSwapModeActive && flightLegGroup[data.aircraft]?.some((x) => x.flightLegKey == data.flightLegKey);

  const FlightGroupOverlay = () => (
    <div
      className={`group-puck-container${selectedPuckThroughDoubleClick ? ' selected-group-puck' : ''}`}
      data-cy="group-puck-container"
    />
  );

  React.useEffect(() => {
    if (flightPuckRef?.current != null && focusedFlightLeg?.flightLegKey === data?.flightLegKey) {
      addFlightPuckRef(data.flightLegKey, flightPuckRef.current);
    }
  }, [flightPuckRef, data.flightLegKey, focusedFlightLeg]);

  return (
    <div
      className={`flightpuck-container ${selectLabel} ${mxMessageindicatorClass} ${shortPuckFlag} ${
        showOverLay ? 'visible-overlay' : ''
      }`}
      data-cy="flightpuck-container"
      ref={flightPuckRef}
    >
      <div
        className={getFlightPuckClassNames(
          ['classic-flight-puck'],
          data,
          isSelected,
          isPaneOpen,
          selectedPuckThroughDoubleClick,
          shortTurnFlag,
          equipmentChangeFlag,
        )}
        data-cy={`${data.irropsCode === IrropsCode.CANCELLED_FLIGHT ? 'canceled-' : ''}flight-puck-${
          data.flightLegKey
        }`}
        style={puckStyle}
        onMouseDown={handleOnDoubleClick}
        onMouseEnter={handleMouseEnter}
        onClick={handleClick}
      >
        {showFlightNumber && (
          <div className="flight-number" ref={flightNumberRef}>
            <span className="flightnumber-text">{displayFlightNumber(data)}</span>
          </div>
        )}
        {showFlightActionMenu && flightActionMenuContainer}
        {selectedPuckThroughDoubleClick ? <FlightGroupOverlay /> : <></>}
      </div>
    </div>
  );
};

ClassicFlightPuck.propTypes = {
  data: PropTypes.shape({
    flightLegKey: PropTypes.number.isRequired,
    flightNumber: PropTypes.number.isRequired,
    orig: PropTypes.string.isRequired,
    dest: PropTypes.string.isRequired,
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    aircraft: PropTypes.string.isRequired,
  }).isRequired,
  openDetailPane: PropTypes.func.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
  summaryPanelMode: PropTypes.string.isRequired,
};

export default React.memo(ClassicFlightPuck, (prevProps, nextProps) => {
  return (
    jsonEqual(prevProps.data, nextProps.data) &&
    prevProps.isPaneOpen === nextProps.isPaneOpen &&
    prevProps.summaryPanelMode === nextProps.summaryPanelMode
  );
});
