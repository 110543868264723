import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import throttle from 'lodash/throttle';
import { useCrosshairDispatch } from '../../../hooks/useCrosshairStore/useCrosshairStore';
import './Crosshair.css';
import { checkWithinBounds } from '../GanttChart/ganttHelpers';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../../lib/constants';

/**
 * Vertical and horizontal lines that span the containing div, including
 * total scroll space, with horizontal line remaining stationary on scroll
 * and vertical line moving with chart on scroll
 * @param {Object} props
 * @param {Element} props.containerRef ref for containing element
 * @param {String} props.height CSS string for height in px
 * @param {Number} props.prevScrollPositions position of previous scroll
 *  this is needed due to sometimes when component mounts container hasn't been updated with scroll yet
 * @returns CrossHairButton component
 */
function Crosshair({
  containerRef,
  height,
  subContainerRef,
  prevScrollPositions,
  index,
  active,
  sessionStoragePosition,
  setSessionStoragePosition,
  setSessionStorageActiveCrosshair,
}) {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const { updateActiveCrosshair } = useCrosshairDispatch();
  const container = containerRef?.current;
  const subContainer = subContainerRef?.current;
  const rect = useMemo(() => container?.getBoundingClientRect() || { top: 0 }, [container]);

  const coordsRef = useRef(null);

  const [coords, _setCoords] = useState(null);

  const setCoords = (coords) => {
    coordsRef.current = coords;
    _setCoords(coords);
  };

  const getInitX = useCallback(() => {
    return (
      ((prevScrollPositions?.scrollLeft && prevScrollPositions.scrollLeft < container?.clientWidth
        ? prevScrollPositions.scrollLeft
        : container?.scrollLeft) ||
        container?.scrollLeft ||
        0) +
      (container?.clientWidth || 2) / 2 -
      index * 10
    );
  }, [container, container?.scrollLeft, prevScrollPositions]);

  useEffect(() => {
    if (container !== null && sessionStoragePosition) {
      if (subContainer !== null && sessionStoragePosition?.x > subContainer?.scrollWidth) {
        setCoords({
          x: (container?.scrollLeft || 0) + (container?.clientWidth || 2) / 2 - index * 10,
          y: rect.top + (container?.clientHeight || 2) / 2 - index * 10,
        });
        if (container?.scrollLeft > 0) {
          setSessionStoragePosition({ index, coords: coordsRef.current });
        }
      } else {
        setCoords(sessionStoragePosition);
      }
    } else if (container !== null && !coords) {
      setCoords({
        x: getInitX(),
        y: rect.top + (container?.clientHeight || 2) / 2 - index * 10,
      });
    }
  }, [container, container?.scrollLeft, getInitX]);

  useEffect(() => {
    if (container && active) {
      container.addEventListener('mousedown', onMouseDown);
      container.addEventListener('mouseup', onMouseUp);
      container.addEventListener('mousemove', onMouseMove);
    }

    if (container && !active) {
      container.removeEventListener('mousedown', onMouseDown);
      container.removeEventListener('mouseup', onMouseUp);
      container.removeEventListener('mousemove', onMouseMove);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', onMouseDown);
        container.removeEventListener('mouseup', onMouseUp);
        container.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [container, active]);

  const onMouseDown = useCallback(
    (e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        setCoords({
          x: x + container.scrollLeft,
          // TODO multiple views type MULTI is not working
          y: showMultipleViews ? y - container.offsetTop : y,
        });
      }
    },
    [container, showMultipleViews],
  );

  const onMouseUp = useCallback(
    (e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        setSessionStoragePosition({ index, coords: coordsRef.current });
      }
    },
    [coords, container],
  );

  const onMouseMove = useCallback(
    throttle((e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        // Prevent highlighting text while dragging
        e.preventDefault();
        setCoords({
          x: x + container.scrollLeft,
          // TODO multiple views type MULTI is not working
          y: showMultipleViews ? y - container.offsetTop : y,
        });
      }
    }, 50),
    [container],
  );

  const onClick = () => {
    setSessionStorageActiveCrosshair(index);
    updateActiveCrosshair({ activeCrosshair: index });
  };

  if (!container || !coords) {
    return <></>;
  }

  return (
    <>
      <div
        className="crosshair-ver-line-wrapper"
        onClick={onClick}
        style={{
          // -7 to center the crosshair given the width of the wrapper is 14px
          transform: `translateX(${coords.x - 7}px)`,
          height,
        }}
        data-cy="crosshair-ver-line"
      >
        <div
          className={`crosshair-ver-line${active ? ' active-crosshair' : ''}`}
          style={{
            height,
          }}
        />
      </div>
      <div
        className="crosshair-hor-line-wrapper"
        onClick={onClick}
        style={{
          top: `${coords.y - 7}px`,
          width: container?.clientWidth ? `${container.clientWidth}px` : '100%',
        }}
        data-cy="crosshair-hor-line"
      >
        <div
          className={`crosshair-hor-line${active ? ' active-crosshair' : ''}`}
          style={{
            width: container?.clientWidth ? `${container.clientWidth}px` : '100%',
          }}
        />
      </div>
    </>
  );
}

export default Crosshair;
