import { useRef, useEffect } from 'react';

/**
 * Custom hook to keep a previous value such as a state or prop.
 * Can be used to compare new props with the previous.
 * @param {Any} value - the previous state or prop to hold on to
 * @returns - the previous value
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
