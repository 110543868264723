import { axiosInstance } from '../apiClient';

const API_ROUTE = 'authorization';

/**
 * Returns a user if authorized and an Error if unauthorized
 * @param {string} airlineCode
 * @param {string} employeeId
 */
export const getAuthorizedUser = async (airlineCode) => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/authorized/user/${airlineCode}`;
  const response = await apiClient.get(url);
  let result = response;
  return result;
};
