import { useMemo } from 'react';
import { useFilterStore } from '../useFilterStore/useFilterStore';
import { useActiveRoleAssignment } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { useHideStore } from '../useHideCancelledFlightsStore/useHideCancelledFlightsStore';
import { useFlightsQuery } from '../../react-query/useFlightsQuery/useFlightsQuery';
import { getFilteredFlightLegs } from '../../lib/utils';

/**
 * Returns filtered flight data for the flight list table
 * @returns {Object} - { flightData, loading, error }
 */
function useFlightListTableData() {
  const { filter } = useFilterStore();
  const { activeAssignments } = useActiveRoleAssignment();
  const { hideCancelledFlights } = useHideStore();
  const { isLoading, data, error } = useFlightsQuery(filter.startDate, filter.endDate);

  return useMemo(() => {
    if (isLoading) {
      return {
        loading: true,
        flightData: [],
        error: false,
      };
    } else if (error) {
      return {
        loading: false,
        flightData: [],
        error: true,
      };
    } else {
      const flightLegs = data?.data?.flattenedFlightLegs ? data.data.flattenedFlightLegs : [];
      const filteredFlightLegs = getFilteredFlightLegs(flightLegs, hideCancelledFlights, filter, activeAssignments);
      return {
        loading: false,
        flightData: filteredFlightLegs,
        error: false,
      };
    }
  }, [filter, activeAssignments, hideCancelledFlights, isLoading, data, error]);
}

export default useFlightListTableData;
