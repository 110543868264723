import React from 'react';
import PropTypes from 'prop-types';

const Circle = ({ color, ...props }) => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2910_33312)">
        <ellipse cx="4.37305" cy="4.86317" rx="4" ry="4.00184" fill={color} data-cy="circle-color" />
      </g>
      <defs>
        <clipPath id="clip0_2910_33312">
          <rect width="8" height="8.00369" fill="white" transform="translate(0.373047 0.861328)" />
        </clipPath>
      </defs>
    </svg>
  );
};
Circle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Circle;
