import React, { useState, useEffect } from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import IrropsFlightModal from '../../../Shared/IrropsFlightModal/IrropsFlightModal';
import {
  IrropsCode,
  EntitlementNames,
  IrropFlightStatusErrorMessage,
  WarningTypes,
  IrropsModalFieldType,
  Treatment,
} from '../../../../lib/constants';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../../lib/dateTimeUtils';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { irregularOps } from '../../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import {
  isNullOrWhitespace,
  getCalculatedIrropDataEstimates,
  getDetailsBlocks,
  getBodyWithSessionId,
} from '../../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../../lib/utils';
import { getFlightDetails, headCheckFlightExists } from '../../../../services/apiClient/flightsApi/flightsApi';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

/**
 * The RedispatchIntermediate component is build the Redispatch Intermediate form.
 * @param {Object} props -
 *  existingRedispatchDetails - existing redispatch details object.
 *  onCommit - callback func when save the redispatch intermediate form.
 *  onClose - callback func when close the redispatch intermediate the form.
 *  redispatchDetails - redispatch details object.
 * @returns RedispatchIntermediate component
 */
const RedispatchIntermediate = ({
  existingRedispatchDetails = null,
  onCommit,
  onClose,
  redispatchDetails,
  ...props
}) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    flightDateUtc,
    etd,
    eta,
    aircraftRegistration,
    scheduledDestination,
    scheduledOperatingDateUTC,
    isEtopsFlight,
    departureCount,
  } = redispatchDetails;

  const [irropStationVar, setIrropStationVar] = useState('');

  const [irropStation, setIrropStation] = useState({
    station: '',
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
  });
  const [irropETA, setIrropETA] = useState({
    value: '',
    isError: false,
    error: '',
    hhmm: '',
  });

  const [userComments, setUserComments] = useState('');
  const [show, setShow] = useState(true);
  const [selectedReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);

  const { trackEvent } = useAppInsightsContext();

  // authorization access hook
  const hasRedispatchEntitlement = useAuthorizationAccess(null, [EntitlementNames.REDISPATCH]);

  //feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  /**
   * Calls updateIrropDataEstimates from IrropUtils.js and updates local state.
   * @param {String} dirtyField - IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyValue - Value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyValue) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      null,
      aircraftRegistration,
      formatDateTime(etd, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      irropStation,
      irropETA,
      false,
      false,
      null,
      null,
      null,
      dirtyField,
      dirtyValue,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      setIrropStation(result[IrropsModalFieldType.STATIONS]);
    }
  };

  /** Set default user-input ETA fields with the ETA display from Current Flight Details. */
  useEffect(() => {
    updateWithEstimates(IrropsModalFieldType.IRROP_ETA, eta);
  }, [eta]);

  /**
   * Clears all input variable state
   */
  const cleanInputs = () => {
    setIrropStationVar('');
    setIrropStation({
      station: '',
      irropBlockMinutes: '',
      contTurnTimeMinutes: '',
      contBlockMinutes: '',
    });
  };

  /**
   * Event handler when the divert airport code has changed.
   * @param {String} value - Three-letter, capitalized airport code
   */
  const handleAirportCodeInputChange = (value, event, target) => {
    if (value === irropStationVar) return;
    if (value.length < 3) {
      cleanInputs();
      return;
    }
    setIrropStationVar(value);

    focusNextElement(event, target);
  };

  /**
   * @description Handle on change of input field.
   * @param {string} - String value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  const isCommitEnabled = () => {
    let isEnabled =
      !isNullOrWhitespace(irropStationVar) &&
      irropStationVar.length === 3 &&
      !isNullOrWhitespace(irropETA.hhmm) &&
      !isNullOrWhitespace(irropETA.value) &&
      !isNullOrWhitespace(selectedReason) &&
      !isNullOrWhitespace(selectedReason.key) &&
      ((selectedReason.isCommentsRequired && !!userComments) || //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectedReason.isCommentsRequired === false) &&
      hasRedispatchEntitlement &&
      !commitButtonClicked;
    return isEnabled;
  };

  /**
   * Handle the on selecting the reason dropdown and set the state.
   * @param {string} reason - Selected reason value.
   * @param {any} reasonKey - Reason key.
   * @param {any} commentsTemplate - Template/placeholder for comments.
   * @param {any} commentsRequired - Is comments required for reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description Handling the commit button click.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);

    await submitRedispatchIntermediateV2();

    setShow(false); // HIDE THE MODAL
  };

  /**
   * @description Close the redispatch intermediate modal
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.REDISPATCH_FLIGHT);
  };

  /**
   * @description Save the redispatch intended form.
   * Calling irregularOps API and call the callbacks.
   */
  const submitRedispatchIntermediateV2 = async () => {
    trackEvent(`Redispatch (Intermediate) - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    let redispatchIntmResult;

    let redispatchIntmFlightRequestBody = getBodyWithSessionId(
      {
        // Restructure redispatch intermediate flight form data for post to API.
        operatingAirlineCode: operatingAirlineCode,
        flightNumber: flightNumber.toString(),
        opsType: IrropsCode.REDISPATCH_FLIGHT,
        actualOrigin: origin,
        actualDestination: destination,
        scheduledOrigin: origin,
        scheduledDestination: scheduledDestination,
        scheduledOperatingDateUTC: scheduledOperatingDateUTC,
        isEtopsFlight: isEtopsFlight,
        legNumber: '', // legNumber,
        comments: userComments,
        opsReason: selectedReason.text,
        projectedDestination: irropStationVar,
        estimatedIn: irropETA.value,
        departureCount,
      },
      sessionId,
    );

    const flightLeg = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (flightLeg !== null) {
      if (flightLeg.projectedDestination === null || flightLeg.projectedDestination === destination) {
        //CALL THE Irrops QueuePublisher API for Redispatch .
        redispatchIntmResult = await irregularOps(redispatchIntmFlightRequestBody);
        trackEvent(
          `Redispatch (Intermediate) - API call to submit Redispatch (Intermediate) details is complete. Status:${redispatchIntmResult?.status}, Flight number : ${flightNumber}`,
        );

        let completeCheckCall = headCheckFlightExists(
          flightNumber,
          scheduledOperatingDateUTC,
          origin,
          destination,
          departureCount,
          operatingAirlineCode,
          IrropsCode.REDISPATCH_FLIGHT,
        );

        // Handle result
        if (redispatchIntmResult !== null && redispatchIntmResult.status === 'SUCCESS')
          // Pass result flight action menu for handle notification
          onCommit(true, 'Redispatch (Intermediate)', '', [completeCheckCall]);
        // Handle toast notification success
        else onCommit(false, 'Redispatch (Intermediate)', '', []); // Handle toast notification error
      } else {
        trackEvent(
          `Redispatch (Intermediate) - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`,
        );
        onCommit(false, 'Redispatch (Intermediate)', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Redispatch (Intermediate) - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Redispatch (Intermediate)', '', []);
    }
  };

  /**
   * @description Save the redispatch intended form.
   * Calling irregularOps API and call the callbacks.
   */

  /**
   * @description Build array current flight detail blocks to pass irrops shared modal.
   * @returns Array of current flight detail blocks object.
   */
  const getRedispatchIntmCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.REDISPATCH_FLIGHT,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      null,
      etd,
      eta,
    );
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.REDISPATCH, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle="Redispatch To Intermediate Station"
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.REDISPATCH_FLIGHT}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getRedispatchIntmCurrentFlightDetailBlocks()}
        showIrropSection={true}
        irropTitle="REDISPATCH STATION"
        irropsAutoPopulateStationsLabel="Flight Leg"
        irropStations={{
          origin,
          destination,
          isDestinationEditable: true,
          onChangeDestination: handleAirportCodeInputChange,
        }}
        irropETA={{
          value: irropETA.hhmm,
          warningType: WarningTypes.NONE,
          errorMessage: '',
          inputTypeLable: true,
        }}
        showFollowFlightSection={false}
        showCanceledFlightSection={false}
        reason={{
          placeholderText: '-Select Reason-',
          reasonText: selectedReason.text,
          commentsTemplate: selectedReason.template,
          isCommentsRequired: selectedReason.isCommentsRequired,
          onSelect: handleOnSelectReason,
        }}
        comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
        isCommitEnabled={isCommitEnabled()}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
    </AocAuthAccessCheck>
  );
};

RedispatchIntermediate.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(RedispatchIntermediate);
