import React from 'react';
import PropTypes from 'prop-types';
import { jsonEqual } from '../../../../lib/utils';
import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { onDropMoveFlights } from '../../../../lib/swapUtil';
import StandbyGroundPuck from '../../StandbyGroundPuck/StandbyGroundPuck';
import './SwapStandbyGroundPuck.css';
import {
  useMultiSelectedFlightGroupStore,
  useMultiSelectedFlightGroupDispatch,
} from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders a standby Ground Puck using the given ground puck data and positions the puck
 * with respect to the gantt start time.
 *
 * A ground puck be a station label to represent turn time between flights,
 * extract out standby ground events into its own component
 * @param {Object} props
 * @param {Object} props.data - Ground puck data to be rendered
 * @returns - A StandbyGroundPuck component
 */
const SwapStandbyGroundPuck = React.memo(
  ({ data, handleChangeActivityKey, summaryPanelMode, isPaneOpen, openDetailPane, getFlightLineForFlightPuck }) => {
    const { updateMoveFlightLeg } = useSwapModeDispatch();
    const { flightLegGroup } = useMultiSelectedFlightGroupStore();
    const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
    const dragItem = useDragItemStore();

    const [dropRef] = useDropItem({
      dropItemKey: `${data.aircraft}-swaps-ground-standby-container`,
      data: data,
      onDrop: () =>
        onDropMoveFlights(
          dragItem,
          flightLegGroup,
          data.aircraft,
          updateMoveFlightLeg,
          removeAllMultiSelectedFlightGroup,
        ),
    });

    // retune the standby aircraft puck lable
    return (
      <div className="swap-ground-standby-puck" data-cy="swap-ground-standby-puck" ref={dropRef}>
        <StandbyGroundPuck
          data={data}
          handleChangeActivityKey={handleChangeActivityKey}
          summaryPanelMode={summaryPanelMode}
          isPaneOpen={isPaneOpen}
          openDetailPane={openDetailPane}
          getFlightLineForFlightPuck={getFlightLineForFlightPuck}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return jsonEqual(prevProps.data, nextProps.data);
  },
);

SwapStandbyGroundPuck.propTypes = {
  data: PropTypes.shape({
    arrivalStation: PropTypes.string,
    arrival: PropTypes.string,
    departureStation: PropTypes.string,
    departure: PropTypes.string,
    aircraft: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }),
};

export default SwapStandbyGroundPuck;
