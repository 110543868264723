import { useEffect, useMemo, useState } from 'react';
import { useGanttConfig } from '../useGanttConfig/useGanttConfig';
import { PuckType } from '../../lib/constants';
import { jsonEqual } from '../../lib/utils';

export const useGroundEventsSummary = (selectedPuck) => {
  const isOtsPuckSelected = useMemo(
    () =>
      selectedPuck?.data?.puckType === PuckType.GROUND_OTS ||
      selectedPuck?.data?.puckType === PuckType.GROUND_UNAVAILABLE,
    [selectedPuck],
  );
  const [outOfServiceData, setOutOfServiceData] = useState({});

  const config = useGanttConfig(isOtsPuckSelected);

  useEffect(() => {
    const selectedAircraft = selectedPuck?.data?.aircraft;
    const selectedGroundEventKey = selectedPuck?.data?.groundEventKey;

    setOutOfServiceData((prevState) => {
      let newState = {};

      if (isOtsPuckSelected && selectedAircraft && selectedGroundEventKey) {
        const { loading, ganttConfig, errors } = config;

        if (!loading && !errors.groundEvents) {
          const puck = ganttConfig[selectedAircraft]?.scheduled.find((p) => {
            return p.groundPuckData && p.groundPuckData.groundEventKey === selectedGroundEventKey;
          });

          newState = puck ? puck.groundPuckData : {};
        }
      }

      // Only update state if the new state is different from the previous state
      return jsonEqual(newState, prevState) ? prevState : newState;
    });
  }, [selectedPuck, isOtsPuckSelected, config]);

  return outOfServiceData;
};
