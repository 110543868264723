import React, { Fragment } from 'react';
import './SummaryActivityLogTab.css';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { useSelectedItemStore } from '../../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useFlightOperationalNotesQuery } from '../../../../../hooks/useNotesQuery/useNotesQuery';
import { NoteTag } from '../../../../../lib/constants';
import { formatDateTime, getDayDifference } from '../../../../../lib/dateTimeUtils';
import SummaryActivityLogGroup from './SummaryActivityLogGroup/SummaryActivityLogGroup';
import TabSkeleton from '../../../FlightDetailPane/TabSkeleton/TabSkeleton';

/**
 * SummaryActivityLogTab renders the activity log / operational notes in flight detail panel.
 * @returns
 */
const SummaryActivityLogTab = () => {
  const selectedSummaryFlightDetails = useSelectedItemStore();
  let summaryflightLeg = selectedSummaryFlightDetails.data;

  let fetchNotesSummaryData = selectedSummaryFlightDetails.isActivityTabOpen && summaryflightLeg != null;

  const { isLoading: loading, data: flightLegOperationalNotes } = useFlightOperationalNotesQuery(
    summaryflightLeg?.flightNumber,
    summaryflightLeg?.departureDate,
    summaryflightLeg?.orig,
    summaryflightLeg?.dest,
    summaryflightLeg?.departureCount,
    summaryflightLeg?.airline,
    true,
    fetchNotesSummaryData,
  );

  let activityLogContent = '';

  let renderSummaryActivityLog =
    !loading && flightLegOperationalNotes && Object.keys(flightLegOperationalNotes)?.length > 0;
  let activityNotes = [];

  if (renderSummaryActivityLog) {
    activityNotes = flightLegOperationalNotes?.operationalNotes.filter((n) => n.noteTag === NoteTag.WATCH_FLIGHT);
  }

  if (renderSummaryActivityLog && activityNotes?.length > 0) {
    let operationalNotesTag = 'Watch Flight';
    let dateFormat = 'MM/DD/YYYY';

    //Group the activity log by date
    const activityGroupByDate = activityNotes.reduce((group, note) => {
      const { operationalNoteCreateDate, operationalNoteClosedDate } = note;
      let date = '';

      if (operationalNoteClosedDate) {
        date = formatDateTime(operationalNoteClosedDate, dateFormat);
        group[date] = group[date] ?? [];
        group[date].push({
          activityDateTime: note.operationalNoteClosedDate,
          activityNote: 'Changed to unwatched flight.',
          activityTag: operationalNotesTag,
        });
      }

      if (operationalNoteCreateDate) {
        date = formatDateTime(operationalNoteCreateDate, dateFormat);
        group[date] = group[date] ?? [];
        group[date].push({
          activityDateTime: note.operationalNoteCreateDate,
          activityNote: 'Changed to watched flight and set reason code to ' + note.operationalNote + '.',
          activityTag: operationalNotesTag,
        });
      }

      return group;
    }, {});

    //create the activity log content
    activityLogContent = (
      <div className="activity-log-details" data-cy="activity-log-details">
        {Object.keys(activityGroupByDate)
          .sort((a, b) => {
            return getDayDifference(b, null, a);
          }) //sort by date
          .map((date) => {
            return <SummaryActivityLogGroup activityDate={date} activityLogDetails={activityGroupByDate[date]} />;
          })}
      </div>
    );
  }

  return (
    <div className="activity-log-summary-tab-container">
      {loading ? (
        <TabSkeleton />
      ) : (
        <Fragment>
          <div className="activity-log-header">FLIGHT ACTIVITY</div>
          {activityLogContent}
        </Fragment>
      )}
    </div>
  );
};

SummaryActivityLogTab.propTypes = {};

export default withAppInsightsTracking(SummaryActivityLogTab);
