/**
 * Module for utility functions related to HTML elements.
 */

/**
 * Gets the translateX and translateY values of the transform property of
 * the given element.
 *
 * Expects the transform property to be in the form of `matrix(a, b, c, d, tx, ty)`.
 * @param {HTMLElement} element - The element whose transform values should be retrieved.
 * @returns {Object|null} An object with the translateX and translateY values, or null if no transform property is found.
 */
export function getTransformValues(element) {
  if (!element || !(element instanceof HTMLElement)) return null;

  const style = window.getComputedStyle(element);
  const transform = style.transform;

  if (transform === 'none' || !(transform.startsWith('matrix(') && transform.endsWith(')'))) {
    return null;
  }

  // Split the transform string to get individual values
  const matrixValues = transform
    .substring(7, transform.length - 1)
    .split(',')
    .map(parseFloat);

  // Extract translateX and translateY values
  const translateX = matrixValues[4];
  const translateY = matrixValues[5];

  if (isNaN(translateX) || isNaN(translateY)) {
    return null;
  }

  return { translateX, translateY };
}

export function getWidthHeightValues(element) {
  if (!element || !(element instanceof HTMLElement)) return null;

  const style = window.getComputedStyle(element);
  const width = parseInt(style.width, 10);
  const height = parseInt(style.height, 10);

  return { width, height };
}
