import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import './ActivityLogDetail.css';

/**
 * ActivityLogDetail renders the details of an activity log.
 * @param {string} activityDateTime - date and time of the activity log
 * @param {string} activityNote - note of the activity log
 * @param {string} activityTag - tag for the actiity e.g. WatchFlight
 * @returns
 */
const ActivityLogDetail = ({ activityDateTime, activityNote, activityTag }) => {
  return (
    <div className="activity-log-detail" data-cy="activity-log-detail">
      <div className="activity-log-detail-time" data-cy="activity-log-detail-time">
        {formatDateTime(activityDateTime, 'hh:mm A')}
      </div>
      <div className="activity-log-detail-note" data-cy="activity-log-detail-note">
        {activityNote}
      </div>
      <div className="activity-log-detail-tag" data-cy="activity-log-detail-tag">
        {activityTag}
      </div>
    </div>
  );
};

ActivityLogDetail.propTypes = {
  activityDateTime: PropTypes.string,
  activityNote: PropTypes.string,
  activityTag: PropTypes.string,
};

export default withAppInsightsTracking(ActivityLogDetail);
