import React from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import InfoBlock from '../../FlightDetailPane/FlightTab/InfoBlock/InfoBlock';
import './MiniSummaryContainer.css';

/**
 * The MiniSummaryContainer component render the details in summary panel
 * @returns MiniSummaryContainer
 */
const MiniSummaryContainer = ({ data }) => {
  return (
    <div className="mini-summary-container">
      <div className="identity-block">
        <div className="flight-number">{data.flightNumber}</div>
        {data.irropsCode != null && data.irropsCode != '- -' ? (
          <div className="irrops-code">
            <span>{data.irropsCode}</span>
          </div>
        ) : null}
        <div className="vertical-line"></div>
      </div>
      <div className="float-left">
        <InfoBlock label="O" isHorizontal={true}>
          {data.origin}
        </InfoBlock>
      </div>
      <div className="float-left">
        <InfoBlock label="D" isHorizontal={true}>
          {data.destination}
        </InfoBlock>
      </div>
      <div className="float-left">
        <InfoBlock label="TD" isHorizontal={true}>
          {data.dateDeparture} {data.timeDeparture}
          <span className="timezone-info">{data.timeDepartureTz}</span>
        </InfoBlock>
      </div>
      <div className="float-left">
        <InfoBlock label="TA" isHorizontal={true}>
          {data.dateArrival} {data.timeArrival}
          <span className="timezone-info">{data.timeArrivalTz}</span>
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-px">
        <InfoBlock label="PX" isHorizontal={true}>
          {data.passengerCount}
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-dd">
        <InfoBlock label="DD" isHorizontal={true}>
          {data.dispatchDesk}
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-gd">
        <InfoBlock label="GD" isHorizontal={true}>
          {data.departureGate}
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-ga">
        <InfoBlock label="GA" isHorizontal={true}>
          {data.arrivalGate}
        </InfoBlock>
      </div>
      <div className="float-left block-space"></div>
      <div className="float-left" data-cy="minisummary-brp">
        <InfoBlock label="BRP" isHorizontal={true}>
          {data.bookedRevenuePassengerCount}
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-ac">
        <InfoBlock label="AC" isHorizontal={true}>
          {data.aircraftSeatConfigurationCount}
        </InfoBlock>
      </div>
      <div className="float-left" data-cy="minisummary-au">
        <InfoBlock label="AU" isHorizontal={true}>
          {data.ticketSalesAuthorizationCount}
        </InfoBlock>
      </div>
    </div>
  );
};

export default withAppInsightsTracking(MiniSummaryContainer);
