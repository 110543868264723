import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { deleteUserAssignments, postUserAssignments } from '../../services/apiClient/assignmentsApi/assignmentsApi';
import roleAssignmentsReducer from './roleAssignmentsReducer';
import { useUserContext } from '../UserContext/UserContext';
import { useUserPreferencesData, useUserPreferencesFunctions } from '../UserPreferencesContext/UserPreferencesContext';
import { jsonEqual } from '../../lib/utils';
import usePrevious from '../../hooks/usePrevious/usePrevious';

const RoleAssignmentContext = createContext(null);
const ActiveRoleAssignmentContext = createContext(null);

const RoleAssignmentProvider = ({ children }) => {
  const [roleAssignments, dispatch] = useReducer(roleAssignmentsReducer, {
    role: null,
    entitlements: [],
    assignments: [],
    loading: true,
  });

  const { state: userState } = useUserContext();
  const { state: userPreferencesState } = useUserPreferencesData();
  const [activeAssignments, setActiveAssignments] = useState({
    flightLegKeys: [],
    aircraft: [],
  });

  // Keep a copy of prev fedauth user state in case it changes
  const prevUserState = usePrevious(userState);

  const { updateRoleAssignmentsPreference } = useUserPreferencesFunctions();

  /**
   * Checks whether user preference data has changed from this context's data
   * @param {*} preference
   * @returns
   */
  const shouldReloadRoleAssignments = (preference) => {
    return (
      !jsonEqual(preference.role, roleAssignments.role) ||
      !jsonEqual(preference.assignments, roleAssignments.assignments) ||
      !jsonEqual(prevUserState?.authorizedRoles, userState.authorizedRoles)
    );
  };

  const dispatchAssignments = (result) => {
    const preference = userPreferencesState.roleAssignments;
    if (result) {
      dispatch({
        type: 'RETRIEVED_ROLE_ASSIGNMENTS',
        payload: {
          role: preference.role,
          authorizedRoles: userState.authorizedRoles,
          assignments: preference.assignments,
        },
      });
    } else {
      // for now, default to going through role selection again
      dispatch({ type: 'NO_ROLE_ASSIGNMENTS' });
    }
  };

  useEffect(() => {
    if (userState.loading || userPreferencesState.loading) {
      // waiting for user and user preferences
      dispatch({ type: 'RETRIEVING_ROLE_ASSIGNMENTS' });
    } else if (userPreferencesState.roleAssignments && shouldReloadRoleAssignments) {
      const preference = userPreferencesState.roleAssignments;

      // create flight and aircraft assignments
      postUserAssignments(preference.role.roleID, preference.assignments).then((result) => {
        setActiveAssignments({
          flightLegKeys: result?.flightAssignments?.flightLegKeys,
          aircraft: result?.aircraftAssignments,
        });
        dispatchAssignments(result);
      });
    } else if (userState.authorizedRoles?.length === 1 && !userState.authorizedRoles[0].assignmentRequired) {
      // user has one role that doesn't require an assignment selection, so use as default
      dispatch({
        type: 'RETRIEVED_ROLE_ASSIGNMENTS',
        payload: {
          role: userState.authorizedRoles[0],
          authorizedRoles: userState.authorizedRoles,
          assignments: [],
        },
      });
    } else {
      // user needs to select their assignments
      dispatch({ type: 'NO_ROLE_ASSIGNMENTS' });
    }
  }, [
    userState.loading,
    userState.authorizedRoles,
    userPreferencesState.loading,
    userPreferencesState.roleAssignments,
  ]);

  /**
   * Ends the user's current role assignment, assigns them to their new
   * role assignment, and saves their user preference.
   * @param {Object} newRoleAssignments - new role assignments to save
   */
  const updateRoleAssignment = (newRoleAssignments) => {
    const doUpdate = async (role, assignments) => {
      try {
        await deleteUserAssignments();
        const result = await postUserAssignments(role.roleID, assignments);
        setActiveAssignments({
          flightLegKeys: result?.flightAssignments?.flightLegKeys,
          aircraft: result?.aircraftAssignments,
        });

        await updateRoleAssignmentsPreference(role, assignments);
        dispatch({
          type: 'SAVED_ROLE_ASSIGNMENTS',
          payload: {
            role: role,
            assignments: assignments,
            authorizedRoles: userState.authorizedRoles,
          },
        });
      } catch (error) {
        console.error(error);
        dispatch({ type: 'ERROR_SAVING_ROLE_ASSIGNMENTS' });
      }
    };

    dispatch({ type: 'SAVING_ROLE_ASSIGNMENTS' });
    doUpdate(newRoleAssignments.role, newRoleAssignments.assignments);
  };

  return (
    <RoleAssignmentContext.Provider value={{ roleAssignments, updateRoleAssignment }}>
      <ActiveRoleAssignmentContext.Provider value={{ activeAssignments }}>
        {children}
      </ActiveRoleAssignmentContext.Provider>
    </RoleAssignmentContext.Provider>
  );
};

// Custom hook that exposes the RoleAssignmentContext
// Allows lower components to access RoleAssignmentProvider's roleAssignments state and the updateRoleAssignment function
const useRoleAssignmentContext = () => {
  const context = useContext(RoleAssignmentContext);
  if (!context) {
    throw new Error(
      'useRoleAssignmentContext must be used within a RoleAssignmentProvider. Wrap a parent component in <RoleAssignmentProvider> to fix this error.',
    );
  }
  return context;
};

// Custom hook that allows lower components to access RoleAssignmentProvider's activeRoleAssignments state and the updateRoleAssignment function
const useActiveRoleAssignment = () => {
  const context = useContext(ActiveRoleAssignmentContext);
  if (!context) {
    throw new Error(
      'useActiveRoleAssignment must be used within a RoleAssignmentProvider. Wrap a parent component in <RoleAssignmentProvider> to fix this error.',
    );
  }
  return context;
};

export { RoleAssignmentProvider, useRoleAssignmentContext, RoleAssignmentContext, useActiveRoleAssignment };
