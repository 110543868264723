import React from 'react';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';
import { Treatment } from '../../lib/constants';
import { ReactComponent as AirtrAKTextLogo } from '../../assets/images/AirtrAK-logo-text.svg';
import './Dashboard.css';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import Analytics from './Analytics/Analytics';

/**
 * AirtrAK Logo component
 * @returns AirtrAK logo
 */
const AirtrAKTextIcon = () => {
  return <AirtrAKTextLogo data-cy="airtrAK-text-logo" className="airtrAK-logo" />;
};

const Dashboard = () => {
  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('DashboardPage');

  //Feature flagging - display of charts
  const { showFeature } = useFeatureFlag();
  const analyticsFlag = showFeature(Treatment.DASHBOARD_ANALYTICS);

  return (
    <div data-cy="dashboard-page-message" className="dashboard">
      {analyticsFlag ? (
        <Analytics />
      ) : (
        <>
          <span>Welcome to</span>
          <div>
            <AirtrAKTextIcon />
          </div>
        </>
      )}
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Dashboard);
