import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AircraftTab from './AircraftTab/AircraftTab';
import AlertTab from './AlertTab/AlertTab';
import CrewTab from './CrewTab/CrewTab';
import FlightTab from './FlightTab/FlightTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import PassengerTab from './PassengerTab/PassengerTab';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './FlightDetailPane.css';
import { getRevenueScores } from '../../../services/apiClient/scoresApi/scoresApi';
import { CSSTransition } from 'react-transition-group';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { Treatment } from '../../../lib/constants';
import { getMonthDayDisplayString, getPhaseOfFlightLabel } from '../../../lib/displayUtils';
import { getPhaseOfFlight } from '../../../lib/utils';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { makeStyles } from '@material-ui/core/styles';
import { useFlightDetailsQuery } from '../../../react-query/useFlightsQuery/useFlightsQuery';
// Text separator, renders " | "

const useStyles = makeStyles(() => ({
  rightArrow: {
    color: 'var(--gray)',
  },
}));

const separator = <span data-cy="pane-header-text-separator" className="pane-header-text-separator"></span>;

const FlightDetailPane = ({ onCloseClick }) => {
  const [props, setProps] = useState({ show: false });
  const [activeKey, setActiveKey] = useState('Flight');
  const [scoreDetails, setScoreDetails] = useState(null);
  const { isReady } = useContext(SplitContext);
  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const treatments = useTreatments([Treatment.FLIGHT_DETAIL_PANE]);
  const flightTabsFlag = isReady && treatments[Treatment.FLIGHT_DETAIL_PANE].treatment === 'on';
  const [activityLogTabFlag, setActivityLogTabFlag] = useState(false);
  const [scoreLoading, setScoreLoading] = useState(true);

  // SelectedFlightdetail hooks and states
  const selectedFlightDetails = useSelectedItemStore();
  const { updateSelectedFlightDetails, clearSelectedFlightDetails } = useSelectedItemDispatch();
  const selectedFlightLeg = selectedFlightDetails.data;

  const { isLoading: flightLoading, data: flightDetailsData } = useFlightDetailsQuery(
    selectedFlightLeg?.flightNumber,
    selectedFlightLeg?.departureDate,
    selectedFlightLeg?.orig,
    selectedFlightLeg?.dest,
    selectedFlightLeg?.departureCount,
    selectedFlightLeg?.airline,
    activeKey === 'Flight',
  );

  const classes = useStyles();
  // Text separator, renders " -> "
  const arrowSeparator = <FontAwesomeIcon className={classes.rightArrow} icon={faArrowRight} />;

  useEffect(() => {
    setScoreLoading(true);

    const fetchRevenueScores = async () => {
      const response = await getRevenueScores([{ ...selectedFlightLeg }]);

      if (response !== null) {
        setScoreDetails(response);
      }
      setScoreLoading(false);
    };

    if (selectedFlightLeg && flightDetailsData && Object.keys(flightDetailsData).length != 0) {
      fetchRevenueScores();
    }
  }, [selectedFlightLeg, flightDetailsData]);

  useEffect(() => {
    let isFlightPanelOpen = activeKey === 'Flight';
    let isActivityTabOpen = activeKey === 'ActivityLog';

    if (isActivityTabOpen) {
      setActivityLogTabFlag(true);
    }

    updateSelectedFlightDetails({
      data: selectedFlightLeg,
      isFlightPanelOpen: isFlightPanelOpen,
      isActivityTabOpen: isActivityTabOpen,
    });
  }, [activeKey]);

  const handleOnSelect = (key) => {
    setActiveKey(key);
  };

  const handleClose = () => {
    setProps({ show: false });
    onCloseClick();
    /* clear selected Flight Store */
    clearSelectedFlightDetails();
  };

  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('FlightPanePage');

  let flightNumber = '';

  let origin = '';
  let destination = '';
  let flightDateAndDay = '';
  let phaseOfFlight = '';

  if (selectedFlightLeg && selectedFlightLeg.flightLegKey) {
    flightNumber = `${selectedFlightLeg.airline} ${selectedFlightLeg.flightNumber}`;
    origin = selectedFlightLeg.orig;
    destination = selectedFlightLeg.projectedDestination ?? (!!selectedFlightLeg.dest ? selectedFlightLeg.dest : '');
    flightDateAndDay = `${getMonthDayDisplayString(selectedFlightLeg.departureDate)} (Z)`;

    if (!flightDetailsData || flightDetailsData?.times?.length !== 4) {
      phaseOfFlight = '';
    } else {
      const flightLegOooiTimes = {
        atd: flightDetailsData?.times[0].actualTimeUTC,
        off: flightDetailsData?.times[1].actualTimeUTC,
        on: flightDetailsData?.times[2].actualTimeUTC,
        ata: flightDetailsData?.times[3].actualTimeUTC,
      };

      phaseOfFlight = `${getPhaseOfFlightLabel(getPhaseOfFlight(flightLegOooiTimes))}`;
    }
  }
  return (
    <div className="flight-pane-container" data-cy="flight-pane-container">
      <div className="flight-pane-header" data-cy="flight-pane-header">
        <div className="flight-pane-header-left" data-cy="flight-pane-header-left">
          {flightDateAndDay}
          {separator}
          {flightNumber}
          {separator}
          {origin} {arrowSeparator} {destination}
          {separator}
          {phaseOfFlight}
        </div>
        <div className="flight-pane-header-right" data-cy="flight-pane-header-right">
          <FontAwesomeIcon
            data-cy="close-detail-pane"
            className="close-detail-pane"
            icon={faTimes}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <CSSTransition in={props.show} appear={true} timeout={300} classNames="fade">
        <div className="flight-pane-content-tab" data-cy="flight-pane-content-tabs">
          <Tabs activeKey={activeKey} onSelect={handleOnSelect} id="flight-detail-pane-tabs">
            <Tab eventKey="Flight" title="Flight" data-cy="FlightTab">
              {activeKey === 'Flight' && (
                <FlightTab
                  flightDetails={flightDetailsData}
                  scoreDetails={scoreDetails}
                  flightLoading={flightLoading}
                  scoreLoading={scoreLoading}
                />
              )}
            </Tab>
            {flightTabsFlag && (
              <Tab eventKey="Alerts" title="Alerts" data-cy="AlertsTab">
                <AlertTab flightDetails={flightDetailsData} loading={flightLoading} />
              </Tab>
            )}
            <Tab eventKey="ActivityLog" title="Activity Log" data-cy="ActivityLogTab">
              {activityLogTabFlag && <ActivityLogTab />}
            </Tab>
            {flightTabsFlag && (
              <Tab eventKey="Passenger" title="Passenger" data-cy="PassengerTab">
                <PassengerTab />
              </Tab>
            )}
            {flightTabsFlag && (
              <Tab eventKey="Aircraft" title="Aircraft" data-cy="AircraftTab">
                <AircraftTab />
              </Tab>
            )}
            {flightTabsFlag && (
              <Tab eventKey="Crew" title="Crew" data-cy="CrewTab">
                <CrewTab />
              </Tab>
            )}
          </Tabs>
        </div>
      </CSSTransition>
    </div>
  );
};

FlightDetailPane.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(FlightDetailPane);
