import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { hasCodeInUrl } from '../../lib/utils';
import {
  signinRedirectCallback,
  isAuthenticated,
  signinRedirect,
  signOut,
  getUser,
} from '../../services/authenticationService/authenticationService';

const AuthenticationContext = createContext({ userData: null, userUUID: null });

/**
 * The AuthenticationProvider component handles user authentication.
 * It will redirect the user to sign in as needed and will
 * not render the children props if the user is not authenticated.
 * @param {Node} children - the components to be rendered if authenticated (i.e. <App />).
 */
export const AuthenticationProvider = ({ children }) => {
  const history = useHistory();
  const [userData, setUserData] = useState(null);

  const authSignOut = async () => {
    try {
      await signOut();
      setUserData(null);
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let mounted = true;

    const authenticateUser = async () => {
      let user = null;
      if (hasCodeInUrl(window.location.search)) {
        // returning from OIDC client - complete sign in
        user = await signinRedirectCallback();
      } else {
        const authenticated = await isAuthenticated();
        if (authenticated) {
          user = await getUser();
        } else {
          localStorage.clear();
          localStorage.setItem('redirectUri', window.location.pathname);
          await signinRedirect();
          return;
        }
      }

      // https://www.debuggr.io/react-update-unmounted-component/
      if (mounted) {
        // woo authenticated!!!
        setUserData(user);

        let redirectUri = localStorage.getItem('redirectUri');
        localStorage.removeItem('redirectUri');
        if (redirectUri && history.location.pathname !== redirectUri) {
          history.push(redirectUri);
        }
      }
    };

    authenticateUser();
    return () => (mounted = false);
  }, [history]);

  return (
    <AuthenticationContext.Provider value={{ userData, authSignOut }}>
      {userData && children}
    </AuthenticationContext.Provider>
  );
};

// Custom hook that exposes the AuthenticationContext
// Allows lower components to access AuthenticationProvider's userData (openID profile) and the signOut function
export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider. Wrap a parent component in <AuthenticationProvider> to fix this error.',
    );
  }
  return context;
};
