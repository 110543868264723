import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './FlightInfoTabs.css';
import FlightInfoTables from './FlightInfoTables/FlightInfoTables';
import mockFlightInfoApi from '../../../../../../services/apiClient/mocks/mockFlightInfoApi.json';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`flightinfo-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function applyProps(index) {
  return {
    id: `flightinfo-tab-${index}`,
    'aria-controls': `flightinfo-tabpanel-${index}`,
  };
}

export default function FlightInfoTabs({ isDeadheadsExpanded }) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`flight-info-tabs-container ${isDeadheadsExpanded ? 'deadheads-expanded' : ''}`}>
      <Tabs className="flightinfo-tabs" value={value} onChange={handleChange} aria-label="flight info tabs">
        <Tab className="flightinfo-tab" label="Yesterday" {...applyProps(0)} />
        <Tab className="flightinfo-tab" label="Today" {...applyProps(1)} />
        <Tab className="flightinfo-tab" label="Tomorrow" {...applyProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <FlightInfoTables
          flightInfo={mockFlightInfoApi.flightInfo}
          trip={mockFlightInfoApi.trip}
          date={mockFlightInfoApi.date}
          columnNames={['AIRCRAFT', 'FLIGHT', 'ORIGIN', 'DEPART', 'DESTIN', 'ARRIVAL']}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FlightInfoTables
          flightInfo={mockFlightInfoApi.flightInfo}
          trip={mockFlightInfoApi.trip}
          date={mockFlightInfoApi.date}
          columnNames={['AIRCRAFT', 'FLIGHT', 'ORIGIN', 'DEPART', 'DESTIN', 'ARRIVAL']}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FlightInfoTables
          flightInfo={mockFlightInfoApi.flightInfo}
          trip={mockFlightInfoApi.trip}
          date={mockFlightInfoApi.date}
          columnNames={['AIRCRAFT', 'FLIGHT', 'ORIGIN', 'DEPART', 'DESTIN', 'ARRIVAL']}
        />
      </TabPanel>
    </div>
  );
}
