import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'style';
const moduleName = 'stylesApi';

/**
 * Returns styles for station and aircraft labels for classic theme
 */
export const getClassicStyle = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/styles/classic`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getClassicStyle', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  return result;
};
