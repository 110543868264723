import { FlightView, RemInPixels, ViewWindowTitle } from '../../lib/constants';
import { WindowManagerAction } from '../actionTypes';

// Default viewWindow object
const defaultViewWindow = {
  id: 0,
  viewTitle: ViewWindowTitle.DEFAULT,
  viewType: FlightView.GANTT_VIEW,
  viewPosition: { x: 0, y: 0 },
  viewSize: { width: 800, height: 600 },
};

const getInitialState = () => {
  // Initialize viewWindows with values from sessionStorage if available
  const viewWindowsFromSessionStorage = JSON.parse(sessionStorage.getItem('windowManagerStore'));
  if (viewWindowsFromSessionStorage) {
    return viewWindowsFromSessionStorage;
  }
  return {
    viewWindows: [defaultViewWindow],
    viewOrder: [0],
    minimizedViews: [],
    maximizedViews: [],
    draggedMinimizedViews: [],
  };
};

// Reducer function
function windowManagerReducer(state = getInitialState(), action) {
  switch (action.type) {
    case WindowManagerAction.ADD_VIEW_WINDOW:
      const newId = state.viewWindows.reduce((acc, curr) => (curr.id >= acc ? curr.id + 1 : acc), 0);
      const newViewTitle = `${ViewWindowTitle.DEFAULT} (${newId})`;

      const newViewWindow = {
        ...defaultViewWindow,
        viewTitle: newViewTitle,
        id: newId,
      };
      return {
        ...state,
        viewWindows: [...state.viewWindows, newViewWindow],
        viewOrder: [newId, ...state.viewOrder],
      };

    case WindowManagerAction.REMOVE_VIEW_WINDOW: {
      const updatedViewWindows = state.viewWindows.filter((view) => view.id !== action.id);
      const updatedViewOrder = state.viewOrder.filter((id) => id !== action.id);
      const updatedMinimizedViews = state.minimizedViews.filter((id) => id !== action.id);
      const updatedMaximizedViews = state.maximizedViews.filter((id) => id !== action.id);
      const updatedDraggedMinimizedViews = state.draggedMinimizedViews.filter((id) => id !== action.id);
      return {
        ...state,
        viewWindows: updatedViewWindows,
        viewOrder: updatedViewOrder,
        minimizedViews: updatedMinimizedViews,
        maximizedViews: updatedMaximizedViews,
        draggedMinimizedViews: updatedDraggedMinimizedViews,
      };
    }

    case WindowManagerAction.UPDATE_VIEW_WINDOW:
      const updatedWindows = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: updatedWindows,
      };

    case WindowManagerAction.SET_ACTIVE_VIEW:
      const newViewOrder = state.viewOrder.filter((id) => id !== action.id);
      return {
        ...state,
        viewOrder: [action.id, ...newViewOrder],
      };

    case WindowManagerAction.SET_MINIMIZED_VIEW:
      const newStateAfterSetMinimized = {
        ...state,
        minimizedViews: [...state.minimizedViews, action.id],
      };
      return newStateAfterSetMinimized;

    case WindowManagerAction.REMOVE_MINIMIZED_VIEW: {
      const newStateAfterRemoveMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: state.draggedMinimizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMinimized;
    }

    case WindowManagerAction.SET_DRAGGED_MINIMIZED_VIEW: {
      const newStateAfterSetDraggedMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: [...state.draggedMinimizedViews.filter((id) => id !== action.id), action.id],
      };
      return newStateAfterSetDraggedMinimized;
    }

    case WindowManagerAction.SET_MAXIMIZED_VIEW:
      const newStateAfterSetMaximized = {
        ...state,
        maximizedViews: [...state.maximizedViews, action.id],
      };
      return newStateAfterSetMaximized;

    case WindowManagerAction.REMOVE_MAXIMIZED_VIEW: {
      const newStateAfterRemoveMaximized = {
        ...state,
        maximizedViews: state.maximizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMaximized;
    }

    case WindowManagerAction.RENAME_VIEW_WINDOW: {
      const renameWindow = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: renameWindow,
      };
    }

    case WindowManagerAction.ARRANGE_VIEWS_CASCADE: {
      const { width: defaultWidth, height: defaultHeight } = defaultViewWindow.viewSize;
      const viewWindowElement = document.getElementsByClassName('view-window')[0];
      const isTailIndicatorsEnabled = viewWindowElement.classList.contains('tail-indicators-enabled');
      if (
        !viewWindowElement ||
        !getComputedStyle(viewWindowElement)?.getPropertyValue(
          isTailIndicatorsEnabled ? '--aircraft-label-width-with-indicators' : '--aircraft-container-width',
        )
      ) {
        return state;
      }
      const aircraftLabelWidthRem = getComputedStyle(viewWindowElement).getPropertyValue(
        isTailIndicatorsEnabled ? '--aircraft-label-width-with-indicators' : '--aircraft-container-width',
      );
      let aircraftLabelWidth = parseFloat(aircraftLabelWidthRem) * RemInPixels.ONE_REM_IN_PX;
      if (isTailIndicatorsEnabled) {
        aircraftLabelWidth += RemInPixels.ONE_REM_IN_PX + 4;
      }
      const toolbarHeight = parseInt(getComputedStyle(document.getElementById('toolbar')).height);
      return {
        ...state,
        viewOrder: state.viewOrder.sort((a, b) => b - a),
        minimizedViews: [],
        maximizedViews: [],
        draggedMinimizedViews: [],
        viewWindows: state.viewWindows.map((window, index) => ({
          ...window,
          viewPosition: { x: aircraftLabelWidth * index, y: index * toolbarHeight },
          viewSize: { width: defaultWidth, height: defaultHeight },
        })),
      };
    }

    default:
      return state;
  }
}

export default windowManagerReducer;
