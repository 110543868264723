import { useQuery } from 'react-query';
import { getFleetTypes } from '../../services/apiClient/aircraftInformationApi/aircraftInformationApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useAircraftInformationQuery';

/**
 * @description Custom hook for retrieving data from AircraftInformation API.
 * @param {string} airlineCode - airline code.
 */
export const useAircraftInformationQuery = (airlineCode, enabled = true) => {
  const config = {
    // Setting stale time to 5 minutes because AircraftInformation fleet data rarely changes.
    // This means that when any component mounts using this hook within the "fresh" time,
    // it will not fetch new data.
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY,
    refetchOnWindowFocus: false,
    enabled: enabled,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getFleetTypes', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['fleetTypes', airlineCode], () => getFleetTypes(airlineCode), config);
};
