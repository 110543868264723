import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ReactComponent as GanttIcon } from '../../../../assets/images/gantt-icon.svg';
import { ReactComponent as ListIcon } from '../../../../assets/images/list-icon.svg';
import { FlightView } from '../../../../lib/constants';
import './NavButtons.css';

const NavButtons = ({ viewType, isSwapModeActive, onViewTypeIconClick, handleKeyDown, showMultipleViews }) => {
  const ganttStyle = viewType === FlightView.GANTT_VIEW ? 'selected' : '';
  let flightsStyle = '';

  if (isSwapModeActive) {
    flightsStyle = 'disabled';
  } else if (viewType === FlightView.TABLE_VIEW) {
    flightsStyle = 'selected';
  }

  const listIconComp = (
    <ListIcon
      data-cy={`List-view-logo${viewType === FlightView.TABLE_VIEW ? '-selected' : ''}`}
      className={`flight-list-icon ${flightsStyle}`}
    />
  );

  const ganttIconComp = (
    <GanttIcon
      data-cy={`gantt-view-logo${viewType === FlightView.GANTT_VIEW ? '-selected' : ''}`}
      className={`gantt-icon ${ganttStyle}`}
    />
  );

  const navSectionLegacy = (
    <>
      <NavLink
        data-cy="flight-gantt-view-link"
        className={`flight-nav-bar-item gantt-icon-container ${ganttStyle}`}
        to="/flights?view=gantt"
        exact="true"
      >
        {ganttIconComp}
      </NavLink>
      <NavLink
        data-cy="flight-table-view-link"
        className={`flight-nav-bar-item ${flightsStyle} flight-list-icon-container`}
        to="/flights?view=table"
        onKeyDown={(e) => handleKeyDown(e)}
        exact="true"
      >
        {listIconComp}
      </NavLink>
    </>
  );

  const navSectionMultipleViews = (
    <>
      <button
        data-cy="flight-gantt-view-link"
        className={`flight-nav-bar-item gantt-icon-container ${ganttStyle}`}
        onClick={() => onViewTypeIconClick(FlightView.GANTT_VIEW)}
      >
        {ganttIconComp}
      </button>
      <button
        data-cy="flight-table-view-link"
        className={`flight-nav-bar-item ${flightsStyle} flight-list-icon-container`}
        onClick={() => onViewTypeIconClick(FlightView.TABLE_VIEW)}
      >
        {listIconComp}
      </button>
    </>
  );

  return showMultipleViews ? navSectionMultipleViews : navSectionLegacy;
};

export default NavButtons;

NavButtons.propTypes = {
  viewType: PropTypes.number,
  isSwapModeActive: PropTypes.bool,
  onViewTypeIconClick: PropTypes.func,
  handleKeyDown: PropTypes.func,
  showMultipleViews: PropTypes.bool,
};
