import React from 'react';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';

import './AppLoadFailPage.css';

const SERVICE_DESK_LINK = 'mailto:its.service.desk@alaskaair.com';

/**
 * @description Page for when authorization or assignment API has an error
 * @returns Page with a message telling user they couldn't be authorized
 */
const AppLoadFail = () => {
  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('AppLoadFailPage');

  return (
    <div data-cy="app-load-failure-page-message" className="app-load-fail">
      <span>Unable to verify your authorization to AirtrAK at this time.</span>
      <span>
        Please try again or contact <a href={SERVICE_DESK_LINK}> ITS Service Desk. </a>
      </span>
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(AppLoadFail);
