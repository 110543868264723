import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'administration';
const moduleName = 'administrationApi';

/**
 * Returns a list of roles and the current authorized users
 */
export const getAllAuthorizedUsers = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/users`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getAllAuthorizedUsers', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Create a new authorized user.
 * @param {string} userId
 * @param {string} userName
 * @param {string} createdBy
 * @param {Array} roleKeys
 * @returns Returns an apiStatusResult with success or failure
 */
export const createAuthorizedUser = async (userId, userName, createdBy, roleKeys) => {
  var user = {
    userId: userId,
    userName: userName,
    createdOrModifiedBy: createdBy,
    roleKeys: roleKeys,
  };

  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/users`;
    const response = await apiClient.post(url, user);
    result = response.data;
    //console.log("CREATE ASSIGNMENTS RESULT: " + JSON.stringify(result));
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'createAuthorizedUser', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Update an authorized user's record.
 * @param {Number} userKey - The unique key for the user
 * @param {string} userId - The employee id for the user
 * @param {string} userName - The user's name
 * @param {string} createdBy - the name of the user modifying the record.
 * @param {Array} roleKeys - Role keys associated to this user.
 * @returns Returns an apiStatusResult with success or failure
 */
export const updateAuthorizedUser = async (userKey, userId, userName, createdBy, roleKeys) => {
  var user = {
    userKey: userKey,
    userId: userId,
    userName: userName,
    createdOrModifiedBy: createdBy,
    roleKeys: roleKeys,
  };

  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/users`;
    const response = await apiClient.put(url, user);
    result = response.data;
    //console.log("UPDATE ASSIGNMENTS RESULT: " + JSON.stringify(result));
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'updateAuthorizedUser', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description  Returns an apiStatusResult with success or failure
 * @param {string} userId - The user's employeeId.
 */
export const deleteAuthorizedUser = async (userId) => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/users/${userId}`;
    const response = await apiClient.delete(url);
    result = response.data;
    //console.log("DELETE ASSIGNMENTS RESULT: " + JSON.stringify(result));
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'deleteAuthorizedUser', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Returns the roles and assignments for admin managing roles
 */
export const getAllRoles = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/roles`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getAllRoles', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Creates a new AssignmentRole and associated assignmentGroup and RoleEntitlement
 * @param {object} assignmentRole - AssignmentRole object with assignmentGroupKeys and RoleEntitlement
 * @param {string} adminUserName - Currrent user's userName
 * @returns {Object} ApiStatusResult with success or failure
 */
export const createRole = async (assignmentRole, adminUserName) => {
  assignmentRole.CreatedOrModifiedBy = adminUserName;
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/roles`;
    const response = await apiClient.post(url, assignmentRole);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'createRole', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Updates an existing AssignmentRole and associated assignmentGroup and RoleEntitlement
 * @param {object} assignmentRole - AssignmentRole object with assignmentGroupKeys and RoleEntitlement
 * @param {string} adminUserName - Currrent user's userName
 * @returns {Object} ApiStatusResult with success or failure
 */
export const updateRole = async (assignmentRole, adminUserName) => {
  assignmentRole.CreatedOrModifiedBy = adminUserName;
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/roles`;
    const response = await apiClient.put(url, assignmentRole);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'updateRole', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Get the assignments and criterias for admin managing assignments
 * @returns Array of objects.
 */
export const getAllAssignments = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getAllAssignments', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Creates a new AssignmentGroup and associated Criteria
 * @param {object} assignmentGroup - AssignmentGrop object with Criteria
 * @returns {Object} ApiStatusResult with success or failure
 */
export const createAssignment = async (assignmentGroup) => {
  console.log('CREATE ASSIGNMENT (PARAM): ', assignmentGroup);
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments`;
    const response = await apiClient.post(url, assignmentGroup);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'createAssignment', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Updates an existing AssignmentGroup and associated Criteria
 * @param {object} assignmentGroup - AssignmentGrop object with Criteria
 * @returns {Object} ApiStatusResult with success or failure
 */
export const updateAssignment = async (assignmentGroup) => {
  //console.log("UPDATE ASSIGNMENT (PARAM): ", assignmentGroup);
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments`;
    const response = await apiClient.put(url, assignmentGroup);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'updateAssignment', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
