/**
 * @description Selected Flight State used for dispatch of Selected Flight Reducer
 * @enum {string}
 */
export const SelectedFlightDetailsState = {
  UPDATE: 'UPDATE_DETAILS',
  CLEAR: 'CLEAR_DETAILS',
  SELECTED: 'IS_SELECTED',
};

/**
 * @description Preference Type used for User Preferences
 * @enum {string}
 */
export const PreferenceType = {
  ROLE_ASSIGNMENTS: 'RoleAssignments',
  THEME: 'Theme',
  PUCK_SIZE: 'PuckSize',
  TIMELINE_HOURS: 'TimelineHours',
  GANTT_VIEW_SCALING: 'GanttViewScaling',
};

/**
 * @description User Preference State used in dispatch of User Preference Reducer
 * @enum {string}
 */
export const UserPreferenceState = {
  NONE: 'NO_USER_PREFERENCES',
  RETRIEVING: 'RETRIEVING_USER_PREFERENCES',
  RETRIEVED: 'RETRIEVED_USER_PREFERENCES',
  UPDATING: 'UPDATING_USER_PREFERENCES',
  UPDATED: 'UPDATED_USER_PREFERENCES',
  UPDATING_SILENT: 'UPDATING_USER_PREFERENCES_SILENT',
  ERROR: 'ERROR_RETRIEVING_USER_PREFERENCES',
};
