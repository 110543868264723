import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import lodashOrderBy from 'lodash/orderBy';
import lodashGet from 'lodash/get';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { getAllAuthorizedUsers } from '../../../services/apiClient/administrationApi/administrationApi';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import { userRolesTableConfig } from './userRolesTableConfig';
import Button from '../../Shared/Button/Button';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import UserForm from './UserForm/UserForm';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../lib/constants';

import '../administration.css';

const ManageUsers = () => {
  const Ascending = 'asc';
  const Descending = 'desc';

  // state
  const [loading, setLoading] = useState(true);
  const [rolesData, setRolesData] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const [orderBy, setOrderBy] = useState('userName');
  const [orderDirection, setOrderDirection] = useState(Ascending);
  const [showUserForm, setShowUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('Admin - Manage Users Page');

  // Call the authorization API
  useEffect(() => {
    const getUsers = async () => {
      const result = await getAllAuthorizedUsers();
      if (result != null) {
        setUserRolesData(result.users);
        setRolesData(result.roles);
        setInitialSort(result.users);
      }
      setLoading(false);
    };

    getUsers();
  }, []);

  const setInitialSort = (users) => {
    // Start sort
    const sortedData = lodashOrderBy(users, [(row) => lodashGet(row, 'userName')?.toLowerCase()], [Ascending]);
    setUserRolesData(sortedData);
    setOrderBy('userName');
    setOrderDirection(Ascending);
  };

  /**
   * @description Handle a sort request by the user clicking a column header.
   * @param {string} columnKey - The key name of the column to be sorted.
   * @param {boolean} retainSortFlag - Flag for retaining the current sorting direction.
   */
  const handleSort = (columnKey) => {
    const isSortedAscending = orderBy === columnKey && orderDirection === Ascending;

    // Change the sort direction if we aren't specifying that the sort should remain the same.
    const newOrderDirection = isSortedAscending ? Descending : Ascending;

    // Start sort
    const sortedData = lodashOrderBy(
      userRolesData,
      [(row) => lodashGet(row, columnKey)?.toLowerCase()],
      [newOrderDirection],
    );
    setUserRolesData(sortedData);
    setOrderBy(columnKey);
    setOrderDirection(newOrderDirection);
  };

  // Place holder for handling row click
  const handleEditClick = (row, col) => {
    if (col.columnKey === 'edit') {
      // get all of the role keys associated to this user
      // editing the user and all their roles, not just the role listed in the row that was clicked.
      const userRoles = userRolesData.filter((ur) => ur.userKey === row.userKey);
      const userRoleKeys = userRoles.map((r) => r.roleKey);

      //{userKey: 412, userName: "Andrew Regier", userID: "1063206", userRoleKey: 839, roleKey: 9,
      const user = {
        userKey: row.userKey,
        userName: row.userName,
        userID: row.userID,
        roleKeys: [...userRoleKeys],
      };

      setSelectedUser(user);
      setShowUserForm(true);
    }
  };

  const handleAddUserClick = () => {
    setSelectedUser(null);
    setShowUserForm(true);
  };

  const handleUserCancel = () => {
    setSelectedUser(null);
    setShowUserForm(false);
  };

  // Refresh data after data is saved in the UserForm
  const handleUserSave = () => {
    // call get all users API again and refresh the table
    const getUsers = async () => {
      const result = await getAllAuthorizedUsers();
      if (result != null) {
        setUserRolesData(result.users);
        setRolesData(result.roles);
        setInitialSort(result.users);
      }
      setLoading(false);
    };

    // reset states
    setLoading(true);
    setUserRolesData([]);
    setRolesData([]);
    setShowUserForm(false);
    setSelectedUser(null);

    // call get
    getUsers();
  };

  let contentToLoad;

  if (loading) {
    contentToLoad = <LoadingIndicator />;
  } else if (userRolesData && rolesData) {
    contentToLoad = (
      <div className="manage-users-content">
        {showUserForm && (
          <UserForm
            roles={rolesData}
            existingUser={selectedUser}
            onSave={handleUserSave}
            onCancel={handleUserCancel}
            data-cy="user-form"
          />
        )}
        <Button variant="primary" isDisabled={false} onClick={handleAddUserClick} data-cy="add-user-button">
          Add User
        </Button>
        <TableContainer className="admin-table-container" data-cy="user-role-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* define table headers */}
                {userRolesTableConfig.map((col) => (
                  <TableCell key={col.columnKey} data-cy="admin-table-header">
                    <TableSortLabel
                      active={orderBy === col.columnKey}
                      direction={orderBy === col.columnKey ? orderDirection : Ascending}
                      onClick={() => handleSort(col.columnKey)}
                      data-cy={
                        orderBy === col.columnKey
                          ? col.columnKey + '-sort-' + orderDirection
                          : col.columnKey + '-unsorted'
                      }
                    >
                      {col.columnDisplayName}{' '}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-cy="admin-table-list-body">
              {/* render each row (user) */}
              {userRolesData.map((row) => (
                // <TableRow key={row.userRoleKey} onClick={() => handleRowClick(row)}>
                <TableRow key={row.userRoleKey}>
                  {/* render each data cell in a row  */}
                  {userRolesTableConfig.map((col, index) => (
                    <TableCell key={`${col.columnKey}-${index}`} onClick={() => handleEditClick(row, col)}>
                      {col.renderFn != null && col.renderFn(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    contentToLoad = (
      <div className="manage-users-content" data-cy="manage-users-nodata">
        Unable to retrieve users. Please refresh.
      </div>
    );
  }

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <div data-cy="admin-users-page" className="admin-page">
        {contentToLoad}
      </div>
    </AocAuthAccessCheck>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(ManageUsers);
