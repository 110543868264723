import React, { useState, useCallback, useEffect } from 'react';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import FlightListTableContainer from '../FlightListTableContainer/FlightListTableContainer';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import './Flights.css';
import GanttChart from '../GanttChart/GanttChart';
import { FlightView, SessionStorageKeys, DateFormat, Treatment, SummaryPanelMode } from '../../../lib/constants';
import FlightDetailPane from '../FlightDetailPane/FlightDetailPane';
import SummaryPanel from '../SummaryPanel/SummaryPanel';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { useFilterStore } from '../../../hooks/useFilterStore/useFilterStore';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { useLocation } from 'react-router-dom';
import { FlightNavigationBar } from '../FlightNavigationBar/FlightNavigationBar';
import { SwapFooter } from '../SwapMode/SwapFooter/SwapFooter';
import { useSelectedItemStore } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useSummaryPanelDispatch } from '../../../hooks/useSummaryDataStore/useSummaryDataStore';

const defaultSort = {
  orderBy: 'etd',
  orderDirection: 'asc',
};

/**
 * The Flights component is the container for the table and gantt view, flight list detail pane,
 * and filters. It's responsible for providing filtered flights and ground events to child components
 * and manage toggling of the flight detail pane (and and... this component has too many responsibilities...)
 * @returns {Flights} - the FlightListTableView component
 */
export const Flights = ({ onShowHideHeader = () => {}, showHeader }) => {
  // ************** HOOKS AND STATE ***********************

  // feature flag
  const { showFeature } = useFeatureFlag();
  const isSummaryPanelAvailable = showFeature(Treatment.FLIGHT_DETAIL_SUMMARY);

  // filter
  const { filter } = useFilterStore();
  const [isPaneOpen, setFlightPane] = React.useState(false); // Note: this syntax is used to spy during a test
  const [summaryPanelMode, setSummaryPanelMode] = useState(SummaryPanelMode.MINI_SUMMARY);
  const [activityKey, setActivityKey] = useState('Flight');
  // useSwapModeStore
  const { isSwapModeActive } = useSwapModeStore();
  // handle to set the now line position
  // SelectedFlightdetail hooks and states

  const selectedFlightDetails = useSelectedItemStore();

  const { updateSummaryPanelMode } = useSummaryPanelDispatch();
  const [summaryPanelHeight, setSummaryPanelHeight] = useState(0);

  useEffect(() => {
    if (!selectedFlightDetails.data) {
      setSummaryPanelMode(SummaryPanelMode.MINI_SUMMARY);
    }
  }, [selectedFlightDetails]);

  const flightToolkitActiveClassHandler = () => {
    let classes = '';
    classes = isSwapModeActive ? ' swap-mode-active' : '';
    if (isSummaryPanelAvailable) {
      classes += summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? ' summary-panel-mini' : ' summary-panel-expanded';
    }
    return classes;
  };
  // sorting state
  const savedSort = window.sessionStorage.getItem(SessionStorageKeys.TABLE_SORT);
  const [sort, setSort] = useState(savedSort ? JSON.parse(savedSort) : defaultSort);
  // clear auto refresh key from session storage
  window.sessionStorage.removeItem(SessionStorageKeys.AUTO_REFRESH);

  //Read query params to know the default view page
  const { search: queryParams } = useLocation();
  const queryParamView = new URLSearchParams(queryParams).get('view');

  // Toggle gantt and table view state
  const viewType = queryParamView && queryParamView !== 'gantt' ? FlightView.TABLE_VIEW : FlightView.GANTT_VIEW;

  const handleFlightPaneCloseClick = useCallback(() => {
    setFlightPane(false);
  }, []);

  /**
   * Event handler for when new table sort is applied. Saves the sort into session storage.
   * NOTE: Actual sort happens in flightlisttable.js dispatch
   */
  const handleSortClick = useCallback(
    (orderBy, orderDirection) => {
      const selectedSort = { orderBy: orderBy, orderDirection: orderDirection };
      window.sessionStorage.setItem(SessionStorageKeys.TABLE_SORT, JSON.stringify(selectedSort));

      if (JSON.stringify(sort) !== JSON.stringify(selectedSort)) {
        setSort({ orderBy: orderBy, orderDirection: orderDirection });
      }
    },
    [sort],
  );

  const clearSort = () => {
    // clear filters and sort from session storage
    window.sessionStorage.setItem(SessionStorageKeys.TABLE_SORT, JSON.stringify({ ...defaultSort }));

    setSort({ ...defaultSort });
  };

  // HANDLE SUMMARY PANEL MODE CHANGE
  const handleChangeSummaryPanelMode = () => {
    setSummaryPanelMode((m) => {
      if (m === SummaryPanelMode.MINI_SUMMARY) {
        updateSummaryPanelMode({ panelMode: SummaryPanelMode.EXPANDED_DETAIL });
        return SummaryPanelMode.EXPANDED_DETAIL;
      } else {
        handleChangeActivityKey('Flight');
        updateSummaryPanelMode({ panelMode: SummaryPanelMode.MINI_SUMMARY });
        return SummaryPanelMode.MINI_SUMMARY;
      }
    });
  };

  const handleChangeSummaryPanelHeight = (height) => {
    setSummaryPanelHeight(height);
  };

  // HANDLE ACTIVITY KEY CHANGE
  const handleChangeActivityKey = (activityKey) => {
    setActivityKey(activityKey);
    setSummaryPanelMode(activityKey == null ? SummaryPanelMode.MINI_SUMMARY : SummaryPanelMode.EXPANDED_DETAIL);
  };

  /**
   * Event handler to open the detail pane
   */
  const handleOpenDetailPane = useCallback(() => {
    setFlightPane(true);
  }, []);

  /**
   * Creates the left content (the gantt or table view)
   */
  const createLeftContent = () => {
    if (viewType === FlightView.TABLE_VIEW) {
      return (
        <FlightListTableContainer
          savedSort={sort}
          openDetailPane={handleOpenDetailPane}
          isPaneOpen={isPaneOpen}
          summaryPanelMode={summaryPanelMode}
          onSortClick={handleSortClick}
          handleChangeActivityKey={handleChangeActivityKey}
        />
      );
    } else {
      const startDate = formatDateTime(filter.startDate, DateFormat.DEFAULT);
      const endDate = formatDateTime(filter.endDate, DateFormat.DEFAULT);
      return (
        <GanttChart
          startDate={startDate}
          endDate={endDate}
          openDetailPane={handleOpenDetailPane}
          isPaneOpen={isPaneOpen}
          summaryPanelMode={summaryPanelMode}
          handleChangeActivityKey={handleChangeActivityKey}
          summaryPanelHeight={summaryPanelHeight}
        />
      );
    }
  };

  // gantt or table view section
  const leftContent = createLeftContent();

  const mainContent = (
    <>
      <div className="flight-list-view-grid" data-cy="flight-list-view-grid">
        {/* left side content */}
        <div className={`grid-col-flight-table${isPaneOpen ? '' : ' full-screen'}`} data-cy="grid-col-flight-table">
          {leftContent}
        </div>
        {/* right side content */}
        {isPaneOpen ? (
          <div className={`grid-col-flight-pane`} data-cy="grid-col-flight-pane">
            <FlightDetailPane onCloseClick={handleFlightPaneCloseClick} />
          </div>
        ) : null}
      </div>
    </>
  );

  return (
    <div data-cy="flights-container" className={`flights-container ${flightToolkitActiveClassHandler()}`}>
      <div className="flights-sub-contianer">
        <FlightNavigationBar
          onShowHideHeader={onShowHideHeader}
          showNavbar={showHeader}
          viewType={viewType}
          clearSort={clearSort}
        />
        <div className="flight-list-view-container new-modal-filters" data-cy="flight-list-view-container">
          <div
            className={
              viewType === FlightView.TABLE_VIEW
                ? 'flight-list-view-table-section flight-view-content'
                : 'flight-view-content'
            }
            data-cy="flight-list-view-table-section"
          >
            {mainContent}
          </div>
        </div>
        {isSwapModeActive && <SwapFooter />}
        {isSummaryPanelAvailable && (
          <SummaryPanel
            summaryPanelMode={summaryPanelMode}
            onChangeSummaryPanelMode={handleChangeSummaryPanelMode}
            onChangedSummaryPanelHeight={handleChangeSummaryPanelHeight}
            activityKey={activityKey}
            handleChangeActivityKey={handleChangeActivityKey}
          ></SummaryPanel>
        )}
      </div>
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Flights);
