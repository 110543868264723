import React, { useState } from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { irregularOps } from '../../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import PropTypes from 'prop-types';
import Button from '../../../Shared/Button/Button';
import {
  KeyCodes,
  IrropsCode,
  EntitlementNames,
  FormDataBrickType,
  IrropFlightStatusErrorMessage,
  IrropsMenuItemAction,
  Treatment,
} from '../../../../lib/constants';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import Modal from '../../../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { canPerformIrrop, getBodyWithSessionId, getFlightDetailBrick } from '../../../../lib/irropUtils';
import { getFlightDetails, headCheckFlightExists } from '../../../../services/apiClient/flightsApi/flightsApi';
import CurrentFlightDetails from '../../../Shared/CurrentFlightDetails/CurrentFlightDetails';
import './ReinstateFlightForm.css';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { uuidv4 } from '../../../../lib/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeader: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  sectionHeaderGrid: {
    paddingBottom: '.55rem !important',
    paddingTop: '.25rem !important',
  },
  brickLabel: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: 'bold',
    marginBottom: '0rem',
  },
  brickLabelData: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: 'normal',
  },
  cfdGridPadding: {
    paddingBottom: '.01rem !important',
  },
}));

const ReinstateFlightForm = ({ reinstateFlight = null, onCommit, onClose, ...props }) => {
  const initialFormData =
    reinstateFlight === null
      ? {
          operatingAirlineCode: '',
          flightNumber: '',
          origin: '',
          destination: '',
          scheduledDestination: '',
          scheduledOperatingDateUTC: '',
          legNumber: '',
          flightDateUtc: '',
          aircraftRegistration: '',
          departure: '',
          arrival: '',
        }
      : reinstateFlight;

  const [show, setShow] = useState(true);
  const classes = useStyles();
  const { trackEvent } = useAppInsightsContext();

  // feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);

  // authorization access hook
  const hasReInstateEntitlement = useAuthorizationAccess(null, [EntitlementNames.REINSTATE]);

  /**
   * @description "Handles the submit api call returns status message"
   */
  const submitReinstateFlightV2 = async () => {
    let result, isExistIrrops;

    trackEvent(`Reinstate - Submit modal for flight number : ${initialFormData.flightNumber.toString()}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    // Restructure cancel flight form data for post to API.
    let flightData = getBodyWithSessionId(
      {
        scheduledOrigin: initialFormData.origin,
        scheduledDestination: initialFormData.scheduledDestination,
        actualOrigin: initialFormData.origin,
        actualDestination: initialFormData.destination,
        flightNumber: initialFormData.flightNumber.toString(),
        legNumber: '', //initialFormData.legNumber,
        operatingAirlineCode: initialFormData.operatingAirlineCode,
        opsType: IrropsCode.REINSTATE_FLIGHT,
        scheduledOperatingDateUTC: initialFormData.scheduledOperatingDateUTC,
        comments: '',
        departureCount: initialFormData.departureCount,
      },
      sessionId,
    );

    // Get current flight details
    const response = await getFlightDetails(
      initialFormData.flightNumber,
      initialFormData.scheduledOperatingDateUTC,
      initialFormData.origin,
      initialFormData.destination,
      initialFormData.departureCount,
      initialFormData.operatingAirlineCode,
    );

    // Check flight leg can perform a given irrop
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.REINSTATE_FLIGHT);

      if (isExistIrrops) {
        // Call the cancel flights API.
        result = await irregularOps(flightData);
        trackEvent(
          `Reinstate - API call to submit reinstate flight details is complete. Status:${
            result?.status
          }, Flight number : ${initialFormData.flightNumber.toString()}`,
        );

        let completeCheckCall = headCheckFlightExists(
          initialFormData.flightNumber,
          initialFormData.scheduledOperatingDateUTC,
          initialFormData.origin,
          initialFormData.destination,
          initialFormData.departureCount,
          initialFormData.operatingAirlineCode,
          IrropsCode.REINSTATE_FLIGHT,
        );

        // Handle result   // Pass result flight action menu for handle notification
        if (result !== null && result.status === 'SUCCESS') onCommit(true, 'Reinstate', '', [completeCheckCall]);
        // Handle toast notification SUCCESS
        else onCommit(false, 'Reinstate', '', []); // Handle toast notification ERROR
      } else {
        trackEvent(
          `Reinstate - ${
            IrropFlightStatusErrorMessage.ERROR
          } Flight number : ${initialFormData.flightNumber.toString()}`,
        );
        onCommit(false, 'Reinstate', IrropFlightStatusErrorMessage.ERROR);
      }
    } else {
      trackEvent(
        `Reinstate - Failed to get flight details for flight number : ${initialFormData.flightNumber.toString()}`,
      );
      onCommit(false, 'Reinstate', '', []);
    }
  };

  /**
   * @description "Handles the submit api call returns status message"
   */

  /**
   * @description "handle Commit Button Click"
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    await submitReinstateFlightV2();

    setShow(false); // Hide The Modal
  };

  /**
   * @description "handle Commit button disabling"
   * @returns boolean
   */
  const isCommitEnabled = () => hasReInstateEntitlement && !commitButtonClicked;

  /**
   * @description hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled()) {
      handleCommitButtonClick();
    }
  };
  /**
   * @description "handle close the modal"
   */
  const handleCancel = () => {
    setShow(false);
    onClose(IrropsCode.REINSTATE_FLIGHT);
  };

  /**
   * @returns current flight detail blocks in array
   */
  const getCurrentFlightDetailBlocks = () => {
    let cfd = [];
    cfd.push(getFlightDetailBrick('Date (Z)', initialFormData.flightDateUtc, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Flight', initialFormData.flightNumber, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Aircraft', initialFormData.aircraftRegistration, null, FormDataBrickType.STRING));
    cfd.push(
      getFlightDetailBrick(
        'Flight Leg',
        initialFormData.origin,
        initialFormData.destination,
        FormDataBrickType.STATION_MULTIPLE,
      ),
    );
    cfd.push(getFlightDetailBrick('ETD', initialFormData.departure, null, FormDataBrickType.TIME));
    cfd.push(getFlightDetailBrick('ETA', initialFormData.arrival, null, FormDataBrickType.TIME));

    return cfd;
  };

  const formHTML = (
    <div className="reinstate-flight-form-modal">
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.sectionHeaderGrid}>
          <div className={classes.sectionHeader}>CURRENT FLIGHT DETAILS</div>
        </Grid>
        <Grid spacing={2} item xs={12} className={classes.cfdGridPadding}>
          <CurrentFlightDetails flightDetailBlocks={getCurrentFlightDetailBlocks()} />
        </Grid>
      </Grid>
    </div>
  );

  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="reinstate-flight-form-save-button"
      isDisabled={!isCommitEnabled()}
      onClick={handleCommitButtonClick}
      onKeyPress={handleKeypress}
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.REINSTATE, EntitlementNames.SUPPORTVALIDATION]}>
      <Modal
        show={show}
        title="Reinstate Flight"
        body={formHTML}
        footerButtons={formButtons}
        onHide={handleCancel}
        customCSSTag="reinstate-flight-form"
        dataCyTag="reinstate-flight-form"
      />
    </AocAuthAccessCheck>
  );
};

ReinstateFlightForm.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(ReinstateFlightForm);
