import React, { useState, useReducer, useRef, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getComponentScroll, setComponentScroll } from '../../../../lib/utils';
import { ScrollLocalStorageElementId } from '../../../../lib/constants';
import { flightListTableConfig } from '../../config/flightListTableConfig';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { TableListActions as ACTIONS } from '../../../../redux/actionTypes';
import { flightListTableReducer } from '../../../../redux/reducers/flightListTableReducer';

import './FlightListTable.css';

/**
 * The FlightListTable component renders a flight list in table view. It's built on top
 * of the Material UI Table component.
 *
 * It's data is driven by both the flight list that's passed as a prop to the component,
 * and the flightListTableConfig array.
 * @param {Object[]} flightData - a list of flight leg objects
 * @param {Object[]} savedSort - the sort to apply to the table
 * @param {onSortClick} onConfirm - function to handle storing the sort
 * @returns {FlightListTable} - the FlightListTable component
 */
const FlightListTable = ({
  flightData,
  savedSort,
  onSortClick,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
}) => {
  const flightDataCopy = useMemo(() => {
    return flightData != null && typeof flightData !== 'undefined' ? [...flightData] : [];
  }, [flightData]);

  const getInitialDisplayCount = () => {
    let scrollPositions = getComponentScroll(ScrollLocalStorageElementId.FLIGHTLIST_TABLE);
    if (scrollPositions != null && !isNaN(scrollPositions.displayCount)) {
      return Math.max(scrollPositions.displayCount, ACTIONS.INITIAL_DISPLAY_COUNT);
    }
    return ACTIONS.INITIAL_DISPLAY_COUNT;
  };

  const [state, dispatch] = useReducer(flightListTableReducer, {
    orderBy: savedSort.orderBy,
    orderDirection: savedSort.orderDirection,
    rawFlightData: flightDataCopy,
    sortedFlightData: flightDataCopy,
    displayCount: Math.min(getInitialDisplayCount(), flightDataCopy.length),
  });

  const selectedFlightDetails = useSelectedItemStore();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();

  // N-th row from the last row to add the intersection observer to
  // (So baSicallyY, when the 10th row from the bottom is scrolled into view, we'll render more rows into the table)
  const WINDOWING_OFFSET = 10;

  const handleClick = (flightLeg, col) => {
    //open Flight Panel when row is clicked
    if (selectedFlightDetails.data?.flightLegKey !== flightLeg.flightLegKey) {
      const newFlightDetails = {
        data: flightLeg,
        isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
        isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
      };
      updateSelectedFlightDetails(newFlightDetails);
    }
  };

  const [handleDefaultScroll, setHandleDefaultScroll] = useState(true);
  const tableContentRef = useRef(null);

  const flightMountRef = useCallback((node) => {
    if (!tableContentRef?.current) {
      tableContentRef.current = node;
    }
    let scrollPositions = getComponentScroll(ScrollLocalStorageElementId.FLIGHTLIST_TABLE);
    if (scrollPositions != null && tableContentRef?.current) {
      setTimeout(() => {
        tableContentRef.current.scrollTop = scrollPositions.scrollTop;
      }, 1);
    }
  }, []);

  useEffect(() => {
    let scrollPositions = getComponentScroll(ScrollLocalStorageElementId.FLIGHTLIST_TABLE);
    if (scrollPositions != null) {
      setComponentScroll(
        ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
        scrollPositions.scrollTop,
        scrollPositions.scrollLeft,
        { displayCount: state.displayCount },
      );
    }
  }, [state.displayCount]);

  const handleScroll = (e) => {
    let scrollPositions = getComponentScroll(ScrollLocalStorageElementId.FLIGHTLIST_TABLE);

    if (handleDefaultScroll && e.target.scrollTop == 0) {
      if (scrollPositions != null && scrollPositions.displayCount != null) {
        tableContentRef.current.scrollTop = scrollPositions.scrollTop;
        setHandleDefaultScroll(false);
        return;
      }
    }
    if (e == null || e.target == null) {
      return;
    }
    setComponentScroll(ScrollLocalStorageElementId.FLIGHTLIST_TABLE, e.target.scrollTop, e.target.scrollLeft, {
      displayCount: state.displayCount,
    });
  };

  /**
   * @description React side-effect to runs when flightData prop changes due to a manual or automatic refresh of the flight data.
   */
  useEffect(() => {
    setHandleDefaultScroll(true);
    // Perform some basic checking on the flightData
    // Make a shallow copy to leave the prop data alone.
    let flights = flightData != null && typeof flightData !== 'undefined' ? [...flightData] : [];
    let initialDisplayCount = getInitialDisplayCount();
    // The flight data prop has changed
    dispatch({
      type: ACTIONS.RECEIVED_DISPLAY_DATA,
      payload: { flightData: flights, initialDisplayCount: initialDisplayCount },
    });
  }, [flightData]);

  /**
   * @description Handle a sort request by the user clicking a column header.
   * @param {string} columnKey - The key name of the column to be sorted.
   * @param {boolean} retainSortFlag - Flag for retaining the current sorting direction.
   */
  const handleSort = (columnKey, retainSortFlag = false) => {
    const isSortedAscending = state.orderBy === columnKey && state.orderDirection === 'asc';
    let newOrderDirection = 'asc';

    // Change the sort direction if we aren't specifying that the sort should remain the same.
    if (!retainSortFlag) {
      newOrderDirection = isSortedAscending ? 'desc' : 'asc';
    }

    // Send the sort back to the parent to save in state
    onSortClick(columnKey, newOrderDirection);

    // notify reducer of the sort
    dispatch({
      type: ACTIONS.SORT_DATA,
      payload: {
        orderBy: columnKey,
        orderDirection: newOrderDirection,
      },
    });
  };

  const observer = useRef();
  const flightLegRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && state.displayCount < state.sortedFlightData.length) {
          // This flight leg has been scrolled into view and we have more flights to be rendered
          dispatch({ type: ACTIONS.INCREMENT_DISPLAY_COUNT });
        }
      });
      if (node) observer.current.observe(node);
    },
    [state.displayCount, state.sortedFlightData.length],
  );

  // Render table rows
  const tableRows = [];
  for (let i = 0; i < state.displayCount; i++) {
    const row = state.sortedFlightData[i];

    let tableRowRef = null;
    if (i === state.displayCount - WINDOWING_OFFSET && state.displayCount !== state.sortedFlightData.length) {
      // Set a ref to observe when to add more rows to the table
      tableRowRef = flightLegRef;
    }

    const tableRowClassName = selectedFlightDetails.data?.flightLegKey === row.flightLegKey ? 'selected' : '';

    tableRows.push(
      <TableRow
        key={row.flightLegKey}
        ref={tableRowRef}
        className={tableRowClassName}
        data-cy={`flight-list-table-row-${row.flightLegKey}`}
      >
        {flightListTableConfig.map((col, index) => (
          <TableCell
            key={`${col.columnKey}-${index}`}
            className={`td-${col.columnKey}`}
            onClick={() => handleClick(row, col)}
          >
            {col.renderFn != null &&
              col.renderFn(row, isPaneOpen, openDetailPane, summaryPanelMode, handleChangeActivityKey)}
          </TableCell>
        ))}
      </TableRow>,
    );
  }

  return (
    <TableContainer
      className="flight-list-table-container"
      onScroll={handleScroll}
      data-cy="flight-list-table"
      ref={flightMountRef}
    >
      <Table stickyHeader>
        <TableHead className="flight-list-table-head">
          <TableRow>
            {/* define table headers */}
            {flightListTableConfig.map((col) => (
              <TableCell
                sortDirection={state.orderBy === col.columnKey ? state.order : false}
                key={col.columnKey}
                data-cy="flight-list-table-header"
                className={`th-${col.columnKey}`}
              >
                {/* Check if Sort label is shown or not */}
                {col.allowSort ? (
                  <TableSortLabel
                    active={state.orderBy === col.columnKey}
                    direction={state.orderBy === col.columnKey ? state.orderDirection : 'asc'}
                    onClick={() => handleSort(col.columnKey)}
                    data-cy={
                      state.orderBy === col.columnKey
                        ? col.columnKey + '-sort-' + state.orderDirection
                        : col.columnKey + '-unsorted'
                    }
                  >
                    {col.columnDisplayName}
                  </TableSortLabel>
                ) : (
                  <span>{col.columnDisplayName}</span>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-cy="flight-list-table-body">{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

FlightListTable.propTypes = {
  flightData: PropTypes.array,
};

export default withAppInsightsTracking(FlightListTable);
