import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Shared/Button/Button';
import Modal from '../Shared/Modal/Modal';
import { SessionAction } from '../../lib/constants';
import AssignmentSessionForm from './AssignmentSessionForm/AssignmentSessionForm';
import { getAssignmentSessionTitle } from '../../lib/displayUtils';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';

import './AssignmentSession.css';

/**
 * The AssignmentSession component handles the assignment session for the user.
 * @param {Object[]} authorizedRoles - list of the user's authorized roles
 * @param {Object[]} authorizedAssignments - list of the user's authorized assignments
 * @param {func} onConfirm - function to handle assignment session confirmation
 * @param {func} onCancel - function to handle assignment session cancelation
 * @param {SessionAction} sessionAction - button on click handler function
 * @param {Object} prevAssignmentSession - the user's previous assignment session, if any
 * @return {AssignmentSession} The AssignmentSession component
 */
const AssignmentSession = ({
  authorizedRoles,
  authorizedAssignments,
  onConfirm,
  onCancel,
  sessionAction,
  prevAssignmentSession,
}) => {
  let defaultFormValues = {
    roleID: authorizedRoles[0].roleID,
    assignmentIDs: [],
  };

  if (sessionAction === SessionAction.SELECT_ROLE && authorizedRoles.length === 1) {
    // has only one role, so we'll move onto assignment
    sessionAction = SessionAction.SELECT_ASSIGNMENT;
  } else if (sessionAction === SessionAction.CHANGE_ROLE) {
    // copy role from previous session
    defaultFormValues.roleID = prevAssignmentSession.role.roleID;
  } else if (sessionAction === SessionAction.CHANGE_ASSIGNMENT) {
    // copy role and assignments from previous session
    defaultFormValues = {
      roleID: prevAssignmentSession.role.roleID,
      assignmentIDs: prevAssignmentSession.assignments.map((a) => a.assignmentID),
    };
  }

  // component states
  const [show, setShow] = useState(true);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [currentSessionAction, setCurrentSessionAction] = useState(sessionAction);

  // updates the role state to what was selected
  const handleRoleSelectChange = (id) => {
    setFormValues({ ...formValues, roleID: id });
  };

  // updates the assignments state to what was checked or unchecked
  const handleAssignmentSelectChange = (id) => {
    let current = formValues.assignmentIDs;
    if (current.includes(id)) {
      // assignment was unchecked
      current = current.filter((a) => a !== id);
    } else {
      current.push(id);
    }
    setFormValues({ ...formValues, assignmentIDs: current });
  };

  // handles when confirm button is clicked
  const handleConfirmClick = () => {
    const selectedRole = authorizedRoles.find((r) => r.roleID === formValues.roleID);
    // get selected assignments that are associated to the selected role
    const selectedAssignments = authorizedAssignments.filter(
      (a) => a.roleID === selectedRole.roleID && formValues.assignmentIDs.includes(a.assignmentID),
    );
    // check prev Assignment Session that are associated to the selected role
    const isAssignmentSession = prevAssignmentSession?.assignments?.length > 0;
    if (selectedRole) {
      // role selected
      if (selectedAssignments.length || !selectedRole.assignmentRequired) {
        // form is complete
        let assignmentSession = {
          role: selectedRole,
          assignments: selectedAssignments,
        };
        onConfirm(assignmentSession);
        setShow(false);
      } else if (!selectedAssignments.length && !isAssignmentSession && authorizedRoles.length === 1) {
        // role selected, but needs assignments and only one role available
        setCurrentSessionAction(SessionAction.SELECT_ASSIGNMENT);
      } else if (!selectedAssignments.length) {
        const roleAssignments = authorizedAssignments.filter((a) => a.roleID === formValues.roleID);
        if (roleAssignments.length === 1) {
          // role selected, but no needs assignments after change role
          const selectedRole = authorizedRoles.find((r) => r.roleID === formValues.roleID);
          let assignmentSession = {
            role: selectedRole,
            assignments: roleAssignments,
          };
          onConfirm(assignmentSession);
          setShow(false);
        } else {
          // role selected, but needs assignments after change role
          setCurrentSessionAction(SessionAction.SELECT_ASSIGNMENT_AFTER_CHANGE_ROLE);
        }
      }
    }
  };

  // handles when cancel button is clicked
  const handleCancelClick = () => {
    onCancel();
    setShow(false);
  };

  // determine content to be rendered
  const roleForm = (
    <AssignmentSessionForm
      data={authorizedRoles.map((r) => {
        return { id: r.roleID, label: r.name };
      })}
      formName="role"
      type="radio"
      title="Choose one of the following roles:"
      onChange={handleRoleSelectChange}
      isChecked={(id) => id === formValues.roleID}
    />
  );

  const assignmentForm = (
    <AssignmentSessionForm
      // only show assignments associated with the selected role
      data={authorizedAssignments
        .filter((a) => a.roleID === formValues.roleID)
        .map((a) => {
          return { id: a.assignmentID, label: a.name };
        })}
      formName="assignment"
      type="checkbox"
      title="Choose your assignment from the list below:"
      onChange={handleAssignmentSelectChange}
      isChecked={(id) => formValues.assignmentIDs.includes(id)}
    />
  );

  const getConfirmButton = (isDisabled) => (
    <Button variant="primary" onClick={handleConfirmClick} data-cy="role-confirm-button" isDisabled={isDisabled}>
      Confirm
    </Button>
  );

  let footerContent = [];
  let formContent = <></>;
  let showCloseButton = true;
  switch (currentSessionAction) {
    case SessionAction.SELECT_ROLE: {
      footerContent = [getConfirmButton(!formValues.roleID)];
      formContent = roleForm;
      showCloseButton = false;
      break;
    }
    case SessionAction.CHANGE_ROLE: {
      footerContent = [getConfirmButton(!formValues.roleID)];
      formContent = roleForm;
      break;
    }
    case SessionAction.SELECT_ASSIGNMENT: {
      footerContent = [getConfirmButton(!formValues.assignmentIDs.length)];
      formContent = assignmentForm;
      showCloseButton = false;
      break;
    }
    case SessionAction.CHANGE_ASSIGNMENT: {
      footerContent = [getConfirmButton(!formValues.assignmentIDs.length)];
      formContent = assignmentForm;
      break;
    }
    case SessionAction.SELECT_ASSIGNMENT_AFTER_CHANGE_ROLE: {
      footerContent = [getConfirmButton(!formValues.assignmentIDs.length)];
      formContent = assignmentForm;
      break;
    }
    default:
      break;
  }

  var body = <div className={'assignment-modal-content-container'}>{formContent}</div>;
  return (
    <Modal
      show={show}
      title={getAssignmentSessionTitle(currentSessionAction)}
      body={body}
      onHide={handleCancelClick}
      footerButtons={footerContent}
      dataCyTag="role-assignment-modal"
      customCSSTag="assignment-modal"
      size="thin"
      showCloseButton={showCloseButton}
      draggable={false}
    />
  );
};

AssignmentSession.propTypes = {
  authorizedRoles: PropTypes.array,
  authorizedAssignments: PropTypes.array,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  sessionAction: PropTypes.number,
  prevAssignmentSession: PropTypes.object,
};

export default withAppInsightsTracking(AssignmentSession);
