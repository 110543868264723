import PropTypes from 'prop-types';
// import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import '../FlightTab.css';
import { Box } from '@material-ui/core';

/**
 * Displays the label of a child
 * @param {string} label - Name of event (e.g Departure, Arrival, etc)
 * @param {bool} isHorizontal - if the InfoBlock is a vertical block or Horizontal block
 * @param {string} children - item text or component
 * @returns InfoBlock
 */
const InfoBlock = ({ label, isHorizontal = false, children = null }) => {
  return (
    <Box display={isHorizontal ? 'flex' : 'block'}>
      <div className={isHorizontal ? ' item-label-horizontal' : 'item-label'}>{label + (isHorizontal ? ': ' : '')}</div>
      <div className={isHorizontal ? ' item-value-horizontal' : ''}>{children}</div>
    </Box>
  );
};

InfoBlock.propTypes = {
  label: PropTypes.string,
  isHorizontal: PropTypes.bool,
  children: PropTypes.any,
};

export default InfoBlock;
