import { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import {
  getUserConfigurations,
  getUserConfiguration,
} from '../../services/apiClient/userConfigurationsApi/userConfigurationsApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import { useHideCancelledFilterDispatch } from '../useHideCancelledFlightsStore/useHideCancelledFlightsStore';

const moduleName = 'userConfigurationQuery';

function createConfig(functionName) {
  return {
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, functionName, createApiErrorTelemetryProperties(error));
    },
  };
}

/**
 * Custom hook for retrieving data from the user configurations API
 */
export const useFetchUserConfigurations = () => {
  const config = createConfig('useFetchUserConfigurations');

  const { isLoading, isError, data, isFetching, refetch, error } = useQuery(
    ['userconfigurations'],
    getUserConfigurations,
    config,
  );

  if (isError) {
    const statusCode = error?.response?.status;

    return { isLoading, isError, userConfigList: [], statusCode };
  }

  return { isLoading, userConfigList: data, isFetching, refetch };
};

/**
 * Custom hook for retrieving a user configuration from API
 */
export const useFetchUserConfigurationById = () => {
  const config = createConfig('useFetchUserConfigurationById');

  const [id, setId] = useState(null);
  const loadedCallbackRef = useRef(() => {});
  const { updateHideCancelledFilter } = useHideCancelledFilterDispatch();

  const { isLoading, isError, data } = useQuery(
    ['userconfiguration', id],
    () => (id === null ? null : getUserConfiguration(id)),
    config,
  );

  const onLoaded = (callback) => {
    loadedCallbackRef.current = callback;
  };

  if (isError) {
    return { isLoading, isError, userConfiguration: null };
  }

  if (data?.configurationDocument) {
    // NOTE: (BA)
    //    This is a temporary solution to set the hideCancelledFlights filter to the first view window's value.
    //    This will be replaced with a more robust solution in the future.
    const firstViewWindows = data.configurationDocument.viewWindows[0];

    updateHideCancelledFilter({ hideCancelledFlights: firstViewWindows.hideCancelledFlights });

    loadedCallbackRef.current();
  }

  return { isLoading, isError, userConfiguration: data, setConfigurationId: setId, configurationId: id, onLoaded };
};
