import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import {
  IrropsCode,
  EntitlementNames,
  IrropsMenuItemAction,
  IrropFlightStatusErrorMessage,
  WarningTypes,
  IrropsModalFieldType,
  Treatment,
} from '../../../lib/constants';
import IrropsFlightModal from '../../Shared/IrropsFlightModal/IrropsFlightModal';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import {
  getCalculatedIrropDataEstimates,
  canPerformIrrop,
  getDetailsBlocks,
  isCommitEnabled,
  getAirTurnBackFlightSubmitBody,
  getBodyWithSessionId,
  getDepartureCount,
} from '../../../lib/irropUtils';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { focusNextElement, uuidv4 } from '../../../lib/utils';
import {
  getFlightDetails,
  headCheckFlightExists,
  getFlightsFiltered,
} from '../../../services/apiClient/flightsApi/flightsApi';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

/**
 * The AirTurnBack component is build the Air Turn Back form.
 * @param {Object} props -
 *  existingAirTurnBackDetails - existingAirTurnBackDetails object.
 *  onCommit - callback func when save the AirTurnBack form.
 *  onClose - callback func when close the AirTurnBack the form.
 *  airTurnBackDetails - airTurnBackDetails object.
 * @returns AirTurnBack component
 */
const AirTurnBack = ({
  existingAirTurnBackDetails = null,
  onCommit,
  onClose,
  airTurnBackDetails,
  getFlightLineForFlightPuck,
  ...props
}) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    scheduledDestination,
    flightDateUtc,
    outTime,
    eta,
    isEtopsFlight,
    scheduledOperatingDateUTC,
    aircraftRegistration,
    legNumber,
    departureCount,
  } = airTurnBackDetails;

  // turnTimeMinutes from contStation state comes from server.
  const [irropStation, setIrropStation] = useState({
    station: '',
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
  });
  const [irropETA, setIrropETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  // turnTimeMinutes state is user input.
  const [turnTimeMinutes, setTurnTimeMinutes] = useState({ value: '', isError: false, error: '' });
  const [contETD, setContETD] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [contETA, setContETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [selectedReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [userComments, setUserComments] = useState('');
  const [show, setShow] = useState(true);
  const [checkingPreviousCancelled, setCheckingPreviousCancelled] = useState(true);

  const { trackEvent } = useAppInsightsContext();

  //feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [existingCancelled, setExistingCancelled] = useState(null);

  useEffect(async () => {
    const previouslyCancelledFlightExists = await headCheckFlightExists(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      scheduledDestination,
      departureCount + 1,
      operatingAirlineCode,
      IrropsCode.CANCELLED_FLIGHT,
      aircraftRegistration,
      false,
    );
    if (previouslyCancelledFlightExists) {
      const previousFlightDetails = await getFlightDetails(
        flightNumber,
        scheduledOperatingDateUTC,
        origin,
        scheduledDestination,
        departureCount + 1,
        operatingAirlineCode,
      );
      setExistingCancelled(previousFlightDetails);
      setCheckingPreviousCancelled(false);
    } else {
      setCheckingPreviousCancelled(false);
    }
  }, []);

  // RoleAssignment hook
  const hasAirTurnBackEntitlement = useAuthorizationAccess(null, [EntitlementNames.AIRTURNBACK]);

  const checkedContFlightNeeded = true;
  const irropStationVar = origin;
  /**
   * Calls updateIrropDataEstimates from IrropUtils.js and updates local state.
   * @param {String} dirtyField IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyVal Value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyVal) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      legNumber,
      aircraftRegistration,
      formatDateTime(outTime, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      irropStation,
      irropETA,
      false,
      checkedContFlightNeeded,
      turnTimeMinutes,
      contETD,
      contETA,
      dirtyField,
      dirtyVal,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      setIrropStation(result[IrropsModalFieldType.STATIONS]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_TURNTIME] != null) {
      setTurnTimeMinutes(result[IrropsModalFieldType.CONTINUATION_TURNTIME]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETD] != null) {
      setContETD(result[IrropsModalFieldType.CONTINUATION_ETD]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETA] != null) {
      setContETA(result[IrropsModalFieldType.CONTINUATION_ETA]);
    }
  };

  /**
   * @description Handle onChange of input field.
   * @param {string} - string value
   */
  const handleETAInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.IRROP_ETA, val);
    } else {
      setIrropETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description Handle on change of input field.
   * @param {string} - string value
   */
  const handleTurnTimeTextInputChange = (val) => {
    let inputNums = val.replace(/[^0-9]/g, '');
    if (inputNums.length > 3) {
      return;
    }
    setTurnTimeMinutes({ value: inputNums, isError: false, error: '' });
  };
  /**
   * @description Handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETDTextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETD, val);
    } else {
      setContETD({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description Handle on blur of input field.
   * @param {any} - event - event object.
   */
  const handleTurnTimeOnBlur = (e) => {
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_TURNTIME, turnTimeMinutes.value);
  };
  /**
   * @description Handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETATextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETA, val);
    } else {
      setContETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * @description Handle on change of input field.
   * @param {string} - string value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  /**
   * @description Handle the on hide the DIVERT MODAL
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.AIR_TURN_BACK);
  };

  /**
   * @description Handle the on selecting the reason dropdown and set the state.
   * @param {string} reason - Selected reason value.
   * @param {any} reasonKey - Reason key.
   * @param {any} commentsTemplate - Template/placeholder for comments.
   * @param {any} commentsRequired - Indicates if comments are required for the reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description Handle the commit button click and show and hide the modal.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);

    await submitAirTurnBackV2();

    setShow(false); // Hide The Modal
  };

  /**
   * @description Save the air turn back form.
   * Calls irregularOps API and calls the callbacks.
   */
  const submitAirTurnBackV2 = async () => {
    let isExistIrrops = true;

    trackEvent(`Air Turn Back - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    let flightData = getAirTurnBackFlightSubmitBody(
      operatingAirlineCode,
      aircraftRegistration,
      flightNumber,
      origin,
      scheduledDestination,
      destination,
      scheduledOperatingDateUTC,
      irropETA.value,
      userComments,
      selectedReason.text,
      departureCount,
      contETD.value,
      contETA.value,
      isEtopsFlight,
      sessionId,
    );

    const response = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.AIR_TURN_BACK);
      if (isExistIrrops) {
        let airTurnBackOperationCompleteCall = null,
          continuationOperationCompleteCall = null,
          reinstatementOperationCompleteCall = null;

        //Call the AirTurnBack flights API .
        let airTurnBackResult = await irregularOps(flightData.airturnbackFlightRequestBody);
        trackEvent(
          `Air Turn Back - API call to submit air turn back flight details is complete. Status:${airTurnBackResult?.status}, Flight number : ${flightNumber}`,
        );
        airTurnBackOperationCompleteCall = headCheckFlightExists(
          flightNumber,
          flightData.airturnbackFlightRequestBody.scheduledOperatingDateUTC,
          flightData.airturnbackFlightRequestBody.actualOrigin,
          flightData.airturnbackFlightRequestBody.actualDestination,
          flightData.airturnbackFlightRequestBody.departureCount,
          flightData.airturnbackFlightRequestBody.operatingAirlineCode,
          IrropsCode.AIR_TURN_BACK,
        );

        let reinstateLegResult;
        let continuedLegResult;
        if (existingCancelled) {
          // Restructure cancel flight form data for post to API.
          let reinstateFlightData = getBodyWithSessionId(
            {
              scheduledOrigin: existingCancelled.origin.scheduled,
              scheduledDestination: existingCancelled.destination.scheduled,
              actualOrigin: existingCancelled.origin.actual,
              actualDestination: existingCancelled.destination.actual,
              flightNumber: existingCancelled.flightNumber.toString(),
              legNumber: '',
              operatingAirlineCode: existingCancelled.aircraft.operatingAirline,
              opsType: IrropsCode.REINSTATE_FLIGHT,
              scheduledOperatingDateUTC: existingCancelled.scheduledOperatingDateUTC,
              departureCount: existingCancelled.departureCount,
            },
            sessionId,
          );
          // Call Irrops API with RIN
          reinstateLegResult = await irregularOps(reinstateFlightData);

          reinstatementOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            existingCancelled.scheduledOperatingDateUTC,
            existingCancelled.origin.scheduled,
            existingCancelled.destination.scheduled,
            existingCancelled.departureCount,
            existingCancelled.aircraft.operatingAirline,
            IrropsCode.REINSTATE_FLIGHT,
          );
        } else {
          // Call Irrops API with ADD
          let newDepartureCount = 0;
          let flightLegs = null;

          if (getFlightLineForFlightPuck != null) {
            // getFlightLineForFlightPuck is available when the modal is opened from Gant view.

            flightLegs = getFlightLineForFlightPuck(null, true);
          } else {
            // if the modal is opened from table view, we need to make a call to get flight legs to determine new departureCount

            let flightLegsData = await getFlightsFiltered(
              flightData.addContinueFlightRequestBody.scheduledOperatingDateUTC,
              flightData.addContinueFlightRequestBody.scheduledOperatingDateUTC,
              [],
              [flightNumber],
              [flightData.addContinueFlightRequestBody.actualOrigin],
              [],
            );
            flightLegs = flightLegsData.flattenedFlightLegs;
          }
          newDepartureCount =
            getDepartureCount(
              flightLegs,
              flightNumber,
              flightData.addContinueFlightRequestBody.actualOrigin,
              flightData.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            ) + 1;

          continuedLegResult = await irregularOps({
            ...flightData.addContinueFlightRequestBody,
            departureCount: newDepartureCount,
          });

          trackEvent(
            `Air Turn Back - API call to submit continue flight details is complete. Status:${continuedLegResult?.status}, Flight number : ${flightNumber}`,
          );
          continuationOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            flightData.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            flightData.addContinueFlightRequestBody.actualOrigin,
            flightData.addContinueFlightRequestBody.actualDestination,
            flightData.addContinueFlightRequestBody.departureCount,
            flightData.addContinueFlightRequestBody.operatingAirlineCode,
            IrropsCode.ADD_FLIGHT,
          );
        }
        // Handle result
        const airTurnBackResultIsSuccess = airTurnBackResult?.status === 'SUCCESS';
        const reinstateLegResultIsSuccess = reinstateLegResult?.status === 'SUCCESS';
        const continuedLegResultIsSuccess = continuedLegResult?.status === 'SUCCESS';

        if (
          airTurnBackResultIsSuccess &&
          ((!!existingCancelled && reinstateLegResultIsSuccess) || continuedLegResultIsSuccess)
        ) {
          onCommit(true, 'Air Turn Back', '', [
            airTurnBackOperationCompleteCall,
            existingCancelled ? reinstatementOperationCompleteCall : continuationOperationCompleteCall,
          ]); // Handle toast notification SUCCESS
        } else {
          onCommit(false, 'Air Turn Back', '', []); // Handle toast notification ERROR
        }
      } else {
        trackEvent(`Air Turn Back - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`);
        onCommit(false, 'Air Turn Back', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Air Turn Back - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Air Turn Back', '', []);
    }
  };

  /**
   * @description Save the air turn back form.
   * Calls irregularOps API and calls the callbacks.
   */

  useEffect(() => {
    updateWithEstimates(IrropsModalFieldType.STATIONS, irropStationVar);
  }, []);

  /**
   * @description Build array with current flight details.
   * @returns Array of current flight details objects.
   */
  const getCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.AIR_TURN_BACK,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      outTime,
      null,
      eta,
    );
  };

  const getCanceledFlightDetailBlocks = () => {
    if (existingCancelled) {
      //let isActive = !checkedCancelFlight;
      const flight = existingCancelled;
      const details = getDetailsBlocks(
        IrropsCode.CANCELLED_FLIGHT,
        false,
        true,
        formatDateTime(flight.scheduledOperatingDateUTC, 'MMMM DD'),
        flight.flightNumber,
        flight.aircraft.aircraftRegistration,
        flight.origin.actual,
        flight.destination.actual,
        null,
        flight.times[0].estimatedTimeUTC,
        formatDateTime(flight.times[3].estimatedTimeUTC, 'HH:mm'),
      );
      return details;
    }
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.AIRTURNBACK, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle={'Air Turn Back'}
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.AIR_TURN_BACK}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getCurrentFlightDetailBlocks()}
        showIrropSection={true}
        irropsAutoPopulateStationsLabel="Flight Leg"
        irropTitle="AIR TURN BACK STATION"
        irropStations={{
          origin,
          destination: irropStationVar,
          isDestinationEditable: false,
          onChangeDestination: () => {},
        }}
        irropETA={{
          value: irropETA.hhmm,
          onChange: handleETAInputChange, //handleIrropETATextInputChange,
          warningType: irropETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: irropETA.error,
        }}
        irropCheckbox={{
          show: false,
          label: '',
          value: false,
          onChange: () => {},
        }}
        showFollowFlightSection={!checkingPreviousCancelled && !existingCancelled}
        followFlightTitle="ORIGINAL FLIGHT"
        followFlightStations={{ origin: irropStationVar, destination }}
        followFlightTurnTime={{
          value: turnTimeMinutes.value,
          onChange: handleTurnTimeTextInputChange,
          onBlur: handleTurnTimeOnBlur,
          warningType: turnTimeMinutes.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: turnTimeMinutes.error,
        }}
        followFlightETD={{
          value: contETD.hhmm,
          onChange: handleContinuationETDTextInputChange,
          warningType: contETD.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETD.error,
        }}
        followFlightETA={{
          value: contETA.hhmm,
          onChange: handleContinuationETATextInputChange,
          warningType: contETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETA.error,
        }}
        showCanceledFlightSection={!!existingCancelled}
        canceledFlightTitle="REINSTATE FLIGHT DETAILS"
        canceledFlightDetails={getCanceledFlightDetailBlocks()}
        canceledFlightCheckbox={{
          hide: true,
        }}
        reason={{
          placeholderText: '',
          reasonText: selectedReason.text,
          commentsTemplate: selectedReason.template,
          isCommentsRequired: selectedReason.isCommentsRequired,
          onSelect: handleOnSelectReason,
        }}
        comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
        isCommitEnabled={isCommitEnabled(
          irropETA,
          turnTimeMinutes,
          contETD,
          contETA,
          selectedReason,
          userComments,
          hasAirTurnBackEntitlement,
          commitButtonClicked,
        )}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
    </AocAuthAccessCheck>
  );
};

AirTurnBack.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(AirTurnBack);
