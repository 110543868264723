import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditSharp';
import { getMonthYearDisplayString } from '../../../lib/displayUtils';

// Default em dash (-) value for empty table cells
const defaultValue = <span className="admin-list-table-default-value">&#8212;</span>;

export const assignmentsTableConfig = [
  {
    columnDisplayName: 'Assignment Name',
    columnKey: 'name',
    renderFn: (row) => (!!row.name ? row.name : defaultValue),
  },
  {
    columnDisplayName: 'Description',
    columnKey: 'description',
    renderFn: (row) => (!!row.description ? row.description : defaultValue),
  },
  {
    columnDisplayName: 'Airline',
    columnKey: 'operatingAirline',
    renderFn: (row) => (!!row.operatingAirline ? row.operatingAirline : defaultValue),
  },
  {
    columnDisplayName: 'Assignment Group Type',
    columnKey: 'typeName',
    renderFn: (row) => (!!row.typeName ? row.typeName : defaultValue),
  },
  {
    columnDisplayName: 'Criteria View',
    columnKey: 'criteria',
    renderFn: (row) => (!!row.criteria ? row.criteria : defaultValue),
  },
  {
    columnDisplayName: 'Created By',
    columnKey: 'createdBy',
    renderFn: (row) => (!!row.createdBy ? row.createdBy : defaultValue),
  },
  {
    columnDisplayName: 'Created Date',
    columnKey: 'createdDate',
    renderFn: (row) => (!!row.createdDate ? getMonthYearDisplayString(row.createdDate) : defaultValue),
  },
  {
    columnDisplayName: 'Modified By',
    columnKey: 'modifiedBy',
    renderFn: (row) => (!!row.modifiedBy ? row.modifiedBy : defaultValue),
  },
  {
    columnDisplayName: 'Modified Date',
    columnKey: 'modifiedDate',
    renderFn: (row) => (!!row.modifiedDate ? getMonthYearDisplayString(row.modifiedDate) : defaultValue),
  },
  {
    columnDisplayName: '',
    columnKey: 'edit',
    renderFn: () => <EditOutlinedIcon color="primary" />,
  },
];
