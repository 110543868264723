import React, { createContext, useCallback, useState, useContext, useRef } from 'react';
import NotificationToast, { ToastType } from '../../components/Shared/NotificationToast/NotificationToast';

const NotificationStateContext = createContext(null);

const NotificationStateProvider = ({ children }) => {
  const [state, setState] = useState({
    toastType: ToastType.SUCCESS,
    toastMessage: '',
    showToast: false,
    autoHide: false,
  });

  let onCloseRef = useRef();

  const onCloseNotification = useCallback((onCloseEvent) => {
    onCloseRef.current = onCloseEvent;
  }, []);

  const handleClose = useCallback(() => {
    closeNotification();
    if (onCloseRef.current) {
      onCloseRef.current();
    }
  }, []);

  const setNotification = (toastTypeInput, toastMessageInput, showToastInput, autoHideInput) => {
    setState({
      toastType: toastTypeInput,
      toastMessage: toastMessageInput,
      showToast: showToastInput,
      autoHide: autoHideInput,
    });
  };

  const getNotification = () => {
    return state;
  };

  const closeNotification = useCallback(() => {
    setState({ toastType: ToastType.SUCCESS, toastMessage: '', showToast: false, autoHide: false });
  }, []);

  const RenderNotification = (props) => {
    return (
      <NotificationToast
        showToast={state.showToast}
        message={state.toastMessage}
        toastType={state.toastType}
        autoHide={state.autoHide}
        onClose={handleClose}
        style={props.style}
        isModal={true}
      />
    );
  };

  return (
    <NotificationStateContext.Provider
      value={{ getNotification, setNotification, closeNotification, RenderNotification, onCloseNotification }}
    >
      {children}
    </NotificationStateContext.Provider>
  );
};
/**
 * @description Custom hook to update notifications
 * */
function useNotificationState() {
  const context = useContext(NotificationStateContext);
  if (!context) {
    throw new Error(
      'useNotificationState must be used within a NotificationStateProvider. Wrap a parent component in <NotificationStateProvider> to fix this error.',
    );
  }
  return context;
}

export { NotificationStateProvider, useNotificationState };
