import React, { useState, useEffect } from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import IrropsFlightModal from '../../../Shared/IrropsFlightModal/IrropsFlightModal';
import {
  IrropsCode,
  FormDataBrickType,
  EntitlementNames,
  IrropFlightStatusErrorMessage,
  WarningTypes,
  IrropsModalFieldType,
  Treatment,
} from '../../../../lib/constants';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../../lib/dateTimeUtils';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { irregularOps } from '../../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import {
  isNullOrWhitespace,
  getCalculatedIrropDataEstimates,
  getDetailsBlocks,
  getFlightDetailBrick,
  getBodyWithSessionId,
} from '../../../../lib/irropUtils';
import { getFlightDetails, headCheckFlightExists } from '../../../../services/apiClient/flightsApi/flightsApi';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { uuidv4 } from '../../../../lib/utils';

/**
 * The RedispatchIntended component is built to display the Redispatch Intended form.
 * @param {Object} props -
 *  existingRedispatchDetails - existing redispatch details object.
 *  onCommit - callback function when the redispatch intended form is saved.
 *  onClose - callback function when the redispatch intended form is closed.
 *  reDispatchDetails - redispatch details object.
 * @returns RedispatchIntended component
 */
const RedispatchIntended = ({ existingRedispatchDetails = null, onCommit, onClose, reDispatchDetails, ...props }) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    flightDateUtc,
    etd,
    eta,
    aircraftRegistration,
    scheduledDestination,
    scheduledOperatingDateUTC,
    isEtopsFlight,
    projectedDestination,
    departureCount,
  } = reDispatchDetails;

  const [irropETA, setIrropETA] = useState({ value: '', hhmm: '' });
  const [show, setShow] = useState(true);

  const intermediateOrigin = origin;
  const reDispatchStationETA = eta;

  const { trackEvent } = useAppInsightsContext();

  // Authorization access hook
  const hasRedispatchIntendedEntitlement = useAuthorizationAccess(null, [EntitlementNames.REDISPATCH]);

  // Feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);

  /**
   * Set default user-input ETA fields with the ETA display from Current Flight Details.
   */
  useEffect(() => {
    updateWithEstimates(IrropsModalFieldType.IRROP_ETA, eta);
  }, [eta]);

  /**
   * Calls updateIrropDataEstimates from IrropUtils.js and updates local state.
   * @param {String} dirtyField - IrropsModalFieldType enum that indicates which field is currently dirty.
   * @param {String} dirtyValue - Value of the dirty field.
   */
  const updateWithEstimates = async (dirtyField, dirtyValue) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      null,
      aircraftRegistration,
      formatDateTime(etd, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      destination,
      irropETA,
      false,
      false,
      null,
      null,
      null,
      dirtyField,
      dirtyValue,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
  };

  const isCommitEnabled = () => {
    return (
      !isNullOrWhitespace(irropETA.hhmm) &&
      !isNullOrWhitespace(irropETA.value) &&
      hasRedispatchIntendedEntitlement &&
      !commitButtonClicked
    );
  };

  /**
   * @description Saves the redispatch intended form.
   * Calls the irregularOps API and call the callbacks.
   */

  /**
   * @description Handles the commit button click.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);

    await submitRedispatchIntendedV2();

    setShow(false); // Hide the modal
  };

  /**
   * @description Saves the redispatch intended form.
   * Calls the irregularOps API and invokes the callbacks.
   */
  const submitRedispatchIntendedV2 = async () => {
    trackEvent(`Redispatch (Intended) - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    let redispatchIntdResult;

    let redispatchIntdFlightRequestBody = getBodyWithSessionId(
      {
        // Restructure redispatch intended flight form data for POST to API.
        operatingAirlineCode: operatingAirlineCode,
        flightNumber: flightNumber.toString(),
        opsType: IrropsCode.REDISPATCH_FLIGHT,
        actualOrigin: origin,
        actualDestination: destination,
        scheduledOrigin: origin,
        scheduledDestination: scheduledDestination,
        scheduledOperatingDateUTC: scheduledOperatingDateUTC,
        isEtopsFlight: isEtopsFlight,
        legNumber: '', //legNumber,
        projectedDestination: destination,
        estimatedIn: irropETA.value,
        departureCount,
      },
      sessionId,
    );

    const flightLeg = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );

    if (flightLeg !== null) {
      if (
        flightLeg.irregularOperationCode === IrropsCode.REDISPATCH_FLIGHT &&
        flightLeg.projectedDestination !== null &&
        flightLeg.projectedDestination !== destination
      ) {
        //CALL THE Irrops QueuePublisher API for Redispatch .
        redispatchIntdResult = await irregularOps(redispatchIntdFlightRequestBody);
        trackEvent(
          `Redispatch (Intended) - API call to submit Redispatch (Intended) details is complete. Status:${redispatchIntdResult?.status}, Flight number : ${flightNumber}`,
        );

        let completeCheckCall = headCheckFlightExists(
          flightNumber,
          scheduledOperatingDateUTC,
          origin,
          destination,
          departureCount,
          operatingAirlineCode,
          IrropsCode.REDISPATCH_FLIGHT,
        );

        //HANDLE RESULT
        if (redispatchIntdResult !== null && redispatchIntdResult.status === 'SUCCESS')
          // PASS RESULT FLIGHT ACTION MENU FOR HANDLE NOTIFACTION
          onCommit(true, 'Redispatch (Intended)', '', [completeCheckCall]);
        //HANDLE TOAST NOTIFICATION SUCCESS
        else onCommit(false, 'Redispatch (Intended)', '', []); //HANDLE TOAST NOTIFICATION ERROR
      } else {
        trackEvent(`Redispatch (Intended) - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`);
        onCommit(false, 'Redispatch (Intended)', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Redispatch (Intended) - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Redispatch (Intended)', '', []);
    }
  };

  /**
   * @description Closes the redispatch intermediate modal.
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.REDISPATCH_FLIGHT);
  };

  /**
   * @description Builds an array of redispatch station blocks to pass to the Irrops shared modal.
   * @returns Array of redispatch station block objects.
   */
  const getRedispatchStationBlocks = () => {
    let rsd = [];
    rsd.push(
      getFlightDetailBrick(
        'Intermediate',
        intermediateOrigin,
        projectedDestination,
        FormDataBrickType.STATION_MULTIPLE,
        true,
      ),
    );
    rsd.push(getFlightDetailBrick('ETA', reDispatchStationETA, null, FormDataBrickType.TIME, true));
    return rsd;
  };

  /**
   * @description Builds an array of current flight detail blocks to pass to the Irrops shared modal.
   * @returns Array of current flight detail blocks objects.
   */
  const getRedispatchCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.REDISPATCH_FLIGHT,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      null,
      etd,
      eta,
    );
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.REDISPATCH, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle="Redispatch to Intended Destination"
        // formDataBricksCol="8"
        irropsAutoPopulateStationsLabel="Flight Leg"
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.REDISPATCH_FLIGHT}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getRedispatchCurrentFlightDetailBlocks()}
        showIrropSection={true}
        showIrropFooterLine={false}
        irropTitle="INTENDED STATION"
        irropETA={{
          value: irropETA.hhmm,
          warningType: WarningTypes.NONE,
          errorMessage: '',
          inputTypeLable: true,
        }}
        irropStationsRequired={false}
        irropStations={{
          origin,
          destination,
          isDestinationEditable: false,
        }}
        flighSationDetailsTitle="REDISPATCH STATION"
        flightStationDetails={getRedispatchStationBlocks()}
        showFlightStationSection={true}
        showFollowFlightSection={false}
        showCanceledFlightSection={false}
        showDocumentationSection={false}
        isCommitEnabled={isCommitEnabled()}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
    </AocAuthAccessCheck>
  );
};

RedispatchIntended.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(RedispatchIntended);
