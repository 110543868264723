import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';

const Passenger = (passengerDetails) => {
  //TODO: implement tab skeleton
  return <div>Passenger Details</div>;
};

Passenger.propTypes = {
  passengerDetails: PropTypes.object,
};

export default withAppInsightsTracking(Passenger);
