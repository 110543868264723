import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import {
  IrropsCode,
  EntitlementNames,
  IrropsMenuItemAction,
  IrropFlightStatusErrorMessage,
  KeyCodes,
  WarningTypes,
  IrropsModalFieldType,
  Treatment,
} from '../../../lib/constants';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import {
  getFlightDetails,
  headCheckFlightExists,
  getFlightsFiltered,
} from '../../../services/apiClient/flightsApi/flightsApi';
import {
  isNullOrWhitespace,
  canPerformIrrop,
  isNullOrNotNumber,
  getCalculatedIrropDataEstimates,
  getDetailsBlocks,
  getIrropsFlightSubmitBody,
  getDepartureCount,
} from '../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../lib/utils';
import IrropsFlightModal from '../../Shared/IrropsFlightModal/IrropsFlightModal';
import ConfirmationPopup from '../../Shared/ConfirmationPopup/ConfirmationPopup';
import './RerouteFlight.css';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

/**
 * The Reroute Flight component is build the Reroute Flight form.
 * @param {Object} props -
 *  rerouteFlight - rerouteFlight object.
 *  onCommit - callback func when save the reroute flight form.
 *  onClose - callback func when close the reroute flight the form.
 *  nextFlightLeg - nextFlightLeg object.
 * @returns RerouteFlight component
 */
const RerouteFlight = ({ onCommit, onClose, rerouteFlight, nextFlightLeg, getFlightLineForFlightPuck, ...props }) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    scheduledDestination,
    flightDateUtc,
    eta,
    etd,
    isEtopsFlight,
    scheduledOperatingDateUTC,
    aircraftRegistration,
    legNumber,
    departureCount,
  } = rerouteFlight;

  const { trackEvent } = useAppInsightsContext();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [irropStationVar, setIrropStationVar] = useState('');
  // turnTimeMinutes from contStation state comes from server.
  const [irropStation, setIrropStation] = useState({
    station: '',
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
    isError: false,
    error: '',
  });
  const [irropETA, setIrropETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  // turnTimeMinutes state is user input.
  const [turnTimeMinutes, setTurnTimeMinutes] = useState({ value: '', isError: false, error: '' });
  const [contETD, setContETD] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [contETA, setContETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [userComments, setUserComments] = useState('');
  const [isMultiLegFlight, setIsMultiLegFlight] = useState(false);
  const [checkedContFlightNeeded, setCheckedContFlightNeeded] = useState(false);
  const [checkedCancelFlight, setCheckedCancelFlight] = useState(false);
  const [showCancelFlight, setShowCancelFlight] = useState(false);
  const [canceledFlightData, setCanceledFlightData] = useState({
    etd: '',
    eta: '',
    scheduledOrigin: '',
    scheduledDestination: '',
  });
  const [show, setShow] = useState(true);
  const [selectReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });

  // authorization access hook
  const hasRerouteEntitlement = useAuthorizationAccess(null, [EntitlementNames.REROUTE]);

  //feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  const [commitButtonClicked, setCommitButtonClicked] = useState(false);

  //warning Messages for destination
  const warningMessages = {
    DEST_SAME_AS_CURRENT: 'Destination same as current',
    DEST_SAME_AS_ORIGIN: 'Destination same as origin. Use BTB?',
  };

  const commentsRequiredByAirline = {
    Airline: 'AS',
    Reason: 'Schedule Change',
    CommentTemplate: 'Describe details',
  };

  /**
   * calls updateIrropDataEstimates from IrropUtils.js and updates localstate
   * @param {String} dirtyField IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyValue value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyValue) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      legNumber,
      aircraftRegistration,
      formatDateTime(etd, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      irropStation,
      irropETA,
      true,
      isContinuationFlightEnabled(),
      turnTimeMinutes,
      contETD,
      contETA,
      dirtyField,
      dirtyValue,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      let isError = false;
      let stations = result[IrropsModalFieldType.STATIONS];

      // If current flight destination matches entered dest, error
      if (destination === stations.station) {
        isError = true;
        stations.error = warningMessages.DEST_SAME_AS_CURRENT;
      } else if (origin === stations.station) {
        isError = true;
        stations.error = warningMessages.DEST_SAME_AS_ORIGIN;
      }

      stations.isError = isError;
      setIrropStation(stations);
    }
    if (result[IrropsModalFieldType.CONTINUATION_TURNTIME] != null) {
      setTurnTimeMinutes(result[IrropsModalFieldType.CONTINUATION_TURNTIME]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETD] != null) {
      setContETD(result[IrropsModalFieldType.CONTINUATION_ETD]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETA] != null) {
      setContETA(result[IrropsModalFieldType.CONTINUATION_ETA]);
    }
  };

  /**
   * clears all input variable state
   */
  const cleanInputs = () => {
    setIrropStationVar('');
    setIrropStation({ station: '', irropBlockMinutes: '', contTurnTimeMinutes: '', contBlockMinutes: '' });
    setIrropETA({ value: '', isError: false, error: '', hhmm: '' });
    setTurnTimeMinutes({ value: '', isError: false, error: '' });
    setContETD({ value: '', isError: false, error: '', hhmm: '' });
    setContETA({ value: '', isError: false, error: '', hhmm: '' });
  };

  /**
   * Event handler when the reroute airport code has changed.
   * @param {String} value - three-letter, capitalized airport code
   */
  const handleAirportCodeInputChange = (value, event, target) => {
    if (value === irropStationVar) return;

    if (value.length < 3) {
      cleanInputs();
      return;
    }

    setIrropStationVar(value);

    if (!isNullOrWhitespace(value) && value.length === 3) {
      updateWithEstimates(IrropsModalFieldType.STATIONS, value);
    }

    focusNextElement(event, target);
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleETAInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.IRROP_ETA, val);
    } else {
      setIrropETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description handle on blur of input field.
   * @param {any} - event - event object.
   */
  const handleTurnTimeOnBlur = (e) => {
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_TURNTIME, turnTimeMinutes.value);
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETDTextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETD, val);
    } else {
      setContETD({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleTurnTimeTextInputChange = (val) => {
    let inputNums = val.replace(/[^0-9]/g, '');
    if (inputNums.length > 3) {
      return;
    }
    setTurnTimeMinutes({ value: inputNums, isError: false, error: '' });
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETATextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETA, val);
    } else {
      setContETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  /**
   * @description handle on continue flight checkbox event.
   */
  const handleChkContFlightNeeded = (e) => {
    const newContinueFlag = !checkedContFlightNeeded;
    setCheckedContFlightNeeded(newContinueFlag);
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_CHECKED, newContinueFlag);
  };
  /**
   * @description handle the check cancel flight checkbox event.
   */
  const handleChkCancelFlight = (e) => {
    setCheckedCancelFlight(!checkedCancelFlight);
  };

  const isCommitEnabled = () => {
    return (
      !isNullOrWhitespace(irropStationVar) &&
      irropStationVar.length === 3 &&
      !isNullOrWhitespace(irropETA.hhmm) &&
      !isNullOrWhitespace(irropETA.value) &&
      !irropStation.isError &&
      ((isContinuationFlightEnabled() &&
        !isNullOrNotNumber(turnTimeMinutes.value) &&
        !isNullOrWhitespace(contETD.hhmm) &&
        !isNullOrWhitespace(contETD.value) &&
        !isNullOrWhitespace(contETA.hhmm) &&
        !isNullOrWhitespace(contETA.value)) ||
        !isContinuationFlightEnabled()) &&
      !isNullOrWhitespace(selectReason) &&
      !isNullOrWhitespace(selectReason.key) &&
      ((selectReason.isCommentsRequired && !!userComments) ||
        //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectReason.isCommentsRequired === false) &&
      hasRerouteEntitlement &&
      !commitButtonClicked
    );
  };

  /**
   * @description handle the on selecting the reason dropdown and set the state.
   * @param {string} reason selected reason value.
   * @param {any} reasonKey reason key.
   * @param {any} commentTemplate template/placeholder for comments.
   * @param {any} commentsRequired is comments required for reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentTemplate, commentsRequired, event, target) => {
    if (operatingAirlineCode === commentsRequiredByAirline.Airline && reason === commentsRequiredByAirline.Reason) {
      commentsRequired = true;
      commentTemplate = commentsRequiredByAirline.CommentTemplate;
    }
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description handle the on hide the REROUTE MODAL
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.REROUTE_FLIGHT);
  };

  const submitReroute = async () => {
    await submitRerouteFlightV2();
  };
  /**
   * @description handle the commit button click and show and hide the modal.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    if (checkedCancelFlight === true) {
      setShowConfirmPopup(true);
    } else {
      await submitReroute();
    }
    setShow(false); // Hide The Modal
  };

  useEffect(() => {
    //Check Next Flight Is Multilge
    const isMultiLegFlight = async () => {
      if (
        nextFlightLeg !== null &&
        nextFlightLeg.flightNumber === flightNumber.toString() &&
        nextFlightLeg.aircraft.aircraftRegistration === aircraftRegistration &&
        nextFlightLeg.scheduledOperatingDateUTC === scheduledOperatingDateUTC
      ) {
        setCanceledFlightData((prevState) => {
          return {
            ...prevState,
            canceledFlightETD: `${nextFlightLeg.times.find((t) => t.name === 'OUT')?.estimatedTimeUTC}`,
            canceledFlightETA: `${nextFlightLeg.times.find((t) => t.name === 'IN')?.estimatedTimeUTC}`,
            // Save properties needed to submit a cancel
            canceledFlightScheduledOrigin: `${nextFlightLeg.origin.scheduled}`,
            canceledFlightScheduledDestination: `${nextFlightLeg.destination.scheduled}`,
            actualOrigin: nextFlightLeg.origin.actual,
            actualDestination: nextFlightLeg.destination.actual,
            flightNumber: nextFlightLeg.flightNumber,
            operatingAirlineCode: nextFlightLeg.operatingAirline,
            scheduledOperatingDateUTC: nextFlightLeg.scheduledOperatingDateUTC,
            departureCount: nextFlightLeg.departureCount,
          };
        });
        setCheckedCancelFlight(false);
        setShowCancelFlight(true);
        setCheckedContFlightNeeded(true);
        setIsMultiLegFlight(true);
      } else {
        setCheckedContFlightNeeded(true);
        setIsMultiLegFlight(false);
      }
    };
    isMultiLegFlight();
  }, [nextFlightLeg]);
  /**
   * checks if continuation flight is enabnled depending on if it is a single leg flight and continuation flight flag checkbox value
   */
  const isContinuationFlightEnabled = useCallback(() => {
    return (isMultiLegFlight && checkedContFlightNeeded) || !isMultiLegFlight;
  }, [isMultiLegFlight, checkedContFlightNeeded]);

  /**
   * Submits a RRT for the current flight V2 when Irrops Refresh Enhancement is turned on.
   * May submit an ADD if continuation flight is checked.
   * May submit a CXL if current flight is multi-leg and canceled flight is checked.
   * Handles the result of requested irrops by invoking callback onCommit.
   */
  const submitRerouteFlightV2 = async () => {
    let isExistIrrops;
    trackEvent(`Reroute - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    //Restructure reroute flight form data for post to API.

    let submitBody = getIrropsFlightSubmitBody(
      IrropsCode.REROUTE_FLIGHT,
      operatingAirlineCode,
      aircraftRegistration,
      flightNumber,
      origin,
      irropStationVar,
      scheduledDestination,
      destination,
      scheduledOperatingDateUTC,
      irropETA.value,
      userComments,
      selectReason.text,
      departureCount,
      contETD.value,
      contETA.value,
      isEtopsFlight,
      checkedCancelFlight,
      canceledFlightData,
      isContinuationFlightEnabled(),
      sessionId,
    );

    const response = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.REROUTE_FLIGHT);
      if (isExistIrrops) {
        let canceledOperationCompleteCall = null,
          canceledLegResult = null,
          continuedLegResult = null,
          continuationOperationCompleteCall = null;

        let rerouteResult = await irregularOps(submitBody.irropFlightRequestBody);
        trackEvent(
          `Reroute - API call to submit reroute flight details is complete. Status:${rerouteResult?.status}, Flight number : ${flightNumber}`,
        );
        let rerouteOperationCompleteCall = headCheckFlightExists(
          flightNumber,
          submitBody.irropFlightRequestBody.scheduledOperatingDateUTC,
          submitBody.irropFlightRequestBody.actualOrigin,
          submitBody.irropFlightRequestBody.actualDestination,
          submitBody.irropFlightRequestBody.departureCount,
          submitBody.irropFlightRequestBody.operatingAirlineCode,
          submitBody.irropFlightRequestBody.opsType,
        );
        let headCallsArr = []; // Track all the headCheckFlightExists calls in an array. we will be able to send this array to FlightActionMenu commit callback.
        headCallsArr.push(rerouteOperationCompleteCall);

        //Make CXL or ADD API call if needed
        if (checkedCancelFlight) {
          //If canceled section is true, make API call. makeIrropsCall will return null if no need to call
          canceledLegResult = await irregularOps(submitBody.canceledRequestBody);
          trackEvent(
            `Reroute - API call to submit canceled flight details is complete. Status:${canceledLegResult?.status}, Flight number : ${flightNumber}`,
          );
          canceledOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            submitBody.canceledRequestBody.scheduledOperatingDateUTC,
            submitBody.canceledRequestBody.actualOrigin,
            submitBody.canceledRequestBody.actualDestination,
            submitBody.canceledRequestBody.departureCount,
            submitBody.canceledRequestBody.operatingAirlineCode,
            submitBody.canceledRequestBody.opsType,
          );
          headCallsArr.push(canceledOperationCompleteCall);
        }

        if (isContinuationFlightEnabled()) {
          //Continuation flight checkbox is checked

          let newDepartureCount = 0;

          let flightLegsData = await getFlightsFiltered(
            submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            [],
            [flightNumber],
            [submitBody.addContinueFlightRequestBody.actualOrigin],
            [],
          );
          let flightLegs = flightLegsData.flattenedFlightLegs;

          newDepartureCount =
            getDepartureCount(
              flightLegs,
              flightNumber,
              submitBody.addContinueFlightRequestBody.actualOrigin,
              submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            ) + 1;

          continuedLegResult = await irregularOps({
            ...submitBody.addContinueFlightRequestBody,
            departureCount: newDepartureCount,
          });

          trackEvent(
            `Reroute - API call to submit continue flight details is complete. Status:${continuedLegResult?.status}, Flight number : ${flightNumber}`,
          );
          continuationOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            submitBody.addContinueFlightRequestBody.actualOrigin,
            submitBody.addContinueFlightRequestBody.actualDestination,
            newDepartureCount,
            submitBody.addContinueFlightRequestBody.operatingAirlineCode,
            submitBody.addContinueFlightRequestBody.opsType,
          );
          headCallsArr.push(continuationOperationCompleteCall);
        }

        // handle result
        const rerouteResultIsSuccess = rerouteResult?.status === 'SUCCESS';
        const continuedLegResultIsSuccess = !isContinuationFlightEnabled() || continuedLegResult?.status === 'SUCCESS';
        const canceledLegResultIsSuccess = !checkedCancelFlight || canceledLegResult?.status === 'SUCCESS';

        if (rerouteResultIsSuccess && continuedLegResultIsSuccess && canceledLegResultIsSuccess) {
          onCommit(true, 'Reroute', '', headCallsArr); //handle toast notifaction SUCCESS
        } else {
          onCommit(false, 'Reroute', '', []); //handle toast notifaction ERROR
        }
      } else {
        trackEvent(`Reroute - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`);
        onCommit(false, 'Reroute', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Reroute - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Reroute', '', []);
    }
  };

  /**
   * Submits a RRT for the current flight.
   * May submit an ADD if continuation flight is checked.
   * May submit a CXL if current flight is multi-leg and canceled flight is checked.
   * Handles the result of requested irrops by invoking callback onCommit.
   */

  /**
   * @description build the current flight details in array format.
   * @returns current flight detail blocks in array
   */
  const getCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.REROUTE_FLIGHT,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      null,
      etd,
      eta,
    );
  };

  /**
   * @description build the cancel flight details in array format.
   * @returns cancel flight detail blocks in array
   */
  const getCanceledFlightDetailBlocks = () => {
    let isActive = !checkedCancelFlight;
    return getDetailsBlocks(
      IrropsCode.REROUTE_FLIGHT,
      false,
      isActive,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      canceledFlightData.canceledFlightScheduledOrigin,
      canceledFlightData.canceledFlightScheduledDestination,
      null,
      canceledFlightData.canceledFlightETD,
      formatDateTime(canceledFlightData.canceledFlightETA, 'HH:mm'),
    );
  };

  //Handle Confirm Reroute Popup modal close.
  const hideHandleConfirmReroute = () => {
    setShowConfirmPopup(false); // hide confirm popup
    onClose(IrropsCode.REROUTE_FLIGHT);
  };

  /**
   * @description Handle popup container text to show confirmation message
   * @returns Cancelling flight message
   */
  const popupContainerText = () => {
    if (
      canceledFlightData.canceledFlightScheduledOrigin != null &&
      canceledFlightData.canceledFlightScheduledDestination != null
    ) {
      return `Cancelling flight ${canceledFlightData.flightNumber.toString()}, 
                ${canceledFlightData.canceledFlightScheduledOrigin}-
                ${canceledFlightData.canceledFlightScheduledDestination}?`;
    }
  };

  //Handle Confirm popup on click comment button
  const handleConfirmButtonClick = async () => {
    setShowConfirmPopup(false);
    await submitReroute();
  };

  //Triggers by pressing the enter key
  const handlehandleOnKeyPress = (e) => {
    if (e.charCode === KeyCodes.ENTER) {
      handleConfirmButtonClick();
    }
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.REROUTE, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle="Reroute Flight"
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.REROUTE_FLIGHT}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getCurrentFlightDetailBlocks()}
        showIrropSection={true}
        irropsAutoPopulateStationsLabel="Flight Leg"
        irropTitle="REROUTE STATION"
        irropStations={{
          origin,
          destination,
          isDestinationEditable: true,
          onChangeDestination: (value, event, target) => handleAirportCodeInputChange(value, event, target),
          warningType: irropStation.isError ? WarningTypes.ERROR : WarningTypes.NONE,
          warningMessage: irropStation.error,
        }}
        irropETA={{
          value: irropETA.hhmm,
          onChange: handleETAInputChange, //handleIrropETATextInputChange,
          warningType: irropETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: irropETA.error,
        }}
        irropCheckbox={{
          show: isMultiLegFlight,
          label: 'Add continuation flight',
          value: checkedContFlightNeeded,
          onChange: handleChkContFlightNeeded,
        }}
        showFollowFlightSection={isContinuationFlightEnabled()}
        followFlightTitle="CONTINUATION FLIGHT"
        followFlightStations={{
          origin: isNullOrWhitespace(irropStation.station) ? '---' : irropStation.station,
          destination,
        }}
        followFlightTurnTime={{
          value: turnTimeMinutes.value,
          onChange: handleTurnTimeTextInputChange,
          onBlur: handleTurnTimeOnBlur,
          warningType: turnTimeMinutes.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: turnTimeMinutes.error,
        }}
        followFlightETD={{
          value: contETD.hhmm,
          onChange: handleContinuationETDTextInputChange, //handleAddETDTextInputChange,
          warningType: contETD.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETD.error,
        }}
        followFlightETA={{
          value: contETA.hhmm,
          onChange: handleContinuationETATextInputChange, //handleAddETATextInputChange,
          warningType: contETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETA.error,
        }}
        showCanceledFlightSection={showCancelFlight}
        canceledFlightTitle="CANCEL  FLIGHT DETAILS"
        canceledFlightDetails={getCanceledFlightDetailBlocks()}
        canceledFlightCheckbox={{
          label: 'Cancel flight',
          value: checkedCancelFlight,
          onChange: handleChkCancelFlight,
        }}
        reason={{
          placeholderText: '',
          reasonText: selectReason.text,
          commentsTemplate: selectReason.template,
          isCommentsRequired: selectReason.isCommentsRequired,
          onSelect: handleOnSelectReason,
        }}
        comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
        isCommitEnabled={isCommitEnabled()}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
      <ConfirmationPopup
        showConfirmPopup={showConfirmPopup}
        handleCommitClick={handleConfirmButtonClick}
        handleHideClick={hideHandleConfirmReroute}
        handleOnKeyPress={handlehandleOnKeyPress}
        dataCyTag="cancel-flight"
        title="Confirm Cancel"
        containerText={popupContainerText()}
      />
    </AocAuthAccessCheck>
  );
};

RerouteFlight.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(RerouteFlight);
