import {
  PhaseOfFlight,
  SessionAction,
  AirlineFilter,
  ThemeMode,
  GroundEventType,
  OutOfServiceState,
  TimeZones,
} from './constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
/**
 * Returns the display string for a given SessionAction enum
 * @param {SessionAction} sessionAction
 */
export const getAssignmentSessionTitle = (sessionAction) => {
  switch (sessionAction) {
    case SessionAction.SELECT_ROLE:
      return 'Select Role';
    case SessionAction.CHANGE_ROLE:
      return 'Change Role';
    case SessionAction.SELECT_ASSIGNMENT:
      return 'Select Assignment';
    case SessionAction.CHANGE_ASSIGNMENT:
      return 'Change Assignment';
    case SessionAction.SELECT_ASSIGNMENT_AFTER_CHANGE_ROLE:
      return 'Select Assignment';
    default:
      return '';
  }
};

/**
 * Returns the display string for a given irrops code
 * @param {irropsCode} irropsCode
 */
export const getIrropsCodeLabel = (irropsCode) => {
  switch (irropsCode) {
    case 'DIV':
      return 'Divert';
    case 'ADD':
      return 'Add';
    case 'CXL':
      return 'Cancel';
    case 'RIN':
      return 'Reinstate';
    case 'RRT':
      return 'Reroute';
    case 'ATB':
      return 'Air Turn Back';
    case 'BTB':
      return 'Block Turn Back';
    case 'EQC':
      return 'Swap';
    case 'EQT':
      return 'Swap';
    case 'RDX':
      return 'Redispatch';
    case 'GTL':
      return 'Go Take a Look';
    case 'RET':
      return 'Return To Gate';
    default:
      return '';
  }
};

/**
 * Returns the display string for a given phase of flight
 * @param {*} phaseOfFlight
 * @returns the display string for the given phase of flight
 */
export const getPhaseOfFlightLabel = (phaseOfFlight) => {
  switch (phaseOfFlight) {
    case PhaseOfFlight.OUT:
      return 'Taxi out';
    case PhaseOfFlight.OFF:
      return 'En route';
    case PhaseOfFlight.ON:
      return 'Taxi in';
    case PhaseOfFlight.ON_ONLY:
      return 'Taxi in';
    case PhaseOfFlight.OFF_ONLY:
      return 'En route';
    case PhaseOfFlight.IN:
      return 'Completed';
    case PhaseOfFlight.IN_ONLY:
      return 'Completed';
    case PhaseOfFlight.OUT_IN_ONLY:
      return 'Completed';
    case PhaseOfFlight.ON_IN_ONLY:
      return 'Completed';
    case PhaseOfFlight.OFF_ON_ONLY:
      return 'En route';
    case PhaseOfFlight.PRE_FLIGHT:
      return 'Scheduled';
    case PhaseOfFlight.UNKNOWN:
      return 'Unknown';
    default:
      return '';
  }
};

/**
 * Returns the short time display string for a given ISO string
 * @param {string} datetime - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @param {bool} withZuluAbbreviation - whether or not 'z' should be appended to the string
 * @return {string} the display string, i.e. "02:11" or "02:11z"
 */
export const getUtcTimeDisplayString = (datetimeString, withZuluAbbreviation = false) => {
  let display = '';
  if (datetimeString) {
    display = dayjs.utc(datetimeString).format(`HH:mm${withZuluAbbreviation ? '[z]' : ''}`);
  }
  return display;
};

/**
 * Returns the MM/DD/YYYY format for an ISO date string
 * @param {string} datetime - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @return {string} the display string, i.e. "09/23/2020"
 */
export const getMonthYearDisplayString = (datetimeString) => {
  let display = '';
  if (datetimeString) {
    display = dayjs.utc(datetimeString).format('MM/DD/YYYY');
  }
  return display;
};

/**
 * Returns the MM/DD format for an ISO date string
 * @param {string} datetime - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @return {string} the display string, i.e. "09/23"
 */
export const getMonthDayDisplayString = (datetimeString) => {
  let display = '';
  if (datetimeString) {
    display = dayjs.utc(datetimeString).format('MM/DD');
  }
  return display;
};

/**
 * Returns the date string to be displayed in the flights and gantt view page
 *
 * @param {string} startDate - the operational start date string formatted as YYYY-MM-DD
 * @param {string} endDate - the operational end date string formatted as YYYY-MM-DD
 */
export const getFlightsHeaderDisplayDateString = (startDate, endDate, timezone = TimeZones.UTC) => {
  let displayString = '';

  if (!startDate || !endDate) {
    return displayString;
  }

  let startDateJS;
  let endDateJS;

  if (timezone === TimeZones.UTC) {
    startDateJS = dayjs(startDate).utc();
    endDateJS = dayjs(endDate).utc();
  } else {
    startDateJS = dayjs(startDate).tz(timezone, true).startOf('day');
    endDateJS = dayjs(endDate).tz(timezone, true).endOf('day');
  }

  if (startDateJS.format('DD/MM/YYYY') === endDateJS.format('DD/MM/YYYY')) {
    // November 12, 2020
    displayString = startDateJS.format('LL');
  } else if (startDateJS.year() === endDateJS.year()) {
    if (startDateJS.month() === endDateJS.month()) {
      // November 12 - 13, 2020
      displayString = `${startDateJS.format('MMMM D')} - ${endDateJS.date()}, ${startDateJS.year()}`;
    } else {
      // November 30 - December 2, 2020
      displayString = `${startDateJS.format('MMMM D')} - ${endDateJS.format('LL')}`;
    }
  } else {
    // December 31, 2020 - January 2, 2021
    displayString = `${startDateJS.format('LL')} - ${endDateJS.format('LL')}`;
  }

  return displayString;
};

/**
 * Returns an array of hex color code to fill background of each pies in the pie chart
 *
 * @param {boolean} isNAData - set as true when there is no data to breakdown the chart
 */
export const getColorSpectrumForPieChart = (isNAData = false) => {
  if (isNAData) {
    return ['var(--pie-background-default)'];
  }
  return [
    'var(--pie-background-1)',
    'var(--pie-background-2)',
    'var(--pie-background-3)',
    'var(--pie-background-4)',
    'var(--pie-background-5)',
    'var(--pie-background-6)',
    'var(--pie-background-7)',
    'var(--pie-background-8)',
    'var(--pie-background-9)',
    'var(--pie-background-10)',
    'var(--pie-background-11)',
  ];
};

/**
 * @description Returns airline name
 * @param {number} - Airline code
 * @returns {string} - Airline name
 */
export const getAirlineName = (value) => {
  let name = '';
  switch (value) {
    case AirlineFilter.AS:
      name = 'AS';
      break;
    case AirlineFilter.QX:
      name = 'QX';
      break;
    case AirlineFilter.OO:
      name = 'OO';
      break;
    default:
  }
  return name;
};

/**
 * @description Formats text by breaking into a new line if length > 15.
 * (Usage: chart tool tip label)
 * @param {string} - Unformatted text
 * @returns {string} - Formatted text
 */
export const getFormattedLabelText = (text) => {
  if (text.length <= 15) return text;

  let formatted = text.split(' ').reduce(function (text, str, index) {
    return `${text} ${index === 2 ? '\n' : ' '} ${str}`;
  });
  return formatted;
};

/**
 * @description Formats text by to lower case.
 * @param {string} - Unformatted text
 * @returns {string} - Formatted text
 */
export const toLowerCase = (text) => {
  if (text != null && text.toString().trim() !== '') {
    return (text = text.toLowerCase());
  }
};

/**
 * @description check value is null or white space.
 * @param {string} - string value
 * @returns {string} - true or false
 */
export const isNullOrWhitespace = (value) => {
  if (value != null && value.toString().trim() !== '') {
    return false;
  }
  return true;
};

/**
 * @description Gets the display strings for the theme submenu items
 * @param {string} theme - ThemeMode enum
 * @returns
 */
export const getThemeDisplayText = (theme) => {
  switch (theme) {
    case ThemeMode.CLASSIC:
      return 'Classic';
    case ThemeMode.MODERN_DARK:
      return 'Modern Dark';
    case ThemeMode.MODERN_LIGHT:
      return 'Modern Light';
    default:
      return '';
  }
};

/**
 * @description Gets the display string for a ground event puck
 * @param {Object} puckConfig - puckConfig object created by mapGroundEventToPuckConfig() in ganttHelpers
 * @returns
 */
export const getOutOfServicePuckLabel = (groundEventType, station, state, short = false) => {
  let prefix = groundEventType === GroundEventType.SCHEDULED_OTS && !short ? 'Scheduled ' : '';

  switch (state) {
    case OutOfServiceState.ADVISORY:
      return short ? 'ADV' : `${prefix}ADV - ${station}`;
    case OutOfServiceState.ESTIMATED_TIME_READY:
      return short ? 'ETR' : `${prefix}ETR - ${station}`;
    case OutOfServiceState.COMPLETED:
      return short ? 'OTS' : `${prefix}Out of Service - ${station}`;
    case OutOfServiceState.COMPLETED_ETR:
      return short ? 'OTS' : `${prefix}Out of Service - ${station}`;
    default:
      return '';
  }
};

/**
 * @description Gets the display string for a ground event puck
 * @param {Object} puckConfig - puckConfig object created by mapGroundEventToPuckConfig() in ganttHelpers
 * @returns
 */
export const getBlockPuckLabel = (station, short = false) => {
  const prefix = 'Block';
  return short ? prefix : `${prefix} - ${station}`;
};

/**
 * @description Display Irrops Status on flight number with asterisk
 * @param {Object} flightLeg - The flight leg object
 * @returns {String} - Return irrops flight with asterisk prefix
 */
export const displayFlightNumber = (lineOfFlight) => {
  if (lineOfFlight.irropsCode == null || lineOfFlight.irropsCode == 'EQT') {
    return lineOfFlight.flightNumber;
  }
  return `*${lineOfFlight.flightNumber}`;
};
