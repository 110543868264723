import { useQuery } from 'react-query';
import { getClassicStyle } from '../../services/apiClient/stylesApi/stylesApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useStylesQuery';

/**
 * Custom hook for retrieving data from the Styles API
 */
export const useStylesQuery = (enabled = false) => {
  const config = {
    // Setting stale time to 24 hours because styles data rarely changes.
    // This means that when any component mounts using this hook within the "fresh" time,
    // it will not fetch new data.
    staleTime: 24 * 60 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getClassicStyle', createApiErrorTelemetryProperties(error));
    },
    enabled: enabled,
  };
  return useQuery(['classicStyle'], getClassicStyle, config);
};
