import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import FlightTabTimeSchedule from '../TimeSchedule/FlightTabTimeSchedule';
import ZuluOffsetDisplay from '../../../../Shared/ZuluOffsetDisplay/ZuluOffsetDisplay';
import '../../FlightDetailPane.css';
import '../FlightTab.css';

/**
 * Displays the time/event information of a specified station
 * @param {string} title - Name of event (e.g Departure, Arrival, etc)
 * @param {string} station - Station of the event (e.g arrival station)
 * @param {Object[]} times - times (scheduled, estimated, projected, and actual) of events of interest (OOOI events)
 * @param {string} event1 - first event of interest
 * @param {string} event2 - second event of interest
 * @param {string} gateInfo - Gate information at the specified station. Defaults to "--".
 * @returns FlightTabStationDetail
 */
const FlightTabStationDetail = ({ title, station, times, event1, event2, gateInfo = '--' }) => {
  let time = times.find((t) => t.name === event1);
  let scheduledUtc = time?.scheduledTimeUTC;

  return (
    <div>
      <div className="flight-header-row">
        <div>
          <span className="flight-header">{title.toUpperCase()} DETAILS </span>
          <span className="flight-header-span">&nbsp;|&nbsp;{`${station} (${gateInfo})`}</span>
        </div>
        <div className="flight-zulu-offset">
          Zulu{' '}
          <ZuluOffsetDisplay
            utcDateTime={scheduledUtc}
            dataCyTag={`${event1.toLowerCase()}-zulu-offset`}
            station={station}
            format="HH"
          />{' '}
          hours
        </div>
      </div>

      <div className="flex-row-fill">
        <div className="flex-col-left" data-cy={`${event1.toLowerCase()}-time-schedule-block`}>
          <div>
            <FlightTabTimeSchedule event={event1} times={times} station={station} />
          </div>
        </div>
        <div className="flex-col-left" data-cy={`${event1.toLowerCase()}-time-schedule-block`}>
          <div>
            <FlightTabTimeSchedule event={event2} times={times} station={station} />
          </div>
        </div>
      </div>
    </div>
  );
};

FlightTabStationDetail.propTypes = {
  title: PropTypes.string,
  station: PropTypes.string,
  stationOffset: PropTypes.number,
  times: PropTypes.arrayOf(PropTypes.object),
  event1: PropTypes.string,
  event2: PropTypes.string,
};

export default withAppInsightsTracking(FlightTabStationDetail);
