import React from 'react';
import MaterialTooltip from '@mui/material/Tooltip';

const Tooltip = ({ title, isClicked, className, children, ...props }) => {
  return (
    <MaterialTooltip
      className={className}
      placement="top"
      title={title}
      arrow
      PopperProps={{
        zIndex: '9999',
        sx: {
          '& .MuiTooltip-tooltip': {
            width: 'auto', // Adjust width as needed
            maxWidth: '200px', // Set a maximum width
            height: 'auto', // Adjust height as needed
            padding: '3px 8px 3px 8px', // Adjust padding as needed
            fontSize: '7px', // Adjust font size as needed
            border: 'none',
            background: '#3E4258',
            marginBottom: '0px',
            boxShadow: isClicked ? '0px 0px 1px 0px #00000033' : 'none',
          },
          '& .MuiTooltip-arrow': {
            marginTop: '0px',
            padding: '0px',
            color: '#3E4258',
            border: 'none',
            boxShadow: isClicked ? '0px 0px 1px 0px #00000033' : 'none',
          },
        },
      }}
      {...props}
    >
      {children}
    </MaterialTooltip>
  );
};

export default Tooltip;
