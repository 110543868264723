import './NoDataMessageHeader.css';

/**
 * Component to display a message when no data is available in the gantt or table view
 * @param {string} message - message to display
 * @returns
 */
const NoDataMessageHeader = ({ message }) => {
  return (
    <div className="flight-list-warning-message" data-cy="flight-list-warning-message">
      {message}
    </div>
  );
};

export default NoDataMessageHeader;
