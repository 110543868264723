import { ResizeType } from './constants';

const MIN_WIDTH = 400;
const MIN_HEIGHT = 400;

/**
 * Calculate the new width for the element based on the resize operation.
 *
 * @param {ResizeType} resizeType - The type of resize operation
 * @param {Number} currentWidth - The current width of the element
 * @param {Number} clientX - The x position of the mouse
 * @param {Number} resizeWidthOffset - The offset value for the width
 * @returns
 */
export function getResizeWidth(resizeType, currentWidth, clientX, resizeWidthOffset) {
  let width;
  switch (resizeType) {
    case ResizeType.BOTTOM:
    case ResizeType.TOP:
      width = currentWidth;
      break;
    case ResizeType.RIGHT:
    case ResizeType.BOTTOM_RIGHT:
    case ResizeType.TOP_RIGHT:
      width = clientX + resizeWidthOffset;
      break;
    case ResizeType.LEFT:
    case ResizeType.BOTTOM_LEFT:
    case ResizeType.TOP_LEFT:
      width = resizeWidthOffset - clientX;
      break;
    default:
      break;
  }

  return width < MIN_WIDTH ? MIN_WIDTH : width;
}

/**
 * Calculate the new height for the element based on the resize operation.
 *
 * @param {ResizeType} resizeType - The type of resize operation
 * @param {Number} currentWidth - The current width of the element
 * @param {Number} clientY - The y position of the mouse
 * @param {Number} resizeHeightOffset - The offset value for the height
 * @returns {Object} - The new width heightfor the element
 */
export function getResizeHeight(resizeType, currentHeight, clientY, resizeHeightOffset) {
  let height;

  switch (resizeType) {
    case ResizeType.RIGHT:
    case ResizeType.LEFT:
      height = currentHeight;
      break;
    case ResizeType.BOTTOM:
    case ResizeType.BOTTOM_RIGHT:
    case ResizeType.BOTTOM_LEFT:
      height = clientY + resizeHeightOffset;
      break;
    case ResizeType.TOP:
    case ResizeType.TOP_LEFT:
    case ResizeType.TOP_RIGHT:
      height = resizeHeightOffset - clientY;
      break;
    default:
      break;
  }

  return height < MIN_HEIGHT ? MIN_HEIGHT : height;
}

/**
 * Calculate the new values for the element based on the resize operation.
 *
 * @param {ResizeType} resizeType - The type of resize operation
 * @param {Number} currentWidth - The current width of the element
 * @param {Number} currentHeight - The current height of the element
 * @param {Number} currentTranslateX - The current x position of the element
 * @param {Number} currentTranslateY - The current y position of the element
 * @param {Number} clientX - The x position of the mouse
 * @param {Number} clientY - The y position of the mouse
 * @param {Number} transformOffset - The offset value for the transform
 * @returns {Object} - The new width, height, and x, y positions for the element
 */
export function getResizeTransform(
  resizeType,
  currentWidth,
  currentHeight,
  currentTranslateX,
  currentTranslateY,
  clientX,
  clientY,
  transformOffset,
) {
  const getAdjustedTranslateX = (width, newX, currentX) => {
    return width <= MIN_WIDTH ? currentX : newX;
  };

  const getAdjustedTranslateY = (height, newY, currentY) => {
    return height <= MIN_HEIGHT ? currentY : newY;
  };

  let translateX, translateY;

  switch (resizeType) {
    case ResizeType.RIGHT:
    case ResizeType.BOTTOM:
    case ResizeType.BOTTOM_RIGHT:
      translateX = currentTranslateX;
      translateY = currentTranslateY;
      break;
    case ResizeType.LEFT:
    case ResizeType.BOTTOM_LEFT:
      translateX = getAdjustedTranslateX(currentWidth, clientX - transformOffset.x, currentTranslateX);
      translateY = currentTranslateY;
      break;
    case ResizeType.TOP:
    case ResizeType.TOP_RIGHT:
      translateX = currentTranslateX;
      translateY = getAdjustedTranslateY(currentHeight, clientY - transformOffset.y, currentTranslateY);
      break;
    case ResizeType.TOP_LEFT:
      translateX = getAdjustedTranslateX(currentWidth, clientX - transformOffset.x, currentTranslateX);
      translateY = getAdjustedTranslateY(currentHeight, clientY - transformOffset.y, currentTranslateY);
      break;
    default:
      break;
  }

  return { translateX, translateY };
}
