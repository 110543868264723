import React, { useState } from 'react';
import Modal from '../../Shared/Modal/Modal';
import PropTypes from 'prop-types';
import FilterContainer from './FilterContainer/FilterContainer';
import { useFilterStore } from '../../../hooks/useFilterStore/useFilterStore';
import Button from '../../Shared/Button/Button';
import { AlertFilter, KeyCodes, RefetchPageData } from '../../../lib/constants';
import { useAircraftList } from '../../../hooks/useAircraftList/useAircraftList';
import { useCancelQuery } from '../../../react-query/useCancelQuery/useCancelQuery';

import './FilterModal.css';

/**
 * The Filter Modal is the parent component of the filters.
 * It is responsible for reading and updating the filter store.
 * @param {bool} showFilterModal - whether to open the modal or not
 * @param {bool} isDisabled - whether applying a filter is disabled or not
 * @param {Function} setShowFilterModal - callback to show/hide modal
 * @param {Function} onFilterClick - callback to apply the filter
 * @param {Function} onFilterClearClick - callback to clear the filter
 * @returns FilterModal component
 */
export const FilterModal = ({ showFilterModal, setShowFilterModal, onFilterClick, onFilterClearClick }) => {
  const { filter, timezone } = useFilterStore();
  let updateInitialFilters = { ...filter };

  const [updatedFilter, setUpdatedFilter] = useState(updateInitialFilters);
  const [clearFilter, setClearFilter] = useState(false);

  const startDate = filter.startDate;
  const endDate = filter.endDate;
  const aircraftList = useAircraftList(startDate, endDate);

  const { cancelQuery } = useCancelQuery();

  const isFilterButtonDisabled = () => {
    // TODO fix logic and unit test
    return (
      (updatedFilter.startDate === null || !updatedFilter.startDate._isValid) &&
      (updatedFilter.endDate === null || !updatedFilter.endDate._isValid) &&
      updatedFilter.flight === '' &&
      updatedFilter.aircraft.length < 6 &&
      updatedFilter.origin.length < 3 &&
      updatedFilter.destination.length < 3 &&
      updatedFilter.alerts === AlertFilter.NO_FILTER
    );
  };

  // FOOTER action buttons
  const formButtons = [
    <Button
      variant="primary"
      className="filter-modal-footer-button apply"
      tabIndex="0"
      onClick={() => handleFilterButtonClick()}
      data-cy="nav-filter-apply-btn"
      isDisabled={isFilterButtonDisabled()}
      onKeyDown={(event) => handleFilterButtonKeyDown(event)}
    >
      Apply
    </Button>,
    <Button
      variant="secondary"
      className="filter-modal-footer-button clear"
      tabIndex="0"
      onClick={() => clearFilters()}
      data-cy="nav-filter-clear-btn clear"
      onKeyDown={(event) => handleClearButtonKeyDown(event)}
    >
      Clear
    </Button>,
  ];

  const handleFilterButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ENTER) {
      handleFilterButtonClick();
    }
  };

  /**CLEAR Button Key Down */
  const handleClearButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ENTER) {
      clearFilters();
    }
  };

  // APPLY Button Clicked
  const handleFilterButtonClick = () => {
    //Retain the hide canceled state
    onFilterClick({
      ...updatedFilter,
    });
    cancelQuery(RefetchPageData.GANTT);
    cancelQuery(RefetchPageData.FLIGHT_LIST_TABLE);
    setShowFilterModal(false);
  };

  /**sfunction to clear data
   */
  const clearFilters = () => {
    setUpdatedFilter(updateInitialFilters);
    setClearFilter(true);
    onFilterClearClick();
  };

  // handle UPDATED filter data
  const handleHandleFilterUpdate = (filter) => {
    setUpdatedFilter(filter);
  };

  //Handle click event of close button on the header to reset any field changes if 'apply'  button is not clicked
  const handleCloseButtonOnClick = () => {
    setUpdatedFilter(updateInitialFilters);
    setShowFilterModal(false);
  };
  const handleClearFilter = () => {
    setClearFilter(false);
  };
  //Runs on the first render
  React.useEffect(() => {
    //show filter modal dependency changes update initial filters value
    setUpdatedFilter(updateInitialFilters);
  }, [showFilterModal, clearFilter]);

  //Runs every time updateInitialFilters changes
  React.useEffect(() => {
    //show filter modal dependency changes update initial filters value
    if (updateInitialFilters.isDefaultFilter && !updatedFilter.isDefaultFilter) {
      setUpdatedFilter(updateInitialFilters);
    }
  }, [updateInitialFilters]);

  return (
    <Modal
      show={showFilterModal}
      title={'Filters'}
      customCSSTag="new-filter-modal"
      body={
        <FilterContainer
          onCloseModal={showFilterModal}
          className="new-flight-list-filter-modal-container"
          onFilterUpdate={handleHandleFilterUpdate}
          initialFilter={updatedFilter}
          aircraftList={aircraftList}
          timeZone={timezone}
          isClearFilter={clearFilter}
          handleClearFilter={handleClearFilter}
        />
      }
      footerButtons={formButtons}
      onHide={setShowFilterModal}
      closeButtonOnClick={handleCloseButtonOnClick}
    />
  );
};

FilterModal.propTypes = {
  showFilterModal: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  setShowFilterModal: PropTypes.func,
  onFilterClick: PropTypes.func,
};
