import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import ActivityLogDetail from '../ActivityLogDetail/ActivityLogDetail';
import './ActivityLogGroup.css';
import { formatDateTime, getTimeDifference } from '../../../../../lib/dateTimeUtils';

/**
 * ActivityLogGroup renders the activity log group and the date (for the activity log group).
 * @param {string} activityDate - date of the activity log group
 * @param {object} activityLogDetails - activity log group mapped to activityDate. Includes the note, time and tag of activity log
 * @returns
 */
const ActivityLogGroup = ({ activityDate, activityLogDetails, isHorizontal = false }) => {
  return (
    <>
      <div className="activity-log-detail-date" data-cy="activity-log-detail-date">
        {formatDateTime(activityDate, 'MMM DD YYYY').toUpperCase()}
      </div>
      {activityLogDetails
        .sort((a, b) => {
          return getTimeDifference(a.activityDateTime, b.activityDateTime, 'second');
        }) //Sort by time
        .map((activityDetail) => {
          return (
            <ActivityLogDetail
              isHorizontal={isHorizontal}
              activityDateTime={activityDetail.activityDateTime}
              activityNote={activityDetail.activityNote}
              activityTag={activityDetail.activityTag}
            />
          );
        })}
    </>
  );
};

ActivityLogGroup.propTypes = {
  activityDate: PropTypes.string,
  activityLogDetails: PropTypes.object,
  isHorizontal: PropTypes.bool,
};

export default withAppInsightsTracking(ActivityLogGroup);
