import { axiosInstance } from '../apiClient';

const API_ROUTE = 'groundevent';

/**
 * Retrieves ground events given a start and end date. Includes:
 * 1. Ground events whose duration is between startDateUtc and endDateUtc
 * 2. Ground events that start before startDateUtc and end after endDateUtc
 * 3. Ground events that start before startDateUtc and end on/before endDateUtc
 * 4. Ground events that start between startDateUtc and endDateUtc, and ends after endDateUtc
 * @param {string} startDateUtc - YYYY-MM-DD formatted start date
 * @param {string} endDateUtc - YYYY-MM-DD formatted end date
 * @returns axios response
 */
export const getGroundEvents = async (startDateUtc, endDateUtc) => {
  const apiClient = axiosInstance;
  const endDateParam = !endDateUtc ? '' : `&endDateUTC=${endDateUtc}`;
  const url = `${API_ROUTE}/api/v2/GroundEvents?startDateUTC=${startDateUtc}${endDateParam}`;

  const response = await apiClient.get(url);
  return response;
};

/**
 * Retrieves ground events using V3 API given a start and end date. Includes:
 * 1. Ground events whose duration is between startDateTimeUTC and endDateTimeUTC
 * 2. Ground events that start before startDateTimeUTC and end after endDateTimeUTC
 * 3. Ground events that start before startDateTimeUTC and end on/before endDateTimeUTC
 * 4. Ground events that start between startDateTimeUTC and endDateTimeUTC, and ends after endDateTimeUTC
 * @param {string} startDateTimeUTC - ISO formatted start date
 * @param {string} endDateTimeUTC - ISO formatted end date
 * @returns axios response
 */
export const getGroundEventsV3 = async (startDateTimeUTC, endDateTimeUTC) => {
  const apiClient = axiosInstance;
  const endDateParam = !endDateTimeUTC ? '' : `&endDateUTC=${endDateTimeUTC}`;
  const url = `${API_ROUTE}/api/v3/GroundEvents?startDateUTC=${startDateTimeUTC}${endDateParam}`;

  const response = await apiClient.get(url);
  return response;
};
