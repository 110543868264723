import React, { useState } from 'react';
import { ReactComponent as SwapIcon } from '../../../../assets/images/flight-swap-icon.svg';
import './SwapButton.css';
import { useSwapModeDispatch, useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { useAutoRefreshDispatch, useAutoRefreshStore } from '../../../../hooks/useAutoRefreshStore/useAutoRefreshStore';
import { AutoRefreshStorageKeys, KeyCodes, ScrollLocalStorageElementId } from '../../../../lib/constants';
import LeaveSwapModeModal from '../LeaveSwapModeModal/LeaveSwapModeModal';
import { useMultiSelectedFlightGroupDispatch } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';
import { clearComponentScrollState } from '../../../../lib/utils';
import {
  useSelectedItemStore,
  useSelectedItemDispatch,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useFocusedFlightLegDispatch } from '../../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFlightPuckRefsDispatch } from '../../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
dayjs.extend(utc);

/**
 * The SwapMode component renders the Swap flight button
 * It provides the toggling feature between Active and Inactive of Swap Button
 * @returns SwapMode button
 */

export const SwapButton = () => {
  // useSwapMode hook
  const { enableSwapMode, clearSwapMode } = useSwapModeDispatch();
  // useSwapModeStore
  const { isSwapModeActive } = useSwapModeStore();
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  //aout refresh
  const { updateAutoRefresh } = useAutoRefreshDispatch();
  const autoRefreshFlags = useAutoRefreshStore();
  const selectedFlightDetails = useSelectedItemStore();
  const { clearSelectedFlightDetails } = useSelectedItemDispatch();
  const { setFocusedFlightLeg } = useFocusedFlightLegDispatch();
  const { removeFlightPuckRef } = useFlightPuckRefsDispatch();

  // handler for setting swap-mode-active
  const swapModeActiveClassHandler = () => (!isSwapModeActive ? 'swap-inactive-mode' : 'swap-active-mode');

  // Event trigger on click event of swap icon
  const swapHandler = () => {
    if (!isSwapModeActive) {
      if (selectedFlightDetails?.data) {
        const swapStartDate = dayjs.utc().startOf('day');
        if (dayjs(selectedFlightDetails.data?.etd).isAfter(swapStartDate)) {
          setFocusedFlightLeg(selectedFlightDetails.data);
          removeFlightPuckRef();
        } else {
          clearSelectedFlightDetails();
        }
      }
      enableSwapMode();

      if (autoRefreshFlags?.ganttChartRefresh) {
        updateAutoRefresh(AutoRefreshStorageKeys.GANTT, false);
      }
    } else {
      setShowConfirmPopup(true);
    }
  };

  //Event trigger after leave button is clicked on LeaveSwapModeModal
  const handleConfirmLeaveClick = () => {
    setShowConfirmPopup(false);
    clearSwapMode();
    removeAllMultiSelectedFlightGroup();
    clearComponentScrollState([ScrollLocalStorageElementId.GANTT_CHART, ScrollLocalStorageElementId.FLIGHTLIST_TABLE]);
    if (!autoRefreshFlags?.ganttChartRefresh) {
      updateAutoRefresh(AutoRefreshStorageKeys.GANTT, true);
    }
  };

  //Event trigger after close LeaveSwapModeModal clicked
  const hideHandleConfirmCancel = () => {
    setShowConfirmPopup(false);
  };

  //Event trigger after any key press on LeaveSwapModeModal
  const handleConfirmKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && showConfirmPopup) {
      setShowConfirmPopup(false);
      clearSwapMode();
      removeAllMultiSelectedFlightGroup();
    }
  };

  return (
    <>
      <div
        data-cy="flight-swap-button"
        className={`flight-swap flight-nav-bar-item ${swapModeActiveClassHandler()}`}
        onClick={() => swapHandler()}
      >
        <SwapIcon data-cy="flight-swap-icon" className="flight-swap-icon icon" />
      </div>
      <LeaveSwapModeModal
        showConfirmPopup={showConfirmPopup}
        handleLeaveClick={handleConfirmLeaveClick}
        handleOnKeyPress={handleConfirmKeypress}
        handleHideClick={hideHandleConfirmCancel}
        dataCyTag="leave-swap-mode"
        title="Leave Swap Mode?"
        containerText={`You will lose unsaved changes if you leave swap mode.`}
      />
    </>
  );
};
