import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import './AddFlightButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';

/**
 * The AddFlightButton component is plus icon to open add flight modal.
 * @param {Object} props -
 *  onClickAddButton - callback func when click on add button icon
 * @returns AddFlightButton component
 */

const AddFlightButton = ({ onClickAddButton }) => {
  // useSwapModeStore store
  const { isSwapModeActive } = useSwapModeStore();
  const disableHandler = () => (isSwapModeActive ? 'disabled' : '');

  return (
    <div className={`add-button-container ${disableHandler()}`} onClick={onClickAddButton}>
      <FontAwesomeIcon className="add-flight-icon add-btn" icon={faPlus} data-cy="add-irrops-flight-btn" />
    </div>
  );
};

AddFlightButton.propTypes = {
  onClickAddButton: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(AddFlightButton);
