import React from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import './TabSkeletonSection.css';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * TabSkeletonSection renders a section of Tab skeleton, for flight detail pane
 * @returns
 */
const TabSkeletonSection = () => {
  const skeletonHtml = (
    <React.Fragment>
      <Skeleton animation="wave" className="flight-tab-skeleton-text-1" />
      <Skeleton animation="wave" className="flight-tab-skeleton-text-2" />
      <Skeleton animation="wave" className="flight-tab-skeleton-text-3" />
    </React.Fragment>
  );

  const contentToRender = (
    <React.Fragment>
      {skeletonHtml}
      {skeletonHtml}
      <div className="flight-tab-skeleton-separator"></div>
    </React.Fragment>
  );

  return (
    <div className="flight-detail-panel-skeleton-section" data-cy="flight-detail-panel-skeleton-section">
      {contentToRender}
    </div>
  );
};

TabSkeletonSection.propTypes = {};

export default withAppInsightsTracking(TabSkeletonSection);
