import React from 'react';
import PropTypes from 'prop-types';
import useAuthorizationAccess from '../../hooks/useAuthorizationAccess/useAuthorizationAccess';

/**
 * @description Check a user's access to child elements.
 * @param {Object} children - Child DOM elements to render if access is allowed.
 * @param {Array} approvedRoles - Array of role names that are approved to render the child elements.
 * @param {Array} approvedEntitlements - Array of entitlement names that are approved to render the child elements.
 * @param {Array} deniedRoles - Array of role names that are denied from rendering the child elements.  Denied roles supersede Approved roles.
 * @returns {Object} - DOM elements or empty fragment.
 */
const AocAuthAccessCheck = ({
  children,
  approvedAirline = '',
  approvedRoles = [],
  approvedEntitlements = [],
  deniedRoles = [],
}) => {
  // Check if the user should have access to the child elements based on the props.
  const isAccessGranted = useAuthorizationAccess(approvedRoles, approvedEntitlements, deniedRoles, approvedAirline);
  // Display the children if access is allowed
  // otherwise return empty fragment.
  return <>{isAccessGranted ? children : null}</>;
};

AocAuthAccessCheck.propTypes = {
  children: PropTypes.node.isRequired,
  approvedRoles: PropTypes.arrayOf(PropTypes.string),
  approvedEntitlements: PropTypes.arrayOf(PropTypes.string),
  deniedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default AocAuthAccessCheck;
