import React from 'react';
import PropTypes from 'prop-types';
import './EarlyDelayDisplay.css';

/**
 * @description Given a time difference, return the correct display
 * - green for early (negative difference)
 * - red for delay (positive difference)
 *
 * difference can be calculated like so:
 * getTimeDifference(startDateTime,endDateTime)
 *
 * @param {number} difference - the time difference to display
 * @param {string} label - a label to append to the time difference
 * @returns display of the difference
 */
const EarlyDelayDisplay = ({ difference, label = '' }) => {
  if (difference === 0) return <></>;

  label = label ? ` ${label}` : '';

  return difference < 0 ? (
    <span data-cy="early-delay-display" className="time-diff-label-green">{`(${difference}${label})`}</span>
  ) : (
    <span data-cy="early-delay-display" className="time-diff-label-red">{`(+${difference}${label})`}</span>
  );
};

export default EarlyDelayDisplay;

EarlyDelayDisplay.propTypes = {
  difference: PropTypes.number.isRequired,
  label: PropTypes.string,
};
