import { FocusedFlightLegActions } from '../../redux/actionTypes';

// initial state values
const initialState = {
  isFocused: false,
  focusedFlightLeg: null,
  highlightFlightLeg: null,
};

const focusedFlightLegReducer = (state = initialState, action) => {
  switch (action.type) {
    case FocusedFlightLegActions.SET_FOCUSED_FLIGHT_LEG:
      return {
        ...state,
        isFocused: false,
        focusedFlightLeg: action.payload.flightLeg,
      };
    case FocusedFlightLegActions.SET_IS_FOCUSED:
      return {
        ...state,
        isFocused: action.payload.isFocused,
      };
    case FocusedFlightLegActions.HIGHLIGHT_FOCUSED_FLIGHT_LEG:
      return {
        ...state,
        highlightFlightLeg: action.payload.highlightFlightLeg,
      };
    case FocusedFlightLegActions.CLEAR_FOCUSED_FLIGHT_LEG:
      return { ...initialState };
    default:
      return state;
  }
};

export default focusedFlightLegReducer;
