import React, { useState } from 'react';
import './ScalingButton.css';
import { ReactComponent as ScalingIcon } from '../../../assets/images/scaling-icon.svg';
import ScalingModal from '../ScalingModal/ScalingModal';

/**
 * The ScalingButton component is Scaling icon
 * @returns ScalingButton component
 */
const ScalingButton = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  /**
   * @description handling closing add flight modal.
   */
  const handleModalCancel = () => {
    setIsShowModal(false);
  };

  const onClickScalingButton = () => {
    setIsShowModal(true);
  };

  return (
    <>
      <div className={`scaling-button-container`} onClick={onClickScalingButton} data-cy="scaling-button">
        <ScalingIcon data-cy="scaling-button-icon" className="scaling-icon" />
      </div>
      <ScalingModal onClose={handleModalCancel} showModal={isShowModal} />
    </>
  );
};

export default ScalingButton;
