import React, { useCallback, useMemo } from 'react';
import BrickContainer from '../../BrickContainer/BrickContainer';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import PropTypes from 'prop-types';
import { WarningTypes } from '../../../../lib/constants';
import './TimeInput.css';

const useStyles = makeStyles((theme) => ({
  rootStyle: {
    height: '4.4rem',
    borderRadius: '0.3rem',
  },
  normalStyle: {
    '& ': {
      border: '2px solid var(--secondary-border-color) !important',
    },
    '&:hover': {
      border: '2px solid var(--secondary-hover-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--secondary-hover-color) !important',
    },
  },
  warningStyle: {
    '& ': {
      border: '2px solid var(--warning-color) !important',
    },
    '&:hover': {
      border: '2px solid var(--warning-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--warning-color) !important',
    },
  },
  errorStyle: {
    '& ': {
      border: '2px solid var(--error-color) !important',
    },
    '&:hover': {
      border: '2px solid var(--error-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--error-color) !important',
    },
  },
  warningLabel: {
    fontSize: '1.2rem',
    position: 'absolute',
    paddingTop: '2px',
  },
  customStyle: {
    height: '4.4rem',
    borderRadius: '0.3rem',
    backgroundColor: 'transparent',
  },
}));

const validStrictRegEx = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
const validEasyRegEx = new RegExp(/^(([0-2]|0[0-9]|1[0-9]|2[0-3])(:([0-5]|[0-5][0-9])?)?)?$/);
/**
 * TimeInput is a Controlled Component and renders time in the formt
 * of HH:MM z.
 * @param {string} label - label of the time input
 * @param {string} value - the value of this component
 * @param {bool} isRequired - creates red asterisk on the label
 * @param {func} onChange - callback function when the input is changed
 * @param {func} onKeyDown - callback function when key pressed down on input control
 * @param {func} onBlur - callback function when user moves away from time input field
 * @param {enum} warningType - "error" or "warning", will drive either the red or orange outline and message
 * @param {string} warningMessage - message to display under label
 * @param {string} dataCyTag - test id attribute
 * @param {bool} isAutoFocus - set focus
 */
const TimeInput = ({
  label,
  value,
  isRequired,
  onChange = () => {
    /* this is intentional */
  },
  onKeyDown = () => {
    /* this is intentional */
  },
  onBlur = () => {
    /* this is intentional */
  },
  warningType = WarningTypes.NONE,
  warningMessage = '',
  dataCyTag = '',
  isAutoFocus = false,
  tzIndicator = 'zulu',
  className = '',
}) => {
  const classes = useStyles();

  const getAppropriateMinutes = useCallback((numStr, isStrictFormat = false) => {
    let nmbr = parseInt(numStr);
    if (numStr.substr(0, 1) === '0') {
      if (numStr.length >= 2) {
        return numStr.substr(0, 2);
      }
      return isStrictFormat ? '00' : '0'; // if it is strict mode and minutes is one character 0, then return 2 characters 00
    }
    if (nmbr <= 5) {
      return isStrictFormat ? nmbr * 10 : nmbr;
    } else if (nmbr > 5 && nmbr <= 9) {
      return isStrictFormat ? 59 : 5;
    } else if (nmbr > 59) {
      return 59;
    }
    return nmbr;
  }, []);

  /**
   * @description gets full 5 digit hh:mm time value
   * @param {string} val  hour time value
   * @param {bool} isStrictFormat whether the time value should be calculated using strict rules
   */
  const getTimeValueHoursGiven = useCallback((val, isStrictFormat = false) => {
    let inputNums = val.replace(/[^0-9]/g, '');
    if (isStrictFormat) {
      if (inputNums.length === 1) return '0' + inputNums + ':00';
      else if (inputNums.length === 2)
        return parseInt(inputNums) <= 23
          ? inputNums + ':00'
          : '0' + inputNums.substr(0, 1) + ':' + getAppropriateMinutes(inputNums.substr(1, 1), isStrictFormat);
    } else {
      if (inputNums.length === 1 && parseInt(inputNums) > 2) return '0' + inputNums + ':';
      else if (inputNums.length === 2 && parseInt(inputNums) > 23)
        return '0' + inputNums.substr(0, 1) + ':' + getAppropriateMinutes(inputNums.substr(1, 1), isStrictFormat);
    }
    return val;
  }, []);

  /**
   * @description gets 5 digit hh:mm time value
   * @param {string} inputNums input time value
   * @param {bool} isStrictFormat whether the time value should be calculated using strict rules
   */
  const getTimeValueHoursAndMinutesGiven = useCallback((inputNums, isStrictFormat = false) => {
    return inputNums.replace(/^(\d{2})(\d{0,2})(\d*)$/, (match, v1, v2) => {
      if (v1 > 23) {
        let hoursPart = '0' + v1.substr(0, 1) + ':';
        let secondsPart = getAppropriateMinutes(v1.substr(1, 1) + v2.substr(0, 1), isStrictFormat);
        return hoursPart + secondsPart;
      } else {
        return `${v1 + ':' + getAppropriateMinutes(v2, isStrictFormat)}`;
      }
    });
  }, []);
  /**
   * @description gets formatted time object in HH:MM. we dont need strictly HH:MM all the time, especialy while the user is still typing on the input.
   * while the user is typing, we set isStrictFormat = false so that we go easy on the formatting to give user good typing experience.
   * after the user is done typing and onBlur, we can get strictly formatted value of the typed string where we set isStrictFormat = true
   * @param {string} val input string we want to format to HH:MM
   * @param {bool} isStrictFormat wheter we want to format the string strictly HH:MM or can it be loosely formatted to user typing experience while the user is still typing on input control
   */
  const getFormattedTimeInputValue = useCallback((val, isStrictFormat = false) => {
    if (val == null || val.length === 0) {
      return '';
    }

    if (validStrictRegEx.test(val)) {
      return val;
    }

    let inputNums = val.replace(/[^0-9]/g, '');

    let valProcessed = getTimeValueHoursGiven(val, isStrictFormat);

    if (valProcessed !== val) {
      return valProcessed;
    }
    if (inputNums.length > 2) {
      return getTimeValueHoursAndMinutesGiven(inputNums, isStrictFormat);
    }
    return inputNums;
  }, []);

  /**
   * @description calls onChange function when there is a need. if the changed value is not equals to current value coming from the parent component
   * @param {string} val input string we want to format to HH:MM
   * @param {bool} isStrictFormat wheter we want to format the string strictly HH:MM or can it be loosely formatted to user typing experience while the user is still typing on input control
   */
  const callOnChangeOnCondition = useCallback(
    (inputVal, isStrictFormat = false) => {
      let updatedInput = getFormattedTimeInputValue(inputVal, isStrictFormat);
      if (value !== updatedInput) {
        onChange(updatedInput);
      }
    },
    [value, getFormattedTimeInputValue, onChange],
  );

  /**
   * @description handles change and calls onChange event to parent with right parameters
   * @param {object} e onChange event object
   */
  const handleChange = (e) => {
    callOnChangeOnCondition(e.target.value);
  };

  /**
   * @description handles blur event and calls onBlur event to parent with right parameters.
   * @param {object} e onBlur event object
   */
  const handleBlur = (e) => {
    // if we can improve the value with strict formatting, calling onChange before blur is our best chance.
    // in some cases, parents may not need to use onBlur handler. calling onChange which is set to update changes will help in all cases.
    callOnChangeOnCondition(e.target.value, true);
    onBlur(e);
  };

  /**
   * @description only want to run this useEffect on First Render. thus empty dependencies. ignore the eslint warnings.
   */
  const getInputValue = useMemo(() => {
    if (!validEasyRegEx.test(value)) {
      callOnChangeOnCondition(value);
      return '';
    }
    return value;
  }, [value, callOnChangeOnCondition]);

  /**
   * @description get warning div style
   * @returns color style string
   */
  const getWarningStyle = () => {
    if (warningType === WarningTypes.WARNING) {
      return 'var(--warning-color)';
    } else {
      if (warningType === WarningTypes.ERROR) {
        return 'var(--error-color)';
      } else {
        return '';
      }
    }
  };

  const getClassStyle = (typeOfWarning) => {
    let classStyle = classes.normalStyle;
    if (typeOfWarning === WarningTypes.WARNING) {
      classStyle = classes.warningStyle;
    } else {
      if (typeOfWarning === WarningTypes.ERROR) {
        classStyle = classes.errorStyle;
      }
    }
    return classStyle;
  };

  return (
    <BrickContainer title={label} isRequired={isRequired} customCSSTag="timeinput-container">
      <OutlinedInput
        value={getInputValue}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        fullWidth={true}
        className={`timeinput ${className} ${classes.customStyle} ${getClassStyle(warningType)}`}
        endAdornment={<InputAdornment position="end">{tzIndicator}</InputAdornment>}
        inputProps={{
          'data-cy': dataCyTag + '-input',
          className: className,
        }}
        notched={false}
        data-cy={dataCyTag}
        labelWidth={0}
        autoFocus={isAutoFocus}
      />
      {warningType !== WarningTypes.NONE ? (
        <div
          data-cy={`${dataCyTag}-validation-message`}
          style={{ color: `${getWarningStyle()}` }}
          className={classes.warningLabel}
        >
          {' '}
          {warningMessage}{' '}
        </div>
      ) : (
        <></>
      )}
    </BrickContainer>
  );
};

export default withAppInsightsTracking(TimeInput);

TimeInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  warningType: PropTypes.string,
  warningMessage: PropTypes.string,
  dataCyTag: PropTypes.string,
  className: PropTypes.string,
};
