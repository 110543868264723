import React, { useEffect, useState } from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import Button from '../../Shared/Button/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import lodashOrderBy from 'lodash/orderBy';
import lodashGet from 'lodash/get';
import { getAllAssignments } from '../../../services/apiClient/administrationApi/administrationApi';
import { assignmentsTableConfig } from './assignmentsTableConfig';
import AssignmentForm from './AssignmentForm/AssignmentForm';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../lib/constants';

import '../administration.css';

const ManageAssignments = () => {
  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('Admin - Manage Assignments Page');

  const Ascending = 'asc';
  const Descending = 'desc';

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [sort, setSort] = useState({
    orderBy: 'name',
    orderDirection: Ascending,
  });
  const [assignmentForm, setAssignmentForm] = useState({
    show: false,
    existingAssignment: null,
  });

  /**
   * @description Flatten a json data set for displaying in the table.
   * @param {Array} dataSet - data returned from the admin api for assignments.
   * @returns An array of objects for use in the table.
   */
  const flattenDataSet = (dataSet) => {
    return dataSet.map((row) => {
      return {
        assignmentKey: row.assignmentKey,
        name: row.assignmentName,
        description: row.description,
        operatingAirline: row.operatingAirline,
        criteria: row.criteria?.criteriaName,
        typeName: row.typeName,
        modifiedBy: row.modifiedBy,
        modifiedDate: row.modifiedDate,
        createdBy: row.createdBy,
        createdDate: row.createdDate,
      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      let response = await getAllAssignments();

      if (response) {
        let dataSet = flattenDataSet(response.assignments);
        const sortedRolesData = lodashOrderBy(dataSet, [(row) => lodashGet(row, 'name')?.toLowerCase()], [Ascending]);
        setData({
          assignments: sortedRolesData,
          criteria: response.criteria,
        });
      }

      setLoading(false);
    };

    getData();
  }, []);

  /**
   * @description Handle a sort request by the user clicking a column header.
   * @param {string} columnKey - The key name of the column to be sorted.
   */
  const handleSort = (columnKey) => {
    const isSortedAscending = sort.orderBy === columnKey && sort.orderDirection === Ascending;

    // Change the sort direction if we aren't specifying that the sort should remain the same.
    const newOrderDirection = isSortedAscending ? Descending : Ascending;

    // Start sort
    const sortedData = lodashOrderBy(
      data.assignments,
      [(row) => lodashGet(row, columnKey)?.toLowerCase()],
      [newOrderDirection],
    );
    setData({ ...data, assignments: sortedData });
    setSort({ orderBy: columnKey, orderDirection: newOrderDirection });
  };

  const handleAddAssignmentClick = () => {
    setAssignmentForm({ show: true, existingAssignment: null });
  };

  const handleEditAssignmentClick = (row, col) => {
    if (col.columnKey === 'edit') {
      setAssignmentForm({ show: true, existingAssignment: row });
    }
  };

  const handleCancelClick = () => {
    setAssignmentForm({ show: false, existingAssignment: null });
  };

  //Reload assignment list after saving changes in form
  const handleAssignmentSave = () => {
    // call get all roles API again and refresh the table
    const getData = async () => {
      let response = await getAllAssignments();

      if (response) {
        let dataSet = flattenDataSet(response.assignments);
        const sortedRolesData = lodashOrderBy(dataSet, [(row) => lodashGet(row, 'name')?.toLowerCase()], [Ascending]);
        setData({
          assignments: sortedRolesData,
          criteria: response.criteria,
        });
      }

      setLoading(false);
    };

    // reset states
    setAssignmentForm({ show: false, existingAssignment: null });
    setLoading(true);
    getData();
  };

  let contentToLoad;

  if (loading) contentToLoad = <LoadingIndicator />;
  else if (data)
    contentToLoad = (
      <div className="manage-assignments-content">
        {assignmentForm.show && (
          <AssignmentForm
            existingAssignment={assignmentForm.existingAssignment}
            onSave={handleAssignmentSave}
            onCancel={handleCancelClick}
          />
        )}
        <Button variant="primary" data-cy="add-assignment-button" onClick={handleAddAssignmentClick}>
          Add Assignment
        </Button>
        <TableContainer className="admin-table-container" data-cy="admin-assignments-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* define table headers */}
                {assignmentsTableConfig.map((col) => (
                  <TableCell key={col.columnKey} data-cy="admin-assignments-table-header">
                    <TableSortLabel
                      active={sort.orderBy === col.columnKey}
                      direction={sort.orderBy === col.columnKey ? sort.orderDirection : Ascending}
                      onClick={() => handleSort(col.columnKey)}
                      data-cy={
                        sort.orderBy === col.columnKey
                          ? col.columnKey + '-sort-' + sort.orderDirection
                          : col.columnKey + '-unsorted'
                      }
                    >
                      {col.columnDisplayName}&nbsp;
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-cy="admin-table-list-body">
              {/* render each row (user) */}
              {data.assignments.map((row) => (
                <TableRow key={row.assignmentKey}>
                  {/* render each data cell in a row  */}
                  {assignmentsTableConfig.map((col, index) => (
                    <TableCell key={`${col.columnKey}-${index}`} onClick={() => handleEditAssignmentClick(row, col)}>
                      {col.renderFn != null && col.renderFn(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  else
    contentToLoad = (
      <div className="manage-assignments-content" data-cy="manage-assignments-nodata">
        Unable to retrieve assignments data. Please refresh.
      </div>
    );

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <div className="manage-assignments-page admin-page">{contentToLoad}</div>
    </AocAuthAccessCheck>
  );
};

export default withAppInsightsTracking(ManageAssignments);
