import React, { createContext, useCallback, useState, useContext } from 'react';
import NotificationToast, { ToastType } from '../../components/Shared/NotificationToast/NotificationToast';
const NotificationUpdateContext = createContext(null);

/**
 * @description Displays Toast Notifications based on parameters passed
 * to a function returned from a react hook
 * */
const NotificationProvider = (props) => {
  const [state, setState] = useState({
    toastType: ToastType.SUCCESS,
    toastMessage: '',
    showToast: false,
    autoHide: false,
    className: '',
  });

  /**
   * @description Resets the state and hides the toast message
   * */
  const handleClose = useCallback(() => {
    setState({ toastType: ToastType.SUCCESS, toastMessage: '', showToast: false, autoHide: false });
  }, []);

  /**
   * @description Displays the toast when the showToast is true
   * */
  const NotificationDisplay = () => {
    return (
      <NotificationToast
        showToast={state.showToast}
        message={state.toastMessage}
        toastType={state.toastType}
        autoHide={state.autoHide}
        onClose={handleClose}
        className={state.className}
      />
    );
  };

  /**
   * @description Sets state based on what is passed to the react hook
   * */
  const updateNotification = useCallback(
    (toastTypeInput, toastMessageInput, showToastInput, autoHideInput, className) => {
      setState({
        toastType: toastTypeInput,
        toastMessage: toastMessageInput,
        showToast: showToastInput,
        autoHide: autoHideInput,
        className: className,
      });
    },
    [],
  );

  return (
    <NotificationUpdateContext.Provider value={updateNotification}>
      <NotificationDisplay />
      {props.children}
    </NotificationUpdateContext.Provider>
  );
};

/**
 * @description Custom hook to update notifications
 * */
function useNotificationUpdate() {
  const context = useContext(NotificationUpdateContext);
  if (!context) {
    throw new Error(
      'useNotificationUpdate must be used within a NotificationProvider. Wrap a parent component in <NotificationProvider> to fix this error.',
    );
  }
  return context;
}

export { NotificationProvider, useNotificationUpdate };
