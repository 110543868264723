import { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const ViewId = createContext(null);

function ViewIdProvider({ children, id }) {
  return <ViewId.Provider value={{ id }}>{children}</ViewId.Provider>;
}

ViewIdProvider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

function useViewId() {
  const context = useContext(ViewId);

  if (!context) {
    throw new Error(
      'useViewId must be used within a ViewIdProvider. Wrap a parent component in <ViewIdProvider> to fix this error.',
    );
  }
  return context;
}

export { ViewIdProvider, useViewId };
