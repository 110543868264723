import { SearchFlightAction } from '../actionTypes';

// Initial state
const initialState = {
  searchFlightNumber: '',
  currentIndex: 0,
  showMatches: false,
  searchResults: [],
};

// Reducer function
function searchFlightReducer(state = initialState, action) {
  switch (action.type) {
    case SearchFlightAction.SET_SEARCH_FLIGHT_NUMBER:
      return {
        ...state,
        searchFlightNumber: action.payload,
      };
    case SearchFlightAction.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case SearchFlightAction.SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.payload,
      };
    case SearchFlightAction.SET_SHOW_MATCHES:
      return {
        ...state,
        showMatches: action.payload,
      };

    default:
      return state;
  }
}

export default searchFlightReducer;
