import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Shared/Modal/Modal';
import Button from '../Shared/Button/Button';
import appSettings from '../../appSettings';
import { deleteUserAssignments } from '../../services/apiClient/assignmentsApi/assignmentsApi';
import { useAuthentication } from '../../contexts/AuthenticationContext/AuthenticationContext';
import { SessionStorageKeys } from '../../lib/constants';
import './IdleTimeOutHandler.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

const IdleTimeOutHandler = (props) => {
  const { authSignOut: authSignOutFunc } = useAuthentication();
  const [countdownText, setCountdownText] = useState('0 mins.');
  const [show, setShow] = useState(false);

  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  const secondIntervalTime = 1000;

  // START USER IDLE TIME CHECKING
  const startWarningInactive = () => {
    startTimerInterval.current = setTimeout(() => {
      let timeStamp = dayjs(); // CUREENT TIME
      console.log('start inactive => ' + timeStamp.format('YYYY-MM-DD HH:mm:ss'));
      logoutWarningCountDown(timeStamp);
    }, appSettings.INACTIVITY_TIMEOUT.WARNING_TIMEOUT); // TO GET SHOW WARNING NOTIFICATION MODALE
  };

  // WARNING INACTIVE METHOD
  const logoutWarningCountDown = (timeStamp) => {
    clearTimeout(startTimerInterval.current); // CLEAR THE INTERVAL
    setCountdownText(millisecondsToMinutesSeconds(appSettings.INACTIVITY_TIMEOUT.COUNTDOWN_TIMEOUT));

    setShow(true); // SHOW MODAL WARING COUNT DOWN

    // REMOVE EVENTLISTENER F
    const events = ['load', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      window.removeEventListener(event, startResetTimer);
    });

    // START INTERVAL FOR COUNT DOWN
    warningInactiveInterval.current = setInterval(() => {
      const countdownt_timeout = appSettings.INACTIVITY_TIMEOUT.COUNTDOWN_TIMEOUT; // Remaining time (notification) left to logout.

      const diff = dayjs.duration(dayjs().diff(timeStamp));
      //const leftminute = diff.minutes();
      const totalmillisecond = dayjs.duration(countdownt_timeout, 'ms').asMilliseconds();
      const leftmillisecond = Math.floor(diff.asMilliseconds());

      let diffDuration = totalmillisecond - leftmillisecond; // GET COUNT DOWN MILLI_SECONDs TIME
      const logoutClockTime = dayjs.duration(diffDuration, 'ms');

      let fromMinutes = Math.floor(logoutClockTime.asMinutes());
      let fromSeconds = Math.floor(logoutClockTime.asSeconds());

      // SHOW MINITUSE AND SECAND COUNT DOWN
      setCountdownText(
        Math.floor(logoutClockTime.asSeconds()) >= 60 ? `${fromMinutes} mins.` : `${fromSeconds} seconds.`,
      );

      // AFTER COUNT DOWN COMPLETE LOGOUT AUTOMATICLLY
      if (fromSeconds < 1) {
        handleAutoLogout();
      }
    }, secondIntervalTime);
  };

  const millisecondsToMinutesSeconds = (ms) => {
    let duration = dayjs.duration(ms, 'ms');
    let fromMinutes = Math.floor(duration.asMinutes() - 1);
    let fromSeconds = Math.floor(duration.asSeconds());

    return Math.floor(duration.asSeconds()) >= 60 ? `${fromMinutes} mins.` : `${fromSeconds} seconds.`;
  };

  // HANDLES LOGOUT WHEN SESSION TIME OUT COMPLITED
  const handleAutoLogout = async () => {
    window.sessionStorage.removeItem(SessionStorageKeys.FILTER);
    await deleteUserAssignments();
    await authSignOutFunc();
    clearInterval(warningInactiveInterval.current); // CLEAR THE INTERVAL
    startResetTimer();
  };

  // RESET INTERVAL TIMER
  let startResetTimer = () => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    startWarningInactive();
    setShow(false);
  };

  // HANDLES CONTINUE SESSION
  const handleContinueSession = () => {
    setShow(false);
    startResetTimer();
  };

  // LIFE CYCLE HOOK
  useEffect(() => {
    const events = ['load', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      document.body.addEventListener(event, startResetTimer);
    });
    //console.log('useeffect called in inactive timeer');
    startWarningInactive();
    return () => {
      clearTimeout(startTimerInterval.current);
      events.forEach((event) => {
        document.body.removeEventListener(event, startResetTimer);
      });
      //startResetTimer();
    };
  }, []);

  /**
   * @description CLOSE THE MODAL
   */
  const handleOnCloseModal = () => {
    setShow(false);
    startResetTimer();
  };

  // MODAL CONTENTS
  const modalContent = (
    <div className="session-time-out-div">
      Your session will expire automatically in {countdownText}
      <br />
      Select “Continue Session” to extend your session.
    </div>
  );

  const modalButtons = [
    <Button
      className="session-timeout-btn"
      variant="primary"
      tabIndex="0"
      data-cy="continue-button"
      onClick={handleContinueSession}
    >
      Continue Session
    </Button>,
  ];

  return (
    <Modal
      data-cy="session-time-out"
      show={show}
      title="Session Timeout"
      customCSSTag="session-timeout-modal"
      irropsModal
      body={modalContent}
      footerButtons={modalButtons}
      onHide={handleOnCloseModal}
      size="thin"
      draggable={false}
      {...props}
    />
  );
};
export default IdleTimeOutHandler;
