import { AutoRefreshAction } from '../actionTypes';

const initialState = {
  ganttChartRefresh: true,
  flightDetailPaneRefresh: false,
};

/**
 * Given the previous state and action payload, returns the new state for the auto refresh store
 */
const autoRefreshReducer = (state = initialState, action) => {
  switch (action.type) {
    case AutoRefreshAction.UPDATE_GANTT_CHART:
      return {
        ...state,
        ganttChartRefresh: action.payload.ganttChartRefresh ?? initialState.ganttChartRefresh,
      };
    case AutoRefreshAction.UPDATE_FLIGHT_DETAIL_PANE:
      return {
        ...state,
        flightDetailPaneRefresh: action.payload.flightDetailPaneRefresh ?? initialState.flightDetailPaneRefresh,
      };
    default:
      return state;
  }
};

export default autoRefreshReducer;
