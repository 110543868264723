import { useQuery } from 'react-query';
import { getIrropsReasons } from '../../services/apiClient/irropsCodeApi/irropsCodeApi';
import appSettings from '../../appSettings';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';

const moduleName = 'useIrropsCodesQuery';

/**
 * @description Custom hook for retrieving data from irropsCode API.
 * @param {string} airlineCode - airline code.
 * @param {string} irropsCode - irrops code.
 */
export const useIrropsCodesQuery = (airlineCode, irropsCode, enabled = true) => {
  const config = {
    cacheTime: 5 * 60 * 1000,
    enabled: enabled, // If false, the query will not execute
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useIrropsCodesQuery', createApiErrorTelemetryProperties(error));
    },
    select: (data) => {
      // Returns the ".data" property from the response
      return data.data;
    },
  };

  return useQuery(['irropsReasons', airlineCode, irropsCode], () => getIrropsReasons(airlineCode, irropsCode), config);
};
