import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'flightreferencetimes';
const moduleName = 'flightReferenceTimesApi';

/**
 * Returns a list of reason codes
 * @param {*} airlineCode
 * @param {*} origin
 * @param {*} flightLegKey
 * @param {*} aircraftRegistration
 */
export const getTurnTimes = async (airlineCode, origin, flightLegKey, aircraftRegistration) => {
  const apiClient = axiosInstance;
  let result = null;
  const apiUrl = `${API_ROUTE}/api/v1/TurnTimes?airlineCode=${airlineCode}&origin=${origin}&aircraftRegistration=${aircraftRegistration}`;
  try {
    const url = flightLegKey ? `${apiUrl}&flightLegKey=${flightLegKey}` : apiUrl;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getTurnTimes', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * Returns a list of reason codes
 * @param {*} airlineCode
 * @param {*} origin
 * @param {*} aircraftRegistration
 * @param {*} inboundFlightLegKey
 * @param {*} inboundAirport
 * @param {*} destination
 */
export const getTurnTimesV2 = async (
  airlineCode,
  origin,
  aircraftRegistration,
  inboundFlightLegKey,
  inboundAirport,
  destination,
) => {
  const apiClient = axiosInstance;
  let result = null;
  const apiUrl = `${API_ROUTE}/api/v2/TurnTimes?airlineCode=${airlineCode}&origin=${origin}&aircraftRegistration=${aircraftRegistration}&inboundFlightLegKey=${inboundFlightLegKey}&inboundAirport=${inboundAirport}&destination=${destination}`;
  try {
    const response = await apiClient.get(apiUrl);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getTurnTimes', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * Returns a ETA for continuation leg
 * @param {*} airlineCode
 * @param {*} origin
 * @param {*} destination
 */

export const getBlockMinutes = async (airlineCode, origin, destination) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/BlockMinutes?airlineCode=${airlineCode}&origin=${origin}&destination=${destination}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getBlockMinutes', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  // console.debug(result);
  return result;
};
