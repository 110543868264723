import { SelectedFlightDetailsAction } from '../actionTypes';

const initialState = {
  data: null,
  isFlightPanelOpen: false,
  isActivityTabOpen: false,
};

const selectedFlightDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SelectedFlightDetailsAction.UPDATE:
      const flightDetails = {
        data: action.payload.data,
        isFlightPanelOpen: action.payload.isFlightPanelOpen == null ? false : action.payload.isFlightPanelOpen,
        isActivityTabOpen: action.payload.isActivityTabOpen == null ? false : action.payload.isActivityTabOpen,
      };

      return {
        ...state,
        data: flightDetails.data,
        isFlightPanelOpen: flightDetails.isFlightPanelOpen,
        isActivityTabOpen: flightDetails.isActivityTabOpen,
      };
    case SelectedFlightDetailsAction.CLEAR:
      return {
        ...state,
        data: null,
        isFlightPanelOpen: false,
        isActivityTabOpen: false,
      };

    default:
      return state;
  }
};

export default selectedFlightDetailsReducer;
