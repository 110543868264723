import { useEffect, useRef, useState } from 'react';

/**
 * Hook for Enabling Double Click Functionality
 * @returns
 */
export const useDoubleClick = (callback, delay = 300) => {
  const [isClicking, setIsClicking] = useState(false);
  const timeoutRef = useRef(null);

  const handleClick = () => {
    if (!isClicking) {
      setIsClicking(true);
      timeoutRef.current = setTimeout(() => {
        setIsClicking(false);
      }, delay);
    } else {
      clearTimeout(timeoutRef.current);
      setIsClicking(false);
      callback(); // Fire double click event
    }
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current); // Cleanup
  }, []); // Empty dependency array to run the effect only once

  return handleClick;
};
