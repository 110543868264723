import React, { useMemo, memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SummaryAircraftInfoBlock from '../SummaryAircraftInfoBlock/SummaryAircraftInfoBlock';
import TabSkeletonSection from '../../../../FlightDetailPane/TabSkeletonSection/TabSkeletonSection';
import { EntitlementNames, Treatment } from '../../../../../../lib/constants';
import { useFeatureFlag } from '../../../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

/**AircraftDetailRow renders the details of a row in the aircraft tab.
 * @param {string} index - index of the row
 * @param {string} title - title of the row
 * @param {string} data - data of the row
 * @param {string} dateTimeFormat - format date and time
 * @param {bool} isDateField - date of the row
 * @param {bool} isTimeField - time of the row
 * @param {int} lGColSize - large column size
 * @param {int} sMColSize - small column size
 * @returns AircraftDetailRow  */
const AircraftDetailRow = ({
  index,
  title,
  data,
  dateTimeFormat,
  isDateField,
  isTimeField,
  lGColSize = 1,
  sMColSize = 1,
  station,
}) => (
  <Grid item sm={sMColSize} xs={lGColSize} md={lGColSize} lg={lGColSize} xl={lGColSize}>
    {title && index === 0 && (
      <div data-cy={`aircraft-title-${title}`} className="aircraft-title">
        {title}
      </div>
    )}
    <SummaryAircraftInfoBlock
      aircraftData={data}
      dateTimeFormat={dateTimeFormat}
      isDateField={isDateField}
      isTimeField={isTimeField}
      title={title}
      station={station}
    />
  </Grid>
);

// Define the row configuration for the MEL/CDL and MX Messages rows
const melCDLRowConfig = [
  { title: 'MEL', dataKey: 'melNumber' },
  { title: 'CDL', dataKey: 'cdlNumber' },
  { title: '', dataKey: '' },
  { title: 'Description', dataKey: 'description', lGColSize: 3, sMColSize: 5 },
  { title: 'Reported', dataKey: 'reportedDateTimeUTC', dateTimeFormat: 'MM/DD/YYYY', isDateField: true },
  { title: '\u00A0', dataKey: 'reportedDateTimeUTC', dateTimeFormat: 'hh:mm A', isTimeField: true },
  { title: 'Due Date', dataKey: 'dueDateUTC', dateTimeFormat: 'MM/DD/YYYY', isDateField: true },
];

// Define the row configuration for the MX Messages row
const mxMessagesRowConfig = [
  { title: 'From', dataKey: 'from' },
  { title: 'Date', dataKey: 'dateTimeUTC', dateTimeFormat: 'MM/DD/YYYY', isDateField: true },
  { title: 'Time', dataKey: 'dateTimeUTC', dateTimeFormat: 'hh:mm A', isTimeField: true },
  { title: 'Message', dataKey: 'message', lGColSize: 3, sMColSize: 5 },
];

// Define the NoDataMessage component to display when no MEL/CDL or MX Messages exist
const NoDataMessage = ({ message }) => (
  <div data-cy="no-mels-cdls-exist-aircraft" className="no-mels-cdls-exist-aircraft">
    {message}
  </div>
);

/**SummaryAircraftDetailRow renders the details of the aircraft tab.
 * @param {array} aircraftDetails - details of the aircraft
 * @param {string} station - station of the aircraft
 * @param {boolean} isMelCDRow - MEL/CDL row
 * @returns SummaryAircraftDetailRow */
const SummaryAircraftDetailRow = ({ aircraftDetails, station, isMelCDRow, isLoading, isShowMessages }) => {
  const rowConfig = useMemo(() => (isMelCDRow ? melCDLRowConfig : mxMessagesRowConfig), [isMelCDRow]);
  const { showFeature } = useFeatureFlag();
  const melcdlFlag = showFeature(Treatment.MEL_MX_MESSAGES);
  const melCdlTitle = melcdlFlag ? EntitlementNames.MELMXMESSAGES : 'MEL/CDL List';
  const hasMxMessageEntitlement = useAuthorizationAccess(null, [EntitlementNames.MXMESSAGES]);
  const MxMessagesList = hasMxMessageEntitlement ? 'Mx Messages List' : '';

  const rowTitle = useMemo(() => (isMelCDRow ? melCdlTitle : MxMessagesList), [isMelCDRow, hasMxMessageEntitlement]);
  const rowType = useMemo(() => rowTitle.toLowerCase().replace(/[^a-z0-9]/g, '-'), [rowTitle]);

  // Define the error messages for when no MEL/CDL or MX Messages exist
  const errMessagess = useMemo(
    () =>
      isShowMessages
        ? `Failed to retrieve ${melCdlTitle} data. Please try again.`
        : `No ${melCdlTitle} exist on this aircraft.`,
    [isShowMessages],
  );
  return (
    <>
      <Grid container spacing={0}>
        <div data-cy={rowType} className={`aircraft-header ${rowType}`}>
          {rowTitle}
        </div>
      </Grid>
      {!aircraftDetails || (aircraftDetails.length === 0 && isMelCDRow) ? (
        <NoDataMessage message={errMessagess} />
      ) : (
        aircraftDetails.map((item, index) => (
          <Grid container spacing={1} key={index} data-cy="aircraft-detail-row">
            {rowConfig.map(({ title, dataKey, ...props }) =>
              index === 0 && isLoading ? (
                <TabSkeletonSection key={dataKey} />
              ) : (
                <AircraftDetailRow
                  key={dataKey}
                  title={title}
                  data={item[dataKey]}
                  index={index}
                  station={station}
                  {...props}
                />
              ),
            )}
          </Grid>
        ))
      )}
    </>
  );
};

SummaryAircraftDetailRow.propTypes = {
  aircraftDetails: PropTypes.array,
  station: PropTypes.string,
  isMelCDRow: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowMessages: PropTypes.bool,
};

export default memo(SummaryAircraftDetailRow);
