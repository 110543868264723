import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';

/**
 * The LeaveSwapModeModal component is confirm the leave swap mode.
 * @param {Object} props -
 *  showConfirmPopup - show/hide confirm modal
 *  handleLeaveClick - callback func when leave button is pressed.
 *  handleOnKeyPress - callback func when any key is pressed in the modal.
 *  handleHideClick - callback func when close the confirm modal.
 *  dataCyTag- data-cy Tag for button and confirm modal
 *  title- title of the confirm modal
 *  containerText- information to be displayed before leaving .
 * @returns LeaveSwapModeModal component
 */

const LeaveSwapModeModal = ({
  showConfirmPopup,
  handleLeaveClick,
  handleOnKeyPress,
  handleHideClick,
  dataCyTag,
  title,
  containerText,
}) => {
  /* footer buttons for LeaveSwapMode popup modal. */
  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy={`${dataCyTag}-button`}
      onClick={handleLeaveClick}
      onKeyPress={handleOnKeyPress}
    >
      Leave
    </Button>,
  ];

  /* build modal body for LeaveSwapMode popup modal. */
  const popupHTML = (
    <Fragment>
      <div className="leave-swap-mode-popup-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>{containerText}</div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  return (
    <Modal
      show={showConfirmPopup}
      title={title}
      body={popupHTML}
      footerButtons={formButtons}
      onHide={handleHideClick}
      customCSSTag="leave-swap-mode-popup"
      dataCyTag={`${dataCyTag}-confirm-modal`}
      size="thin"
      bottomLine={false}
    />
  );
};

export default withAppInsightsTracking(LeaveSwapModeModal);
