import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextInput from '../Shared/Inputs/TextInput/TextInput';
import HorizontalRule from '../Shared/HorizontalRule/HorizontalRule';
import BrickContainer from '../Shared/BrickContainer/BrickContainer';
import { formatDateTime } from '../../lib/dateTimeUtils';
import {
  getUpdatedOutTime,
  getUpdatedOffTime,
  getUpdatedOnTime,
  getUpdatedInTime,
  isDateGreaterThanOrEqual,
  isDateFormatValid,
} from './FlifoUtils';
import FlifoModal from './FlifoModal/FlifoModal';
import dayjs from 'dayjs';
import { useFlightDetailsQuery } from '../../react-query/useFlightsQuery/useFlightsQuery';
import './Flifo.css';
import ZuluOffsetDisplay from '../Shared/ZuluOffsetDisplay/ZuluOffsetDisplay';
import { EntitlementNames, WarningTypes, Treatment } from '../../lib/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useAirportsQuery } from '../../hooks/useAirportsQuery/useAirportsQuery';
import AirportCodeInput from '../Shared/AirportCodeInput/AirportCodeInput';
import { flighttimesqueuepublisherApi } from '../../services/apiClient/flighttimesqueuepublisherApi/flighttimesqueuepublisherApi';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';
import useAuthorizationAccess from '../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { findFlightLegs } from '../../services/apiClient/flightsApi/flightsApi';
import { isEmpty } from 'lodash';
import DatePicker from '../Shared/Inputs/DatePicker/DatePicker';
import { ToastType } from '../Shared/NotificationToast/NotificationToast';
import { useNotificationUpdate } from '../../contexts/NotificationsContext/NotificationsContext';
import { isNullOrWhitespace } from '../../lib/irropUtils';
import CopyButtonIcon from '../Shared/CopyButtonIcon/CopyButtonIcon';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    fontSize: 14,
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  detailsHzRule: {
    marginTop: '-11px',
  },
  brickMarginBottom: {
    marginBottom: '1.4rem !important',
    paddingRight: '4.9rem !important',
  },
  sectionNewDocGrid: {
    paddingTop: '0.84375rem !important',
    paddingBottom: '0.375rem !important',
  },
  flightStationHzRule: {
    marginTop: '0.6rem',
  },
  flightInputMaxWidth: {
    maxWidth: '12.5%',
  },
  brickLabel2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '0.25rem',
  },
  sectionHeaderGrid: {
    paddingTop: '0.125rem !important',
    paddingBottom: '0.34375rem !important',
  },
}));

/**
 * The FlifoModal component handles the FLIFO
 * @param {func} onCommit - function to handle FLIFO confirmation
 * @param {func} onClose - function to handle FLIFO
 * @param {obj} flightLeg - object for selected flight leg
 * @returns Flifo component
 */
const Flifo = ({ onCommit, onClose, flightLeg }) => {
  // Destructure flightLeg object
  const { orig, dest, flightNumber, departureDate, departureCount, airline } = flightLeg;

  const getDefaultState = (value) => {
    return {
      value: value ?? '',
      warningType: WarningTypes.NONE,
      warningMessage: '',
      initialVal: '',
    };
  };

  const classes = useStyles();

  // feature flag
  const { showFeature } = useFeatureFlag();
  const datePickerMaxDateFlag = showFeature(Treatment.DATE_PICKER_MAXDATE);
  // useAuthorizationAccess hook
  const hasFlifoEntitlement = useAuthorizationAccess([], [EntitlementNames.FLIFO]);
  // useUserContext hook
  const { state: userContextState } = useUserContext();
  // useAppInsightsContext hook
  const { trackEvent, logInformation } = useAppInsightsContext();
  const [submitButtonClicked, setsubmitButtonClicked] = useState(false);
  const [lastCopied, setLastCopied] = useState(null);
  // useAirportsQuery hook
  const { isLoading: isLoadingAirportsData, data: airportsData, error: airportsDataError } = useAirportsQuery();

  const dateTimeFormat = (date) => (date ? formatDateTime(date, 'MM/DD/YYYY HH:mm') : '');
  const isDateTimeValid = (date, format = 'MM/DD/YYYY HH:mm') => dayjs(date, format, true).isValid();
  const formatDateOnly = 'YYYY-MM-DD';

  const convertToDateTimeFormat = (date) => {
    // if no date value? should return nothing.
    if (date == null || typeof date === 'undefined' || date == '') return null;
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
  };

  /**
   * @description This method is used by Dispatch Desk field which is alphanumeric
   * @returns true or false
   */
  const checkRegexInput = (regexVal, str) => {
    return regexVal.test(str);
  };

  /**
   * @description get field value by removing the extra and special charactor.
   * @param {string} value new dispatch value
   * @param {int} maxCharAllowed max int count to allow
   * @returns new dispatch field value.
   */
  const getInputValue = (value, maxCharAllowed) => {
    const removedSpChars = value.replace(/[\W_]+/g, '');
    return removedSpChars.substring(0, maxCharAllowed);
  };

  const maxFlightNumberCharacters = 4;
  const regexFourDigitNumber = new RegExp('^(?=[1-9][0-9]{0,4}$)');
  const maxDateProps = {};

  const [schedUTCOutDate, setSchedUTCOutDate] = useState('');

  const [schedUTCOutTime, setSchedUTCOutTime] = useState({
    initialVal: '',
    value: '',
    hhmm: '',
    tzDisplay: '',
    warningType: WarningTypes.NONE,
    warningMessage: '',
  });
  const [flightNumberState, setFlightNumberState] = useState({
    value: flightNumber,
    initialVal: flightNumber,
  });
  const [originStation, setOriginStation] = useState({
    value: orig,
    initialVal: orig,
  });
  const [destinationStation, setDestinationStation] = useState({ value: dest, initialVal: dest });

  const initialDepartureDate = departureDate ?? '';
  const [departureDateState, setDepartureDateState] = useState({
    value: initialDepartureDate,
    warningType: WarningTypes.NONE,
    warningMessage: '',
    initialVal: initialDepartureDate,
  });

  const [isSearchDataAvailable, setIsSearchDataAvailable] = useState(false);

  const [schedETA, setSchedETA] = useState(getDefaultState());
  const [etd, setETD] = useState(getDefaultState());
  const [eta, setETA] = useState(getDefaultState());

  const [outTime, setOutTime] = useState(getDefaultState());
  const [offTime, setOffTime] = useState(getDefaultState());
  const [onTime, setOnTime] = useState(getDefaultState());
  const [inTime, setInTime] = useState(getDefaultState());

  const [enableUpdate, setEnableUpdate] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const [utcForArrivalZuluTime, setUtcForArrivalZuluTime] = useState('');
  const [utcForDepartureZuluTime, setUtcForDepartureZuluTime] = useState('');
  const [departureGateInfo, setDepartureGateInfo] = useState('');
  const [arrivalGateInfo, setArrivalGateInfo] = useState('');
  const [minScheduledOperatingDate] = useState(dayjs().format(formatDateOnly));
  const maxScheduledOperatingDate = dayjs(minScheduledOperatingDate).add(7, 'day').format(formatDateOnly);

  //  useNotificationUpdate hook
  const notificationUpdate = useNotificationUpdate();

  //Check feature flag for datePickerMaxDate & set propos datePicker
  maxDateProps.maxDate = datePickerMaxDateFlag ? maxDateProps.maxDate : maxScheduledOperatingDate;

  // useFlightDetailsQuery hook
  const {
    isLoading: flightLoading,
    data: flightDetailsData,
    refetch,
  } = useFlightDetailsQuery(
    flightNumberState.value,
    departureDateState.value,
    originStation.value,
    destinationStation.value,
    departureCount,
    airline,
    true,
    !enableUpdate,
  );

  const checkValidityAndUpdates = () => {
    let isSchedTimesValid =
      schedETA.warningType !== WarningTypes.ERROR &&
      etd.warningType !== WarningTypes.ERROR &&
      eta.warningType !== WarningTypes.ERROR &&
      schedUTCOutTime.warningType !== WarningTypes.ERROR;

    let isOOOIValid =
      outTime.warningType !== WarningTypes.ERROR &&
      offTime.warningType !== WarningTypes.ERROR &&
      onTime.warningType !== WarningTypes.ERROR &&
      inTime.warningType !== WarningTypes.ERROR;

    let isOOOIValuesUpdated =
      outTime.initialVal !== outTime.value ||
      offTime.initialVal !== offTime.value ||
      onTime.initialVal !== onTime.value ||
      inTime.initialVal !== inTime.value;

    let isSchedValuesUpdated =
      schedETA.initialVal !== schedETA.value ||
      etd.initialVal !== etd.value ||
      eta.initialVal !== eta.value ||
      schedUTCOutTime.initialVal !== schedUTCOutTime.value;

    return {
      isSchedTimesValid,
      isOOOIValid,
      isOOOIValuesUpdated,
      isSchedValuesUpdated,
    };
  };

  useEffect(() => {
    if (submitButtonClicked) {
      setETA({ ...eta, initialVal: eta.value });
      setETD({ ...etd, initialVal: etd.value });
      setOutTime({ ...outTime, initialVal: outTime.value });
      setOffTime({ ...offTime, initialVal: offTime.value });
      setOnTime({ ...onTime, initialVal: onTime.value });
      setInTime({ ...inTime, initialVal: inTime.value });
      setSchedETA({ ...schedETA, initialVal: schedETA.value });
      setSchedUTCOutTime({ ...schedUTCOutTime, initialVal: schedUTCOutTime.value });
    }
  }, [submitButtonClicked]);

  useEffect(() => {
    const { isSchedTimesValid, isOOOIValid, isOOOIValuesUpdated, isSchedValuesUpdated } = checkValidityAndUpdates();

    if (isOOOIValid && isSchedTimesValid && (isOOOIValuesUpdated || isSchedValuesUpdated)) {
      setsubmitButtonClicked(false);
    }
  }, [
    destinationStation,
    originStation,
    departureDateState,
    schedUTCOutTime,
    schedETA,
    etd,
    eta,
    outTime,
    offTime,
    onTime,
    inTime,
  ]);

  useEffect(() => {
    if (flightNumber && departureCount && airline && orig && dest && departureDate) {
      setEnableUpdate(true);
    }

    if (
      !flightLoading &&
      flightDetailsData &&
      flightDetailsData?.flightNumber &&
      flightDetailsData?.scheduledOperatingDateUTC &&
      flightDetailsData?.origin?.actual &&
      flightDetailsData?.destination?.actual &&
      flightDetailsData?.times?.length > 0
    ) {
      let selectedFlightLegTimes = flightDetailsData?.times;

      setSchedUTCOutDate(formatDateTime(flightDetailsData.scheduledOperatingDateUTC, 'MM/DD/YYYY'));
      setDestinationStation({
        ...destinationStation,
        value: flightDetailsData.destination?.actual,
        initialVal: flightDetailsData.destination?.actual,
      });
      setOriginStation({
        ...originStation,
        value: flightDetailsData.origin?.actual,
        initialVal: flightDetailsData.origin?.actual,
      });
      setDepartureDateState({ ...departureDateState, value: flightDetailsData.scheduledOperatingDateUTC });
      setUtcForArrivalZuluTime(selectedFlightLegTimes?.find((t) => t.name === 'IN').scheduledTimeUTC);
      setUtcForDepartureZuluTime(selectedFlightLegTimes?.find((t) => t.name === 'OUT')?.scheduledTimeUTC);
      setDepartureGateInfo(flightDetailsData.origin?.parkingSpot ? flightDetailsData.origin?.parkingSpot : 'N/A');
      setArrivalGateInfo(
        flightDetailsData.destination?.parkingSpot ? flightDetailsData.destination?.parkingSpot : 'N/A',
      );

      let initialOutTime = selectedFlightLegTimes?.find((t) => t.name === 'OUT')?.actualTimeUTC;
      let initialOffTime = selectedFlightLegTimes?.find((t) => t.name === 'OFF')?.actualTimeUTC;
      let initialOnTime = selectedFlightLegTimes?.find((t) => t.name === 'ON')?.actualTimeUTC;
      let initialInTime = selectedFlightLegTimes?.find((t) => t.name === 'IN')?.actualTimeUTC;
      let etdFromFlightDetailsData = dateTimeFormat(
        selectedFlightLegTimes?.find((t) => t.name === 'OUT')?.estimatedTimeUTC,
      );
      let initialSchedUTCOutTime = formatDateTime(
        selectedFlightLegTimes?.find((t) => t.name === 'OUT')?.scheduledTimeUTC,
        'HH:mm',
      );

      setSchedUTCOutTime({
        ...schedUTCOutTime,
        value: initialSchedUTCOutTime,
        initialVal: initialSchedUTCOutTime,
      });
      let initialSchedETA = dateTimeFormat(selectedFlightLegTimes?.find((t) => t.name === 'IN')?.scheduledTimeUTC);
      setSchedETA({
        ...schedETA,
        value: initialSchedETA,
        initialVal: initialSchedETA,
      });
      let initialETA = dateTimeFormat(selectedFlightLegTimes?.find((t) => t.name === 'IN')?.estimatedTimeUTC);
      setETA({
        ...eta,
        value: initialETA,
        initialVal: initialETA,
      });
      setETD({
        ...etd,
        value: etdFromFlightDetailsData,
        initialVal: etdFromFlightDetailsData,
      });
      setOutTime({ ...outTime, value: dateTimeFormat(initialOutTime), initialVal: dateTimeFormat(initialOutTime) });
      setOffTime({ ...offTime, value: dateTimeFormat(initialOffTime), initialVal: dateTimeFormat(initialOffTime) });
      setOnTime({ ...onTime, value: dateTimeFormat(initialOnTime), initialVal: dateTimeFormat(initialOnTime) });
      setInTime({ ...inTime, value: dateTimeFormat(initialInTime), initialVal: dateTimeFormat(initialInTime) });
    }
  }, [flightDetailsData, flightLoading]);

  // handleAirportCodeDestinationChange action
  const handleAirportCodeDestinationChange = async (value) => {
    if (value == null || value?.length !== 3) {
      setDestinationStation({ ...destinationStation, value: '' });
      return;
    }
    setDestinationStation({ ...destinationStation, value: value });
  };

  // handleFlightNumberChange action
  const handleFlightNumberChange = (value) => {
    if (checkRegexInput(regexFourDigitNumber, value.toString()) === true) {
      const newValue = getInputValue(value, maxFlightNumberCharacters);
      setFlightNumberState({ ...flightNumberState, value: newValue });
    } else if (isNullOrWhitespace(value)) {
      setFlightNumberState({ ...flightNumberState, value: '' });
    }
  };

  /**
   * @description handle Airport origin change
   * @param {string} values
   * @param {*} leg
   */
  const handleAirportCodeOriginChange = async (value) => {
    if (value == null || value?.length !== 3) {
      setOriginStation({ ...originStation, value: '' });
      return;
    }
    setOriginStation({ ...originStation, value: value });
  };

  const handleScheduleTimeChange = (value) => {
    if (isEmpty(value)) {
      setSchedUTCOutTime({
        ...schedUTCOutTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: `*Scheduled time is required`,
      });
    } else if (!isDateFormatValid(`${schedUTCOutDate} ${value}`)) {
      setSchedUTCOutTime({
        ...schedUTCOutTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid time format.',
      });
    } else {
      setSchedUTCOutTime({ ...schedUTCOutTime, value: value, warningType: WarningTypes.NONE, warningMessage: '' });
      if (isDateGreaterThanOrEqual(`${schedUTCOutDate} ${value}`, schedETA.value)) {
        setSchedETA({
          ...schedETA,
          value: schedETA.value,
          warningType: WarningTypes.ERROR,
          warningMessage: '*Scheduled Arrival must be > than Scheduled Departure.',
        });
      } else {
        setSchedETA({ ...schedETA, value: schedETA.value, warningType: WarningTypes.NONE, warningMessage: '' });
      }
    }
  };

  const handleEtdCopyClick = async () => {
    setLastCopied('etd');
    await navigator.clipboard.writeText(etd.value);
  };

  const handleEstCopyClick = async () => {
    setLastCopied('eta');
    await navigator.clipboard.writeText(eta.value);
  };

  // handle enable/disable for Update button
  const isUpdateBtnEnabled = () => {
    const { isSchedTimesValid, isOOOIValid, isOOOIValuesUpdated, isSchedValuesUpdated } = checkValidityAndUpdates();

    if (!isOOOIValid || !isSchedTimesValid) {
      return false;
    }

    if (!(isSchedValuesUpdated || isOOOIValuesUpdated)) {
      return false;
    }

    if (searchClicked && isSearchDataAvailable) {
      return false;
    }

    return (
      !submitButtonClicked &&
      hasFlifoEntitlement &&
      ((isOOOIValid && isSchedTimesValid) || isSchedValuesUpdated || isOOOIValuesUpdated)
    );
  };

  // handle estDateAndDayHandler action
  const estDateAndDayHandler = (value) => {
    if (!dayjs.isDayjs(value)) {
      setDepartureDateState({ ...departureDateState, value: '' });
    }
    // setEnableUpdate(false);
    let dateValue = dayjs(value).format('YYYY-MM-DD');
    setDepartureDateState({ ...departureDateState, value: dateValue });
  };

  // handle schedETD action
  const scheduleArrivalHandler = (value) => {
    if (isEmpty(value)) {
      setSchedETA({
        ...schedETA,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Schedule Arrival is required',
      });
    } else if (!isDateFormatValid(value)) {
      setSchedETA({
        ...schedETA,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    } else {
      if (isDateGreaterThanOrEqual(`${schedUTCOutDate} ${schedUTCOutTime.value}`, value)) {
        setSchedETA({
          ...schedETA,
          value: value,
          warningType: WarningTypes.ERROR,
          warningMessage: '*Scheduled Arrival must be > than Scheduled Departure.',
        });
      } else {
        setSchedETA({ ...schedETA, value: value, warningType: WarningTypes.NONE, warningMessage: '' });
      }
    }
  };
  // handle etd action
  const etdHandler = (value) => {
    if (isEmpty(value)) {
      setETD({
        ...etd,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Est Departure is required',
      });
    } else if (!isDateFormatValid(value)) {
      setETD({
        ...etd,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    } else {
      setETD({ ...etd, value: value, warningType: WarningTypes.NONE, warningMessage: '' });
      if (isDateGreaterThanOrEqual(value, eta.value)) {
        setETA({
          ...eta,
          value: eta.value,
          warningType: WarningTypes.ERROR,
          warningMessage: '*Est Arrival must be > than Est Departure.',
        });
      } else {
        setETA({
          ...eta,
          value: eta.value,
          warningType: WarningTypes.NONE,
          warningMessage: '',
        });
      }
    }
  };

  // handle schedETD action
  const estArrivalHandler = (value) => {
    if (isEmpty(value)) {
      setETA({
        ...eta,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Est Arrival is required',
      });
    } else if (!isDateFormatValid(value)) {
      setETA({
        ...eta,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    } else {
      if (isDateGreaterThanOrEqual(etd.value, value)) {
        setETA({
          ...eta,
          value: value,
          warningType: WarningTypes.ERROR,
          warningMessage: '*Est Arrival must be > than Est Departure',
        });
      } else {
        setETA({ ...eta, value: value, warningType: WarningTypes.NONE, warningMessage: '' });
        if (isDateTimeValid(etd.value)) {
          setETD({ ...etd, value: etd.value, warningType: WarningTypes.NONE, warningMessage: '' });
        }
      }
    }
  };

  // out time handler
  const outTimeHandler = (value) => {
    if (value === '' || isDateFormatValid(value)) {
      // date is valid, so we want to check for other errors
      showErrorForIncorrectData(value, 'OUT');
    } else {
      // date is invalid
      setOutTime({
        ...outTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    }
  };

  // off time handler
  const offTimeHandler = (value) => {
    if (value === '' || isDateFormatValid(value)) {
      // date is valid, so we want to check for other errors
      showErrorForIncorrectData(value, 'OFF');
    } else {
      // date is invalid
      setOffTime({
        ...offTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    }
  };

  // on time handler
  const onTimeHandler = (value) => {
    if (value === '' || isDateFormatValid(value)) {
      // date is valid, so we want to check for other errors
      showErrorForIncorrectData(value, 'ON');
    } else {
      // date is invalid
      setOnTime({
        ...onTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    }
  };

  // in time handler
  const inTimeHandler = (value) => {
    if (value === '' || isDateFormatValid(value)) {
      // date is valid, so we want to check for other errors
      showErrorForIncorrectData(value, 'IN');
    } else {
      // date is invalid
      setInTime({
        ...inTime,
        value: value,
        warningType: WarningTypes.ERROR,
        warningMessage: '*Invalid date format.',
      });
    }
  };

  const showErrorForIncorrectData = (newValue, valueText) => {
    const timeStates = {
      OUT: { state: outTime, setState: setOutTime },
      OFF: { state: offTime, setState: setOffTime },
      ON: { state: onTime, setState: setOnTime },
      IN: { state: inTime, setState: setInTime },
    };

    const updateWarningMessages = (updatedTime) => {
      if (updatedTime.warningType === WarningTypes.ERROR) {
        timeStates[updatedTime.inputText].setState({
          ...timeStates[updatedTime.inputText].state,
          warningMessage: updatedTime.warningMessage,
          warningType: updatedTime.warningType,
          value: updatedTime.value,
        });
      } else {
        Object.keys(timeStates).forEach((type) => {
          if (type != 'OUT' && isDateTimeValid(timeStates[type].state.value)) {
            timeStates[type].setState({
              ...timeStates[type].state,
              warningType: WarningTypes.NONE,
              warningMessage: '',
              value: timeStates[type].state.value,
            });
          }
        });
      }
    };

    const clearWarningMessages = (timeType) => {
      Object.keys(timeStates).forEach((type) => {
        if (type !== timeType && timeStates[type].state.warningMessage.includes(`${timeType} time`)) {
          timeStates[type].setState({
            ...timeStates[type].state,
            warningMessage: '',
            warningType: WarningTypes.NONE,
          });
        }
      });
    };

    switch (valueText) {
      case 'OUT': {
        const updatedOutTime = getUpdatedOutTime(newValue, offTime, onTime, inTime);
        setOutTime({ ...outTime, value: newValue, warningType: WarningTypes.NONE, warningMessage: '' });
        updateWarningMessages(updatedOutTime);
        break;
      }
      case 'OFF': {
        const updatedOffTime = getUpdatedOffTime(newValue, outTime, onTime, inTime);
        if (updatedOffTime.warningType === WarningTypes.NONE) {
          clearWarningMessages('OFF');
        }
        setOffTime({ ...offTime, ...updatedOffTime });
        break;
      }
      case 'ON': {
        const updatedOnTime = getUpdatedOnTime(newValue, outTime, offTime, inTime);
        if (updatedOnTime.warningType === WarningTypes.NONE) {
          clearWarningMessages('ON');
        }
        setOnTime({ ...onTime, ...updatedOnTime });
        break;
      }
      case 'IN': {
        const updatedInTime = getUpdatedInTime(newValue, outTime, offTime, onTime);
        if (updatedInTime.warningType === WarningTypes.NONE) {
          clearWarningMessages('IN');
        }
        setInTime({ ...inTime, ...updatedInTime });
        break;
      }
      default:
        break;
    }
  };

  // onUpdate handler
  const onUpdate = async () => {
    trackEvent(`Flifo Info & Schedule - Submit modal for flight number : ${flightDetailsData?.flightNumber}`);

    const flifoBodyRequest = createFlifoBodyRequest(flightDetailsData);
    const flightLegRequestPayload = createFlightLegRequestPayload(flifoBodyRequest);

    const result = await flighttimesqueuepublisherApi(flifoBodyRequest);
    trackEvent(
      `Flifo Info & Schedule - API call to submit flifo is complete. Flight number : ${flightDetailsData?.flightNumber}`,
    );

    const isSuccess = result?.status === 'SUCCESS';
    const findFlightLegResponse = await findFlightLegs(flightLegRequestPayload);
    trackEvent(
      `Flifo Info & Schedule - API call to find flight leg. Flight number : ${flightLegRequestPayload?.flightNumber}`,
    );
    checkFlightLegAndCommit(isSuccess, findFlightLegResponse);
    if (isSuccess && findFlightLegResponse) {
      setsubmitButtonClicked(true);

      notificationUpdate(
        ToastType.SUCCESS,
        `Flifo Info & Schedule was submitted for Flight ${flightDetailsData?.flightNumber}`,
        true,
        false,
        'flifo-toast',
      );

      setTimeout(() => {
        notificationUpdate('', '', false, true, 'flifo-toast');
      }, 3000);
    } else {
      notificationUpdate(
        ToastType.ERROR,
        `Failed to submit Flifo Info & Schedule for Flight ${flightDetailsData?.flightNumber}`,
        true,
        false,
        'flifo-toast',
      );
    }
  };

  const createFlifoBodyRequest = (flightDetailsData) => ({
    aircraftRegistration: flightDetailsData?.aircraft?.aircraftRegistration,
    operatingAirlineCode: flightDetailsData?.operatingAirline,
    scheduledOperatingDateUTC: flightDetailsData?.scheduledOperatingDateUTC,
    flightNumber: flightDetailsData?.flightNumber,
    actualOrigin: flightDetailsData.origin?.actual,
    actualDestination: flightDetailsData.destination?.actual,
    scheduledOrigin: originStation.value,
    scheduledDestination: destinationStation.value,
    departureCount: flightDetailsData?.departureCount,
    scheduledOutUTC: convertToDateTimeFormat(`${schedUTCOutDate} ${schedUTCOutTime.value}`),
    scheduledInUTC: convertToDateTimeFormat(schedETA.value),
    estimatedOutUTC: convertToDateTimeFormat(etd.value),
    estimatedInUTC: convertToDateTimeFormat(eta.value),
    actualOutUTC: convertToDateTimeFormat(outTime.value),
    actualOffUTC: convertToDateTimeFormat(offTime.value),
    actualOnUTC: convertToDateTimeFormat(onTime.value),
    actualInUTC: convertToDateTimeFormat(inTime.value),
    employeeID: userContextState.employeeID,
  });

  const createFlightLegRequestPayload = (flifoBodyRequest) => [
    {
      flightNumber: flifoBodyRequest.flightNumber,
      scheduledOperatingDateUTC: flifoBodyRequest.scheduledOperatingDateUTC,
      origin: flifoBodyRequest.scheduledOrigin,
      destination: flifoBodyRequest.scheduledDestination,
      operatingAirline: flifoBodyRequest.operatingAirlineCode,
      departureCount: flifoBodyRequest.departureCount,
      findFlightLegCriteria: {
        aircraftRegistration: flifoBodyRequest.aircraftRegistration,
        scheduledOutUTC: flifoBodyRequest.scheduledOutUTC,
        scheduledInUTC: flifoBodyRequest.scheduledInUTC,
        estimatedInUTC: flifoBodyRequest.estimatedInUTC,
        estimatedOutUTC: flifoBodyRequest.estimatedOutUTC,
        actualOutUTC: flifoBodyRequest.actualOutUTC,
        actualInUTC: flifoBodyRequest.actualInUTC,
        actualOnUTC: flifoBodyRequest.actualOnUTC,
        actualOffUTC: flifoBodyRequest.actualOffUTC,
      },
    },
  ];

  const checkFlightLegAndCommit = (isSuccess, findFlightLegResponse) => {
    const swapFlightLegsFoundList = findFlightLegResponse?.flightLegsFound;
    const swapFlightLegsNotFoundList = findFlightLegResponse?.flightLegsNotFound;

    if (isSuccess) {
      if (swapFlightLegsFoundList?.length > 0) {
        logInformation(`Received Find Flights API Response. FlightLeg found`, 'Flifo Modal', 'onUpdate');
        onCommit(true, 'Flifo Info & Schedule');
      } else {
        if (swapFlightLegsNotFoundList?.length > 0) {
          logInformation(
            `Received Flights API Response. Flight Leg not found: \n${JSON.stringify(
              swapFlightLegsNotFoundList,
              null,
              2,
            )}`,
            'Flifo Modal',
            'onUpdate',
          );
          onCommit(
            false,
            '',
            `Unable to find Flight after update. Flight ${swapFlightLegsNotFoundList[0].flightNumber} Please try again`,
          );
        } else {
          logInformation(`Failed to receive Flights API Response.`, 'Flifo Modal', 'onUpdate');
          onCommit(false, '', `Failed to receive Flights API Response. Flight ${flightNumber} Please try again`);
        }
      }
    } else {
      onCommit(false, 'Flifo Info & Schedule');
    }
  };
  // onCancel handler
  const onCancel = () => {
    onClose('FLIFO');
    // Cancel button functionality
  };

  const onCloseHandler = () => {
    onClose();
    if (searchClicked && isSearchDataAvailable) {
      notificationUpdate('', '', false, true, 'flifo-toast'); // HIDE TOAST NOTIFICATION
    }
  };

  const isSearchInvalid = searchClicked && isSearchDataAvailable;
  let isDepartureDateDifferent = !dayjs(departureDateState.initialVal).isSame(departureDateState.value);
  let isFlightNumberDifferent = flightNumberState.initialVal !== flightNumberState.value;
  let isOriginStationDifferent = originStation.initialVal !== originStation.value;
  let isDestinationStationDifferent = destinationStation.initialVal !== destinationStation.value;

  let departureDateInput =
    isDepartureDateDifferent || (isSearchInvalid && isDepartureDateDifferent) ? 'outlined' : 'standard';
  let flightNumberInput =
    isFlightNumberDifferent || (isSearchInvalid && isFlightNumberDifferent) ? 'outlined' : 'standard';
  let originStationInput =
    isOriginStationDifferent || (isSearchInvalid && isOriginStationDifferent) ? 'outlined' : 'standard';
  let destinationStationInput =
    isDestinationStationDifferent || (isSearchInvalid && isDestinationStationDifferent) ? 'outlined' : 'standard';

  const originStationClass = `flifo origin-input ${originStationInput}`;
  const destinationStationClass = `flifo dest-input ${destinationStationInput}`;
  const flightNumberClass = `flifo-flight-container position-relative ${flightNumberInput}`;
  const departureDateClass = `flifo-date-picker ${departureDateInput}`;

  const isFlightNumberNullOrWhitespace = isNullOrWhitespace(flightNumberState.value);
  const isOriginStationNullOrWhitespace = isNullOrWhitespace(originStation.value);
  const isDestinationStationNullOrWhitespace = isNullOrWhitespace(destinationStation.value);

  const FODisNullWhiteSpace =
    isFlightNumberNullOrWhitespace || isOriginStationNullOrWhitespace || isDestinationStationNullOrWhitespace;

  const isDepartureDateSame = dayjs(departureDate).isSame(departureDateState.value);
  const isFlightNumberSame = flightNumber == flightNumberState.value;
  const isDestinationSame = dest == destinationStation.value;
  const isOriginSame = orig == originStation.value;
  const isSearchValid = !isSearchDataAvailable && !searchClicked;

  const isAllConditionsMet =
    isDepartureDateSame && isFlightNumberSame && isDestinationSame && isOriginSame && isSearchValid;

  const areConditionsNotMet = !(isAllConditionsMet || FODisNullWhiteSpace);
  const isEnable = areConditionsNotMet ? 'enable' : 'disable';

  // onSearchClick handler
  const onSearchClick = async () => {
    if (isEnable === 'disable') {
      return;
    }
    setEnableUpdate(true);
    setSearchClicked(true);
    /**
     * refetch the flight details data
     */
    try {
      await refetch().then((result) => {
        const data = result.data;
        const isDataAvailable =
          data?.flightNumber &&
          data?.scheduledOperatingDateUTC &&
          data?.origin?.actual &&
          data?.destination?.actual &&
          data?.times?.length > 0 &&
          data?.irregularOperationCode != 'CXL';
        setIsSearchDataAvailable(!isDataAvailable);

        if (!isDataAvailable) {
          setSchedUTCOutDate('');
          setSchedUTCOutTime({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setSchedETA({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setETD({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setETA({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setOutTime({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setOffTime({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setOnTime({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setInTime({ value: '', warningType: WarningTypes.NONE, warningMessage: '' });
          setOriginStation({ ...originStation, initialVal: null });
          setDestinationStation({ ...destinationStation, initialVal: null });

          const toastMessageText = 'Sorry, flight not found.';
          notificationUpdate(ToastType.ERROR, toastMessageText, true, false, 'flifo-toast'); // SHOW TOAST NOTIFICATION
        } else {
          setOriginStation({ ...originStation, initialVal: originStation.value });
          setDestinationStation({ ...destinationStation, initialVal: destinationStation.value });
          setFlightNumberState({ ...flightNumberState, initialVal: flightNumberState.value });
          setDepartureDateState({ ...departureDateState, initialVal: departureDateState.value });
          notificationUpdate('', '', false, true, 'flifo-toast'); // HIDE TOAST NOTIFICATION
        }
      });
    } catch (err) {
      console.error('Refetch error:', err);
    }
  };

  // Extracted function to get the formatted origin station
  const getFormattedStation = (station, gateInfo) => {
    return station ? `${station} (${gateInfo})` : '';
  };
  // get suffix for zulu time
  const suffixHandler = (value) => (!value ? 'Zulu' : '');
  // getCopyButtonTitle
  const getCopyButtonTitle = (lastCopied, type) => (lastCopied === type ? 'Copied' : 'Copy');

  const formHtml = (
    <Grid spacing={2} container className={'flifo-details-container'} data-cy={'flifo-details-container'}>
      <Grid xs={12} lg={12} item>
        <Grid container>
          <Grid item md={3} sm={6} xs={6} className="position-relative">
            <BrickContainer customCSSTag="flifo-info-date">
              <DatePicker
                dataCyTag="flifo-info-date-input"
                value={departureDateState.value}
                format="MMMM DD, YYYY"
                className={departureDateClass}
                onChange={estDateAndDayHandler}
                isDisabled={false}
                isDisablePast={false}
                isAutoFocus={false}
                {...maxDateProps} //maxDate Props
              />
            </BrickContainer>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={8} className="container-grid">
            <Grid container>
              <Grid item xs={6} sm={6} md={3} lg={4} className="align-horizontal">
                <Grid container className="input-container">
                  <Grid item lg={4} className="text-grid" paddingRight="10px">
                    <div> Flight:</div>
                  </Grid>
                  <Grid item lg={6} className={flightNumberClass}>
                    <TextInput
                      value={flightNumberState.value}
                      onChange={handleFlightNumberChange}
                      onBlur={() => {}}
                      warningType={'NONE'}
                      warningMessage={''}
                      dataCyTag="flifo-info-flight-number"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={4} className="align-horizontal">
                <Grid container className="input-container">
                  <Grid item lg={4} className="text-grid">
                    Origin
                  </Grid>
                  <Grid item lg={6}>
                    <AirportCodeInput
                      airports={
                        isLoadingAirportsData || airportsDataError || !airportsData?.data?.length
                          ? []
                          : airportsData.data
                      }
                      onChange={(value) => handleAirportCodeOriginChange(value)}
                      testId="flifo-info-origin"
                      inputVal={originStation.value}
                      className={originStationClass}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={4} className="align-horizontal">
                <Grid container className="input-container">
                  <Grid item lg={6} className="text-grid">
                    <div> Destination:</div>
                  </Grid>
                  <Grid item lg={6}>
                    <AirportCodeInput
                      airports={
                        isLoadingAirportsData || airportsDataError || !airportsData?.data?.length
                          ? []
                          : airportsData.data
                      }
                      onChange={(value) => handleAirportCodeDestinationChange(value)}
                      testId="flifo-info-destination"
                      inputVal={destinationStation.value}
                      className={destinationStationClass}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} className="search-icon-container">
            <div className="search-icon-rectangle">
              <FontAwesomeIcon
                className={`${classes.searchIcon} search-icon ${isEnable}`}
                icon={faMagnifyingGlass}
                data-cy="searchinput-search-icon"
                onClick={onSearchClick}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.detailsHzRule}>
        <HorizontalRule className={classes.flightStationHzRule} />
      </Grid>
      <Grid xs={12} lg={12} item>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid lg={3} item>
            <Grid className="column-alignment">
              <Grid item className="heading-row">
                <div className="flight-header">DEPARTURE DETAILS</div>
                <div>
                  {' '}
                  <span className="flight-header-span" data-cy="flight-header-span">
                    &nbsp;|&nbsp;{getFormattedStation(originStation.initialVal, departureGateInfo)}
                  </span>
                </div>
              </Grid>
              <Grid item>Schedule Departure</Grid>
              <Grid item>
                <div class="schedule-departure-input-container">
                  <div data-cy="schedule-departure-date-display"> {schedUTCOutDate} </div>
                  <div class="schedule-departure-time-input grid-item-container">
                    <TextInput
                      value={schedUTCOutTime.value}
                      tzIndicator={schedUTCOutTime.tzDisplay}
                      onChange={(value) => handleScheduleTimeChange(value)}
                      warningType={schedUTCOutTime.warningType}
                      warningMessage={schedUTCOutTime.warningMessage}
                      dataCyTag="flifo-schedule-departure-time"
                      isAutoFocus={false}
                      placeholderText="Zulu"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={3} item>
            <Grid className="column-alignment">
              <Grid item className="heading-row">
                <div className="flight-zulu-offsets" data-cy="flight-zulu-offsets">
                  Zulu{' '}
                  {originStation.initialVal ? (
                    <ZuluOffsetDisplay
                      utcDateTime={utcForDepartureZuluTime}
                      dataCyTag={`out-zulu-offset`}
                      station={originStation.initialVal}
                      format="HH"
                    />
                  ) : (
                    '--'
                  )}{' '}
                  hours
                </div>
              </Grid>

              <Grid item>Est Departure</Grid>
              <Grid item className="flifo-etd-container">
                <TextInput
                  value={etd.value}
                  onChange={etdHandler}
                  onBlur={() => {}}
                  warningType={etd.warningType}
                  warningMessage={etd.warningMessage}
                  dataCyTag="flifo-etd"
                  placeholderText="Date/Time"
                  suffixText={suffixHandler(etd.value)}
                />
                <CopyButtonIcon
                  title={getCopyButtonTitle(lastCopied, 'etd')}
                  testId="etd"
                  onClick={handleEtdCopyClick}
                  className="copy-icon"
                />
              </Grid>
              <Grid lg={10} item>
                <BrickContainer title="OUT" customCSSTag="flifo-times-out-container"></BrickContainer>
              </Grid>
              <Grid item className="grid-item-container out-time-input">
                <TextInput
                  value={outTime.value}
                  onChange={outTimeHandler}
                  onBlur={() => {}}
                  warningType={outTime.warningType}
                  warningMessage={outTime.warningMessage}
                  dataCyTag="flifo-times-out"
                  suffixText={suffixHandler(outTime.value)}
                  placeholderText="Date/Time"
                />
              </Grid>
              <Grid lg={10} item>
                <BrickContainer title="OFF" customCSSTag="flifo-times-off-container"></BrickContainer>
              </Grid>
              <Grid item className="grid-item-container off-time-input">
                <TextInput
                  value={offTime.value}
                  onChange={offTimeHandler}
                  onBlur={() => {}}
                  warningType={offTime.warningType}
                  warningMessage={offTime.warningMessage}
                  dataCyTag="flifo-times-off"
                  suffixText={suffixHandler(offTime.value)}
                  placeholderText="Date/Time"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={3} item>
            <Grid className="column-alignment">
              <Grid item className="heading-row">
                <div className="flight-header">ARRIVING DETAILS</div>
                <div dataCyTag="flifo-destination-station">
                  {' '}
                  <span className="flight-header-span" data-cy="flight-header-span">
                    &nbsp;|&nbsp;
                    {getFormattedStation(destinationStation.initialVal, arrivalGateInfo)}
                  </span>
                </div>
              </Grid>
              <Grid item>Schedule Arrival</Grid>
              <Grid item className="grid-item-container flifo-sched-eta">
                <TextInput
                  value={schedETA.value}
                  onChange={scheduleArrivalHandler}
                  onBlur={() => {}}
                  warningType={schedETA.warningType}
                  warningMessage={schedETA.warningMessage}
                  dataCyTag="flifo-sched-eta"
                  placeholderText="Date/Time"
                  suffixText={suffixHandler(schedETA.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={3} item>
            <Grid className="column-alignment">
              <Grid item className="heading-row">
                <div className="flight-zulu-offsets" data-cy="flight-zulu-offsets">
                  Zulu{' '}
                  {destinationStation.initialVal ? (
                    <ZuluOffsetDisplay
                      utcDateTime={utcForArrivalZuluTime}
                      dataCyTag={`in-zulu-offset`}
                      station={destinationStation.initialVal}
                      format="HH"
                    />
                  ) : (
                    '--'
                  )}{' '}
                  hours
                </div>
              </Grid>

              <Grid item xs={12}>
                Est Arrival
              </Grid>
              <Grid item xs={12} className="flifo-eta-container">
                <TextInput
                  value={eta.value}
                  onChange={estArrivalHandler}
                  onBlur={() => {}}
                  warningType={eta.warningType}
                  warningMessage={eta.warningMessage}
                  dataCyTag="flifo-times-eta"
                  placeholderText="Date/Time"
                  suffixText={suffixHandler(eta.value)}
                />
                <CopyButtonIcon
                  title={getCopyButtonTitle(lastCopied, 'eta')}
                  testId="eta"
                  onClick={handleEstCopyClick}
                />
              </Grid>
              <Grid item xs={12}>
                <BrickContainer title="ON" customCSSTag="flifo-times-on-container"></BrickContainer>
              </Grid>
              <Grid item xs={12} className="grid-item-container flifo-times-on">
                <TextInput
                  value={onTime.value}
                  onChange={onTimeHandler}
                  onBlur={() => {}}
                  warningType={onTime.warningType}
                  warningMessage={onTime.warningMessage}
                  dataCyTag="flifo-times-on"
                  placeholderText="Date/Time"
                  suffixText={suffixHandler(onTime.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <BrickContainer title="IN" customCSSTag="flifo-times-in-container"></BrickContainer>
              </Grid>
              <Grid item xs={12} className="grid-item-container flifo-times-in">
                <TextInput
                  value={inTime.value}
                  onChange={inTimeHandler}
                  onBlur={() => {}}
                  warningType={inTime.warningType}
                  warningMessage={inTime.warningMessage}
                  dataCyTag="flifo-times-in"
                  placeholderText="Date/Time"
                  suffixText={suffixHandler(inTime.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <FlifoModal
      formHtml={formHtml}
      isDisabled={!isUpdateBtnEnabled()}
      onUpdate={onUpdate}
      onCancel={onCancel}
      onClose={onCloseHandler}
    />
  );
};

Flifo.propTypes = {
  onClose: PropTypes.func,
  onCommit: PropTypes.func,
  flightLeg: PropTypes.shape({
    flightNumber: PropTypes.string,
    departureDate: PropTypes.string,
    orig: PropTypes.string,
    dest: PropTypes.string,
    departureCount: PropTypes.number,
    airline: PropTypes.string,
  }),
};
export default withAppInsightsTracking(Flifo);
export { CopyButtonIcon };
