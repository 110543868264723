import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import EarlyDelayDisplay from '../../Shared/EarlyDelayDisplay/EarlyDelayDisplay';
import { useAirportsContext } from '../../../contexts/AirportsContext/AirportsContext';
import { getTimeDifference } from '../../../lib/dateTimeUtils';
import './TimeDisplay.css';

/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * @description Display a Time in either UTC or Station Local, as well as a Early/Delay Indicator if a comparison time is provided.
 * @param {string} utcTime - The ISO 8601 string representing a UTC time.
 * @param {string} dataCyTag - Value for data-cy tag for integration tests. Time zone element appends '-time-zone' to the data-cy.
 * @param {string} station - Optional: The station code associated with this time.  Will be used for performing UTC to Station Local conversions.
 * @param {string} comparisonUtcTime - Optional: The ISO 8601 string representing a comparions UTC time.
 * @param {string} label - Optional: The label for the time difference display. (ie. mins).
 * @returns HTML display a time in "HH:mm zzz" with an Early/Delay Indicator if comparisonUtcTime and utcTime don't match.
 */
export const TimeDisplay = ({
  utcTime,
  dataCyTag,
  station = null,
  comparisonUtcTime = null,
  label = '',
  delayTime = null,
}) => {
  const displayTimeFormat = 'HH:mm';
  // Default display value
  const defaultDisplay = <>&#8212;</>;

  const [stationTimeZone, setStationTimeZone] = useState(null);
  const [timeFormatted, setTimeFormatted] = useState(null);
  const [timeZoneFormatted, setTimeZoneFormatted] = useState(null);
  const { isLoading, getAirportDetail } = useAirportsContext();

  /**
   * Create JSX for the Early/Delay indicator if there is a comparison time.
   */
  const timeDiffDisplay = () => {
    if (comparisonUtcTime == null || typeof comparisonUtcTime === 'undefined') return null;

    let difference = delayTime == null ? getTimeDifference(comparisonUtcTime, utcTime) : delayTime;

    return <EarlyDelayDisplay difference={difference} label={label} />;
  };

  /**
   * Side-effect if the isLoading (context state), getAirportDetail (context func),
   * utcTime (prop), or station (prop) change.
   */
  useEffect(() => {
    if (station == null || typeof station == 'undefined') {
      if (utcTime != null && typeof utcTime !== 'undefined') {
        setTimeFormatted(dayjs.utc(utcTime).format(displayTimeFormat));
        setTimeZoneFormatted('Z');
      }
      return;
    }

    if (isLoading) return;

    const airportObject = getAirportDetail(station);

    if (airportObject != null) setStationTimeZone(airportObject.airportTimeZone);
  }, [isLoading, getAirportDetail, utcTime, station]);

  /**
   * Side-effect if the station time zone (state), station (prop), or utcTime (prop) change.
   */
  useEffect(() => {
    if (station == null || utcTime == null || typeof utcTime === 'undefined') return;

    if (stationTimeZone == null) {
      setTimeFormatted(null);
      setTimeZoneFormatted(null);
    } else {
      if (utcTime != null && typeof utcTime !== 'undefined') {
        const localTime = dayjs.utc(utcTime).tz(stationTimeZone);
        setTimeFormatted(localTime.format(displayTimeFormat));
        setTimeZoneFormatted(localTime.format('z'));
      }
    }
  }, [stationTimeZone, station, utcTime]);

  return (
    <>
      <span data-cy={dataCyTag}>{timeFormatted ? timeFormatted.trim() : defaultDisplay}</span>

      {timeZoneFormatted ? (
        <span className="secondary-label zulu-label gray-pad-left" data-cy={`${dataCyTag}-time-zone`}>
          {timeZoneFormatted.trim()}
        </span>
      ) : null}
      {timeFormatted ? timeDiffDisplay() : null}
    </>
  );
};

TimeDisplay.propTypes = {
  utcTime: PropTypes.string.isRequired,
  dataCyTag: PropTypes.string.isRequired,
  station: PropTypes.string,
  comparisonUtcTime: PropTypes.string,
  label: PropTypes.string,
};

// Memoizing the component to prevent re-renders.
export default React.memo(TimeDisplay, (prevProps, nextProps) => {
  // Are the props equal between the previous and next?
  return (
    prevProps.utcTime === nextProps.utcTime &&
    prevProps.dataCyTag === nextProps.dataCyTag &&
    prevProps.station === nextProps.station &&
    prevProps.comparisonUtcTime === nextProps.comparisonUtcTime &&
    prevProps.label === nextProps.label
  );
});
