import { useDispatch } from 'react-redux';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
import { DragDropAction } from '../../redux/actionTypes';
import { useCallback } from 'react';

// Selector into redux store
const selectDragDropStore = (state) => state.dragDropReducer;

// Selector for dragItem object within redux store
const selectDragItemStore = (state) => state.dragDropReducer.dragItem;

/**
 * Hook for drag item within drag and drop store
 * @returns
 */
export const useDragItemStore = () => {
  return useSelector(selectDragItemStore);
};

/**
 * Hook for drag and drop store
 * @returns
 */
export const useDragDropStore = () => {
  return useSelector(selectDragDropStore);
};

/**
 * Dispatch wrapper for updating drag and drop store
 * @returns functions to update drag and drop store
 */
export const useDragDropDispatch = () => {
  const dispatch = useDispatch();

  const startDrag = useCallback(
    (dragItem) => {
      dispatch({ type: DragDropAction.START_DRAG, payload: dragItem });
    },
    [dispatch],
  );

  const endDrag = useCallback(() => {
    dispatch({ type: DragDropAction.END_DRAG });
  }, [dispatch]);

  const hover = useCallback(
    (dropItem) => {
      dispatch({ type: DragDropAction.HOVER, payload: dropItem ? { ...dropItem } : null });
    },
    [dispatch],
  );

  return { startDrag, endDrag, hover };
};
