import PropTypes from 'prop-types';
import './PuckFilterButton.css';
import { ReactComponent as PuckFilterIcon } from '../../../assets/images/puck-filter-button.svg';

const PuckFilterButton = ({ className, onClick }) => {
  return (
    <button onClick={onClick} className={`${className} overlapping-pucks-filter`} tabIndex={0}>
      <PuckFilterIcon />
    </button>
  );
};

PuckFilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default PuckFilterButton;
