/**
 * @description  multi Select Dropdown functions.
 * @returns returns true if matchTime is after targetTime
 */
export const multiSelectDropdownUtils = () => {
  /**
   * @description  check and uncheck the dropdown item.
   * @param {*} listItems
   * @param {*} itemName
   * @param {*} checked
   * @returns returns list of items with changes made.
   */
  const setItemSelection = (listItems, itemName, checked) => {
    return listItems.map((item) => {
      if (item.name === itemName)
        return {
          key: item.key,
          name: item.name,
          checked: checked,
          isFocus: true,
        };
      item.isFocus = false;

      return item;
    });
  };
  /**
   * @description check and uncheck multiple items.
   * @param {*} listItems
   * @param {*} selectedItems
   * @param {*} checked
   * @returns returns list of items with changes made.
   */
  const setMultipleItemsSelection = (listItems, selectedItems = [], checked, selectedItemName) => {
    return listItems.map((item) => {
      if (selectedItems.includes(item.key)) item.checked = checked;
      else item.checked = !checked;
      item.isFocus = item.name === selectedItemName;
      return item;
    });
  };

  /**
   * @description check, uncheck and disable multiple items based on selectedAssignment.
   * @param {*} listItems
   * @param {*} selectedAssignment
   * @param {*} checked
   * @returns returns list of items with changes made.
   */
  const checkAircrafts = (listItems, selectedAssignment, checked) => {
    return listItems.map((item) => {
      if (item.name == 'My Flights' || item.name == 'All Aircraft') {
        return { key: item.key, name: item.name, checked: false };
      } else if (item.name == selectedAssignment) {
        return { key: item.key, name: item.name, checked: checked };
      }
      return item;
    });
  };

  /**
   * @description check, uncheck and disable multiple items based on selectedAssignment.
   * @param {*} listItems
   * @param {*} selectedAssignment
   * @param {*} checked
   * @returns returns list of items with changes made.
   */
  const checkFlights = (listItems, selectedAssignment, checked) => {
    return listItems.map((item) => {
      if (item.name == selectedAssignment && checked) {
        return { key: item.key, name: item.name, checked: checked };
      } else {
        return { key: item.key, name: item.name, checked: false };
      }
    });
  };
  /**
   * @description check and uncheck all dropdown items based on checked param.
   * @param {*} listItems
   * @param {*} checked
   * @returns returns list of items with changes made.
   */
  const setAllItemsSelection = (listItems, checked) => {
    return listItems.map((item) => {
      return { key: item.key, name: item.name, checked: checked, isFocus: item.name === 'Select All' };
    });
  };
  /**
   * @description uncheck all dropdown items.
   * @param {*} listItems
   * @returns returns list of items with uncheck all.
   */
  const uncheckAllItems = (listItems) => {
    return setAllItemsSelection(listItems, false);
  };
  /**
   * @description check all dropdown items.
   * @param {*} listItems
   * @returns returns list of items with check all.
   */
  const checkAllItems = (listItems) => {
    return setAllItemsSelection(listItems, true);
  };
  /**
   * @description get item by name.
   * @param {*} listItems
   * @param {*} itemName
   * @returns returns object of matched item name.
   */
  const getItem = (listItems, itemName) => {
    return listItems.find((item) => item.name === itemName);
  };
  /**
   * @description checks specific item is checked or not.
   * @param {*} listItems
   * @param {*} itemName
   * @returns returns true or false
   */
  const isChecked = (listItems, itemName) => {
    return getItem(listItems, itemName)?.checked;
  };
  /**
   * @description checks all items is checked or not.
   * @param {*} listItems
   * @returns returns true or false
   */
  const isAllChecked = (listItems) => {
    return listItems.every((item) => item.checked);
  };
  /**
   * @description checks is any one item checked or not.
   * @param {*} listItems
   * @returns returns true or false
   */
  const isAnyItemChecked = (listItems) => {
    return listItems.some((item) => item.checked);
  };
  /**
   * @description get count of checked items.
   * @param {*} listItems
   * @returns returns length of filter array.
   */
  const getCheckedCount = (listItems) => {
    return listItems.filter((item) => item.checked).length;
  };
  /**
   * @description get checked items key array.
   * @param {*} listItems
   * @returns returns key array.
   */
  const getCheckedKeyList = (listItems) => {
    const selectedItems = listItems.reduce((result, item) => {
      if (item.checked) {
        result.push(item.key);
      }
      return result;
    }, []);
    return selectedItems;
  };
  /**
   * @description get default and selected filtered items if any.
   * @param {*} listItems
   * @param {*} checkedItems
   * @param {*} selectAllKey
   * @returns returns items array with changes.
   */
  const getSelectedFilterItems = (listItems, checkedItems, selectAllKey) => {
    if (checkedItems.length === 1 && checkedItems[0] === selectAllKey) {
      return listItems.map((x) => x);
    } else if (checkedItems.length > 0) {
      return listItems.map((item) => {
        if (!checkedItems.includes(item.key)) return { key: item.key, name: item.name, checked: false };
        return item;
      });
    }
  };

  /**
   * @description function to get Placeholder text formatted string.
   * if user select one item in drop down it showing (selected item)
   * if user slect multiple item in drop down it showing (Selected Items + remaining count)
   * @param {*} listItems
   * @param {array} itemsToExclude items like Select All and Select None which should be excluded
   * @returns returns placeholder text.
   */
  const getPlaceholdertext = (listItems, itemsToExclude) => {
    const cutoffTextLength = 15; // 14 characters
    let placeholderText = '';
    let checkedCount = 0;
    for (let i = 0; i < listItems.length; i++) {
      if (listItems[i].checked && !itemsToExclude.includes(listItems[i].name)) {
        if (placeholderText.length === 0) placeholderText = listItems[i].name;

        checkedCount++;
      }
    }
    if (placeholderText.length > cutoffTextLength + 4)
      placeholderText = placeholderText.substring(0, cutoffTextLength) + '...';
    if (checkedCount > 1) placeholderText += ' + ' + (checkedCount - 1).toString();
    return placeholderText;
  };

  /**
   * @description  filter dropdown items based on selected option.
   * @param {*} alertsWithSelection
   * @param {*} selectedOption
   * @returns
   */
  const filterDropdown = (alertsWithSelection, selectedOption) => {
    const filteredAlertTypes = alertsWithSelection.filter((d) => {
      if (selectedOption?.includes(d.key)) {
        return d;
      }
    });

    return filteredAlertTypes;
  };

  return {
    setItemSelection,
    setMultipleItemsSelection,
    checkAircrafts,
    checkFlights,
    uncheckAllItems,
    checkAllItems,
    isChecked,
    getItem,
    isAllChecked,
    isAnyItemChecked,
    getCheckedCount,
    getCheckedKeyList,
    getPlaceholdertext,
    getSelectedFilterItems,
    filterDropdown,
  };
};
/**
 * @description a utility class that can be used alongside ChipInput / ChipInputDropdown component.
 * @returns an object with methods that can help with computations usually required when using ChipInput / ChipInputDropdown component
 */
export const chipInputUtils = () => {
  /**
   * @description add a chip to the array
   * @param {*} allChips array of all the chips.
   * @param {*} chipToAdd string value of the chip to add to allChips array
   * @returns returns array of items with changes made.
   */
  const addChip = (allChips, chipToAdd) => {
    if (chipToAdd == null || allChips.indexOf(chipToAdd) > -1) return [...allChips];
    return [...allChips, chipToAdd];
  };
  /**
   * @description delete a chip from the array
   * @param {*} allChips array of all the chips.
   * @param {*} chipToDelete string value of the chip to delete from allChips array
   * @returns returns array of items with changes made.
   */
  const deleteChip = (allChips, chipToDelete) => {
    if (chipToDelete == null || allChips.indexOf(chipToDelete) < 0) return [...allChips];
    return allChips.filter((c) => c !== chipToDelete);
  };

  return {
    addChip,
    deleteChip,
  };
};
