import React, { useState, useRef, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './SearchableDropdown.css';
import { getInputPoppersModifier, setPopperWidth } from '../../../../lib/utils';
import { KeyCodes } from '../../../../lib/constants';
const useStyles = makeStyles((theme) => {
  return {
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      marginTop: 3,
      marginBottom: 3 * 3,
      left: 0,
      right: 0,
      zIndex: 1,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      zIndex: 100,
    },
    textField: {
      width: '100%',
    },
    root: {},
    inputRoot: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'flex-start',
    },
    input: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      appearance: 'none',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      float: 'left',
      flex: 1,
      cursor: 'pointer',
    },
    chipContainer: {
      display: 'inline',
      flexFlow: 'row wrap',
      cursor: 'pointer',
    },
    // focused: {},
    disabled: {},
  };
});

/**
 * SearchableDropdown is a component for searchable dropdown element.
 * @param {array} displayList - array of suggestions to display as the user types on the input
 * @param {func} onSelect - callback funciton when the user select the dropdown menu item.
 * @param {string} textInput - text on the input to display on the control
 * @param {func} onChange - callback function when user enters input or input text changes
 * @param {string} dataCyTag - test id attribute
 * @param {string} className - an additional custom class name
 * @param {bool} isDisabled - to set if the control is enabled or disabled
 * @param {bool} isFullWidth - boolean to set if the control has to be full width of the parent
 * @param {bool} isReadOnly - boolean to set the control to ReadOnly
 */
const SearchableDropdown = ({
  displayList = [],
  onSelect = () => {
    /* this is intentional */
  },
  textInput = '',
  onChange = () => {
    /* this is intentional */
  },
  className = '',
  dataCyTag = '',
  isDisabled = false,
  isFullWidth = true,
  isReadOnly = false,
  isAutoFocus = false,
  isRequired = false,
  isOpenList = false,
}) => {
  const [openList, setOpenList] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const classes = useStyles();

  const anchorEl = useRef(null);
  const menuRef = useRef(null);

  /**
   * @description opens the list of dropdown items set width of menu list  parent div width
   */
  useEffect(() => {
    resizeListener(); // set resize listener
    window.addEventListener('resize', resizeListener); // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener); // remove resize listener
    };
  }, []);

  /**
   * @description set open dropdown items based on isOpenList prop.
   */
  useEffect(() => {
    setOpenList(isOpenList);
  }, [isOpenList]);

  const resizeListener = () => {
    if (anchorEl.current) {
      setMenuWidth(anchorEl.current.getBoundingClientRect().width);
    }
  };

  /**
   * @description opens the list of dropdown items on text change and calls the parent event handler
   * @param {*} textchange event from the input text field
   */
  const handleTextInputChange = (event) => {
    setOpenList(true);
    // let target = anchorEl.current.querySelector('input');
    onChange(event.target.value, event);
  };

  const handleTextInputKeyDown = (event) => {
    if (
      event.target.value.trim().length === 0 &&
      (event.keyCode === KeyCodes.SPACE || event.keyCode === KeyCodes.ENTER || event.keyCode === KeyCodes.TAB)
    ) {
      setOpenList(true);
    }
  };

  /**
   * @description opens the list of dropdown items on focus and click
   */
  const onInputFocus = () => {
    if (isAutoFocus) {
      setOpenList(false);
    } else if (isRequired) {
      setOpenList(true);
    } else {
      setOpenList(false);
    }
  };

  /**
   * @description handles click event on cheveron and opens/closes the dropdown
   * @param {any} e click event object
   */
  const handleChevronClick = (e) => {
    setOpenList(true);
  };

  /**
   * @description handles menu close.
   * @param {*} click event that comes either from ClickAwayListener or MenuItemClick
   */
  const handleClose = (event) => {
    if (anchorEl.current.contains(event.target)) {
      // This click is considered a part of user input process.
      // we do not need to close the dropdown in this case. return without closing the dropdown list
      return;
    }
    setOpenList(false);
  };

  /**
   * @description handles menu item click. closes the dropdown list and calls parent onSuggestionSelect handler
   * @param {*} click event on the suggestions list menu item
   */
  const handleMenuItemClick = (event) => {
    var val = event?.target?.attributes['value']?.value;
    handleClose(event);
    if (val != null && val !== 'NoItemsFound') {
      const { value, name } = displayList.find((element) => element.value === val);
      let target = anchorEl.current.querySelector('input');
      onSelect(name, value, event, target);
    }
  };
  /**
   * @description simulates click when enter, tab or space
   * @param {any} event
   */
  const handleMenuItemKeyDown = (event) => {
    if (
      event.keyCode === KeyCodes.ENTER ||
      event.keyCode === KeyCodes.TAB ||
      (event.keyCode === KeyCodes.SPACE && !event.shiftKey)
    ) {
      handleMenuItemClick(event);
    }
  };
  const modifiersArray = getInputPoppersModifier(setPopperWidth);
  const modifiersObject = { modifiersArray };

  return (
    <>
      <div className={className + ' dropdownsearchable-container'} ref={anchorEl} onClick={handleChevronClick}>
        <div className={`dropdowntextbox ${classes.chipContainer}  ${isDisabled ? classes.disabled : ''}`}>
          <OutlinedInput
            classes={{
              input: `${classes.input}`,
              root: `${classes.inputRoot} ${className}`,
            }}
            id={dataCyTag}
            data-cy={dataCyTag}
            value={textInput}
            onChange={handleTextInputChange}
            onFocus={onInputFocus}
            onKeyDown={handleTextInputKeyDown}
            disabled={isDisabled}
            fullWidth={isFullWidth}
            placeholder={''}
            readOnly={isReadOnly}
            inputProps={{
              'data-cy': dataCyTag + '-input',
              autoFocus: isAutoFocus,
              className: className,
              autocomplete: 'off',
            }}
          />
          <div className="dropdown-chevrondown-icon">
            <FontAwesomeIcon className="menu-icon MuiSelect-icon" icon={faChevronDown} />
          </div>
        </div>
      </div>
      <Popper
        open={openList}
        anchorEl={anchorEl.current}
        className={classes.suggestionsList}
        placement="bottom-start"
        modifiers={modifiersObject}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Paper elevation={0} className={'dropdownsearchable-paper'}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                ref={menuRef}
                className={'menulist'}
                autoFocusItem={false}
                autoFocus={false}
                style={{ maxHeight: '15rem', overflowY: 'auto', width: menuWidth }}
                data-cy={dataCyTag + '-menu'}
              >
                {displayList.length > 0 ? (
                  displayList.map((ele, index) => (
                    <MenuItem
                      key={ele.value}
                      index={index}
                      value={ele.name}
                      onClick={handleMenuItemClick}
                      onKeyDown={handleMenuItemKeyDown}
                    >
                      {ele.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="noitemsfound" index={0} value={'NoItemsFound'} onKeyDown={handleMenuItemKeyDown}>
                    No matching results!
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

SearchableDropdown.propTypes = {
  displayList: PropTypes.array,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  dataCyTag: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default withAppInsightsTracking(SearchableDropdown);
