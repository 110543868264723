import { useQuery } from 'react-query';
import { getSwapsReasons } from '../../services/apiClient/referenceApi/referenceApi';
import appSettings from '../../appSettings';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';

const moduleName = 'useSwapReasonsQuery';

/**
 * @description Custom hook for retrieving data from Reference API.
 * @param {string} airlineCode - airline code.
 */
export const useSwapReasonsQuery = (airlineCode) => {
  const config = {
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useSwapReasonsQuery', createApiErrorTelemetryProperties(error));
    },
  };

  return useQuery(['swapReasons', airlineCode], () => getSwapsReasons(airlineCode), config);
};
