import { useQuery } from 'react-query';
import { getAuthorizedUser } from '../../services/apiClient/authorizationApi/authorizationApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useAuthorizationQuery';

/**
 * @description Custom hook for authorization data from authorization API.
 * @param {string} airlineCode
 * @returns Authorization data or an error if the API call has an error
 */
export const useAuthorizationQuery = (airlineCode) => {
  const config = {
    retry: appSettings.RETRY_QUERY_DELAY,
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getAuthorizedUser', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['authorization', airlineCode], () => getAuthorizedUser(airlineCode), config);
};
