import React from 'react';
import PropTypes from 'prop-types';
import './ActivityLogTab.css';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { useSelectedItemStore } from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useFlightOperationalNotesQuery } from '../../../../hooks/useNotesQuery/useNotesQuery';
import { NoteTag } from '../../../../lib/constants';
import { formatDateTime, getDayDifference } from '../../../../lib/dateTimeUtils';
import ActivityLogGroup from './ActivityLogGroup/ActivityLogGroup';
import TabSkeleton from '../TabSkeleton/TabSkeleton';

/**
 * ActivityLogTab renders the activity log / operational notes in flight detail panel.
 * @returns
 */
const ActivityLogTab = ({ isHorizontal = false }) => {
  const selectedFlightDetails = useSelectedItemStore();
  let flightLeg = selectedFlightDetails.data;

  let fetchNotesData = selectedFlightDetails.isActivityTabOpen && flightLeg != null;

  const { isLoading: loading, data: flightLegOperationalNotes } = useFlightOperationalNotesQuery(
    flightLeg?.flightNumber,
    flightLeg?.departureDate,
    flightLeg?.orig,
    flightLeg?.dest,
    flightLeg?.departureCount,
    flightLeg?.airline,
    true,
    fetchNotesData,
  );

  let activityContent = '';

  let renderActivityLog = !loading && flightLegOperationalNotes && Object.keys(flightLegOperationalNotes)?.length > 0;
  let activityLogNotes = [];

  if (renderActivityLog) {
    activityLogNotes = flightLegOperationalNotes?.operationalNotes.filter((n) => n.noteTag === NoteTag.WATCH_FLIGHT);
  }

  if (renderActivityLog && activityLogNotes?.length > 0) {
    let operationalNotesTag = 'Watch Flight';
    let dateFormat = 'MM/DD/YYYY';

    //Group the activity log by date
    const activityGroupByDate = activityLogNotes.reduce((group, note) => {
      const { operationalNoteCreateDate, operationalNoteClosedDate } = note;
      let date = '';

      if (operationalNoteClosedDate) {
        date = formatDateTime(operationalNoteClosedDate, dateFormat);
        group[date] = group[date] ?? [];
        group[date].push({
          activityDateTime: note.operationalNoteClosedDate,
          activityNote: 'Changed to unwatched flight.',
          activityTag: operationalNotesTag,
        });
      }

      if (operationalNoteCreateDate) {
        date = formatDateTime(operationalNoteCreateDate, dateFormat);
        group[date] = group[date] ?? [];
        group[date].push({
          activityDateTime: note.operationalNoteCreateDate,
          activityNote: 'Changed to watched flight and set reason code to ' + note.operationalNote + '.',
          activityTag: operationalNotesTag,
        });
      }

      return group;
    }, {});

    //create the activity log content
    activityContent = (
      <div className="activity-log-details" data-cy="activity-log-details">
        {Object.keys(activityGroupByDate)
          .sort((a, b) => {
            return getDayDifference(b, null, a);
          }) //sort by date
          .map((date) => {
            return (
              <ActivityLogGroup
                isHorizontal={isHorizontal}
                activityDate={date}
                activityLogDetails={activityGroupByDate[date]}
              />
            );
          })}
      </div>
    );
  }

  return loading ? (
    <TabSkeleton />
  ) : (
    <div className="activity-log-tab-container">
      <div className="activity-log-header">FLIGHT ACTIVITY</div>
      {activityContent}
    </div>
  );
};

ActivityLogTab.propTypes = {
  isHorizontal: PropTypes.bool,
};

export default withAppInsightsTracking(ActivityLogTab);
