import React, { Fragment } from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import Modal from '../../../Shared/Modal/Modal';
import Button from '../../../Shared/Button/Button';
import { Grid } from '@material-ui/core';
import { KeyCodes, AircraftCertificateStatus } from '../../../../lib/constants';
import './SwapWarningModal.css';
import SwapWarningBadge from './SwapWarningBadge';
import AircraftCertificateIcon from '../../../Shared/AircraftCertificateIcon/AircraftCertificateIcon';
import NotificationToast from '../../../Shared/NotificationToast/NotificationToast';

const SwapWarningModal = ({
  onCancel,
  onClose,
  onHide,
  swapWarnings,
  toastNotificationProps = {
    showToast: false,
    message: '',
    toastType: '',
    autoHide: false,
  },
}) => {
  /**
   * @description Hitting Enter on Preview button closes the modal
   */
  const handleKeypress = (e) => {
    //It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      onClose();
    }
  };

  /**
   * @description Hitting Enter on Cancel button closes the modal and does undo once
   */
  const handleKeypressOnCancel = (e) => {
    //It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      onCancel();
    }
  };

  /**
   * @description default on Blur.
   */
  const handleOnBlur = (e) => {};

  const handleOnCancelClick = () => {
    onCancel();
  };

  // Certificate Item component
  const CertificateItem = ({ status, label, dataCy }) => (
    <div className="swap-warning-modal-certificate-item">
      <AircraftCertificateIcon status={status} />
      <div className="label" data-cy={dataCy}>
        {label}
      </div>
    </div>
  );

  const formHTML = (
    <Fragment>
      <div className="swap-warning-modal-container" data-cy="swap-warning-modal-container">
        <Grid container spacing={2} direction="column">
          {!swapWarnings?.length ? 'WARNING MESSAGE' : null}
          {swapWarnings?.length > 0 &&
            swapWarnings.map((aircraft, index) => (
              <div key={index}>
                <Grid item xs={12} className="headerGrid">
                  <div className="sectionHeader" data-cy="swap-warning-aircraft-Header">
                    AIRCRAFT - {aircraft.aircraftRegistration}
                  </div>
                </Grid>
                <div className="swap-warning-modal-warningmessage-header">WARNING MESSAGE</div>
                <div className="swap-warning-modal-messages-container">
                  {aircraft.swapWarnings.length > 0 && <SwapWarningBadge messages={aircraft.swapWarnings} />}{' '}
                </div>
                {(aircraft.operatingAriline === 'AS' || aircraft.operatingAriline === 'QX') && (
                  <Grid item xs={12} className="swap-warning-modal-certificates-container">
                    <Grid item className="swap-warning-modal-details certificates-container">
                      <div className="swap-warning-modal-items-container">
                        {aircraft?.certificates?.temporaryAircraftRegistration ? (
                          aircraft.operatingAriline === 'QX' ? (
                            <CertificateItem
                              status={AircraftCertificateStatus.MISSING_AIRCRAFT_CERTIFICATE}
                              label="Aircraft Registration"
                              dataCy="air-registration-certificate"
                            />
                          ) : (
                            <CertificateItem
                              status={AircraftCertificateStatus.TEMPORARY_AIRCRAFT_CERTIFICATE}
                              label="Temp Certificate"
                              dataCy="air-registration-certificate"
                            />
                          )
                        ) : (
                          <CertificateItem
                            status={AircraftCertificateStatus.AIRCRAFT_CERTIFICATE}
                            label="Aircraft Registration"
                            dataCy="air-registration-certificate"
                          />
                        )}
                        {aircraft.operatingAriline === 'AS' &&
                          (aircraft?.certificates?.foreignDocumentsMissing ? (
                            <CertificateItem
                              status={AircraftCertificateStatus.MISSING_AIRCRAFT_CERTIFICATE}
                              label="Foreign Documents"
                              dataCy="air-registration-certificate"
                            />
                          ) : (
                            <CertificateItem
                              status={AircraftCertificateStatus.AIRCRAFT_CERTIFICATE}
                              label="Foreign Documents"
                              dataCy="air-mexico-certificate"
                            />
                          ))}
                      </div>
                    </Grid>
                  </Grid>
                )}
                {index === swapWarnings.length - 1 ? null : <div className="sectionSeparator"></div>}
              </div>
            ))}
        </Grid>
      </div>
      <div>
        <NotificationToast
          showToast={toastNotificationProps.showToast}
          message={toastNotificationProps.message}
          toastType={toastNotificationProps.toastType}
          autoHide={toastNotificationProps.autoHide}
          onClose={toastNotificationProps.onClose}
          style={{
            toast: {
              minWidth: '30rem',
            },
            textStyle: {
              fontSize: '1.2rem',
              fontWeight: '700',
            },
          }}
        />
      </div>
    </Fragment>
  );

  // Footer button of Swap Warning Modal.
  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="swap-warning-modal-cancel-button"
      isDisabled={false}
      onClick={handleOnCancelClick}
      onKeyPress={handleKeypressOnCancel}
      className={'swap-warning-modal-cancel-button'}
    >
      {'Cancel'}
    </Button>,
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="swap-flight-warning-preview-button"
      onClick={onClose}
      onKeyPress={handleKeypress}
      onBlur={handleOnBlur}
    >
      {'Preview Swap'}
    </Button>,
  ];

  return (
    <Modal
      show={true}
      title="Swap Summary"
      body={formHTML}
      size={'responsive swap-warning-form'}
      footerButtons={formButtons}
      onHide={onHide}
      customCSSTag={'swap-warning-form'}
      dataCyTag="swap-warning-form"
      bottomLine={false}
    />
  );
};
export default withAppInsightsTracking(SwapWarningModal);
