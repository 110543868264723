/**
 * Get the latest array of entitlements from the authorized roles list that match the selected role.
 * @param {object} role - The selected role for the RoleAssignmentContext.
 * @param {array} authorizedRoles - Array of objects that represent the authorized roles for the user and the entitlements associated with those roles.
 * @return {array} - An array of entitlements associated with the role.  Defaults to an empty array.
 */
const extractRoleEntitlements = (role, authorizedRoles) => {
  // Validate objects before using them.  Default to empty array if we don't have what we need to continue.
  if (
    typeof authorizedRoles === 'undefined' ||
    authorizedRoles == null ||
    authorizedRoles.length === 0 ||
    typeof role === 'undefined' ||
    role == null
  )
    return [];

  // Find the matching role to get the role's current entitlements.
  // We want to use the entitlements that recently came from the Auth API and
  // not what might have been stored in User Preferences the last time (entitlements might change)
  let entitlements = [];

  authorizedRoles.forEach((ar) => {
    if (ar.roleID === role.roleID) {
      // shallow copy of the array.
      entitlements = [...ar.entitlements];
      return entitlements;
    }
  });

  return entitlements;
};

const roleAssignmentReducer = (state, action) => {
  switch (action.type) {
    case 'NO_ROLE_ASSIGNMENTS':
      return {
        role: null,
        entitlements: [],
        assignments: [],
        loading: false,
      };
    case 'RETRIEVING_ROLE_ASSIGNMENTS':
    case 'SAVING_ROLE_ASSIGNMENTS':
      return {
        role: null,
        entitlements: [],
        assignments: [],
        loading: true,
      };
    case 'RETRIEVED_ROLE_ASSIGNMENTS':
    case 'SAVED_ROLE_ASSIGNMENTS':
      // Create a shallow copy of the role, and clear it's Entitlements.
      var role = { ...action.payload.role, entitlements: [] };

      return {
        role: role,
        entitlements: extractRoleEntitlements(role, action.payload.authorizedRoles),
        assignments: action.payload.assignments,
        loading: false,
      };
    case 'ERROR_SAVING_ROLE_ASSIGNMENTS':
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default roleAssignmentReducer;
