import { baseTheme } from './baseTheme';

export const darkTheme = {
  ...baseTheme,
  palette: {
    type: 'dark',
    background: {
      default: '#1D212C',
      //Background color for mui-popover such as calendar
      paper: '#1D212C', //var(--page-container-background)
    },
    text: {
      primary: '#ffffff', //var(--page-container-text)
    },
  },
  overrides: {
    MuiTableContainer: {
      root: {
        backgroundColor: '#1D212C', //var(--page-container-background)
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#242936', //var(--table-odd-stripe)
        },
      },
    },
    MuiTableCell: {
      body: {
        color: '#fff',
      },
      root: {
        fontSize: '1.4rem',
      },
    },
    //Icon button such as prev-next in calendar
    MuiIconButton: {
      root: {
        color: '#ffffff',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'lightgrey',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
};
