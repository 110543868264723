import { appInsightsClient } from '../services/appInsightsFactory/appInsightsFactory';

/**
 * Defines the level of severity for the event.
 */
export const SeverityLevel = {
  // As a standard, we only allow info and error
  // Verbose:  0,
  INFORMATION: 1,
  // Warning:  2,
  ERROR: 3,
  // Critical:  4,
};

/**
 * Returns a standard, formatted log message
 *
 * Example:
 * Message=GET AOC Styles API Ping
 * ClassName=PingController
 * MethodName=Get()
 * @param {string} message - log message
 * @param {string} moduleName - module (JS filename)
 * @param {string} functionName - function name
 * @returns formatted log message string
 */
const getStructuredLogMessage = (message, moduleName, functionName) => {
  message = `Message=${message}`;
  moduleName = `ModuleName=${moduleName}`;
  functionName = `FunctionName=${functionName}`;

  return message + '\n' + moduleName + '\n' + functionName;
};

/**
 * Returns the default custom dimensions
 *
 * @returns default custom dimensions
 */
const getDefaultCustomDimensions = () => {
  return { Application: 'FO\\\\AOC-AirTrakUI' };
};

/**
 * @description Get the error message from an API Error.  Checks Json "errorMessage" property before defaulting to the standard Error.message property.
 * @param {Error} error - The Error object. Expected to be either ApiStatusResult or Error object definition.
 * @returns {string} - the error message from the object.
 */
export const getApiErrorMessage = (error) => {
  // ApiStatusResult object.
  var errorMessage = error.response?.data?.statusMessage;

  // Standard Javascript Exception/Error object.
  if (errorMessage == null || typeof errorMessage === 'undefined') errorMessage = error.message;

  // Obsolete: ApiErrorResult object.
  // Left here for backward compatibility.
  if (errorMessage == null || typeof errorMessage === 'undefined') errorMessage = error.response?.data?.errorMessage;

  // default message.
  if (errorMessage == null || typeof errorMessage === 'undefined') errorMessage = 'Unknown error message.';

  return errorMessage;
};

/**
 * @description Creates an Application Insights custom properties object that has information related to web exceptions.
 * @param {Error} error - Exception caught from API calls.
 */
export const createApiErrorTelemetryProperties = (error) => {
  // returns null if not an object.
  return !error
    ? null
    : {
        Domain: Object.is(error.config?.baseURL, undefined) ? null : error.config?.baseURL,
        Uri: Object.is(error.config?.url, undefined) ? null : error.config?.url,
        'Http Status': Object.is(error.response?.status, undefined) ? null : error.response?.status,
      };
};

/**
 * @description Writes a log to the App Insights PageViews table
 * @param {string} pageName - name of page
 */
export const trackPageView = (pageName, role = null) => {
  const pageView = {
    name: pageName,
  };

  if (role !== null) {
    pageView['properties'] = {
      role: role.name,
    };
  }

  appInsightsClient.trackPageView(pageView);
};

/**
 * @description Writes to the App Insights customEvents table
 * Used to track any custom event, such as a button click
 * @param {string} eventName - name of event
 */
export const trackEvent = (eventName, role = null) => {
  const event = {
    name: eventName,
  };

  if (role !== null) {
    event['properties'] = {
      role: role.name,
    };
  }

  appInsightsClient.trackEvent(event);
};

/**
 * @description Writes a log info message to the App Insights traces table
 * @param {string} message - log message
 * @param {string} moduleName - name of module (the JS filename)
 * @param {string} functionName - name of the function, hook, or component
 */
export const logInformation = (message, moduleName, functionName) => {
  var customProperties = getDefaultCustomDimensions();
  appInsightsClient.trackTrace(
    {
      message: getStructuredLogMessage(message, moduleName, functionName),
      severityLevel: SeverityLevel.INFORMATION,
    },
    customProperties,
  );
};

/**
 * @description Writes a log error message to the App Insights traces table
 * @param {string} message - log message
 * @param {string} moduleName - name of module (the JS filename)
 * @param {string} functionName - name of the function, hook, or component
 * @param {*} customProperties - Optional. Object with string key/value pairs for custom properties to attach to the track trace. Defaults to null.
 */
export const logError = (message, moduleName, functionName, customProperties = null) => {
  customProperties = { ...customProperties, ...getDefaultCustomDimensions() };

  appInsightsClient.trackTrace(
    {
      message: getStructuredLogMessage(message, moduleName, functionName),
      severityLevel: SeverityLevel.ERROR,
    },
    customProperties,
  );
};

/**
 * @description Sets authenticated user for App Insights
 * @param {string} message - log message
 * @param {string} moduleName - name of module (the JS filename)
 * @param {string} functionName - name of the function, hook, or component
 */
export const setAuthenticatedUser = (userEmail) => {
  if (userEmail) {
    appInsightsClient.setAuthenticatedUserContext(userEmail, null, true);
  }
};
