import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationProvider } from './contexts/AuthenticationContext/AuthenticationContext';
import { AppInsightsProvider } from './contexts/AppInsightsContext/AppInsightsContext';
import { RoleAssignmentProvider } from './contexts/RoleAssignmentContext/RoleAssignmentContext';
import { UserProvider } from './contexts/UserContext/UserContext';
import { UserPreferencesProvider } from './contexts/UserPreferencesContext/UserPreferencesContext';
import { QueryClientProvider, QueryClient } from 'react-query';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext/FeatureFlagContext';
import { SplitFactoryProvider } from './contexts/SplitFactoryContext/SplitFactoryContext';
import { NotificationProvider } from './contexts/NotificationsContext/NotificationsContext';
import { ReduxDispatcherProvider } from './contexts/ReduxDispatcherProvider/ReduxDispatcherProvider';
import { ReduxStoreProvider } from './contexts/ReduxStoreContext/ReduxStoreContext';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/fonts.css';
import './styles/index.css';

// Instantiate react-query client
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticationProvider>
        <SplitFactoryProvider>
          <FeatureFlagProvider>
            <ReduxStoreProvider>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <UserPreferencesProvider>
                    <RoleAssignmentProvider>
                      <ReduxDispatcherProvider>
                        <AppInsightsProvider>
                          <NotificationProvider>
                            <App />
                          </NotificationProvider>
                        </AppInsightsProvider>
                      </ReduxDispatcherProvider>
                    </RoleAssignmentProvider>
                  </UserPreferencesProvider>
                </UserProvider>
              </QueryClientProvider>
            </ReduxStoreProvider>
          </FeatureFlagProvider>
        </SplitFactoryProvider>
      </AuthenticationProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
