import React, { useEffect, useState } from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { getFilteredFlightLegs } from '../../../lib/utils';
import { AlertFilter, DateFormat, AirlineFilter } from '../../../lib/constants';
import { useReferenceAlertsQuery } from '../../../hooks/useReferenceQuery/useReferenceAlertsQuery';
import { useFlightsQuery } from '../../../react-query/useFlightsQuery/useFlightsQuery';
import FlightCountByAlertChart from './FlightCountByAlertChart/FlightCountByAlertChart';
import dayjs from 'dayjs';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import './Analytics.css';

const Analytics = () => {
  const currentDateUtc = dayjs.utc().startOf('day').format(DateFormat.DEFAULT);
  const currentDateUtcPlusOne = dayjs.utc(currentDateUtc).add(1, 'd').format(DateFormat.DEFAULT);

  //Get full flight Data
  const {
    isLoading: isLoadingFlightData,
    data: flattenedFlightData,
    dataUpdatedAt,
  } = useFlightsQuery(currentDateUtc, currentDateUtcPlusOne);

  //Get all alert types
  const { isLoading: isLoadingAlertTypesData, data: alertTypes } = useReferenceAlertsQuery();

  //Filtered Flight data with alert notification
  const [alertFilteredFlightData, setAlertFilteredFlightData] = useState([]);

  const alertFilter = { alerts: [AlertFilter.ALL] };

  //React side-effect to handle flight data fetch call
  useEffect(() => {
    if (!isLoadingFlightData) {
      console.log(`Fetched new flight data: ${formatDateTime(dataUpdatedAt, 'YYYY-MM-DD HH:mm:ss')}`);

      const newData = getFilteredFlightLegs(flattenedFlightData.data.flattenedFlightLegs, alertFilter);
      if (JSON.stringify(alertFilteredFlightData) !== JSON.stringify(newData)) {
        console.log('Chart reloaded');
        setAlertFilteredFlightData(newData);
      }
    }
  }, [flattenedFlightData]);

  return (
    <div>
      {!isLoadingAlertTypesData && alertTypes && alertFilteredFlightData && (
        <div className="chart-container" data-cy="chart-container">
          <div className="chart-row" data-cy="chart-row">
            <div className="chart-column-md-4">
              <div className="chart-card">
                <div className="chart-card-header">Flight Count by Alert (AS)</div>
                <div className="chart-card-body">
                  <FlightCountByAlertChart
                    alertTypes={alertTypes.alertTypes}
                    operatingAirline={AirlineFilter.AS}
                    flightData={alertFilteredFlightData}
                  />
                </div>
              </div>
            </div>

            <div className="chart-column-md-4">
              <div className="chart-card">
                <div className="chart-card-header">Flight Count by Alert (QX)</div>
                <div className="chart-card-body">
                  <FlightCountByAlertChart
                    alertTypes={alertTypes.alertTypes}
                    operatingAirline={AirlineFilter.QX}
                    flightData={alertFilteredFlightData}
                  />
                </div>
              </div>
            </div>

            <div className="chart-column-md-4">
              <div className="chart-card">
                <div className="chart-card-header">Flight Count by Alert (OO)</div>
                <div className="chart-card-body">
                  <FlightCountByAlertChart
                    alertTypes={alertTypes.alertTypes}
                    operatingAirline={AirlineFilter.OO}
                    flightData={alertFilteredFlightData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAppInsightsTracking(Analytics);
