import { HideCancelledFlightsActions } from '../actionTypes';
import { SessionStorageKeys } from '../../lib/constants';

/**
 * Gets the initial state for the hide store.
 * It uses any saved filter in session storage if available, or returns the default filter.
 * Only exporting for unit tests
 * @returns the initial state for the hide store
 */
export const getInitialState = () => {
  let initialState = {
    hideCancelledFlights: true,
  };
  try {
    const savedFilter = JSON.parse(window.sessionStorage.getItem(SessionStorageKeys.HIDE_CANCELLED_FLIGHTS));
    if (savedFilter) {
      initialState = {
        ...savedFilter,
      };
    }
  } catch (error) {
    initialState = {
      hideCancelledFlights: true,
    };
  }
  return initialState;
};

const initialState = getInitialState();

/**
 * Given the previous state and action payload, returns the new state for the store
 */
const hideCancelledFlightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case HideCancelledFlightsActions.TOGGLE_HIDE_CANCELLED_FLIGHTS:
      return { hideCancelledFlights: action.payload.hideCancelledFlights };
    default:
      return state;
  }
};

export default hideCancelledFlightsReducer;
