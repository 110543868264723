import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';

const SummaryAlertTab = (summaryAlertTabDetails) => {
  return <div>Alert Details</div>;
};

SummaryAlertTab.propTypes = {
  summaryAlertTabDetails: PropTypes.object,
};

export default withAppInsightsTracking(SummaryAlertTab);
