import { DragDropAction } from '../../actionTypes';

const initialState = {
  isDragging: false,
  dragItem: {
    dragItemKey: null,
    data: null,
  },
  dropItem: {
    dropItemKey: null,
    data: null,
  },
};

/**
 * Given the previous state and action payload, returns the new state for the drag and drop store
 */
const dragDropReducer = (state = initialState, action) => {
  switch (action.type) {
    case DragDropAction.START_DRAG:
      return {
        ...state,
        isDragging: true,
        dragItem: { ...action.payload },
      };
    case DragDropAction.END_DRAG:
      return {
        ...initialState,
      };
    case DragDropAction.HOVER:
      return {
        ...state,
        isDragging: true,
        dropItem: action.payload ? { ...action.payload } : { ...initialState.dropItem },
      };
    case DragDropAction.DROP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default dragDropReducer;
