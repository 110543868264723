import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { getTransformValues } from '../../lib/htmlElementUtils/htmlElementUtils';

/**
 * Hook to make an element draggable.
 * @param {Object} props
 * @param {String} props.dragHandleElementId - The id of the element that should be used as the drag handle
 * @param {Boolean} props.canDrag - A flag to determine if the element can be dragged
 * @param {Array} props.excludedElementIds - A list of element IDs to exclude from dragging
 * @returns {React.RefObject} A ref object to be attached to the element that should be draggable
 */
export default function useDraggableViewWindow({
  dragHandleElementId,
  canDrag,
  excludedElementIds = [],
  dragEndCallback = () => {},
}) {
  const ref = useRef();

  const [dragging, setDragging] = useState(false);
  const [cursorDragOffset, setCursorDragOffset] = useState({ x: 0, y: 0 });

  /**
   * Handle the drag start event
   */
  const handleDragStart = useCallback(
    (e) => {
      const isTargetId = e.target.closest(`#${dragHandleElementId}`);

      if (!ref.current || !isTargetId || !canDrag) return;

      e.preventDefault();

      const { translateX, translateY } = getTransformValues(ref.current);

      setDragging(true);
      setCursorDragOffset({
        x: e.clientX - translateX,
        y: e.clientY - translateY,
      });
    },
    [dragHandleElementId, canDrag, excludedElementIds],
  );

  /**
   * Debounced version of the handleDrag function
   */
  const debouncedHandleDrag = useMemo(() => {
    const handleDrag = (e) => {
      if (!dragging || !ref.current) return;

      // Calculate the new position
      const newX = e.clientX - cursorDragOffset.x;
      let newY = e.clientY - cursorDragOffset.y;
      newY = newY < 0 ? 0 : newY; // prevent from being dragged beyond top

      // Set the new position
      ref.current.style.transform = `translate(${newX}px, ${newY}px)`;
    };

    return debounce(handleDrag, 5);
  }, [dragging, cursorDragOffset]);

  /**
   * Handle the dragging event
   */
  const handleDrag = useCallback(
    (e) => {
      e.preventDefault();
      debouncedHandleDrag(e);
    },
    [debouncedHandleDrag],
  );

  /**
   * Handle the drag end event
   */
  const handleDragEnd = useCallback(() => {
    dragEndCallback(ref.current);
    setDragging(false);
  }, [dragEndCallback]);

  /**
   * Side-effect to add event listeners when the component mounts and remove them when it unmounts
   */
  useEffect(() => {
    const refCopy = ref?.current;

    if (refCopy) {
      refCopy.addEventListener('mousedown', handleDragStart);
    }

    if (dragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    }

    return () => {
      if (refCopy) {
        refCopy.removeEventListener('mousedown', handleDragStart);
      }

      if (dragging) {
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
      }
    };
  }, [ref, dragging, handleDragStart, handleDrag, handleDragEnd]);

  return ref;
}
