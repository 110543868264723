import { SummaryPanelAction } from '../actionTypes';
import { SummaryPanelMode } from '../../lib/constants';

const initialSummaryData = {
  flightNumber: '-',
  irropsCode: '- -',
  origin: '-',
  destination: '-',
  timeDeparture: '-',
  timeArrival: '-',
  departureGate: '-',
  arrivalGate: '-',
  passengerCount: '-',
  dispatchDesk: '-',
  bookedRevenuePassengerCount: '-',
  aircraftSeatConfigurationCount: '-',
  ticketSalesAuthorizationCount: '-',
};
const initialState = {
  summaryData: initialSummaryData,
  detailsData: null,
  panelMode: SummaryPanelMode.MINI_SUMMARY,
  selectedPuckData: null,
  lastModified: null,
};

const summaryPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SummaryPanelAction.UPDATE_PANEL_MODE:
      return {
        ...state,
        panelMode: action.payload.panelMode,
        lastModified: new Date(),
      };
    default:
      return state;
  }
};

export default summaryPanelReducer;
