import { Provider as ReduxProvider } from 'react-redux';
import { useFeatureFlag } from '../FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';
import configureStore from '../../redux/store';

function ReduxStoreProvider({ children }) {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const store = configureStore({}, showMultipleViews);
  console.log('Initial Redux Store:\n', store.getState());
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}

export { ReduxStoreProvider };
