import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import HorizontalRule from '../../Shared/HorizontalRule/HorizontalRule';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Treatment } from '../../../lib/constants';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';

import './Modal.css';

const ModalSize = {
  RESPONSIVE: 'responsive',
  THIN: 'thin',
  WIDE: 'wide',
};

/**
 * The component used to render the body of the modal dialog.
 * @param {props} - Paper component properties
 * @returns The Paper component
 */
const DraggablePaperComponent = (props) => {
  return (
    <Draggable handle="#dialog-title-id" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

/**
 * Generic modal that can display content plus a header and footer.
 * A custom CSS class can be applied if desired
 * @param {boolean} show -
 * @param {string} title - title of the modal
 * @param {object} body -
 * @param {object[]} footerButtons - list of buttons to be displayed in the footer
 * @param {func} onHide - function to handle when modal is hidden
 * @param {string} customCSSTag - optional CSS class that should be applied to the modal
 * @param {string} size - optional flag to have modal be a fixed width, default is responsive
 * @param {boolean} showCloseButton -
 * @param {func} closeButtonOnClick - closeButton onClick event handler
 * @param {string} dataCyTag - optional data cy tag
 * @param {boolean} bottomLine -
 * @param {boolean} draggable - Set dialog moveable capability within browser window
 * @returns {Modal} the Modal component
 */
const Modal = ({
  show,
  title,
  body,
  footerButtons,
  onHide,
  customCSSTag = '',
  size = ModalSize.RESPONSIVE,
  showCloseButton = true,
  closeButtonOnClick = null,
  dataCyTag = '',
  bottomLine = true,
  draggable = true,
  bottomDivider = true,
  ...props
}) => {
  const [showWideBottomLine, setShowWideBottomLine] = useState(bottomLine);
  const { showFeature } = useFeatureFlag();
  draggable = draggable && showFeature(Treatment.DRAGGABLE_MODAL);

  useEffect(() => {
    resizeListener(); // set resize listener
    window.addEventListener('resize', resizeListener); // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener); // remove resize listener
    };
  }, []);

  const onScrollModal = (e) => {
    if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - 20) {
      setShowWideBottomLine(false);
    } else {
      setShowWideBottomLine(true);
    }
  };
  const resizeListener = () => {
    let ele = document.getElementsByClassName('dialog-container')[0];
    if (ele) {
      if (ele.clientHeight >= ele.scrollHeight) {
        //check current dialog hight is < then scroll br height
        setShowWideBottomLine(false); // set Hide Buttom Line
      } else {
        setShowWideBottomLine(true);
      }
    }
  };

  /**
   * Render moveable dialog based on draggable property settingd on Modal component
   */
  const RenderDialog = useCallback(
    (props) => {
      if (draggable) {
        return (
          <Dialog PaperComponent={DraggablePaperComponent} {...props}>
            {props.children}
          </Dialog>
        );
      }
      return <Dialog {...props}>{props.children}</Dialog>;
    },
    [draggable],
  );

  return (
    <RenderDialog
      onClose={onHide}
      data-cy={!!dataCyTag ? dataCyTag : 'dialog'}
      scrollable={true}
      className={`dialog ${size}`}
      dialogClassName={customCSSTag}
      disableBackdropClick={true}
      open={show}
    >
      <DialogTitle
        id="dialog-title-id"
        data-cy="dialog-title"
        className={`${draggable ? 'dialog-title-container draggable-dialog-title-icon' : 'dialog-title-container'}`}
        closeButton={false}
      >
        {showCloseButton && (
          <div className="modal-close-container" data-cy="modal-close-container">
            <FontAwesomeIcon
              data-cy="modal-close"
              className="modal-close"
              onClick={closeButtonOnClick === undefined || closeButtonOnClick === null ? onHide : closeButtonOnClick}
              icon={faTimes}
            />
          </div>
        )}
        {title}
        <HorizontalRule className="dialog-hr-top" />
      </DialogTitle>
      <DialogContent
        data-cy="dialog-container"
        onScroll={onScrollModal}
        scroll={'paper'}
        className={`dialog-container`}
      >
        {body}
      </DialogContent>

      <DialogActions
        className={
          `dialog-actions-container ${showWideBottomLine ? 'wide' : 'thin'}` +
          (footerButtons.length > 1 ? ' multi-buttons' : '')
        }
      >
        {bottomDivider && <HorizontalRule dataCyTag="dialog-hr-bottom" className="dialog-hr-bottom" />}
        <div className="dialog-actions-buttons">
          {footerButtons.map((button, index) => (
            <React.Fragment key={`button-${index}`}>{button}</React.Fragment>
          ))}
        </div>
      </DialogActions>
    </RenderDialog>
  );
};

//show, title, body, footerButtons, onHide, customCSSTag,...props
Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  body: PropTypes.object,
  footerButtons: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  customCSSTag: PropTypes.string,
  dataCyTag: PropTypes.string,
  size: PropTypes.oneOf(['responsive', 'thin', 'wide']),
  draggable: PropTypes.bool,
};

export default Modal;
