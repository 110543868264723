import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { useCrosshairDispatch, useCrosshairStore } from '../../../../../hooks/useCrosshairStore/useCrosshairStore';
import './CrosshairActiveButton.css';
import { CrosshairSessionStorageId } from '../../../../../lib/constants';

/**
 * The CrosshairActiveButton component is crosshair icon to active moving lines on gantt chart.
 * @returns CrossHairButton component
 */
const CrosshairActiveButton = () => {
  const { isCrosshairActive } = useCrosshairStore();
  const { updateCrosshair, updateCrosshairCount, updateActiveCrosshair } = useCrosshairDispatch();

  const activeModeHandler = useCallback(
    () => (!isCrosshairActive ? 'inactive-mode' : 'active-mode'),
    [isCrosshairActive],
  );

  // onclick handler
  const onClickCrosshairButton = () => {
    const crosshairSessionStorage = JSON.parse(sessionStorage.getItem(CrosshairSessionStorageId)) ?? {};
    if (!isCrosshairActive) {
      crosshairSessionStorage.isCrosshairActive = true;
      updateCrosshair({ isCrosshairActive: true });
    } else {
      crosshairSessionStorage.isCrosshairActive = false;
      crosshairSessionStorage.crosshairCount = 1;
      crosshairSessionStorage.activeCrosshair = 0;
      updateCrosshair({ isCrosshairActive: false });
      updateCrosshairCount({ crosshairCount: 1 });
      updateActiveCrosshair({ activeCrosshair: 0 });
    }
    sessionStorage.setItem(CrosshairSessionStorageId.CROSSHAIR, JSON.stringify(crosshairSessionStorage));
  };

  return (
    <div
      className={`button crosshair-button-container crosshair-button-${activeModeHandler()}`}
      onClick={onClickCrosshairButton}
      data-cy="crosshair-button"
    >
      <FontAwesomeIcon className="crosshair-icon crosshair-btn" icon={faCrosshairs} data-cy="crosshair-btn" />
    </div>
  );
};

export default CrosshairActiveButton;
