import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../Shared/Button/Button';
import Modal from '../Modal/Modal';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';

/**
 * The ConfirmationPopup component is confirm the cancel flight form.
 * @param {Object} props -
 *  showConfirmPopup - show/hide confirm modal
 *  handleCommitClick - callback func when confirm the cancel form.
 *  handleHideClick - callback func when close the confirm cancel modal.
 *  dataCyTag- data-cy Tag for button and confirm modal
 *  title- title of the confirm modal
 *  containerText- current Cancel flight details object.
 * @returns ConfirmationPopup component
 */

const ConfirmationPopup = ({
  showConfirmPopup,
  handleCommitClick,
  handleOnKeyPress,
  handleHideClick,
  dataCyTag,
  title,
  containerText,
  disableButton,
}) => {
  /* footer buttons for confirmation popup modal. */
  const confirmButton = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy={`${dataCyTag}-commit-button`}
      onClick={handleCommitClick}
      onKeyPress={handleOnKeyPress}
      isDisabled={disableButton}
    >
      {disableButton ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  /* build modal body for confirmation popup modal. */
  const popupHTML = (
    <Fragment>
      <div className="confirmation-popup-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>{containerText}</div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  return (
    <Modal
      show={showConfirmPopup}
      title={title}
      body={popupHTML}
      footerButtons={confirmButton}
      onHide={handleHideClick}
      customCSSTag="confirmation-popup"
      dataCyTag={`${dataCyTag}-confirm-modal`}
      size="thin"
      bottomLine={false}
    />
  );
};

export default withAppInsightsTracking(ConfirmationPopup);
