import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import AddFlightButton from '../AddFlight/AddFlightButton/AddFlightButton';
import AddFlightModal from '../AddFlight/AddFlightModal/AddFlightModalV3';
import { ToastType } from '../Shared/NotificationToast/NotificationToast';
import { useRefetchPageData } from '../../hooks/useRefetchQuery/useRefetchQuery';
import { TimeZonePreference, RefetchPageData } from '../../lib/constants';
import { useNotificationUpdate } from '../../contexts/NotificationsContext/NotificationsContext';
import { refetchDataRule } from '../../lib/utils';
import { useSummaryPanelStore } from '../../hooks/useSummaryDataStore/useSummaryDataStore';

/**
 * The AddFlight component is handling opening add flight modal and closing modal and also responsible for showing toast messages.
 * @param {Object} props -
 *  isShowAddBtn - flag to show hide add flight icon.
 * @returns AddFlight component
 */
const AddFlight = ({ isShowAddBtn = false }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { refetchData, refetchDataFor } = useRefetchPageData();
  //  useNotificationUpdate hook
  const notificationUpdate = useNotificationUpdate();
  //SummaryPanelStore redux store
  const { panelMode } = useSummaryPanelStore();

  /**
   * @description handling closing add flight modal.
   */
  const handleModalCancel = () => {
    setIsShowModal(false);
  };

  /**
   * @description Save the Add Flight modal data upon clicking on commit button.
   * @param {Object} result - The response from the IrropsQueuePublisher API.
   * @param {Object} actionText - The string text from Irrops Modal for eg Add, Cancel etc for specific modal.
   * @param {Object} flightNumber - The Flight Number which user entered in Add Flight Modal.
   * @return
   */
  const handleModalSave = async (result, actionText, flightNumber, checkFlightExistsArr = []) => {
    await handleModalSaveV2(result, actionText, flightNumber, checkFlightExistsArr);
  };

  /**
   * @description Save the Add Flight modal data upon clicking on commit button.
   * @param {Object} result - The response from the IrropsQueuePublisher API.
   * @param {Object} actionText - The string text from Irrops Modal for eg Add, Cancel etc for specific modal.
   * @param {Object} flightNumber - The Flight Number which user entered in Add Flight Modal.
   * @param {*} checkFlightExistsArr - list of all promises of checkFlightExists head requests.
   * @return
   */
  const handleModalSaveV2 = async (result, actionText, flightNumber, checkFlightExistsArr) => {
    setIsShowModal(false);
    // GOT SAVE SIGNAL FROM THE MODAL (actionText = 'Cancel | Reinstate ...')

    let headCheckFlightsCallsSucceded = true;

    if (result == true) {
      const toastMessageText = `${actionText} was submitted for Flight ${flightNumber}. Checking Status...`;
      notificationUpdate(ToastType.SUCCESS, toastMessageText, true, false); // SHOW TOAST NOTIFICATION

      // Waiting for all the Head CheckFlightExiss calls to be finished.
      // these head calls will return status 200 if irrop is successful and requested flight exists in db
      const headCallsArr = await Promise.all(checkFlightExistsArr);

      // Loop through the head calls Array and set success flag to false if any call from array fails.
      for (let i = 0; i < headCallsArr.length; i++) {
        if (headCallsArr[i] == null || headCallsArr[i]?.status !== 200) {
          headCheckFlightsCallsSucceded = false;
        }
      }

      if (headCheckFlightsCallsSucceded) {
        // If irrop calls are success, then refresh the gantt chart
        notificationUpdate(ToastType.SUCCESS, '', false, true); // SHOW TOAST NOTIFICATION

        refetchData(RefetchPageData.GANTT, false);
        refetchData(RefetchPageData.FLIGHT_LIST_TABLE, false);

        // Refreshing for ShortTurn data after 5 seconds.
        setTimeout(() => {
          refetchData(RefetchPageData.GANTT, true);
          refetchData(RefetchPageData.FLIGHT_DETAIL_PANE_ACTIVITY_LOG, true);

          // Refetch some queries based on fetching rules for the page.
          refetchDataFor(refetchDataRule(false, panelMode));
        }, 5000);

        return;
      }
    }

    const toastMessageText = `Failed to submit ${actionText.toLowerCase()} for Flight ${flightNumber} Please try again`;
    notificationUpdate(ToastType.ERROR, toastMessageText, true, false); // SHOW TOAST NOTIFICATION
  };

  /**
   * @description Save the Add Flight modal data upon clicking on commit button.
   * @param {Object} result - The response from the IrropsQueuePublisher API.
   * @param {Object} actionText - The string text from Irrops Modal for eg Add, Cancel etc for specific modal.
   * @param {Object} flightNumber - The Flight Number which user entered in Add Flight Modal.
   * @return
   */

  /**
   * @description handling showing add flight modal.
   */

  const handleAddButtonClick = () => {
    setIsShowModal(true);
  };

  /**
   * @description opening the add flight modal when click on icon.
   * @return AddFlightModal component.
   */

  const openModal = () => {
    return (
      <AddFlightModal
        onClose={handleModalCancel}
        onCommit={handleModalSave}
        showModal={isShowModal}
        tzPreference={TimeZonePreference.STATION_LOCAL}
      />
    );
  };

  return (
    <div className="add-flight">
      {isShowAddBtn ? <AddFlightButton onClickAddButton={handleAddButtonClick}></AddFlightButton> : null}
      {isShowModal ? openModal() : null}
    </div>
  );
};

AddFlight.propTypes = {
  isShowAddBtn: PropTypes.bool,
};

export default withAppInsightsTracking(AddFlight);
