import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '../../Shared/Modal/Modal';
import Button from '../../Shared/Button/Button';
import './ConfirmationConfigModal.css';
import { ReactComponent as AlertExclamationIcon } from '../../../assets/images/block-square-exclamation-icon.svg';

/**
 * ConfirmationConfigModal component is used to display the modal for the scalability feature.
 * @param {funcation}  onClose - Function to close the modal.
 * @param {boolean}  showModal - Boolean value to show/hide the modal
 * @returns  Modal component with the body and footer buttons.
 */
const ConfirmationConfigModal = ({
  title,
  confirmationText,
  secondLineText = '',
  onYes,
  onNo,
  onModalClose,
  yesButtonText = 'Yes',
  noButtonText = 'No',
  showModal = false,
}) => {
  // Body of the modal with the counter components for hoursBefore, hoursAfter and numberOfAircrafts values
  const body = (
    <div className="confirmation-config-modal-row" data-cy="confirmation-config-modal">
      <Grid container item xs={12} className="confirmation-config-grid-container">
        <Grid item xs={12}>
          <div className="confirmation-config-message">
            <div className="alert-icon">
              <AlertExclamationIcon data-cy="alert-exclamationmark-icon" className="alert-exclamationmark-icon" />
            </div>
            <div className="confirmation-config-text">
              <span>{confirmationText}</span>
              <br />
              <span>{secondLineText}</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  // Footer buttons for the modal component with Apply and cancel buttons
  const formButtons = [
    <Button
      key="cancel"
      variant="secondary"
      className="confirmation-config-footer-button cancel"
      tabIndex="0"
      onClick={onYes}
      data-cy="nav-confirmation-config-yes-btn"
    >
      {yesButtonText}
    </Button>,
    <Button
      key="save"
      variant="primary"
      className="confirmation-config-footer-button save"
      tabIndex="0"
      isDisabled={false}
      onClick={onNo}
      autofocus={true}
      data-cy="nav-confirmation-config-no-btn"
    >
      {noButtonText}
    </Button>,
  ];

  // Modal component with the body and footer buttons for the scalability feature modal
  return (
    <Modal
      focusId="focusId"
      title={title}
      body={body}
      show={showModal}
      size="confirmation-config-dialog"
      onHide={onModalClose}
      customCSSTag="confirmation-config-dialog"
      dataCyTag="confirmation-config-dialog"
      footerButtons={formButtons}
      bottomLine={false}
      bottomDivider={false}
    />
  );
};

export default ConfirmationConfigModal;
