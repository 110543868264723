import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';

const SummaryPassengerTab = (summaryPassengerTabDetails) => {
  return <div>Passenger Details</div>;
};

SummaryPassengerTab.propTypes = {
  summaryPassengerTabDetails: PropTypes.object,
};

export default withAppInsightsTracking(SummaryPassengerTab);
