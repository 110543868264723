import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import './AlertTab.css';
import orderBy from 'lodash/orderBy';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { getMonthDayDisplayString, getUtcTimeDisplayString } from '../../../../lib/displayUtils';
import TabSkeleton from '../TabSkeleton/TabSkeleton';

const Alert = ({ flightDetails, loading }) => {
  /**
   * @description Filter a flight leg's notifications to be a unique set of alert types. User Story:
   * @param {object} flightLeg - The flight leg object returned from the AOC Flights API
   */
  const filterFlightLegNotifications = (flightLeg) => {
    // Returns empty array if not valid.
    if (
      flightLeg?.flightLegNotifications == null ||
      typeof flightLeg?.flightLegNotifications === 'undefined' ||
      (flightLeg?.flightLegNotifications?.length ?? 0) === 0
    ) {
      return [];
    }

    // Sort by date ascending, we want to display the earliest alert of each type
    const currentNotifications = orderBy(
      flightLeg.flightLegNotifications,
      [(object) => new dayjs(object.notificationCreatedTime)],
      ['asc'],
    );

    var uniqueNotifications = [];

    // Get array of unique notifications of sorted by creation datetime (asc)
    currentNotifications.forEach((cn) => {
      if (!uniqueNotifications.find((un) => un.name === cn.notificationName))
        uniqueNotifications.push({
          title: cn.notificationTitle,
          name: cn.notificationName,
          message: cn.notificationMessage,
          createdTime: cn.notificationCreatedTime,
          closedTime: cn.notificationClosedTime,
        });
    });

    const notificationsByCreateTimeDesc = orderBy(
      flightLeg.flightLegNotifications,
      [(n) => new dayjs(n.notificationCreatedTime)],
      ['desc'],
    );

    for (let index = 0; index < uniqueNotifications.length; index++) {
      // Get the last notification inserted (by name), and use its closed time.
      const foundElement = notificationsByCreateTimeDesc.find(
        (n) => n.notificationName === uniqueNotifications[index].name,
      );
      if (foundElement != null) {
        uniqueNotifications[index].closedTime = foundElement.notificationClosedTime;
      }
    }

    const formattedNotifications = uniqueNotifications.map((n) => {
      return (
        <tr key={n.name}>
          <td>{`${getMonthDayDisplayString(n.createdTime)} ${getUtcTimeDisplayString(n.createdTime)}`}</td>
          <td>{n.title}</td>
          <td>
            {n.closedTime === null
              ? 'Active'
              : `${getMonthDayDisplayString(n.closedTime)} ${getUtcTimeDisplayString(n.closedTime)}`}
          </td>
        </tr>
      );
    });

    return [formattedNotifications];
  };

  return loading ? (
    <TabSkeleton />
  ) : (
    <div>
      <table className="alert-table">
        <thead>
          <tr className="alert-header">
            <td>Created Time</td>
            <td>Title</td>
            <td>Closed Time</td>
          </tr>
        </thead>
        <tbody>{filterFlightLegNotifications(flightDetails)}</tbody>
      </table>
    </div>
  );
};

Alert.propTypes = {
  flightDetails: PropTypes.object,
  loading: PropTypes.bool,
};

export default withAppInsightsTracking(Alert);
