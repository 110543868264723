import PropTypes from 'prop-types';
import ViewWindow from './ViewWindow/ViewWindow';
import OperationsView from '../../OperationsView/OperationsView';
import './MultipleViewsContainer.css';
import { Treatment, ViewWindowType } from '../../../../lib/constants';
import { useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import {
  useWindowManagerStore,
  useWindowManagerDispatch,
} from '../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { ViewIdProvider } from '../../../../contexts/ViewIdContext/ViewIdContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';

function MultipleViewsContainer({ currentActiveId, handleSortClick, sort }) {
  const { isSwapModeActive } = useSwapModeStore();
  const windowManagerStore = useWindowManagerStore();
  const { viewWindows, viewOrder, draggedMinimizedViews, maximizedViews, minimizedViews } = windowManagerStore;
  const { setActiveView, removeViewWindow, removeMinimizedView } = useWindowManagerDispatch();
  const { showFeature } = useFeatureFlag();
  const isTailIndicatorsEnabled = showFeature(Treatment.TAIL_NUMBER_INDICATORS);

  if (!viewWindows) {
    return null;
  }

  let viewType = ViewWindowType.SINGLE;
  if (isSwapModeActive) {
    viewType = ViewWindowType.SWAP_MODE;
  } else if (viewWindows.length > 1) {
    viewType = ViewWindowType.MULTI;
  }

  const handleFocusToWindow = (id) => {
    setActiveView(id);
  };

  const handleCloseWindow = (id) => {
    removeViewWindow(id);
  };

  const windows = () =>
    viewWindows.map((view) => {
      const { id, viewType: viewMode, viewTitle, ...viewData } = view;
      if (viewType === ViewWindowType.SINGLE && minimizedViews.includes(id)) {
        removeMinimizedView(id);
      }
      const isMinimizedNotDragged = minimizedViews.includes(id);
      const isMinimizedDragged = draggedMinimizedViews.includes(id);
      const isMinimized = isMinimizedNotDragged || isMinimizedDragged;
      return (
        <ViewIdProvider key={`${id}-${viewType}`} id={id}>
          <ViewWindow
            id={id}
            variant={viewType}
            onFocusToWindow={() => handleFocusToWindow(id)}
            onClose={() => handleCloseWindow(id)}
            windowPosition={viewOrder.indexOf(id)}
            isFocused={id === viewOrder[0]}
            isMinimized={isMinimized}
            isMaximized={maximizedViews.includes(id)}
            minimizedIndex={isMinimizedDragged ? null : minimizedViews.indexOf(id)}
            isSwapModeActive={isSwapModeActive}
            currentActiveId={currentActiveId}
            title={viewTitle}
            isTailIndicatorsEnabled={isTailIndicatorsEnabled}
            {...viewData}
          >
            {!isMinimized ? (
              <OperationsView
                isSwapModeActive={false}
                viewMode={viewMode}
                handleSortClick={handleSortClick}
                sort={sort ? sort[id] : null}
              />
            ) : (
              <></>
            )}
          </ViewWindow>
        </ViewIdProvider>
      );
    });

  const swapModeWindow = () => (
    <ViewIdProvider key={`${currentActiveId}-${viewType}`} id={currentActiveId}>
      <ViewWindow
        variant={ViewWindowType.SWAP_MODE}
        onFocusToWindow={() => handleFocusToWindow(currentActiveId)}
        onClose={() => handleCloseWindow(currentActiveId)}
        windowPosition={viewWindows.find((view) => view.id === currentActiveId).windowPosition}
        isFocused={true}
        isTailIndicatorsEnabled={isTailIndicatorsEnabled}
      >
        <OperationsView
          isSwapModeActive={true}
          viewMode={null}
          handleSortClick={handleSortClick}
          sort={sort ? sort[currentActiveId] : null}
        />
      </ViewWindow>
    </ViewIdProvider>
  );

  return (
    <div data-cy="multiple-views-container" className="multiple-views-container">
      {isSwapModeActive ? swapModeWindow() : windows()}
    </div>
  );
}

MultipleViewsContainer.propTypes = {
  handleSortClick: PropTypes.func,
  sort: PropTypes.object,
  currentActiveId: PropTypes.number,
};

export default MultipleViewsContainer;
