import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useAirportsContext } from '../../../contexts/AirportsContext/AirportsContext';
import { formatUtcOffset, utcToStationLocalTime } from '../../../lib/dateTimeUtils';

/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * @description Display UTC offset for a station given utc time
 * @param {string} utcDateTime - The ISO 8601 string representing a UTC time.
 * @param {string} dataCyTag - Value for data-cy tag for integration tests.
 * @param {string} station - Optional: The station code associated with this time.  Will be used for performing UTC to Station Local conversions.
 * @returns HTML display of zulu offset, i.e. -8 hrs,
 */
export const ZuluOffsetDisplay = ({ utcDateTime, dataCyTag, station = null }) => {
  // Default display value
  const defaultDisplay = <>&#8212;</>;
  const [zuluOffset, setZuluOffset] = useState(null);
  const { isLoading, getAirportDetail } = useAirportsContext();

  /**
   * Side-effect if the isLoading (context state), getAirportDetail (context func),
   * utcDateTime (prop), or station (prop) change.
   */
  useEffect(() => {
    if (isLoading) return;

    const airportObject = getAirportDetail(station);

    if (airportObject != null) {
      const stationLocalTime = utcToStationLocalTime(utcDateTime, airportObject.airportTimeZone);
      const offSet = formatUtcOffset(utcDateTime, stationLocalTime);
      const formattedOffset = parseInt(offSet);

      setZuluOffset(formattedOffset);
    }
  }, [isLoading, getAirportDetail, utcDateTime, station]);

  /**
   * Side-effect if the station time zone (state), station (prop), or utcDateTime (prop) change.
   */
  useEffect(() => {
    if (station == null || utcDateTime == null || typeof utcDateTime === 'undefined') return;
  }, [station, utcDateTime]);

  return (
    <>
      <span data-cy={dataCyTag}>{zuluOffset ? zuluOffset : defaultDisplay}</span>
    </>
  );
};

ZuluOffsetDisplay.propTypes = {
  utcDateTime: PropTypes.string.isRequired,
  dataCyTag: PropTypes.string.isRequired,
  station: PropTypes.string,
};

// Memoizing the component to prevent re-renders.
export default React.memo(ZuluOffsetDisplay, (prevProps, nextProps) => {
  // Are the props equal between the previous and next?
  return (
    prevProps.utcDateTime === nextProps.utcDateTime &&
    prevProps.station === nextProps.station &&
    prevProps.dataCyTag === nextProps.dataCyTag
  );
});
