import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthentication } from '../AuthenticationContext/AuthenticationContext';
import { useAuthorizationQuery } from '../../hooks/useAuthorizationQuery/useAuthorizationQuery';
import { useRoleAssignmentsQuery } from '../../hooks/useAssignmentsQuery/useAssignmentsQuery';

const UserContext = createContext(null);

/**
 * The UserProvider component provides user information state including their
 * authorization and available roles and assignments
 @param {Object} props -
 *  children - children components to render
 */
const UserProvider = ({ children }) => {
  // state
  const [roleIdsWithAssignmentRequired, setRoleIdsWithAssignmentRequired] = useState([]);
  const [state, setState] = useState({
    userName: '',
    userKey: null,
    employeeID: '',
    authorizedRoles: [],
    authorizedAssignments: [],
    loading: true,
    error: false,
  });

  // hooks
  const { userData: openIdProfile } = useAuthentication();
  const {
    isLoading: authorizationIsLoading,
    data: authorizationData,
    error: authorizationError,
  } = useAuthorizationQuery(openIdProfile.profile.company);
  const {
    isLoading: assignmentsIsLoading,
    data: assignmentData,
    error: assignmentError,
  } = useRoleAssignmentsQuery(roleIdsWithAssignmentRequired);

  useEffect(() => {
    const newUserState = {};

    // Set state for Auth API data
    if (!authorizationIsLoading && authorizationData?.data) {
      const { userName, userKey, employeeID, authorizedRoles } = authorizationData.data;
      const roleIds = authorizationData.data.authorizedRoles.filter((r) => r.assignmentRequired).map((r) => r.roleID);

      newUserState.userName = userName;
      newUserState.userKey = userKey;
      newUserState.employeeID = employeeID;
      newUserState.authorizedRoles = [...authorizedRoles];

      if (roleIds.length > 0) {
        // Set the role ids state, which will trigger call to Assignments API
        setRoleIdsWithAssignmentRequired(roleIds);
      }
    } else if (authorizationError) {
      let isUnauthorizedResponse = authorizationError.response?.status === 401;
      if (isUnauthorizedResponse) {
        // Auth API 401 needs to be handled specifically by setting employee ID to ''
        newUserState.employeeID = '';
      } else {
        newUserState.error = true;
      }
    }

    // Set state for Assignments API data
    if (!assignmentsIsLoading && assignmentData?.data?.length > 0) {
      newUserState.authorizedAssignments = [...assignmentData.data];
    } else if (assignmentError) {
      newUserState.error = true;
    }

    // Set loading state
    newUserState.loading = authorizationIsLoading || assignmentsIsLoading;

    setState((previousState) => {
      return { ...previousState, ...newUserState };
    });
  }, [
    authorizationIsLoading,
    authorizationData?.data,
    authorizationError,
    assignmentsIsLoading,
    assignmentData?.data,
    assignmentError,
  ]);

  return <UserContext.Provider value={{ state }}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      'useUserContext must be used within a UserProvider. Wrap a parent component in <UserProvider> to fix this error.',
    );
  }
  return context;
};

export { UserProvider, useUserContext };
