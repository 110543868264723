import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import './FlightInfoTable.css';

const FlightInfoTable = ({ flightInfo, trip, date, columnNames = [] }) => {
  const displayHeader = flightInfo?.length > 0;
  return (
    <div style={{ padding: '1rem' }} className="flightInfo-table">
      <div className="trip-info">
        <span style={{ width: '3.5rem', fontWeight: 700 }}>TRIP {trip}</span> |{' '}
        <span style={{ width: '1rem' }}>{date}</span>
      </div>
      <Table sx={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
            {columnNames.map((columnName, index) => (
              <TableCell key={index} align="left" style={{ height: '3.5rem' }}>
                {displayHeader && columnName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(flightInfo).map((info, index) => (
            <TableRow key={`${flightInfo?.aircraft}-${info.flight}`} data-cy={`${flightInfo?.aircraft}-${index}`}>
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {info.aircraft}
              </TableCell>
              <TableCell align="left" break autoWidth style={{ verticalAlign: 'top' }}>
                {info.flight}
              </TableCell>
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {info.origin}
              </TableCell>
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {info.depart}
              </TableCell>
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {info.destin}
              </TableCell>
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {info.arrival}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

FlightInfoTable.propTypes = {
  trip: PropTypes.object.isRequired,
  flightInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string),
};

export default FlightInfoTable;
