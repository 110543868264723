import { useMemo } from 'react';
import { useFlightsQuery } from '../../react-query/useFlightsQuery/useFlightsQuery';
import { useGroundEventsQuery } from '../useGroundEventsQuery/useGroundEventsQuery';
import { getUniqueAircraftList } from '../../lib/utils';
import { isNullOrWhitespace } from '../../lib/displayUtils';

/**
 * @description -  Custom hook for getting unique aircraft list for each fleettype filtered by airline
 * @param {date} startDate - start date for the query
 * @param {date} endDate - end date for the query
 * @param {string} operatingAirline - (optional) operating airline code
 * @returns {Array} - an array of unique aircrafts for each fleettype
 */
export const useAircraftBySubfleetTypeQuery = (startDate, endDate, operatingAirline = '') => {
  //Get all sorted ground events info
  const { isLoading: isLoadingGroundEvents, data: groundEventsData } = useGroundEventsQuery(startDate, endDate);

  //Get all sorted flight info
  const { isLoading: isLoadingFlights, data: flightsData } = useFlightsQuery(startDate, endDate);

  return useMemo(() => {
    const sortedFlightLeg =
      !isLoadingFlights && flightsData?.data?.flattenedFlightLegs?.length > 0
        ? flightsData.data.flattenedFlightLegs
        : [];

    const sortedgroundEvents =
      !isLoadingGroundEvents && Object.keys(groundEventsData?.data || {}).length > 0 ? groundEventsData.data : {};

    //List of unique aircrafts along with fleet type and airline information
    const aircraftLists = isNullOrWhitespace(operatingAirline)
      ? getUniqueAircraftList(sortedFlightLeg, sortedgroundEvents)
      : getUniqueAircraftList(sortedFlightLeg, sortedgroundEvents).filter((item) => item.airline === operatingAirline);

    //Return an array of unique aircraft by tail number
    const filtredItems = [...new Map(aircraftLists.map((m) => [m.fleetType, m])).values()];
    let uniqueSubFleet = {};

    for (let i = 0; i < filtredItems.length; i++) {
      if (filtredItems[i].fleetType !== null && aircraftLists.length) {
        let fleetList = aircraftLists.filter((e) => e.fleetType == filtredItems[i].fleetType);
        if (fleetList && fleetList.length > 0) {
          uniqueSubFleet[filtredItems[i].fleetType] = fleetList?.map((a) => a.aircraft);
        }
      }
    }

    return uniqueSubFleet; //{"737-800": [ "N001QE", "N002QE"]}
  }, [flightsData, groundEventsData, isLoadingGroundEvents, isLoadingFlights, operatingAirline]);
};
