import React from 'react';
import PropTypes from 'prop-types';
import EarlyDelayDisplay from '../../../../../Shared/EarlyDelayDisplay/EarlyDelayDisplay';
import { withAppInsightsTracking } from '../../../../../../services/appInsightsFactory/appInsightsFactory';

import '../../../../FlightDetailPane/FlightDetailPane.css';
import '../../../../FlightDetailPane/FlightTab/FlightTab.css';

/**
 * Displays the block time of the given flight
 * @param {string} startTime - start date time of block
 * @param {string} endTime - end date time of block
 * @param {string} scheduledStartTime - start time of time to compare
 * @param {string} scheduledEndTime - end time of time to compare
 * @param {bool} showBlockInMinutes - indicator to display block in minutes
 * @returns FlightTabBlockTime - formatted block time in hours and minutes
 */
const FlightTabBlockTimes = ({ totalMinutes, delayMinutes, showBlockInMinutes = false }) => {
  const formatDuration = (duration) => {
    if (duration == null || typeof duration === 'undefined') return null;

    const hours = Math.floor(Math.abs(duration / 60));
    const minutes = Math.floor(Math.abs(duration % 60));
    return duration < 60 ? `${duration} mins` : `${hours} hrs ${minutes} mins`;
  };
  return (
    <div>
      <div className="basic-cell-left" data-cy="block-time-hours-minutes">
        {formatDuration(totalMinutes)}
      </div>
      {showBlockInMinutes && (
        <div className="basic-cell" data-cy="block-time-minutes-only">
          ({totalMinutes} mins)
        </div>
      )}
      <div className="basic-cell" data-cy="block-time-early-delay-indicator">
        <EarlyDelayDisplay difference={delayMinutes ?? 0} label="mins" />
      </div>
    </div>
  );
};

FlightTabBlockTimes.propTypes = {
  totalMinutes: PropTypes.string,
  delayMinutes: PropTypes.string,
  showBlockInMinutes: PropTypes.bool,
};

export default withAppInsightsTracking(FlightTabBlockTimes);
