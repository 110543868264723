import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'airports';
const moduleName = 'airportsApi';

/**
 * Returns the airports list from the Airports API
 * @returns the axios response
 */
export const getAirports = async () => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/airport`;
  return apiClient.get(url);
};

/**
 * Returns the airports list from the Airports API
 * @param {Array} airportCodes - an array of airport Code
 * @param {Array} airportCharacteristicNames - an array of airport Characteristic Names
 * @param {*} includeCharacteristics - boolean - true to include Characteristics
 * @returns airport list
 */
export const getAirportsV2 = async (airportCodes, airportCharacteristicNames, includeCharacteristics = false) => {
  const apiClient = axiosInstance;
  const airportCodeQueryParams = airportCodes.map((airportCode) => `airportCodes=${airportCode}`).join('&');
  const airportCharacteristicNameQueryParams = airportCharacteristicNames
    .map((airportCharacteristicName) => `airportCharacteristicNames=${airportCharacteristicName}`)
    .join('&');

  let result = null;

  try {
    const url = `${API_ROUTE}/api/v2/airport?${airportCodeQueryParams}&includeCharacteristics=${includeCharacteristics}&${airportCharacteristicNameQueryParams}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getAirportsV2', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
