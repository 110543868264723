import React from 'react';
import PropTypes from 'prop-types';
import SummaryActivityLogDetail from '../SummaryActivityLogDetail/SummaryActivityLogDetail';
import './SummaryActivityLogGroup.css';
import { formatDateTime, getTimeDifference } from '../../../../../../lib/dateTimeUtils';

/**
 * ActivityLogGroup renders the activity log group and the date (for the activity log group).
 * @param {string} activityDate - date of the activity log group
 * @param {object} activityLogDetails - activity log group mapped to activityDate. Includes the note, time and tag of activity log
 * @returns
 */
const SummaryActivityLogGroup = ({ activityDate, activityLogDetails }) => {
  const activityDateStr = formatDateTime(activityDate, 'MMM DD YYYY');
  return (
    <>
      <div className="summary-activity-log-tab-container" data-cy="summary-activity-log-tab-container">
        {activityLogDetails
          .sort((a, b) => {
            return getTimeDifference(a.activityDateTime, b.activityDateTime, 'second');
          }) //Sort by time
          .map((activityDetail) => {
            return (
              <SummaryActivityLogDetail
                activityDate={activityDateStr}
                activityDateTime={activityDetail.activityDateTime}
                activityNote={activityDetail.activityNote}
                activityTag={activityDetail.activityTag}
              />
            );
          })}
      </div>
    </>
  );
};

SummaryActivityLogGroup.propTypes = {
  activityDate: PropTypes.string,
  activityLogDetails: PropTypes.object,
};

export default SummaryActivityLogGroup;
