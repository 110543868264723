import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';

import './DynamicCheckbox.css';

/**
 * Displays as a checkbox or radio button based on type.
 * Type can be either 'checkbox' or 'radio'
 * @param {boolean} checked - true if DynamicCheckbox is checked
 * @param {string} label - label associated with the DynamicCheckbox
 * @param {func} onChange - function to handle when the DynamicCheckbox is clicked
 * @param {string} type - type of input ('checkbox' or 'radio' for now)
 * @param {string} customCSSTag - custom class name
 * @param {bool} isDisabled - disables dynamic checkbox from toggling when true
 * @returns {DynamicCheckbox} the DynamicCheckbox component
 */
const DynamicCheckbox = ({
  checked,
  label,
  onChange = () => {},
  type,
  customCSSTag,
  isDisabled,
  dataCyTag = '',
  ...props
}) => {
  const handleClick = () => {
    if (!isDisabled) onChange(label);
  };

  let icon = '';
  const iconClassNames = ['checkbox-icon'];
  checked ? iconClassNames.push('checked') : iconClassNames.push('unchecked');
  if (type === 'checkbox') {
    icon = checked ? faCheckSquare : faSquare;
    iconClassNames.push('checkbox-type');
  } else if (type === 'radio') {
    icon = checked ? faDotCircle : faCircle;
    iconClassNames.push('radio-type');
  }

  return (
    <div
      onClick={handleClick}
      data-cy={dataCyTag}
      className={`checkbox-container${!!customCSSTag ? ` ${customCSSTag}` : ''} ${isDisabled ? 'enable' : ''}`}
      {...props}
    >
      <FontAwesomeIcon data-cy="dynamic-checkbox-icon" icon={icon} className={iconClassNames.join(' ')} />
      <div className="label">{label}</div>
    </div>
  );
};

DynamicCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  customCSSTag: PropTypes.string,
  isDisabled: PropTypes.bool,
  dataCyTag: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
};

export default DynamicCheckbox;
