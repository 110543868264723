import { isNullOrWhitespace } from '../../lib/displayUtils';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { useGanttConfigQuery } from '../useAirTrakManagerQuery/useAirTrakManagerQuery';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';

/**
 * @description - Custom hook for getting unique aircraft list filtered by airline and gantt config from AirTrakManager if feature flag is on
 * @param {date} startDate - start date for the query
 * @param {date} endDate - end date for the query
 * @param {string} operatingAirline - operating airline code
 * @returns {Array} - an array of unique aircrafts
 */
export const useAircraftList = (startDate, endDate, operatingAirline = '') => {
  const airtrakManager = useAirtrakManager(startDate, endDate);

  // Get gantt config from AirTrakManager
  const aircraftList = JSON.parse(JSON.stringify(airtrakManager?.aircraftList || {}));

  if (Object.keys(aircraftList).length === 0) {
    return [];
  }

  return isNullOrWhitespace(operatingAirline)
    ? Object.keys(aircraftList)
    : Object.keys(aircraftList).filter((key) => aircraftList[key].airline === operatingAirline);
};

/**
 * @description - Returns gantt config from AirTrakManager API
 * @param {date} startDate - start date for the query
 * @param {date} endDate - end date for the query
 * @returns {Array} - an array of unique aircrafts
 */
const useAirtrakManager = (startDate, endDate) => {
  // TODO this function is duplicated with one within useGanttConfig.
  // This should be removed once we convert the AirtrakManager API call to a GET request.

  const { roleAssignments } = useRoleAssignmentContext();
  const userContext = useUserContext();

  const payload = {
    employeeID: userContext.state.employeeID,
    startDateUTC: startDate.isValid() ? startDate.toISOString() : startDate,
    endDateUTC: endDate.isValid() ? endDate.toISOString() : endDate,
    roleKey: roleAssignments.role.roleID,
    operatingAirline: roleAssignments.role.operatingAirline,
  };

  const { isLoading, isSuccess, data } = useGanttConfigQuery(payload, true);

  if (isLoading) {
    return {
      loading: true,
      aircraftList: {},
      errors: {
        flights: false,
        groundEvents: false,
      },
    };
  } else if (isSuccess && data) {
    const { lineOfFlights, errors } = data;
    return {
      loading: false,
      aircraftList: lineOfFlights,
      errors: {
        flights: errors.some((e) => e.errorName === 'FlightLegData'),
        groundEvents: errors.some((e) => e.errorName === 'GroundEventsData'),
      },
    };
  } else {
    return {
      loading: false,
      ganttConfig: {},
      errors: {
        flights: true,
        groundEvents: true,
      },
    };
  }
};
