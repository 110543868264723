import { axiosInstance } from '../apiClient';
import {
  getApiErrorMessage,
  logError,
  logInformation,
  createApiErrorTelemetryProperties,
} from '../../../lib/appInsightsUtils';

const API_ROUTE = 'flighttimesqueuepublisher';
const moduleName = 'flighttimesqueuepublisherApi';

/**
 * Submits a flighttimesqueuepublisher API POST request.
 * @param {Object} flighttimesqueuepublisherPayload - The payload to be sent in the request.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 */
export const flighttimesqueuepublisherApi = async (flighttimesqueuepublisherPayload) => {
  const apiClient = axiosInstance;
  let result = null;

  const formattedLogPayload = {
    flightNumber: flighttimesqueuepublisherPayload.flightNumber,
    actualOrigin: flighttimesqueuepublisherPayload.actualOrigin,
    actualDestination: flighttimesqueuepublisherPayload.actualDestination,
    scheduledOrigin: flighttimesqueuepublisherPayload.scheduledOrigin,
    scheduledOutUTC: flighttimesqueuepublisherPayload.scheduledOutUTC,
    scheduledInUTC: flighttimesqueuepublisherPayload.scheduledInUTC,
    estimatedOutUTC: flighttimesqueuepublisherPayload.estimatedOutUTC,
    estimatedInUTC: flighttimesqueuepublisherPayload.estimatedInUTC,
    actualOnUTC: flighttimesqueuepublisherPayload.actualOnUTC,
    actualInUTC: flighttimesqueuepublisherPayload.actualInUTC,
  };

  try {
    logInformation(
      `Submitting flighttimesqueuepublisher API POST request for payload: \n${formattedLogPayload}`,
      moduleName,
      'flighttimesqueuepublisher',
    );
    const url = `${API_ROUTE}/api/v1/FlightTimes/submit`;
    const response = await apiClient.post(url, flighttimesqueuepublisherPayload);
    result = response.data;
  } catch (error) {
    logError(
      getApiErrorMessage(error),
      moduleName,
      'flighttimesqueuepublisher',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }

  return result;
};
