import orderBy from 'lodash/orderBy';
import { TableListActions as ACTIONS } from '../actionTypes';

/**
 * @description Applies a sorting order to an array of objects.  returns as-is if column and direction parameters are not specified.
 * @param {Object[]} data - An array of objects to be sorted.
 * @param {string} column - The column key to sort by.
 * @param {string} direction - The sorting direction.
 */
const applySort = (data, column, direction) => {
  if (column === '' || column === null || direction === '' || direction === null) {
    return data;
  }

  // If the sort is on route, sort orig and dest at the same time.
  switch (column) {
    case 'alert':
      return orderBy(
        data,
        (leg) => {
          return leg.notifications.length;
        },
        [direction],
      );
    default:
      return orderBy(data, [column], [direction]);
  }
};

/**
 * @description useReducer method for FlightListTable.  Manages state and logical actions in the component.
 * @param {Object} state - The current state
 * @param {Object} action - Details about the changes to be made to the state.
 */
export const flightListTableReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.RECEIVED_DISPLAY_DATA:
      const sortedFlightData = applySort(action.payload.flightData, state.orderBy, state.orderDirection);
      return {
        ...state,
        rawFlightData: action.payload.flightData,
        sortedFlightData: sortedFlightData,
        displayCount: Math.min(action.payload.initialDisplayCount, sortedFlightData.length),
      };
    case ACTIONS.SORT_DATA:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        orderDirection: action.payload.orderDirection,
        sortedFlightData: applySort(state.sortedFlightData, action.payload.orderBy, action.payload.orderDirection),
      };
    case ACTIONS.INCREMENT_DISPLAY_COUNT:
      let updatedDisplayCount = state.displayCount + ACTIONS.DISPLAY_COUNT_INCREMENT;
      if (updatedDisplayCount > state.sortedFlightData.length) {
        updatedDisplayCount = state.sortedFlightData.length;
      }
      return {
        ...state,
        displayCount: updatedDisplayCount,
      };
    case ACTIONS.CHANGE_DISPLAY_COUNT:
      let nextDisplayCount = action.payload.displayCount;
      if (nextDisplayCount > state.sortedFlightData.length) {
        nextDisplayCount = state.sortedFlightData.length;
      }
      return {
        ...state,
        displayCount: nextDisplayCount,
      };
    case ACTIONS.SEARCH_FLIGHT_NUMBER:
      const currentFlightIndex = action.payload.flightData.findIndex(
        (idx) => idx.flightLegKey === action.payload.searchFlightNumber,
      );
      return {
        ...state,
        orderBy: action.payload.orderBy,
        displayCount: currentFlightIndex,
      };
    default:
      return state;
  }
};
