import { useQueryClient, useIsFetching } from 'react-query';
import { RefetchPageData } from '../../lib/constants';
import { useCallback } from 'react';

/**
 * @description Custom hook for Cancel Query.
 * @returns {Object} - cancelQuery
 */
const useCancelQuery = () => {
  const queryClient = useQueryClient();

  //**Check isFetching status of query */
  const isFlightListFetching = useIsFetching(['flights']);
  const isGanttConfigFetching = useIsFetching(['airtrakmanager-gantt-config']);

  const cancelQuery = useCallback(
    (refetchPageData) => {
      switch (refetchPageData) {
        case RefetchPageData.GANTT:
          if (isGanttConfigFetching >= 1) {
            queryClient.cancelQueries('airtrakmanager-gantt-config');
          }
          break;
        case RefetchPageData.FLIGHT_LIST_TABLE:
          if (isFlightListFetching >= 1) {
            queryClient.cancelQueries('flights');
          }
          break;
        default:
          break;
      }
    },
    [queryClient, isGanttConfigFetching, isFlightListFetching],
  );
  return { cancelQuery };
};

export { useCancelQuery };
