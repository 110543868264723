// timeValidation.js
import dayjs from 'dayjs';
import { WarningTypes } from '../../lib/constants';

const getUpdatedOutTime = (newValue, offTime, onTime, inTime) => {
  if (newValue && offTime.value && isDateGreaterThanOrEqual(newValue, offTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: offTime.value,
      inputText: 'OFF',
    };
  } else if (newValue && onTime.value && isDateGreaterThanOrEqual(newValue, onTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: onTime.value,
      inputText: 'ON',
    };
  } else if (newValue && inTime.value && isDateGreaterThanOrEqual(newValue, inTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: inTime.value,
      inputText: 'IN',
    };
  } else {
    return { warningType: WarningTypes.NONE, warningMessage: '', value: newValue };
  }
};

const getUpdatedOffTime = (newValue, outTime, onTime, inTime) => {
  if (newValue && outTime.value && isDateGreaterThanOrEqual(outTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: newValue,
    };
  } else if (newValue && onTime.value && isDateGreaterThanOrEqual(newValue, onTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be < than ON time',
      value: newValue,
    };
  } else if (newValue && inTime.value && isDateGreaterThanOrEqual(newValue, inTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be < than IN time',
      value: newValue,
    };
  } else {
    return { warningType: WarningTypes.NONE, warningMessage: '', value: newValue };
  }
};

const getUpdatedOnTime = (newValue, outTime, offTime, inTime) => {
  if (newValue && outTime.value && isDateGreaterThanOrEqual(outTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: newValue,
    };
  } else if (newValue && offTime.value && isDateGreaterThanOrEqual(offTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OFF time',
      value: newValue,
    };
  } else if (newValue && inTime.value && isDateGreaterThanOrEqual(newValue, inTime.value)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be < than IN time',
      value: newValue,
    };
  } else {
    return { warningType: WarningTypes.NONE, warningMessage: '', value: newValue };
  }
};

const getUpdatedInTime = (newValue, outTime, offTime, onTime) => {
  if (newValue && outTime.value && isDateGreaterThanOrEqual(outTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OUT time',
      value: newValue,
    };
  } else if (newValue && offTime.value && isDateGreaterThanOrEqual(offTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than OFF time',
      value: newValue,
    };
  } else if (newValue && onTime.value && isDateGreaterThanOrEqual(onTime.value, newValue)) {
    return {
      warningType: WarningTypes.ERROR,
      warningMessage: '*Values must be > than ON time',
      value: newValue,
    };
  } else {
    return { warningType: WarningTypes.NONE, warningMessage: '', value: newValue };
  }
};

/**
 * @description returns true if firstParam >= secondParam
 * @param {string} firstParam : date string in MM/DD/YYYY hh:mm format
 *@param {string} secondParam : date string in MM/DD/YYYY hh:mm format
 */
const isDateGreaterThanOrEqual = (firstParam, secondParam) => {
  let startDateString = getValidDayJsDate(secondParam);
  let endDateString = getValidDayJsDate(firstParam);
  let test = endDateString.diff(startDateString, 'second');
  return startDateString && endDateString && test >= 0;
};

/**
 * @description returns dayjs date object
 * @param {string} dateString : date string in MM/DD/YYYY hh:mm format
 */
const getValidDayJsDate = (dateString) => {
  const dateFormat = 'MM/DD/YYYY HH:mm';
  const parsedDate = dayjs(dateString, dateFormat);

  if (!parsedDate.isValid()) {
    throw new Error('Invalid date format. Expected format: MM/DD/YYYY HH:mm');
  }

  return parsedDate;
};

const isDateTimeValid = (date, format = 'MM/DD/YYYY HH:mm') => dayjs(date, format, true).isValid();
/**
 * @description checks if the date is valid
 * @param {string} value : date value to be checked
 * @returns {boolean} - Returns true if date is in MM/DD/YYYY hh:mm format.
 */
const isDateFormatValid = (value) => {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4} ([01]\d|2[0-3]):[0-5]\d$/;
  return value && regex.test(value) && isDateTimeValid(value);
};

/**
 * @description Sets error for empty date.
 * @param {string} text - The text to display in the error message.
 * @param {function} setValue - Function to set the error and updated value.
 * @param {string} updatedValue - The value to be checked.
 * @returns {boolean} - Returns true if error exists, otherwise false.
 */
const setEmptyError = (text, setValue, updatedValue) => {
  setValue({ value: updatedValue, warningType: WarningTypes.ERROR, warningMessage: `*${text} is required` });
};

/**
 * @description sets error is date is invalid
 * @param {string} setValue : function to set error and udpatedValue
 * @param {string} updatedValue : date string in MM/DD/YYYY hh:mm format to be checked
 */
const setInvalidDateError = (setValue, updatedValue) => {
  setValue({ value: updatedValue, warningType: WarningTypes.ERROR, warningMessage: '*Invalid date format.' });
};

/**
 * @description Compare two times in the format of hh:mm using dayjs
 * @param {string} time1 - The first time string in the format of hh:mm
 * @param {string} time2 - The second time string in the format of hh:mm
 * @returns {number} - Returns true if time1 is greater or equal time2, else returns false
 */
const isTimeGreaterThanOrEqual = (time1, time2) => {
  if (!time1 || !time2) {
    return false;
  }
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  // Compare the hours first
  if (hours1 > hours2) {
    return true;
  } else if (hours1 === hours2) {
    // If hours are equal, compare the minutes
    return minutes1 >= minutes2;
  } else {
    return false;
  }
};

const extractTime = (dateString) => {
  // Split the date string by space to separate the date and time parts
  const [, timePart] = dateString.split(' ');
  // Return the time part
  return timePart;
};

export {
  extractTime,
  setEmptyError,
  isDateFormatValid,
  isDateTimeValid,
  getUpdatedOutTime,
  getUpdatedOffTime,
  getUpdatedOnTime,
  getUpdatedInTime,
  isDateGreaterThanOrEqual,
  isTimeGreaterThanOrEqual,
  setInvalidDateError,
};
