import { useDispatch } from 'react-redux';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
import { useCallback } from 'react';
import { MultiSelectedFlightGroup } from '../../redux/actionTypes';

/**
 * Selector for the multi selected flight store
 */

const multiSelectedFlightGroup = (state) => state.multiSelectedFlightGroupReducer;

/**
 * Custom hook for retrieving the multi selected flight store
 * @returns selector hook for retrieving multi selected flight store
 */

export const useMultiSelectedFlightGroupStore = () => {
  return useSelector(multiSelectedFlightGroup);
};

/**
 * Custom hook for dispatching actions to update or clear the multi selected flight store
 * @returns object of dispatch actions to interact with the multi selected flight store
 */
export const useMultiSelectedFlightGroupDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to update the multi selected flight store
   * @param {object} flightGroup - the updated flight groups
   */
  const updateMultiSelectedFlightGroup = useCallback(
    (payload) => {
      dispatch({ type: MultiSelectedFlightGroup.UPDATE, payload: { ...payload } });
    },
    [dispatch],
  );

  /**
   * Callback to clear the multi selected flight store
   */
  const removeMultiSelectedFlightGroup = useCallback(
    (payload) => {
      dispatch({ type: MultiSelectedFlightGroup.REMOVE, payload: { ...payload } });
    },
    [dispatch],
  );

  const removeAllMultiSelectedFlightGroup = useCallback(() => {
    dispatch({ type: MultiSelectedFlightGroup.REMOVEALL });
  }, [dispatch]);

  /**
   * Callback to update aircraft registration for a group of flight legs
   */
  const updateAircraftRegistration = useCallback(
    (payload) => {
      dispatch({ type: MultiSelectedFlightGroup.UPDATE_AIRCRAFT, payload: { ...payload } });
    },
    [dispatch],
  );

  /**
   * Callback to swap aircraft registrations for two groups of flight legs
   */
  const swapAircraftRegistration = useCallback(
    (payload) => {
      dispatch({ type: MultiSelectedFlightGroup.SWAP_AIRCRAFT, payload: { ...payload } });
    },
    [dispatch],
  );

  return {
    updateMultiSelectedFlightGroup,
    removeMultiSelectedFlightGroup,
    removeAllMultiSelectedFlightGroup,
    updateAircraftRegistration,
    swapAircraftRegistration,
  };
};
