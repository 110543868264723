import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { FormDataBrickType } from '../../../lib/constants';
import { toLowerCase } from '../../../lib/displayUtils';
import BrickContainer from '../BrickContainer/BrickContainer';
import { makeStyles } from '@material-ui/core/styles';

// import './CurrentFlightDetails.css';

const useStyles = makeStyles((theme) => ({
  cfdChild: {
    [theme.breakpoints.down('md')]: {
      width: '25% !important',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto !important',
      paddingRight: '5.6rem !important',
    },
  },
  stationsArrow: {
    color: 'var(--gray)',
    margin: '0px 2px 1px 2px',
    height: '1.3rem',
  },
  brickForm: {
    display: 'flex',
    flexFirection: 'column',
    width: '100%',
  },
  brickLabel: {
    display: 'block',
    color: 'rgb(54, 54, 54)',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  brickValue: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
  },
  zuluLabel: {
    color: 'var(--gray)',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    marginLeft: '1px',
  },
  fadeGray: {
    color: 'var(--fade-gray)',
  },
}));
/**
 * CurrentFlightDetails is a Controlled Component and renders a current flight details.
 * @param {any} flightDetailBlocks - array of the current flight details.
 * @param {string} customCSSTag - custom class name
 * @param {string} dataCyTag - test id attribute
 * @returns {CurrentFlightDetails} the CurrentFlightDetails component
 */
const CurrentFlightDetails = ({ flightDetailBlocks, customCSSTag = '', customBrickCSSTag = '', dataCyTag = '' }) => {
  const classes = useStyles();

  return (
    <Grid container className={'cfd-parent ' + customCSSTag} data-cy={dataCyTag}>
      {flightDetailBlocks.map((brick, index) => (
        <React.Fragment>
          <Grid item key={`fd-g-${brick.title}`} className={`cfd-child ${classes.cfdChild} ${customBrickCSSTag}`}>
            <BrickContainer
              title={brick.brickType !== FormDataBrickType.STATION_MULTIPLE ? brick.title : 'Origin'}
              dataCyTag={`${dataCyTag}-${toLowerCase(brick.title)}-t`}
              customCSSTag={`${brick.isActive ? '' : classes.fadeGray}`}
            >
              <div
                data-cy={`${dataCyTag}-${toLowerCase(brick.title)}-v`}
                className={`${classes.brickValue} ${brick.isActive ? '' : classes.fadeGray}`}
              >
                {brick.value}
                {brick.brickType === FormDataBrickType.TIME ? (
                  <>
                    <span className={`${classes.zuluLabel}`}>zulu</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </BrickContainer>
          </Grid>
          {brick.brickType === FormDataBrickType.STATION_MULTIPLE && brick.value2 ? (
            <Grid item key={`fd-g-${brick.title}`} className={`cfd-child ${classes.cfdChild} ${customBrickCSSTag}`}>
              <BrickContainer
                title={'Destination'}
                dataCyTag={`${dataCyTag}-${toLowerCase(brick.title)}-t`}
                customCSSTag={`${brick.isActive ? '' : classes.fadeGray}`}
              >
                <div
                  data-cy={`${dataCyTag}-${toLowerCase(brick.title)}-v`}
                  className={`${classes.brickValue} ${brick.isActive ? '' : classes.fadeGray}`}
                >
                  {brick.value2}
                </div>
              </BrickContainer>
            </Grid>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default withAppInsightsTracking(CurrentFlightDetails);

CurrentFlightDetails.propTypes = {
  flightDetailBlocks: PropTypes.array.isRequired,
  customCSSTag: PropTypes.string,
  customBrickCSSTag: PropTypes.string,
  dataCyTag: PropTypes.string,
};
