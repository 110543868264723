import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

/**
 * AOC custom styled button
 * @param {boolean} isDisabled - makes the button disabled
 * @param {string} className - custom class name to be included with this button
 * @param {string} variant - default AOC style, options: 'primary', 'secondary'
 * @param {func} onClick - button on click handler function
 * @param {Node} children - body to be rendered in the button, can just be a string
 * @return {Button} The Button component
 */
const Button = ({ isDisabled = false, className, variant = 'primary', onClick, children, ...props }) => {
  const handleClick = (e) => onClick(e);

  // build list of classes for this component
  let classList = ['button'];
  let btnProps = { ...props };
  if (variant) classList.push(`button-${variant}`);
  classList.push(`btn-' + ${className ? className : ''}`);
  classList = classList.join(' ');
  return (
    <div className={`button-container ${className ? className : ''}`} {...props}>
      <button
        data-cy={`${btnProps['data-cy']}-${variant}`}
        tabIndex="-1"
        onClick={handleClick}
        className={classList}
        disabled={isDisabled}
      >
        {children}
      </button>
    </div>
  );
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Button;
