import React from 'react';
import PropTypes from 'prop-types';
import TimeDisplay from '../../../../../Shared/TimeDisplay/TimeDisplay';
import { withAppInsightsTracking } from '../../../../../../services/appInsightsFactory/appInsightsFactory';

import '../../../../FlightDetailPane/FlightDetailPane.css';
import '../../../../FlightDetailPane/FlightTab/FlightTab.css';

/**
 * Displays the scheduled, estimated and actual times
 * of an event of interest in UTC and local time
 * @param {string} event - event of interest
 * @param {array} utcTime - scheduled, estimated and actual times of all OOOI events of the flight
 * @param {string} comparisonUtcTime - the station for this schedule event.
 * @param {string} delayMinutes - the station delayMinutes.
 * @param {string} itemLabel - itemLabel.
 * @returns FlightTabStationTimes
 */
const FlightTabStationTimes = ({ event, utcTime, comparisonUtcTime, delayMinutes, station, itemLabel }) => {
  const defaultDisplay = <>&#8212;&#8212;&#8212;</>;
  return (
    <div data-cy="flight-tab-schedule-table">
      <>
        {itemLabel == 'Scheduled' ? <div className="section-sub-header">{event}</div> : <></>}
        <div className="item-label">{itemLabel}</div>
        {utcTime !== null ? (
          <div className="item-cell" data-cy={`${event.toLowerCase()}-${itemLabel.toLowerCase()}-times`}>
            <TimeDisplay
              utcTime={utcTime}
              dataCyTag={`${event.toLowerCase()}-${itemLabel.toLowerCase()}-timedisplay-zulu`}
            />
            &nbsp; / &nbsp;
            <TimeDisplay
              utcTime={utcTime}
              dataCyTag={`${event.toLowerCase()}-${itemLabel.toLowerCase()}-timedisplay-local`}
              station={station}
              comparisonUtcTime={comparisonUtcTime}
              label="mins"
              delayTime={delayMinutes}
            />
          </div>
        ) : (
          <div>{defaultDisplay}</div>
        )}
      </>
    </div>
  );
};

FlightTabStationTimes.propTypes = {
  event: PropTypes.string.isRequired,
  utcTime: PropTypes.string.isRequired,
  comparisonUtcTime: PropTypes.string.isRequired,
  delayMinutes: PropTypes.string.isRequired,
  station: PropTypes.string.isRequired,
  itemLabel: PropTypes.string.isRequired,
};

export default withAppInsightsTracking(FlightTabStationTimes);
