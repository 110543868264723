import { useQuery, useQueryClient } from 'react-query';
import { getFlightsV3, getFlightDetails, checkFlightExists } from '../../services/apiClient/flightsApi/flightsApi';
import appSettings from '../../appSettings';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import usePrevious from '../../hooks/usePrevious/usePrevious';
import { useAutoRefreshStore } from '../../hooks/useAutoRefreshStore/useAutoRefreshStore';
import { DateFormat } from '../../lib/constants';
import { formatDateTime, getDatetimeUtcNowString } from '../../lib/dateTimeUtils';

const moduleName = 'useFlightsQuery';

const baseConfig = {
  cacheTime: 0,
  staleTime: 1 * 60 * 1000,
  retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
  refetchInterval: appSettings.REFRESH_INTERVAL,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  onError: (error) => {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'useFlightsQuery', createApiErrorTelemetryProperties(error));
  },
};

/**
 * Custom hook for retrieving data from the Flights API
 * @param {string} startDate - operational start date utc formatted as yyyy-MM-DD
 * @param {string} endDate - operational end date utc formatted as yyyy-MM-DD
 * @param {boolean} enabled - enable/disable the query
 * @returns
 */
export const useFlightsQuery = (startDate, endDate, enabled = true) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const enabledAutoRefresh = autoRefreshFlags?.ganttChartRefresh;

  const config = {
    ...baseConfig,
    enabled: enabled,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getFlights', createApiErrorTelemetryProperties(error));
    },
    onSuccess: () => {
      const time = getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss');
      console.log(`Flights API data refreshed or fetched at ${time}z`);
    },
  };

  let startDateString;
  let endDateString;

  startDateString = formatDateTime(startDate, DateFormat.ISO_STANDARD);
  endDateString = formatDateTime(endDate, DateFormat.ISO_STANDARD);

  return useQuery(
    ['flights', startDateString, endDateString],
    () => getFlightsV3(startDateString, endDateString),
    config,
  );
};

/**
 * Custom hook for retrieving data from the Flights API
 * @param {string} flightNumber - Flight Number
 * @param {string} departureDateUTC - departure date utc formatted as yyyy-MM-DD
 * @param {string} origin - origin station
 * @param {string} destination - destination station
 * @param {string} departureCount - Departure Count
 * @param {string} airlineCode - Airline code of the flight
 * @param {bool}   enabledAutoRefresh - Autorere
 * @returns
 */
export const useFlightDetailsQuery = (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
  enabledAutoRefresh,
  enabled = true,
) => {
  const previousData = usePrevious([flightNumber, departureDateUTC, origin, destination, departureCount, airlineCode]);
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: enabled,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getFlightDetails', createApiErrorTelemetryProperties(error));
    },
  };

  // if the data have changed from the previous, we want to trigger
  // a refresh and remove the previous data from the cache.
  // if they haven't changed, it means another component has mounted (i.e. aircraft filter).
  // in that case we can return from the cache.
  if (previousData?.length) {
    const [prevFlightNumber, prevDepartureDateUTC, prevOrigin, prevDestination, prevDepartureCount, prevAirlineCode] =
      previousData;

    if (
      prevFlightNumber !== flightNumber ||
      prevDepartureDateUTC !== departureDateUTC ||
      prevOrigin !== origin ||
      prevDestination !== destination ||
      prevDepartureCount !== departureCount ||
      prevAirlineCode !== airlineCode
    ) {
      queryClient.removeQueries('flightdetails');
    }
  }

  return useQuery(
    ['flightdetails', flightNumber, departureDateUTC, origin, destination, departureCount, airlineCode],
    () => getFlightDetails(flightNumber, departureDateUTC, origin, destination, departureCount, airlineCode),
    config,
  );
};

/**
 * @description Custom hook to head check to see flight exist
 * @param {string} airlineCode - airline code.
 * @param {string} flightNumber - flight number.
 * @param {string} departureDateUTC - departure date in UTC.
 * @param {string} origin - origin airport code.
 * @param {string} destination - destination airport code.
 * @param {string} departureCount - departure count.
 * @param {string} irropsCode - irrops code.
 * @param {string} aircraftRegistration - aircraft registration.
 * @param {number} queryTimeout - query timeout.
 * @param {object} config - query configuration.
 */
export const useFlightExistsCheck = (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
  irropsCode,
  aircraftRegistration,
  queryTimeout,
  config,
) => {
  const useQueryConfig = {
    ...baseConfig,
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false; // Don't retry 404 errors
      }
      return failureCount < 1; // Retry failed requests 2 times before displaying an error
    },
    retryDelay: 100, // Retry after 100 miliseconds
    enabled: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'checkFlightExists', createApiErrorTelemetryProperties(error));
    },
  };
  const query = useQuery(
    [
      'FlightExistsCheck',
      flightNumber,
      departureDateUTC,
      origin,
      destination,
      departureCount,
      airlineCode,
      irropsCode,
      aircraftRegistration,
      queryTimeout,
    ],
    () =>
      checkFlightExists(
        {
          flightNumber,
          departureDateUTC,
          origin,
          destination,
          airlineCode,
          irropsCode,
          aircraftRegistration,
          queryTimeout,
        },
        config,
      ),
    useQueryConfig,
  );
  query.found = !query.isError && query.data?.status === 200;
  query.notFound = query.isError && query.error?.response?.status === 404;
  query.failed = query.isError && query.error?.response?.status !== 404;
  return query;
};
