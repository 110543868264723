import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { mapFlightDataToOutput } from '../../../../lib/swapUtil';
import './SwapEmptyAircraftContainer.css';
import { useMultiSelectedFlightGroupStore } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders an empty aircraft container to trigger swap drop
 * @param {*} props
 * @returns
 */
export default function SwapEmptyAircraftContainer(props) {
  const { flightLegGroup } = useMultiSelectedFlightGroupStore();
  const swapModeDispatch = useSwapModeDispatch();
  const dragItem = useDragItemStore();

  const [dropRef] = useDropItem({
    dropItemKey: `${props.aircraftRegistration}-swaps-empty-aircraft-container`,
    data: props.data,
    onDrop: () => {
      if (
        flightLegGroup[props.aircraftRegistration] != undefined &&
        flightLegGroup[props.aircraftRegistration].some((x) => x.flightLegKey == props.data.flightLegKey)
      ) {
        let selectedFlights = flightLegGroup[props.aircraftRegistration];
        for (var flight of selectedFlights) {
          swapModeDispatch.updateMoveFlightLeg({
            flightData: [flight],
            aircraftRegistration: props.aircraftRegistration,
          });
        }
      } else {
        swapModeDispatch.updateMoveFlightLeg({
          flightData: [...dragItem.data.filter((f) => !f.groundFlag).map((f) => mapFlightDataToOutput(f))],
          aircraftRegistration: props.aircraftRegistration,
        });
      }
    },
  });

  return (
    <div ref={dropRef} className="swap-empty-aircraft-container" data-cy="swap-empty-aircraft-container">
      {/* empty container */}
    </div>
  );
}
