import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'userpreference';
const moduleName = 'preferencesApi';

/**
 * Returns a user's stored user preferences
 */
export const getUserPreferences = async () => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/preferences/users`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getUserPreferences', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  return result;
};

/**
 * Updates the user's list of preferences
 * @param {Array} userPreferencesList - Array of preference types to update
 *   Each preference type object in the list should contain:
 *   - preferenceType - string
 *   - userPreferenceContent - stringified JSON
 * @returns
 */
export const updateUserPreferences = async (userPreferencesList) => {
  const apiClient = axiosInstance;
  const request = { userPreferences: userPreferencesList };
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/preferences/users`;
    const response = await apiClient.put(url, request);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'updateUserPreferences', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
