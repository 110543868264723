import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import { createAssignment, updateAssignment } from '../../../../services/apiClient/administrationApi/administrationApi';
import { useAuthentication } from '../../../../contexts/AuthenticationContext/AuthenticationContext';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../../lib/constants';
import DynamicCheckbox from '../../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';
import { getAssignmentTypeList } from '../../../../lib/utils';

import './AssignmentForm.css';

const airlineList = [
  { code: 'AS', key: 0 },
  { code: 'QX', key: 1 },
  { code: 'OO', key: 2 },
];

const AssignmentForm = ({ existingAssignment = null, onSave, onCancel, ...props }) => {
  const initialFormData =
    existingAssignment === null
      ? {
          name: '',
          description: '',
          operatingAirline: '',
          criteria: '',
          typeName: '',
        }
      : existingAssignment;

  // Hooks
  const [assignmentFormData, setAssignmentFormData] = useState(initialFormData);
  const [show, setShow] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { userData } = useAuthentication();

  const assignmentTypeList = getAssignmentTypeList();

  const validateAssignmentForm = (assignmentForm) => {
    const isAssignmentFormComplete =
      assignmentForm.name !== '' &&
      assignmentForm.operatingAirline !== '' &&
      assignmentForm.criteria !== '' &&
      assignmentForm.typeName !== '';

    let isAssignmentFormUnchanged = false;
    if (existingAssignment !== null) {
      // check that edits have been made to the assignment Group
      const sameAssignmentName = existingAssignment.name === assignmentForm.name;
      const sameDescription = existingAssignment.description === assignmentForm.description;
      const sameOperatingAirline = existingAssignment.operatingAirline === assignmentForm.operatingAirline;
      const sameCriteria = existingAssignment.criteria === assignmentForm.criteria;
      const sameAssignmentType = existingAssignment.typeName === assignmentForm.typeName;
      isAssignmentFormUnchanged =
        sameAssignmentName && sameDescription && sameOperatingAirline && sameCriteria && sameAssignmentType;
    }
    setIsSaveDisabled(!isAssignmentFormComplete || isAssignmentFormUnchanged);
  };

  const handleTextInputChange = (e) => {
    let newAssignmentFormData = {};

    switch (e.target.id) {
      case 'assignment-name':
        newAssignmentFormData = { ...assignmentFormData, name: e.target.value };
        break;
      case 'assignment-description':
        newAssignmentFormData = { ...assignmentFormData, description: e.target.value };
        break;
      case 'assignment-criteria':
        newAssignmentFormData = { ...assignmentFormData, criteria: e.target.value };
        break;
      default:
        newAssignmentFormData = { ...assignmentFormData, operatingAirline: e.target.value };
    }

    setAssignmentFormData(newAssignmentFormData);
    validateAssignmentForm(newAssignmentFormData);
  };

  const handleSaveButtonClick = () => {
    const saveAssignmentForm = async () => {
      let result;

      // call API
      if (existingAssignment === null) {
        assignmentFormData.modifiedBy = userData.profile.name;
        result = await createAssignment(assignmentFormData);
      } else {
        assignmentFormData.modifiedBy = userData.profile.name;
        result = await updateAssignment(assignmentFormData);
      }
      // handle result
      if (result === null) {
        setStatusMessage('Unable to save changes.');
        setIsSaveDisabled(false);
      } else {
        setStatusMessage('');
        setShow(false);
        onSave();
      }
    };

    setStatusMessage('Saving...');
    setIsSaveDisabled(true);
    saveAssignmentForm();
  };

  const handleCancel = () => {
    setShow(false);
    onCancel();
  };
  const handleAirlineSelectChange = (code) => {
    let newAssignmentFormData = {};
    newAssignmentFormData = { ...assignmentFormData, operatingAirline: code };
    setAssignmentFormData(newAssignmentFormData);
    validateAssignmentForm(newAssignmentFormData);
  };
  /**
   * Updates the assignment type in assignmentFormData state and validates form data.
   * @param {string} assignmentType - assignment type to be updated
   * @returns
   */
  const handleAssignmentTypeSelectChange = (assignmentTypeName) => {
    let newAssignmentFormData = {};
    newAssignmentFormData = { ...assignmentFormData, typeName: assignmentTypeName };
    setAssignmentFormData(newAssignmentFormData);
    validateAssignmentForm(newAssignmentFormData);
  };
  const isChecked = (code) => {
    return code === assignmentFormData.operatingAirline;
  };

  const formContent = (
    <>
      <div className="assignment-form-container" data-cy="assignment-form-container">
        <div>
          <TextField
            className="text-field"
            id="assignment-name"
            label="Assignment Name"
            value={assignmentFormData.name}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'assignment-name-input' }}
          />
          <TextField
            className="text-field"
            id="assignment-description"
            label="Assignment Description"
            value={assignmentFormData.description}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'assignment-description-input' }}
          />
          <FormGroup className="assignment-form-type" data-cy="assignment-form-type">
            <FormLabel component="legend">Assignment Type</FormLabel>
            <div className="assignment-type-container">
              {assignmentTypeList.map(({ typeName }, index) => {
                return (
                  <DynamicCheckbox
                    key={`assignment-type-${typeName}-${index}`}
                    dataCyTag={`assignment-type-${typeName}`}
                    customCSSTag="assignment-type-item"
                    type={'radio'}
                    checked={typeName === assignmentFormData.typeName}
                    label={typeName}
                    onChange={() => handleAssignmentTypeSelectChange(typeName)}
                  />
                );
              })}
            </div>
          </FormGroup>
          <FormGroup className="role-form-airline" data-cy="role-form-airline">
            <FormLabel component="legend">Airline</FormLabel>
            <div className="assignment-airline-container">
              {airlineList.map(({ code, key }, index) => {
                return (
                  <DynamicCheckbox
                    key={`airline-code-${code}-${index}`}
                    dataCyTag={`airline-code-${code}`}
                    customCSSTag="assignment-airline-item"
                    type={'radio'}
                    checked={isChecked(code)}
                    label={code}
                    onChange={() => handleAirlineSelectChange(code)}
                  />
                );
              })}
            </div>
          </FormGroup>

          <TextField
            className="text-field"
            id="assignment-criteria"
            label="View Name"
            value={assignmentFormData.criteria}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'assignment-criteria-input' }}
          />
        </div>
      </div>
      <div className="status-message" data-cy="status-message">
        {statusMessage}
      </div>
    </>
  );

  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="assignment-form-save-button"
      onClick={handleSaveButtonClick}
      isDisabled={isSaveDisabled}
    >
      Save
    </Button>,
  ];

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <Modal
        show={show}
        title={existingAssignment === null ? 'Add Assignment' : 'Edit Assignment'}
        body={formContent}
        footerButtons={formButtons}
        onHide={handleCancel}
        size="thin"
        draggable={false}
        {...props}
      />
    </AocAuthAccessCheck>
  );
};

AssignmentForm.propTypes = {
  existingAssignment: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AssignmentForm;
