import React from 'react';
import Form from 'react-bootstrap/Form';
import DynamicCheckbox from '../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';

export const AssignmentSessionForm = ({ data, formName, title, type, onChange, isChecked }) => {
  //type can be radio or checkbox

  return (
    <>
      <div className="form-title" data-cy={`${formName}-form-title`}>
        {title}
      </div>
      <Form className="assignment-session-form">
        {data.map(({ id, label }, index) => {
          return (
            <DynamicCheckbox
              dataCyTag={`${formName}-form-item`}
              customCSSTag="form-item"
              name={formName}
              id={`${formName}-${id}`}
              key={`${formName}-${id}-${index}`}
              type={type}
              checked={isChecked(id)}
              label={label}
              onChange={() => onChange(id)}
            />
          );
        })}
      </Form>
    </>
  );
};

export default AssignmentSessionForm;
