import React, { useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import ChipInput from '../ChipInput/ChipInput';
import { makeStyles } from '@material-ui/core/styles';
import { KeyCodes } from '../../../../lib/constants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { getInputPoppersModifier, setPopperWidth, focusNextElement } from '../../../../lib/utils';
import './ChipInputDropdown.css';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      marginTop: '.3rem',
      marginBottom: '.9rem',
      left: 0,
      right: 0,
      zIndex: 1,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      zIndex: 100,
    },
    textField: {
      width: '100%',
    },
  };
});

/**
 * ChipInputDropdown is a Controlled Component and renders an input control along with chips added
 * @param {array} displayList - array of suggestions to display as the user types on the input
 * @param {func} onSuggestionSelect - callback funciton when the user clicks on a suggestion / selects a suggestion using keyboard enter key
 * @param {array} displayChips - array of chips to display on the control
 * @param {func} onChipClick - callback funciton when the user clicks on a chip
 * @param {func} onChipAdd - callback function when a new chip is being aded by the user
 * @param {func} onChipDelete - callback function to when the chip is deleted by user
 * @param {string} textInput - text on the input to display on the control
 * @param {func} onTextChange - callback function when user enters input or input text changes
 * @param {func} onTextKeyDown - callback function when keypress on the text input
 * @param {func} onInputFocus - callback function when input function comes into focus
 * @param {func} onInputBlur - callback function when the input is blur
 * @param {string} dataCyTag - test id attribute
 * @param {string} className - an additional custom class name
 * @param {bool} isDisabled - to set if the control is enabled or disabled
 * @param {bool} isFullWidth - boolean to set if the control has to be full width of the parent
 * @param {bool} isReadOnly - boolean to set the control to ReadOnly
 */
const ChipInputDropdown = ({
  displayList = [],
  onSuggestionSelect = () => {
    /* This is intentional */
  },
  displayChips = [],
  onChipClick = () => {
    /* This is intentional */
  },
  onChipAdd = () => {
    /* This is intentional */
  },
  onChipDelete = () => {
    /* This is intentional */
  },
  textInput = '',
  onTextChange = () => {
    /* This is intentional */
  },
  onTextKeyDown = () => {
    /* This is intentional */
  },
  onInputFocus = () => {
    /* This is intentional */
  },
  onInputBlur = () => {
    /* This is intentional */
  },
  onClickAway = () => {
    /* This is intentional */
  },
  className = '',
  dataCyTag = 'chipddl',
  isDisabled = false,
  isFullWidth = true,
  isReadOnly = false,
}) => {
  const [openList, setOpenList] = useState(false);

  const classes = useStyles();

  const anchorEl = useRef(null);
  const menuRef = useRef(null);

  /**
   * @description opens the list of dropdown items on text change and calls the parent event handler
   * @param {*} textchange event from the input text field
   */
  const handleOnTextChange = (e) => {
    setOpenList(true);
    onTextChange(e);
  };

  /**
   * @description handles menu close.
   * @param {*} click event that comes either from ClickAwayListener or MenuItemClick
   */
  const handleClose = (event) => {
    if (anchorEl.current.contains(event.target)) {
      // This event has originated from the ChipInput Component itself or the containing div. this click is considered a part of user input process.
      // we do not need to close the dropdown in this case. return without closing the dropdown list
      return;
    }

    onClickAway(event);
    setOpenList(false);
  };

  /**
   * @description handles menu item click. closes the dropdown list and calls parent onSuggestionSelect handler
   * @param {*} click event on the suggestions list menu item
   */
  const handleMenuItemClick = (event) => {
    var value = event?.target?.attributes['value']?.value;
    handleClose(event);

    if (value != null) {
      let target = anchorEl.current.querySelector('input');
      onSuggestionSelect(value);

      if (event.keyCode === KeyCodes.TAB) {
        focusNextElement(event, target);
      } else {
        target.focus();
      }
    }
  };

  /**
   * @description handles the enter, tab or space key down event of the items menu dropdown
   * @param {any} event
   */
  const handleMenuItemKeyDown = (event) => {
    let target = anchorEl.current.querySelector('input');
    if (
      event.keyCode === KeyCodes.ENTER ||
      (event.keyCode === KeyCodes.TAB && !event.shiftKey) ||
      (event.keyCode === KeyCodes.SPACE && !event.shiftKey)
    ) {
      handleMenuItemClick(event);
      target.focus();
      event.preventDefault();
    } else if (event.keyCode === KeyCodes.TAB && event.shiftKey) {
      onTextChange('');
      setOpenList(false);
      target.focus();
      event.preventDefault();
    }
  };

  /**
   * @description handles the enter, tab or space key down event of the no items menu dropdown
   * @param {any} event
   */
  const handleNoItemsMenuItemKeyDown = (event) => {
    if (
      event.keyCode === KeyCodes.ENTER ||
      event.keyCode === KeyCodes.TAB ||
      (event.keyCode === KeyCodes.SPACE && !event.shiftKey)
    ) {
      onTextChange('');
      setOpenList(false);

      let target = anchorEl.current.querySelector('input');

      if (event.keyCode === KeyCodes.TAB && event.shiftKey) {
        target.focus();
        event.preventDefault();
      } else if (event.keyCode === KeyCodes.TAB && !event.shiftKey) {
        focusNextElement(event, target);
      } else {
        target.focus();
      }
    }
  };

  /**
   * @description handles keydown event coming from ChipInput component. focuses the first dropdown list item and passes the event to the parent handler.
   * @param {*} Keydown event
   */
  const handleOnTextKeyDown = (event) => {
    if (event.keyCode === KeyCodes.DOWN_ARROW) {
      if (menuRef && menuRef?.current?.children && menuRef.current?.children.length > 0) {
        menuRef.current.children[0].focus();
      }
    }

    if (event.keyCode === KeyCodes.TAB && textInput == '') {
      setOpenList(false);
      focusNextElement(event, event.target);
    } else if (event.keyCode === KeyCodes.TAB && textInput != '') {
      if (!event.shiftKey) {
        if (menuRef && menuRef?.current?.children && menuRef.current?.children.length > 0) {
          menuRef.current.children[0].focus();
        }
        event.preventDefault();
      } else {
        onTextChange('');
        setOpenList(false);
        focusNextElement(event, event.target);
      }
    }

    onTextKeyDown(event);
  };

  /**
   * @description handles child add on enter key and closes the dropdown list and calls parent onChipAdd handler
   * @param {string} chid adding chip value.
   */
  const handleOnChipAdd = (chid) => {
    onChipAdd(chid);
    setOpenList(false);
  };

  const modifiersArray = getInputPoppersModifier(setPopperWidth);
  const modifiersObject = { modifiersArray };

  return (
    <>
      <div className={className + ' chipsinputdropdown-container'} ref={anchorEl}>
        <ChipInput
          displayChips={displayChips}
          onChipClick={onChipClick}
          onChipAdd={handleOnChipAdd}
          onChipDelete={onChipDelete}
          textInput={textInput}
          onTextChange={handleOnTextChange}
          onTextKeyDown={handleOnTextKeyDown}
          onInputFocus={onInputFocus}
          onInputBlur={onInputBlur}
          isDisabled={isDisabled}
          isFullWidth={isFullWidth}
          isReadOnly={isReadOnly}
          className={className + ' ' + className + '-ci'}
          dataCyTag={dataCyTag + '-ci'}
        />
      </div>
      <Popper
        open={openList}
        anchorEl={anchorEl.current}
        className={classes.suggestionsList}
        placement="bottom-start"
        modifiers={modifiersObject}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Paper elevation={0} className={'chipinputdropdown-paper'}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                ref={menuRef}
                className={'menulist'}
                autoFocusItem={false}
                autoFocus={false}
                style={{ maxHeight: '10rem', overflowY: 'auto' }}
                data-cy={dataCyTag + '-menu'}
              >
                {displayList.length > 0 ? (
                  displayList.map((ele, index) => (
                    <MenuItem
                      key={ele.value}
                      index={index}
                      value={ele.value}
                      onClick={handleMenuItemClick}
                      onKeyDown={handleMenuItemKeyDown}
                    >
                      {ele.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    key="noitemsfound"
                    index={0}
                    value={'NoItemsFound'}
                    onKeyDown={handleNoItemsMenuItemKeyDown}
                  >
                    No matching results!
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

ChipInputDropdown.propTypes = {
  displayList: PropTypes.array,
  onSuggestionSelect: PropTypes.func,
  displayChips: PropTypes.array,
  onChipClick: PropTypes.func,
  onChipAdd: PropTypes.func,
  onChipDelete: PropTypes.func,
  textInput: PropTypes.string,
  onTextChange: PropTypes.func,
  onTextKeyDown: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  dataCyTag: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ChipInputDropdown;
