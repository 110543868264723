import { useDispatch } from 'react-redux';
import { CrosshairActionKeys } from '../../redux/actionTypes';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Selector for the CrossHair store
 */
const selectCrosshairMode = (state) => state.crosshairReducer;

/**
 * Custom hook for retrieving the cross hair store
 * @returns selector hook for retrieving crosshair store
 */
export const useCrosshairStore = () => {
  return useSelector(selectCrosshairMode);
};

/**
 * Custom hook for dispatching actions to update crosshair flags
 */

export const useCrosshairDispatch = () => {
  const dispatch = useDispatch();

  // callback to update the crosshair flag
  const updateCrosshair = (payload) => {
    dispatch({
      type: CrosshairActionKeys.UPDATE_CROSSHAIR_MODE,
      payload: { isCrosshairActive: payload.isCrosshairActive },
    });
  };

  // callback to update number of crosshairs
  const updateCrosshairCount = (payload) => {
    dispatch({
      type: CrosshairActionKeys.UPDATE_CROSSHAIR_COUNT,
      payload: { crosshairCount: payload.crosshairCount },
    });
  };

  // callback to update the current active crosshair
  const updateActiveCrosshair = (payload) => {
    dispatch({
      type: CrosshairActionKeys.UPDATE_ACTIVE_CROSSHAIR,
      payload: { activeCrosshair: payload.activeCrosshair },
    });
  };

  return { updateCrosshair, updateCrosshairCount, updateActiveCrosshair };
};
