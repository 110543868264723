import React from 'react';
import './AddViewButton.css';
import { ReactComponent as AddViewIcon } from '../../../../assets/images/add-view-icon.svg';
import { ReactComponent as AddViewDisabledIcon } from '../../../../assets/images/add-view-disabled-icon.svg';

const AddViewButton = ({ onClickHandler = () => {}, disabled = false }) => {
  const onClickAddViewButton = () => {
    onClickHandler();
  };

  return (
    <div className={`add-view-button-container ${disabled ? ' disabled' : ''}`} onClick={onClickAddViewButton}>
      {!disabled ? (
        <AddViewIcon className="add-view-icon add-btn active" data-cy="add-view-btn" />
      ) : (
        <AddViewDisabledIcon className="add-view-icon add-btn disabled" data-cy="add-view-btn-disabled" />
      )}
    </div>
  );
};

export default AddViewButton;
