import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import './SummaryPanel.css';
import MiniSummaryContainer from './MiniSummaryContainer/MiniSummaryContainer';
import SummaryDetailPaneContainer from './SummaryDetailPaneContainer/SummaryDetailPaneContainer';
import { SummaryPanelMode, DateFormat, ActiveKeys } from '../../../lib/constants';
import { useSelectedItemStore } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { useFlightSummary } from '../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import { useGroundEventsSummary } from '../../../hooks/useGroundEventsSummary/useGroundEventsSummary';
import { getSummaryInfo } from './summaryPanelUtils';

/**
 * The SummaryPanel component render the swap mode footer
 * @returns SummaryPanel
 */
const SummaryPanel = ({
  summaryPanelMode = SummaryPanelMode.MINI_SUMMARY,
  onChangeSummaryPanelMode = () => {},
  onChangedSummaryPanelHeight = () => {},
  activityKey,
  handleChangeActivityKey,
}) => {
  const [isMaximized, setIsMaximized] = useState(false);
  // SelectedFlightdetail hooks and states
  const selectedFlightDetails = useSelectedItemStore();
  const selectedFlightLeg = selectedFlightDetails?.data;
  const summaryPanelRef = useRef(null);

  //call groundEventsSummary hook
  const groundEventData = useGroundEventsSummary(selectedFlightDetails);

  const selFlightLegParams = selectedFlightLeg && {
    airline: selectedFlightLeg?.airline,
    origin: selectedFlightLeg?.orig,
    destination: selectedFlightLeg?.dest,
    flightNumber: selectedFlightLeg?.flightNumber,
    departureCount: selectedFlightLeg?.departureCount,
    scheduledOperatingDate: formatDateTime(selectedFlightLeg?.departureDate, DateFormat.DEFAULT),
  };

  // call getFlightSummary endpoint from AirTrakManagerApi
  const { isLoading: summaryLoading, data: summaryData } = useFlightSummary(selFlightLegParams);

  const [flightSummaryData, setFlightSummaryData] = useState({});

  const disableSummaryExpandButton = () => {
    if (selectedFlightLeg === null) {
      return 'disabledExpandIcon';
    }
    return '';
  };

  useEffect(() => {
    const height = summaryPanelRef.current ? summaryPanelRef.current.getBoundingClientRect().height : 0;
    onChangedSummaryPanelHeight(height);
  }, [summaryPanelMode]);

  const handleChangeSummaryPanelMode = () => {
    setIsMaximized(false);
    onChangeSummaryPanelMode();
    handleChangeActivityKey(summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? ActiveKeys.Flight : null);
  };

  useEffect(() => {
    let result = getSummaryInfo(selectedFlightDetails, groundEventData, summaryLoading, summaryData);
    setFlightSummaryData(result);
  }, [summaryLoading, summaryData, groundEventData, selectedFlightDetails, selectedFlightDetails.data]);

  return (
    <div
      data-cy="summary-panel-footer"
      className={`summary-panel-footer ${isMaximized ? 'maximized' : ''}`}
      ref={summaryPanelRef}
    >
      <div className="details">
        {summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? (
          <MiniSummaryContainer data={flightSummaryData}></MiniSummaryContainer>
        ) : (
          <div>
            <SummaryDetailPaneContainer
              onClick={handleChangeSummaryPanelMode}
              activityKey={activityKey}
              handleChangeActivityKey={handleChangeActivityKey}
              onMaximize={() => setIsMaximized(!isMaximized)}
              isMaximized={isMaximized}
            />
          </div>
        )}
      </div>
      <div className={`icon-bar ${disableSummaryExpandButton()}`}>
        {summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? (
          <FontAwesomeIcon
            data-cy="summary-panel-expand"
            className="summary-panel-expand"
            onClick={handleChangeSummaryPanelMode}
            icon={faUpRightAndDownLeftFromCenter}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
SummaryPanel.propTypes = {
  onChangedSummaryPanelHeight: PropTypes.func.isRequired,
  handleChangeActivityKey: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(SummaryPanel);
