import { SplitFactory } from '@splitsoftware/splitio-react';
import { useAuthentication } from '../AuthenticationContext/AuthenticationContext';
import appSettings from '../../appSettings';

/**
 * The SplitFactoryProvider initializes the Split.io SDK,
 * all child components passed will have access to the SDK functionality
 * @param {{ children: JSX.Element}} [props.children]
 * @returns - renders children component with access to the SDK functionality
 */
function SplitFactoryProvider({ children }) {
  const { userData: openIdProfile } = useAuthentication();

  const splitSdkConfig = {
    core: {
      authorizationKey: appSettings.SPLIT_FEATURE_FLAGGING_KEY,
      key: openIdProfile.profile?.email?.toLowerCase(),
    },
  };

  return (
    <SplitFactory data-cy="split-factory" config={splitSdkConfig}>
      {children}
    </SplitFactory>
  );
}

export { SplitFactoryProvider };
