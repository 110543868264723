import { useDispatch } from 'react-redux';
import { HideCancelledFlightsActions } from '../../redux/actionTypes';
import { useCallback } from 'react';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Custom hook for retrieving hideCancelledFlightsReducer from the hide store
 * @returns selector hook for retrieving hideCancelledFlightsReducer
 */
export const useHideStore = () => {
  return useSelector((state) => state.hideCancelledFlightsReducer);
};

/**
 * Custom hook for dispatching actions to hide cancelled flights
 * @returns object of dispatch actions to interact with the hide store
 */
export const useHideCancelledFilterDispatch = () => {
  const dispatch = useDispatch();

  const updateHideCancelledFilter = useCallback(
    (filter) => {
      dispatch({ type: HideCancelledFlightsActions.TOGGLE_HIDE_CANCELLED_FLIGHTS, payload: { ...filter } });
    },
    [dispatch],
  );

  return { updateHideCancelledFilter };
};
