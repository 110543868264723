import { ConfigFileAction } from '../actionTypes';
// initial state values
const initialState = {
  openSaveConfig: false,
  activeConfigurationId: null,
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const configReducer = (state = initialState, action) => {
  // graduate to SWITCH statement when there are more actiontypes available for this reducer
  switch (action.type) {
    case ConfigFileAction.UPDATE_SAVECONFIG:
      return {
        ...state,
        openSaveConfig: action.payload.openSaveConfig ?? initialState.openSaveConfig,
      };
    case ConfigFileAction.UPDATE_ACTIVE_CONFIGURATION_ID:
      return {
        ...state,
        activeConfigurationId: action.payload.activeConfigurationId ?? initialState.activeConfigurationId,
      };
    default:
      return state;
  }
};

export default configReducer;
