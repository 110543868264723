import ModernFlightPuck from './ModernFlightPuck/ModernFlightPuck';
import ClassicFlightPuck from './ClassicFlightPuck/ClassicFlightPuck';
import { useThemeContext } from '../../../contexts/ThemeContext/ThemeContext';
import { ThemeMode } from '../../../lib/constants';

/**
 * Renders the flight puck based on user's theme
 * @param {*} props
 * @returns
 */
export default function FlightPuck(props) {
  const { currentTheme } = useThemeContext();
  if (currentTheme === ThemeMode.CLASSIC) {
    return <ClassicFlightPuck {...props} />;
  } else {
    return <ModernFlightPuck {...props} />;
  }
}
