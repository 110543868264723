import React from 'react';
import PropTypes from 'prop-types';
import TimeDisplay from '../../../../Shared/TimeDisplay/TimeDisplay';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import '../../FlightDetailPane.css';
import '../FlightTab.css';

/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * Displays the utc and local time of a given event at a specified station
 * @param {string} station - Station of the event (e.g arrival station)
 * @param {string} actualTime - Time of the event
 * @param {string} scheduledTime - Scheduled time of the event
 * @param {string} dataCyTag - tag used for integration testing
 * @param {string} label - used to label date difference display
 * @returns MiniInfoBlock -
 */
const MiniInfoBlock = ({ station, actualTime, scheduledTime, delayTime, dataCyTag = '' }) => {
  // Default display value
  const defaultDisplay = <>&#8212;</>;

  return (
    <div>
      <div className="basic-cell" data-cy={`${dataCyTag}-mini-info-block`}>
        <TimeDisplay utcTime={actualTime} dataCyTag={dataCyTag} delayTime={delayTime} />
        {actualTime != null && station != null ? (
          <>
            {' '}
            &nbsp;/&nbsp;
            <TimeDisplay
              utcTime={actualTime}
              dataCyTag={dataCyTag}
              station={station}
              comparisonUtcTime={scheduledTime}
              label="mins"
              delayTime={delayTime}
            />
          </>
        ) : (
          <div className="basic-cell-left" data-cy="mini-info-local-time">
            {' '}
            / {defaultDisplay}
          </div>
        )}
      </div>
    </div>
  );
};

MiniInfoBlock.propTypes = {
  station: PropTypes.string,
  actualTime: PropTypes.string,
  scheduledTime: PropTypes.string,
  dataCyTag: PropTypes.string,
  label: PropTypes.string,
};

export default withAppInsightsTracking(MiniInfoBlock);
