import { useQuery } from 'react-query';
import { getAirports } from '../../services/apiClient/airportsApi/aiportsApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useAirportsQuery';

/**
 * Custom hook for retrieving data from the Airports API
 */
export const useAirportsQuery = () => {
  const config = {
    // Setting stale time to 5 minutes because airports data rarely changes.
    // This means that when any component mounts using this hook within the "fresh" time,
    // it will not fetch new data.
    retry: appSettings.RETRY_QUERY_DELAY,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getAirports', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['airports'], getAirports, config);
};
