import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ReactComponent as CheckmarkChecked } from '../../../../../../assets/images/checkmark-checked.svg';
import { ReactComponent as CheckmarkUnchecked } from '../../../../../../assets/images/checkmark-unchecked.svg';
import './CrewTable.css';

const CrewTable = ({ flightInfo, crewInfoList, hasQualifications = false, columnNames = [] }) => {
  const displayHeader = crewInfoList?.length > 0;
  crewInfoList = crewInfoList || [];
  return (
    <Grid container spacing={2} style={{ padding: '1rem' }} className="crew-tab-info">
      <Grid item xs={12} style={{ color: flightInfo ? '' : '--dark-secondary' }} className="flight-info">
        FLIGHT <span style={{ width: '3.5rem' }}>{flightInfo?.flightNumber}</span>&nbsp; DEPARTURE STATION |{' '}
        <span style={{ width: '1rem' }}>{flightInfo?.departureStation}</span>
      </Grid>
      <Table sx={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
            {columnNames.map((columnName, index) => (
              <TableCell key={index} align="left" style={{ height: '3.5rem' }}>
                {displayHeader && columnName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(crewInfoList).map((crewInfo, index) => (
            <TableRow
              key={`${flightInfo?.flightNumber}-${crewInfo.crewName}`}
              data-cy={`${flightInfo?.flightNumber}-${index}`}
            >
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.position}
              </TableCell>
              <TableCell align="left" break autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.crewName}
              </TableCell>
              {'sameCrewOnNextFlight' in crewInfo && (
                <TableCell align="left" autoWidth>
                  {crewInfo.sameCrewOnNextFlight ? (
                    <CheckmarkChecked className="checkmark-checked" />
                  ) : (
                    <CheckmarkUnchecked className="checkmark-unchecked" />
                  )}
                </TableCell>
              )}
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.tripDisplay}
              </TableCell>
              {hasQualifications && (
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {crewInfo.qualificationsDisplay}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

CrewTable.propTypes = {
  flightInfo: PropTypes.object.isRequired,
  crewInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasQualifications: PropTypes.bool,
  columnNames: PropTypes.arrayOf(PropTypes.string),
};

export default CrewTable;
