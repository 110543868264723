import { MultiSelectedFlightGroup } from '../actionTypes';

const initialState = {
  flightLegGroup: {},
};

/**
 * @param {Object} state - list of multi selected flight legs object
 * @param {Object} action - action payalod object
 * @returns state for the Multi selected store
 */
const multiSelectedFlightGroupReducer = (state = initialState, action) => {
  const flightLegGroup = state.flightLegGroup;
  const sel_FlightLeg = action.payload;
  switch (action.type) {
    case MultiSelectedFlightGroup.UPDATE:
      if (sel_FlightLeg?.aircraftRegistration) {
        flightLegGroup[sel_FlightLeg?.aircraftRegistration] = sel_FlightLeg?.flightLegs;
      }
      return {
        flightLegGroup: flightLegGroup,
      };
    case MultiSelectedFlightGroup.REMOVE:
      const filterFlightLegGroup = Object.keys(state.flightLegGroup)
        ?.filter((key) => key != action.payload?.aircraftRegistration)
        .reduce((obj, key) => {
          obj[key] = state.flightLegGroup[key];
          return obj;
        }, {});
      return {
        flightLegGroup: filterFlightLegGroup,
      };
    case MultiSelectedFlightGroup.REMOVEALL:
      return {
        flightLegGroup: {},
      };
    case MultiSelectedFlightGroup.UPDATE_AIRCRAFT:
      const { oldAircraftRegistration, newAircraftRegistration } = action.payload;
      if (!flightLegGroup[oldAircraftRegistration]) {
        return state;
      }
      const flightLegs = flightLegGroup[oldAircraftRegistration].map((f) => ({
        ...f,
        aircraft: newAircraftRegistration,
      }));
      const updatedGroup = Object.assign({}, flightLegGroup, { [newAircraftRegistration]: flightLegs });
      delete updatedGroup[oldAircraftRegistration];
      return {
        flightLegGroup: updatedGroup,
      };
    case MultiSelectedFlightGroup.SWAP_AIRCRAFT:
      const { aircraftRegistration1, aircraftRegistration2 } = action.payload;
      if (!flightLegGroup[aircraftRegistration1] || !flightLegGroup[aircraftRegistration2]) {
        return {
          ...state,
        };
      }
      const flightLegs1 = flightLegGroup[aircraftRegistration1].map((f) => ({
        ...f,
        aircraft: aircraftRegistration2,
      }));
      const flightLegs2 = flightLegGroup[aircraftRegistration2].map((f) => ({
        ...f,
        aircraft: aircraftRegistration1,
      }));
      const updatedGroup2 = Object.assign({}, flightLegGroup, { [aircraftRegistration1]: flightLegs2 });
      updatedGroup2[aircraftRegistration2] = flightLegs1;
      return {
        flightLegGroup: updatedGroup2,
      };
    default:
      return state;
  }
};

export default multiSelectedFlightGroupReducer;
