import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';

/**
 * @description Custom hook that calculates
 * @param {Array} approvedRoles - Array of role names that are approved to render the child elements.
 * @param {Array} approvedEntitlements - Array of entitlement names that are approved to render the child elements.
 * @param {Array} deniedRoles - Array of role names that are denied from rendering the child elements.  Denied roles supersede Approved roles.
 * @returns {Boolean} - Does the user have access?
 */
const useAuthorizationAccess = (
  approvedRoles = [],
  approvedEntitlements = [],
  deniedRoles = [],
  approvedAirline = '',
) => {
  const { roleAssignments } = useRoleAssignmentContext();
  const [isAccessGranted, setIsAccessGranted] = useState(false);

  ////////////////////////////////////////////////////////////////////////////
  // Initialization of null or undefined props

  // Default approvedRoles parameter to empty array if it is an unexpected value.
  if (approvedRoles == null || typeof approvedRoles === 'undefined') {
    approvedRoles = [];
  }
  // Default approvedEntitlements parameter to empty array if it is an unexpected value.
  if (approvedEntitlements == null || typeof approvedEntitlements === 'undefined') {
    approvedEntitlements = [];
  }
  // Default deniedRoles parameter to empty array if it is an unexpected value.
  if (deniedRoles == null || typeof deniedRoles === 'undefined') {
    deniedRoles = [];
  }
  // Default approvedAirline to  empty string if it is an unexpected value.
  if (approvedAirline == null || typeof approvedAirline === 'undefined') {
    approvedAirline = '';
  }

  useEffect(() => {
    if (roleAssignments.loading) return;

    let newAccess = false;

    // Is the user's current role in the list of approved roles?
    const isRoleApproved =
      (approvedRoles.length > 0 && approvedRoles.includes(roleAssignments.role.name)) || approvedRoles.length === 0;

    // Is the user's current role's entitlements in the list of approved entitlements?
    const isEntitlementApproved =
      (approvedEntitlements.length > 0 &&
        approvedEntitlements.some((e) => roleAssignments.entitlements.some((rae) => rae.name === e))) ||
      approvedEntitlements.length === 0;

    // Is the user's current role in the list of roles that are denied access?
    const isRoleDenied = deniedRoles.length > 0 && deniedRoles.includes(roleAssignments.role.name);

    // Does the user role's operatingAirline match approvedAirline?
    const isAirlineApproved =
      (approvedAirline.length > 0 && approvedAirline === roleAssignments.role?.operatingAirline) ||
      approvedAirline.length === 0;

    // Should the user have access?
    newAccess = isRoleApproved && isEntitlementApproved && isAirlineApproved;

    // Should the user be denied access? Only need to check if the user is going to be given access.
    // Having a matching Denied role overrides any access they might otherwise have.
    newAccess = newAccess ? !isRoleDenied : newAccess;

    // Set new access value.
    setIsAccessGranted(newAccess);
  }, [roleAssignments, approvedRoles, approvedEntitlements, deniedRoles, approvedAirline]);

  return isAccessGranted;
};

useAuthorizationAccess.propTypes = {
  approvedRoles: PropTypes.arrayOf(PropTypes.string),
  approvedEntitlements: PropTypes.arrayOf(PropTypes.string),
  deniedRoles: PropTypes.arrayOf(PropTypes.string),
  approvedAirline: PropTypes.string,
};

export default useAuthorizationAccess;
