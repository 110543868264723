import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAirportsQuery } from '../../hooks/useAirportsQuery/useAirportsQuery';

const AirportsContext = createContext(null);

const AirportsProvider = ({ children }) => {
  //Fetch airport details
  const { isLoading: isLoadingAirportsData, data: airportQueryData, error: airportDataError } = useAirportsQuery();

  const [airportData, setAirportData] = useState({});

  /**
   * Side-effect to create the dictionary for the airport data.
   */
  useEffect(() => {
    if (!isLoadingAirportsData && airportQueryData && airportQueryData.data) {
      let keyValuePair = {};
      airportQueryData.data.forEach((item) => {
        keyValuePair[item.airportCode] = item;
      });
      setAirportData(keyValuePair);
    }
  }, [isLoadingAirportsData, airportQueryData]);

  /**
   * Side-effect to log when airportDataError changes
   */
  useEffect(() => {
    if (airportDataError == null || typeof airportDataError === 'undefined') return;

    console.error('AirportsProvider => useAirportsQuery() Error:');
    console.error(airportDataError);
  }, [airportDataError]);

  const airportDetail = (airportCode) => {
    if (airportCode == null || typeof airportCode === 'undefined') return null;

    return !isLoadingAirportsData && Object.keys(airportData).length > 0 ? airportData[airportCode] : null;
  };

  const airportsContextData = {
    isLoading: isLoadingAirportsData,
    getAirportDetail: airportDetail,
  };

  return <AirportsContext.Provider value={airportsContextData}>{children}</AirportsContext.Provider>;
};

/**
 * @description Custom hook to provide function to fetch timezone info for a particular airport code
 * */
const useAirportsContext = () => {
  const context = useContext(AirportsContext);
  if (!context) {
    throw new Error(
      'useAirportsContext must be used within a AirportsProvider. Wrap a parent component in <AirportsProvider> to fix this error.',
    );
  }
  return context;
};

export { AirportsProvider, useAirportsContext };
