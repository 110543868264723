import { SplitContext, useManager, useTreatments } from '@splitsoftware/splitio-react';
import { useContext, createContext, useCallback, useMemo } from 'react';
import { logError } from '../../lib/appInsightsUtils';

const FeatureFlagContext = createContext(null);
const moduleName = 'FeatureFlagContext';
/**
 * The FeatureFlagProvider initializes feature flagging check
 * and allows lower components to check a given feature flag value.
 * @param {{ children: JSX.Element}} [props.children]
 * @returns - renders children with showFeature callback function to check feature flag value
 */
function FeatureFlagProvider({ children }) {
  const { isReady, client } = useContext(SplitContext);
  const manager = useManager();

  client.once(client.Event.SDK_READY_TIMED_OUT, function () {
    // Log the error message when SDK times out.
    logError('Unable to initialize Split SDK', moduleName, 'FeatureFlagProvider');
  });

  // list of all flags and their treatments (on/off)
  const treatmentNames = useMemo(() => (isReady && manager ? manager.names() : []), [isReady, manager]);
  const treatments = useTreatments(treatmentNames);

  /**
   * Returns whether the given feature flag treatment is on or off
   * @param {string} treatmentName - the treatment name from Treatment enum in constants.js
   * @returns {bool} - true if feature is on
   */
  const showFeature = useCallback(
    (treatmentName) => {
      return treatments[treatmentName]?.treatment === 'on';
    },
    [treatments],
  );

  const value = useMemo(() => ({ showFeature, isReady }), [showFeature, isReady]);

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}

/**
 * Custom hook for checking whether a given feature flag treatment is on or off
 * @returns {Object} - context value object
 *   - showFeature: callback that returns a bool for whether the given feature flag treatment is on or off
 */
function useFeatureFlag() {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error(
      'useFeatureFlag must be used within a FeatureFlagProvider. Wrap a parent component in <FeatureFlagProvider> to fix this error.',
    );
  }
  return context;
}

export { FeatureFlagProvider, useFeatureFlag };
