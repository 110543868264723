import React, { useMemo } from 'react';
import TimelineBar from '../TimelineBar/TimelineBar';
import './TimelineContainer.css';
import { getUTCOffsetValue } from '../../../../lib/dateTimeUtils';

const SEA_TIMEZONE = 'America/Los_Angeles';
const ZULU_TIMEZONE = 'UTC';

/**
 * TimelineContainer container renders a list of timeline bars in multiple time zones.
 * @param props -
 *  - timelineStartDateTime - when the timeline starts
 *  - timelineHours - number of hours to display
 *  - oneHourRemWidth - the width in rem of one-hour of time
 *  - timeZoneStartDate - the UTC date to convert the timezone from
 * @param ref - a ref to attach to the timeline bars for scrolling
 */
const TimelineContainer = React.forwardRef((props, ref) => {
  const {
    timelineStartDateTime,
    timelineHours,
    oneHourRemWidth,
    timeZoneStartDate,
    hoursToDisplay,
    onDateTimeChanged,
    tailNumberIndicatorsFlag,
    extendedStartTimeHours,
  } = props;

  /*
  Eventually, this should reflect a user's selection
  One could also use dayjs.tz.guess() to infer a user's timezone

  List of valid timezones
  https://stackoverflow.com/questions/38399465/how-to-get-list-of-all-timezones-in-javascript
  */
  const dstIndicator = useMemo(
    () => (getUTCOffsetValue(timeZoneStartDate, SEA_TIMEZONE) === -8 ? 'PST' : 'PDT'),
    [timeZoneStartDate],
  );
  const localTitle = 'SEA (' + dstIndicator + ')';

  let timezoneLabelClasses = 'timeline-timezone-label';
  if (tailNumberIndicatorsFlag) {
    timezoneLabelClasses += ' tail-number-indicators';
  }

  return (
    <>
      <div className="timeline-timezone-label-container">
        <div className={timezoneLabelClasses}>Zulu</div>
        <div className={timezoneLabelClasses}>{localTitle}</div>
      </div>
      <div className="timeline-container" ref={ref}>
        <div className="timeline-inner-container">
          <TimelineBar
            timelineStartDateTime={timelineStartDateTime}
            timelineHours={timelineHours}
            oneHourRemWidth={oneHourRemWidth}
            timeZone={ZULU_TIMEZONE}
            hoursToDisplay={hoursToDisplay}
            onDateTimeChanged={onDateTimeChanged}
            timelineContainerRef={ref}
            extendedStartTimeHours={extendedStartTimeHours}
          />
          <TimelineBar
            timelineStartDateTime={timelineStartDateTime}
            timelineHours={timelineHours}
            oneHourRemWidth={oneHourRemWidth}
            timeZone={SEA_TIMEZONE}
            hoursToDisplay={hoursToDisplay}
            extendedStartTimeHours={extendedStartTimeHours}
          />
        </div>
      </div>
    </>
  );
});

export default TimelineContainer;
