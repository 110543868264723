import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../../../../lib/dateTimeUtils';
import './SummaryActivityLogDetail.css';

/**
 * SummaryActivityLogDetail renders the details of an activity log.
 * @param {string} activityDateTime - date and time of the activity log
 * @param {string} activityNote - note of the activity log
 * @param {string} activityTag - tag for the actiity e.g. WatchFlight
 * @returns
 */
const SummaryActivityLogDetail = ({ activityDate, activityDateTime, activityNote, activityTag }) => {
  return (
    <div className="flight-activity-details">
      <span className="activity-log-date" data-cy="activity-log-date">
        {activityDate}
      </span>
      <span className="activity-log-time" data-cy="activity-log-time">
        {formatDateTime(activityDateTime, 'hh:mm A')}
      </span>
      <span className="activity-log-text" data-cy="activity-log-text">
        {activityNote}
      </span>
      <span className="activity-log-actiontag" data-cy="activity-log-actiontag">
        {activityTag}
      </span>
    </div>
  );
};

SummaryActivityLogDetail.propTypes = {
  activityDate: PropTypes.string,
  activityDateTime: PropTypes.string,
  activityNote: PropTypes.string,
  activityTag: PropTypes.string,
};

export default SummaryActivityLogDetail;
