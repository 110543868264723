import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { KeyCodes } from '../../../../lib/constants';
import './ChipInput.css';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    inputRoot: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'flex-start',
    },
    input: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      appearance: 'none',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      float: 'left',
      flex: 1,
    },
    chipContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      cursor: 'text',
    },
    focused: {},
    disabled: {},
  };
});

/**
 * ChipInput is a Controlled Component and renders an input control along with chips added
 * @param {array} displayChips - array of chips to display on the control
 * @param {func} onChipClick - callback funciton when the user clicks on a chip
 * @param {func} onChipAdd - callback function when a new chip is being aded by the user
 * @param {func} onChipDelete - callback function to when the chip is deleted by user
 * @param {string} textInput - text on the input to display on the control
 * @param {func} onTextChange - callback function when user enters input or input text changes
 * @param {func} onTextKeyDown - callback function when keypress on the text input
 * @param {func} onInputFocus - callback function when input function comes into focus
 * @param {func} onInputBlur - callback function when the input is blur
 * @param {string} dataCyTag - test id attribute
 * @param {string} className - an additional custom class name
 * @param {bool} isDisabled - to set if the control is enabled or disabled
 * @param {bool} isFullWidth - boolean to set if the control has to be full width of the parent
 * @param {bool} isReadOnly - boolean to set the control to ReadOnly
 */
const ChipInput = ({
  displayChips = [],
  onChipClick = () => {},
  onChipAdd = () => {},
  onChipDelete = () => {},
  textInput = '',
  onTextChange = () => {},
  onTextKeyDown = () => {},
  onInputFocus = () => {},
  onInputBlur = () => {},
  dataCyTag = 'ci',
  className = '',
  isDisabled = false,
  isFullWidth = true,
  isReadOnly = false,
}) => {
  const classes = useStyles();

  if (displayChips === null) {
    displayChips = [];
  }

  let inputMore = {};

  /**
   * @description handles the Chip delete command click from the user and passes calls handleChipDelete from parent with appropriate chip text
   * @param {e} click event on delete icon on the chip
   */
  const handleChipDelete = (e) => {
    let chip = e.target?.parentElement?.innerText;
    if (chip == null || e.target.nodeName === 'path') chip = e.target?.parentElement?.parentElement?.innerText;

    onChipDelete(chip);
  };

  /**
   * @description gets text change event from Text input field and calls OnTextChange parent event handler with text only
   * @param {e} onChange event from text input field
   */
  const handleTextInputChange = (event) => {
    onTextChange(event.target.value);
  };

  /**
   * @description handles the text keydown event from input text field and triggers appropriate parent handler whether it is add or delete or simply onTextKeyDown handler
   * @param {e} onKeyDown event from text input field
   */
  const handleOnTextKeyDown = (event) => {
    let addChipKeyCode = event.keyCode === KeyCodes.ENTER || (event.keyCode === KeyCodes.SPACE && !event.shiftKey);

    if (addChipKeyCode && textInput.length > 0) {
      onChipAdd(textInput);
    } else if (event.keyCode === KeyCodes.BACKSPACE && textInput === '' && displayChips.length > 0) {
      onChipDelete(displayChips[displayChips.length - 1]);
    }
    onTextKeyDown(event);
  };

  // Buildling necessary material chip components to be used as startAdornment on the input control.
  const chipComponents = displayChips?.map((chip, i) => {
    return (
      <Chip
        key={chip.replace(/\s+/g, '')}
        style={{
          pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
        }}
        onClick={onChipClick}
        deleteIcon={<FontAwesomeIcon data-cy={dataCyTag + '-dc'} icon={faTimesCircle} />}
        onDelete={handleChipDelete}
        label={chip}
      />
    );
  });

  inputMore.startAdornment = <React.Fragment>{chipComponents}</React.Fragment>;

  return (
    <div className={`chipinput ${classes.chipContainer}  ${isDisabled ? classes.disabled : ''}`}>
      <OutlinedInput
        classes={{
          input: `${classes.input} ${className}`,
          root: `${classes.inputRoot} ${className}`,
        }}
        data-cy={dataCyTag}
        value={textInput}
        onChange={handleTextInputChange}
        onKeyDown={handleOnTextKeyDown}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        disabled={isDisabled}
        fullWidth={isFullWidth}
        placeholder={''}
        readOnly={isReadOnly}
        inputProps={{
          'data-cy': dataCyTag + '-input',
        }}
        {...inputMore}
      />
    </div>
  );
};

ChipInput.propTypes = {
  displayChips: PropTypes.array,
  onChipClick: PropTypes.func,
  onChipAdd: PropTypes.func,
  onChipDelete: PropTypes.func,
  textInput: PropTypes.string,
  onTextChange: PropTypes.func,
  onTextKeyDown: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  dataCyTag: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ChipInput;
