import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const API_ROUTE = 'assignment';
const moduleName = 'assignmentApi';

export const getAirlineAssignments = async (airlineCode) => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/assignments/airline/${airlineCode}`;
  const response = await apiClient.get(url);
  let result = response;
  return result;
};

/**
 * @param {Array} roleIds - an array of integer
 */
export const getRoleAssignments = async (roleIds) => {
  const apiClient = axiosInstance;
  let queryParam = roleIds.map((role) => `roleId=${role}`).join('&');
  const url = `${API_ROUTE}/api/v2/assignments/roles?${queryParam}`;
  const response = await apiClient.get(url);
  let result = response;
  return result;
};

export const createUserAssignments = async (selectedRoleID, selectedAssignments) => {
  const apiClient = axiosInstance;

  let assignmentIds = [];

  if (selectedAssignments.length > 0) {
    assignmentIds = selectedAssignments.map((assignment) => {
      return 'assignmentGroupKeys=' + assignment.assignmentID;
    });
  } else {
    return 'No Assignments - no user assigned flights will be added';
  }

  const assignmentString = assignmentIds.join('&');

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments/flights?roleKey=${selectedRoleID}&${assignmentString}`;
    const response = await apiClient.post(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'createUserAssignments', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

export const endUserAssignments = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments/flights/endAssignments`;
    const response = await apiClient.put(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'endUserAssignments', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description - Returns active Flight and Aircraft assignments
 * @param {*} selectedRoleID - an integer
 * @param {Array} selectedAssignments - an array of integer
 */
export const postUserAssignments = async (selectedRoleID, selectedAssignments) => {
  const apiClient = axiosInstance;
  const filterAssignmentStart = dayjs.utc().subtract(1, 'day').format('YYYY-MM-DD');

  let assignmentGroupKeys = [];

  if (selectedAssignments.length > 0) {
    selectedAssignments.forEach((assignment) => {
      return assignmentGroupKeys.push(assignment.assignmentID);
    });
  } else {
    return 'No Assignments - no user assigned will be added';
  }

  const payload = {
    roleKey: selectedRoleID,
    assignmentGroupKeys,
  };

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments?filterAssignmentStart=${filterAssignmentStart}`;
    const response = await apiClient.post(url, payload);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'postUserAssignments', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Returns assignments with success or failure
 */
export const deleteUserAssignments = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/assignments`;
    const response = await apiClient.delete(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'deleteUserAssignments', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
