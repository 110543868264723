let config = window._env_ == null ? process.env : window._env_;
const appSettings = {
  OIDC_PROVIDER: config.OIDC_PROVIDER,
  OIDC_CLIENT_ID: config.OIDC_CLIENT_ID,
  OIDC_REDIRECT_ROUTE: config.OIDC_REDIRECT_ROUTE,
  OIDC_END_SESSION_ENDPOINT: config.OIDC_END_SESSION_ENDPOINT,
  APPINSIGHTS_INSTRUMENTATIONKEY: config.APPINSIGHTS_INSTRUMENTATIONKEY,
  REFRESH_INTERVAL: parseInt(isNaN(config.REFRESH_INTERVAL) ? '60000' : config.REFRESH_INTERVAL, 10),
  TEAM_MESSAGING_BASE_URL: config.TEAM_MESSAGING_BASE_URL,
  SPLIT_FEATURE_FLAGGING_KEY: config.SPLIT_FEATURE_FLAGGING_KEY,
  INACTIVITY_TIMEOUT: { WARNING_TIMEOUT: 7200000, COUNTDOWN_TIMEOUT: 300000 }, // PROVIDES TIMES IN MILLI-SECONDS e.g 1000 FOR 1 SECOND,
  ALASKA_APIM_BASE_URL: config.ALASKA_APIM_BASE_URL,
  RETRY_QUERY_DELAY: 3,
  environment: config.environment,
  ADO_PIPELINE_BUILD: config.ADO_PIPELINE_BUILD,
  APP_DYNAMICS_KEY: config.APP_DYNAMICS_KEY,
  APP_DYNAMICS_EXT_URL: config.APP_DYNAMICS_EXT_URL,
  APP_DYNAMICS_BEACON: config.APP_DYNAMICS_BEACON,
};
export default appSettings;
