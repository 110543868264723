import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { VictoryTooltip, VictoryPie, VictoryLegend, VictoryLabel, Slice } from 'victory';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { useHistory } from 'react-router-dom';
import { useFilterDispatch } from '../../../../hooks/useFilterStore/useFilterStore';
import { getDefaultFilter } from '../../../../lib/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getFormattedLabelText, getAirlineName, getColorSpectrumForPieChart } from '../../../../lib/displayUtils';
dayjs.extend(utc);

const FlightCountByAlertChart = ({ alertTypes, operatingAirline, flightData }) => {
  const [chartData, setChartData] = useState([]);
  const [legendLabelData, setLegendLabelData] = useState([]);
  const [colorScale, setColorScale] = useState([]);
  const [totalFlightCount, setTotalFlightCount] = useState(0);

  //History hooks to redirect to a new page
  const history = useHistory();

  //Hook to get default filter object
  const filter = getDefaultFilter();

  //Hook to get update the filter store object
  const { updateFilter } = useFilterDispatch();

  /**
   * @description Maps flight data into data format required to render pie chart
   */
  const mapDisplayChartData = () => {
    const airlineData = flightData.filter((x) => x.airline === getAirlineName(operatingAirline));

    let mappedChartData = [];

    let totalCount = 0;

    if (airlineData.length > 0) {
      mappedChartData = alertTypes.flatMap((alertType) => {
        let count = airlineData.filter((a) =>
          a.notifications.find((n) => n.notificationName === alertType.name),
        ).length;

        totalCount = totalCount + count;

        if (count === 0) return [];
        return {
          x: alertType.name,
          y: count,
          label: `${getFormattedLabelText(alertType.title)}: ${count}`,
          legendLabel: alertType.title,
        };
      });
    } else {
      mappedChartData = [
        {
          x: 'NoActiveAlert',
          y: 1,
          label: 'No Active Alerts',
          legendLabel: 'No Active Alerts',
        },
      ];
    }

    let labelData = mappedChartData.map((obj) => {
      return {
        name: obj.legendLabel,
      };
    });

    setChartData(mappedChartData);
    setLegendLabelData(labelData);
    setTotalFlightCount(totalCount);
    setColorScale(getColorSpectrumForPieChart(totalCount === 0));
  };

  useEffect(() => {
    mapDisplayChartData();
  }, [flightData, alertTypes]);

  const onSliceClickHandler = () => {
    return [
      {
        mutation: (props) => {
          if (props.datum.xName !== 'NoActiveAlert') {
            filter.alerts = [props.datum.xName];
            filter.airline = [operatingAirline];
            filter.startDate = dayjs.utc().startOf('day');
            filter.endDate = dayjs.utc(filter.startDate).add(1, 'd');

            //Update filter values based on alert slice click
            updateFilter(filter);

            //Redirect to gantt view page
            history.push('/flights?view=gantt');
          }
        },
      },
    ];
  };

  return (
    chartData && (
      <div data-cy={`flight-count-by-alert-chart-${getAirlineName(operatingAirline)}`}>
        <svg viewBox="0 25 400 330">
          <VictoryPie
            events={[{ eventHandlers: { onClick: onSliceClickHandler } }]}
            standalone={false}
            data={chartData}
            colorScale={colorScale}
            innerRadius={60}
            height={380}
            width={400}
            animate={{ duration: 500 }}
            style={{
              labels: {
                fontSize: 12,
                fontFamily: 'var(--defaultFontFamily)',
              },
            }}
            labelRadius={({ innerRadius }) => innerRadius + 25}
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{
                  strokeWidth: 1,
                  stroke: 'var(--pie-chart-tooltip-border)',
                  fill: 'var(--pie-chart-tooltip-background)',
                }}
                pointerLength={5}
                cornerRadius={0}
                flyoutHeight={35}
                style={{
                  fill: 'var(--pie-chart-tooltip-text)',
                  fontSize: 10,
                  fontWeight: 'bold',
                  fontFamily: 'var(--defaultFontFamily)',
                }}
                renderInPortal={false}
                labelComponent={<VictoryLabel className="alert-chart-tooltip-label" />}
              />
            }
            dataComponent={<Slice className={`alert-chart-slice${totalFlightCount === 0 ? '-inactive' : ''}`} />}
          />

          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 30, fontFamily: 'var(--defaultFontFamily)' }}
            x={200}
            y={190}
            text={totalFlightCount}
          />
        </svg>

        <VictoryLegend
          colorScale={colorScale}
          data={legendLabelData}
          x={20}
          y={0}
          orientation="horizontal"
          gutter={35}
          height={100}
          style={{ labels: { fontFamily: 'var(--defaultFontFamily)' } }}
          itemsPerRow={2}
          symbolSpacer={10}
        />
      </div>
    )
  );
};

FlightCountByAlertChart.propTypes = {
  alertTypes: PropTypes.array.isRequired,
  operatingAirline: PropTypes.number.isRequired,
  flightData: PropTypes.array.isRequired,
};

export default withAppInsightsTracking(FlightCountByAlertChart);
