import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import './RenameViewModal.css';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import TextInput from '../../../Shared/Inputs/TextInput/TextInput';
import BrickContainer from '../../../Shared/BrickContainer/BrickContainer';
import { useWindowManagerStore } from '../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { KeyCodes } from '../../../../lib/constants';
import PropTypes from 'prop-types';

/**
 * The RenameViewModal component is to rename a specific view from the different views available.
 * @param {Object} props -
 *  showConfirmPopup - show/hide confirm modal
 *  handleCancelClick - callback func when cancel button is pressed.
 *  handleApplyClick - callback func when apply button is pressed.
 *  handleOnKeyPress - callback func when any key is pressed in the modal.
 *  handleHideClick - callback func when close the rename modal.
 *  dataCyTag- data-cy Tag for button and rename modal
 *  placeholder- placeholder for the text area
 * setRenameTitle - callback func to set the rename title.
 * @returns RenameViewModal component
 */

const RenameViewModal = ({
  showConfirmPopup,
  handleCancelClick,
  handleApplyClick,
  handleOnKeyPress,
  handleHideClick,
  placeholder,
  setRenameTitle,
}) => {
  const [renameVal, setRenameVal] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const windowManagerStore = useWindowManagerStore();
  const { viewWindows } = windowManagerStore;

  useEffect(() => {
    if (!showConfirmPopup) {
      setRenameVal('');
      setErrorMessage('');
      setIsDisabled(false);
    }
  }, [showConfirmPopup]);

  const handleTextInputChange = (value) => {
    if (value.length && viewWindows.some((view) => view.viewTitle === value)) {
      setErrorMessage('A view with the same name already exists.');
      setIsDisabled(true);
      setRenameVal(value);
      return;
    }

    if (value.length > 75) {
      setErrorMessage('Name must be less than 75 characters long.');
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setRenameVal(value);
      setErrorMessage('');
      setRenameTitle(value);
    }
  };

  const enterOnKeyPress = (e) => {
    if (e.keyCode === KeyCodes.ENTER && isApplyEnabled()) {
      handleApplyClick();
    }
  };

  const isApplyEnabled = () => {
    return !isDisabled && renameVal.length > 0;
  };

  /* footer buttons for RenameView popup modal. */
  const formButtons = [
    <Button
      key={'rename-modal-cancel'}
      variant="secondary"
      tabIndex="0"
      data-cy="rename-view-cancel-button"
      onClick={handleCancelClick}
      onKeyDown={handleOnKeyPress}
      className={'rename-view-cancel-button'}
    >
      Cancel
    </Button>,
    <Button
      key={'rename-modal-apply'}
      variant="primary"
      tabIndex="0"
      data-cy="rename-view-apply-button"
      onClick={handleApplyClick}
      onKeyDown={enterOnKeyPress}
      isDisabled={!isApplyEnabled()}
      className={'rename-view-apply-button'}
    >
      Apply
    </Button>,
  ];

  /* build modal body for RenameView popup modal. */
  const popupHTML = (
    <div className="rename-view-popup-modal">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BrickContainer title="Name" customCSSTag="title-input-container">
            <TextInput
              value={renameVal}
              onChange={handleTextInputChange}
              dataCyTag="rename-view-textarea"
              maxLength={75}
              warningType={errorMessage ? 'error' : 'none'}
              warningMessage={errorMessage}
            />
          </BrickContainer>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal
      show={showConfirmPopup}
      title={'Rename View'}
      body={popupHTML}
      size={'thin rename-view-popup'}
      footerButtons={formButtons}
      onHide={handleHideClick}
      customCSSTag={'rename-view-popup'}
      dataCyTag="rename-view-popup"
      bottomDivider={false}
    />
  );
};

RenameViewModal.propTypes = {
  showConfirmPopup: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleApplyClick: PropTypes.func.isRequired,
  handleOnKeyPress: PropTypes.func.isRequired,
  handleHideClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  setRenameTitle: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(RenameViewModal);
