import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import './TextAreaInput.css';
/**
 * TextAreaInput is a Controlled Component and renders a text area input.
 * @param {any} value - the value of this component
 * @param {string} placeholder - the short hint displayed in the input before the user enters a value.
 * @param {string} rows - number of rows to display when multiline
 * @param {bool} isDisabled - whether or not this input is disabled
 * @param {func} onChange - callback function when the input is updated
 * @param {func} onKeyUp - callback function when any key is pressed
 * @param {string} dataCyTag - test id attribute
 */
const TextAreaInput = ({
  value,
  placeholder = '',
  rows = 1,
  isDisabled = false,
  onChange = () => {},
  onKeyUp = () => {},
  dataCyTag = '',
  className = '',
}) => {
  const innerClassName = isDisabled ? 'textareafield disabled' : 'textareafield';
  const inputProps = {
    disabled: isDisabled,
  };

  if (dataCyTag) {
    inputProps['data-cy'] = dataCyTag;
  }
  if (className) inputProps['className'] = className;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <TextField
        id={dataCyTag}
        placeholder={placeholder}
        size="small"
        variant="outlined"
        className={innerClassName}
        onChange={handleOnChange}
        onKeyUp={onKeyUp}
        value={value}
        rows={rows}
        multiline={true}
        fullWidth={true}
        inputProps={inputProps}
      />
    </>
  );
};

export default TextAreaInput;

TextAreaInput.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  dataCyTag: PropTypes.string,
};
