import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';

const CrewTab = (crewTabDetails) => {
  //TODO: implement tab skeleton
  return <div>Crew Details</div>;
};

CrewTab.propTypes = {
  crewTabDetails: PropTypes.object,
};

export default withAppInsightsTracking(CrewTab);
