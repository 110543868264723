import React from 'react';
import './CrosshairButtons.css';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import ButtonGroup from '../../../Shared/ButtonGroup/ButtonGroup';
import CrosshairActiveButton from './CrosshairActiveButton/CrosshairActiveButton';
import CrosshairCountButton from './CrosshairCountButton/CrosshairCountButton';

/**
 * The CrossHairButtons component is crosshair icon and select to activate moving lines on gantt chart.
 * @returns CrossHairButtons component
 */
const CrosshairButton = () => {
  return (
    <ButtonGroup>
      <CrosshairActiveButton />
      <div className="crosshair-vertical-line" />
      <CrosshairCountButton />
    </ButtonGroup>
  );
};

export default withAppInsightsTracking(CrosshairButton);
