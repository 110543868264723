import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import FlightTabBlockTimes from '../FlightTabBlockTimes/FlightTabBlockTimes';
import InfoBlock from '../../../../FlightDetailPane/FlightTab/InfoBlock/InfoBlock';
import { withAppInsightsTracking } from '../../../../../../services/appInsightsFactory/appInsightsFactory';

const useStyles = makeStyles((theme) => ({
  firstRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '4.8rem',
    },
    [theme.breakpoints.only('md')]: {
      height: '4rem',
    },
  },
  secondRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '9.933rem',
    },
  },
  thirdRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '8.533rem',
    },
    [theme.breakpoints.only('xl')]: {
      height: 'auto',
    },
  },
  infoLinkItem: {
    marginRight: '1.5rem',
    float: 'right',
  },
}));

/** returns the block times and flight value index of the flight tab
// @param {Object} blockTimesDetails - block times details of the flight
// @param {Object} scoreDetails - score details of the 
// @param {bool} scoreLoading - loading state of the score details API
// @returns FlightTabBlockTimesContainer */
const FlightTabBlockTimesContainer = ({ blockTimesDetails, scoreDetails, scoreLoading }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container item spacing={1} className={classes.firstRowHeight}>
        <Grid item xs={7}>
          <div className="flight-header-row" data-cy="block-times-header">
            <span className="flight-header">BLOCK TIMES & FLIGHT VALUE INDEX</span>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.infoLinkItem}>
            <a
              className="info-link-item"
              data-cy="info-link"
              href="https://alaskaair.sharepoint.com/:w:/r/sites/Ops360Program/_layouts/15/Doc.aspx?sourcedoc=%7B7ab9cea3-4529-4093-9ba6-ceeffc50e0b9%7D&action=default&mobileredirect=true"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Get more info
              <FontAwesomeIcon className="external-link-icon" data-cy="external-link-icon" icon={faExternalLinkAlt} />
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1} className={classes.secondRowHeight} data-cy="block-time">
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="block-total">
            <InfoBlock label="Block Total">
              <FlightTabBlockTimes
                totalMinutes={blockTimesDetails?.blockTime?.totalMinutes ?? 0}
                delayMinutes={blockTimesDetails?.blockTime?.delayMinutes ?? 0}
                showBlockInMinutes={true}
              />
            </InfoBlock>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="flight-total">
            <InfoBlock label="Flight Time">
              <FlightTabBlockTimes
                totalMinutes={blockTimesDetails?.flightTime?.totalMinutes ?? 0}
                delayMinutes={blockTimesDetails?.flightTime?.delayMinutes ?? 0}
              />
            </InfoBlock>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1} className={classes.thirdRowHeight} data-cy="taxi-time">
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="taxi-out-time">
            <InfoBlock label="Taxi Out">
              <FlightTabBlockTimes
                totalMinutes={blockTimesDetails?.taxiOutTime?.totalMinutes ?? 0}
                delayMinutes={blockTimesDetails?.taxiOutTime?.delayMinutes ?? 0}
                itemLabel="Taxi Out"
              />
            </InfoBlock>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="taxi-in-time">
            <InfoBlock label="Taxi In">
              <FlightTabBlockTimes
                totalMinutes={blockTimesDetails?.taxiInTime?.totalMinutes ?? 0}
                delayMinutes={blockTimesDetails?.taxiInTime?.delayMinutes ?? 0}
              />
            </InfoBlock>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="revenue-score">
            <div className={'item-label'}>{'Revenue Score'}</div>
            {scoreLoading ? (
              <Skeleton />
            ) : (
              <div className={'basic-cell-left'}>{scoreDetails?.find((t) => t.type === 'Revenue')?.value ?? 'Err'}</div>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="guest-score">
            <div className={'item-label'}>{'Guest Score'}</div>
            {scoreLoading ? (
              <Skeleton />
            ) : (
              <div className={'basic-cell-left'}>{scoreDetails?.find((t) => t.type === 'Guest')?.value ?? 'N/A'}</div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

FlightTabBlockTimesContainer.propTypes = {
  blockTimesDetails: PropTypes.object,
  scoreDetails: PropTypes.object,
};
export default withAppInsightsTracking(FlightTabBlockTimesContainer);
