import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import StationPuck from '../../StationPuck/StationPuck';
import { onDropMoveFlights } from '../../../../lib/swapUtil';
import './SwapStationPuck.css';
import {
  useMultiSelectedFlightGroupStore,
  useMultiSelectedFlightGroupDispatch,
} from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders the station puck based on user's theme after swapMode is on
 * @param {*} props
 * @returns
 */
export default function SwapStationPuck(props) {
  const { flightLegGroup } = useMultiSelectedFlightGroupStore();
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
  const { updateMoveFlightLeg } = useSwapModeDispatch();
  const dragItem = useDragItemStore();

  const { data } = props;

  const { aircraft, index } = data;

  const [dropRef] = useDropItem({
    dropItemKey: `${aircraft}-${index}`,
    data,
    onDrop: () =>
      onDropMoveFlights(dragItem, flightLegGroup, aircraft, updateMoveFlightLeg, removeAllMultiSelectedFlightGroup),
  });

  return (
    <div ref={dropRef} className="swap-station-puck">
      <StationPuck {...props} />
    </div>
  );
}
