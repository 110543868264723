import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import './IrropsReasons.css';
import { useIrropsCodesQuery } from '../../../hooks/useIrropsCodesQuery/useIrropsCodesQuery';
import SearchableDropdown from '../../Shared/Inputs/SearchableDropdown/SearchableDropdown';
import { smartSearch } from '../../../lib/utils';

/**
 * The IrropsReasons component handles the Irrops Reasons dropdown.
 * @param {string} airlineCode - airline Code.
 * @param {string} irropsCode - irrops Code.
 * @param {func} onSelect - function to handle on select of reason.
 * @param {string} selectedReason - selected Reason.
 */

const IrropsReasons = ({
  airlineCode,
  irropsCode,
  onSelect,
  selectedReason,
  isAutoFocus = false,
  reasonsList = [],
  isRequired = true,
  isOpenList = false,
  className = '',
  fetchReasonsApi = true, // this is used to check if we need to call api to get reasons or not
}) => {
  const [reasons, setReasons] = useState(reasonsList.length === 0 ? [] : reasonsList);
  const [filterReasons, setFilterReasons] = useState(reasonsList.length === 0 ? [] : reasonsList);
  const { data: irropsReasonsData } = useIrropsCodesQuery(airlineCode, irropsCode, fetchReasonsApi);
  const commentsRequiredByAirline = {
    Airline: 'AS',
    Reason: 'Schedule Change',
    CommentTemplate: 'Describe details',
  };

  const isNullOrWhitespace = (value) => {
    if (value != null && value.toString().trim() !== '') {
      return false;
    }
    return true;
  };

  /**
   * @description Get the Irrops Reasons data using an api call
   * @returns an array of IrropsReasons
   */

  const irropsReasonsList = useMemo(() => {
    let result = [];
    if (isNullOrWhitespace(airlineCode) || isNullOrWhitespace(irropsCode)) {
      return;
    }
    if (
      irropsReasonsData != null &&
      Array.isArray(irropsReasonsData?.irropsCodes) &&
      irropsReasonsData?.irropsCodes.length > 0
    ) {
      const reasonCodes = irropsReasonsData.irropsCodes[0].reasonCodes;
      result = reasonCodes.map((item) => {
        return {
          value: item.irregularOperationsReason,
          name: item.irregularOperationsReason,
          commentsTemplate: item.irregularOperationsCommentsTemplate,
          isCommentsRequired: item.irregularOperationsCommentRequired,
        };
      });
    }
    return result;
  }, [airlineCode, irropsCode, irropsReasonsData]);

  useEffect(() => {
    if ((reasons === null || reasons?.length === 0) && irropsCode != 'swapReason') {
      setReasons(irropsReasonsList);
      setFilterReasons(irropsReasonsList);
    } else if (irropsCode == 'swapReason' && reasonsList.length > 0) {
      setReasons(reasonsList);
      setFilterReasons(reasonsList);
    }
  }, [airlineCode, irropsCode, irropsReasonsList, reasons, reasonsList]);

  const handleOnSelectReason = (eventKey, eKey, event, target) => {
    // find reason in reason array
    let enteredReason = getReason(eventKey);
    onSelect(
      enteredReason.name,
      enteredReason.value,
      enteredReason.commentsTemplate,
      enteredReason.isCommentsRequired,
      event,
      target,
    );
  };

  const handleOnChange = (value) => {
    const filteredItems = smartSearch(value, reasons);
    let enteredReason = getReason(value);
    if (enteredReason != undefined) {
      onSelect(
        enteredReason.name,
        enteredReason.value,
        enteredReason.commentsTemplate,
        enteredReason.isCommentsRequired,
      );
    } else {
      setFilterReasons(filteredItems);
      onSelect(value, value, null, null);
    }
  };

  /**
   * @description returns the reason if it matches exactly with any of the values of reasons list.
   * @param {string} value - the text entered by user.
   */
  function getReason(value) {
    let enteredReason = reasons.find((element) => element.value === value);

    if (enteredReason != undefined) {
      if (
        airlineCode === commentsRequiredByAirline.Airline &&
        enteredReason.name === commentsRequiredByAirline.Reason
      ) {
        enteredReason.isCommentsRequired = true;
        enteredReason.commentsTemplate = commentsRequiredByAirline.CommentTemplate;
      }
    }
    return enteredReason;
  }

  return (
    <SearchableDropdown
      textInput={selectedReason}
      isDisabled={false}
      onSelect={handleOnSelectReason}
      onChange={handleOnChange}
      displayList={filterReasons}
      dataCyTag={'irrops-code-reason'}
      isAutoFocus={isAutoFocus}
      isOpenList={isOpenList}
      isRequired={isRequired}
      className={className}
    ></SearchableDropdown>
  );
};

IrropsReasons.propTypes = {
  airlineCode: PropTypes.string.isRequired,
  irropsCode: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedReason: PropTypes.string.isRequired,
  className: PropTypes.string,
  fetchReasonsApi: PropTypes.bool,
};

export default withAppInsightsTracking(IrropsReasons);
