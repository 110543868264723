import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

import './LoadingIndicator.css';

const LoadingIndicator = () => {
  return (
    <div className="d-flex justify-content-center loading-indicator" data-cy="loading-indicator">
      <BarLoader color="var(--primary-icon-color)" />
    </div>
  );
};

export default LoadingIndicator;
