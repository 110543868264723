import React from 'react';
import { getMonthYearDisplayString } from '../../../lib/displayUtils';
import EditOutlinedIcon from '@material-ui/icons/EditSharp';

// Default em dash (-) value for empty table cells
const defaultValue = <span className="admin-list-table-default-value">&#8212;</span>;

// user role table config
export const userRolesTableConfig = [
  {
    columnDisplayName: 'User Name',
    columnKey: 'userName',
    renderFn: (row) => (!!row.userName ? row.userName : defaultValue),
  },
  {
    columnDisplayName: 'User ID',
    columnKey: 'userID',
    renderFn: (row) => (!!row.userID ? row.userID : defaultValue),
  },
  {
    columnDisplayName: 'Role Name',
    columnKey: 'roleName',
    renderFn: (row) => (!!row.roleName ? row.roleName : defaultValue),
  },
  {
    columnDisplayName: 'Operating Airline',
    columnKey: 'operatingAirline',
    renderFn: (row) => (!!row.operatingAirline ? row.operatingAirline : defaultValue),
  },
  {
    columnDisplayName: 'Created Date',
    columnKey: 'createdDate',
    renderFn: (row) => (!!row.createdDate ? getMonthYearDisplayString(row.createdDate) : defaultValue),
  },
  {
    columnDisplayName: 'Created By',
    columnKey: 'createdBy',
    renderFn: (row) => (!!row.createdBy ? row.createdBy : defaultValue),
  },
  {
    columnDisplayName: 'Modified Date',
    columnKey: 'modifiedDate',
    renderFn: (row) => (!!row.modifiedDate ? getMonthYearDisplayString(row.modifiedDate) : defaultValue),
  },
  {
    columnDisplayName: 'Modified By',
    columnKey: 'modifiedBy',
    renderFn: (row) => (!!row.modifiedBy ? row.modifiedBy : defaultValue),
  },
  {
    columnDisplayName: '',
    columnKey: 'edit',
    renderFn: (row) => <EditOutlinedIcon color="primary" />,
  },
];
