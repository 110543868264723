import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toLowerCase } from '../../../../lib/displayUtils';
import PropTypes from 'prop-types';
import './TimelineBar.css';
import minMax from 'dayjs/plugin/minMax';
import { onTimeChanged } from '../ganttHelpers';
dayjs.extend(minMax);

/**
 * TimeIncrement component is one "tick mark" in the gantt timeline axis.
 * @param {string} hour - text to be displayed above time increment tick mark
 * @param {Object} date - dayjs object to display date text along with hour above tick mark when hour equals 00
 * @param {Number} oneHourRemWidth - width of one hour in rem
 * @param {bool} isFirst - whether or not the time increment needs extra padding (applies to the first time increment in a timeline bar)
 */
const TimeIncrement = ({ hour, date, oneHourRemWidth, isFirst = false, observer }) => {
  const isStartOfDay = hour === '00' || hour === '12';
  const WIDTH_REM = 2.4;

  const timeIncrementRef = useRef(null);

  useLayoutEffect(() => {
    if (observer && timeIncrementRef.current) {
      observer.observe(timeIncrementRef.current);

      return () => {
        observer.unobserve(timeIncrementRef.current);
      };
    }
  }, [observer, timeIncrementRef.current]);

  let marginLeft = oneHourRemWidth / 2 - WIDTH_REM + (isFirst ? WIDTH_REM / 2 : 0);
  return (
    <div
      ref={timeIncrementRef}
      data-cy="time-increment"
      className="time-increment"
      style={{ width: `${WIDTH_REM}rem`, marginLeft: `${marginLeft}rem` }}
      {...(date ? { 'data-date': date.format('YYYY-MM-DD HH:mm') } : {})}
    >
      {isStartOfDay ? <div className="day-mark-text">{date.format('MM/DD')}</div> : ''}
      <div className={`hour-text`}>{hour}</div>
      <span className="tick-mark"></span>
    </div>
  );
};

/**
 *
 * @param {string} timelineStartDateTime - start time of the timeline
 * @param {number} timelineHours - number of total hours in the timeline hour
 * @param {number} oneHourRemWidth - width of one hour in rem
 * @param {number} hoursToDisplay - hours to display on visible viewport of the screen at any screen size
 * @param {string} timeZone - time zone identifier to number timeline
 * @returns
 */
const TimelineBar = React.memo(
  ({
    timelineStartDateTime,
    timelineHours,
    oneHourRemWidth,
    hoursToDisplay,
    timeZone = 'UTC',
    onDateTimeChanged,
    timelineContainerRef,
    extendedStartTimeHours,
  }) => {
    const [observer, setObserver] = useState(null);

    useEffect(() => {
      if (onDateTimeChanged && timelineContainerRef?.current) {
        const options = {
          root: timelineContainerRef.current,
          rootMargin: '0px',
          threshold: [0, 1.0],
        };
        const currentObserver =
          timelineContainerRef.current &&
          new IntersectionObserver((entries) => onTimeChanged(entries, onDateTimeChanged), options);
        setObserver(currentObserver);

        return () => {
          currentObserver.disconnect();
        };
      }
    }, [timelineContainerRef?.current]);

    const isUTC = timeZone === 'UTC';
    const keyPrefix = isUTC ? 'utc' : toLowerCase(timeZone);
    const timelineIncrements = [];
    const utcStartTime =
      extendedStartTimeHours > 0
        ? dayjs.utc(timelineStartDateTime).subtract(extendedStartTimeHours, 'hours')
        : dayjs.utc(timelineStartDateTime);
    const skipHourMark = hoursToDisplay >= 48;

    for (var i = 1; i <= timelineHours; i++) {
      const currentDateUTC = utcStartTime.add(i, 'hour');
      const currentDate = isUTC
        ? currentDateUTC
        : // local time zone
          dayjs.tz(currentDateUTC, timeZone);

      const currentHour = currentDate.format('HH');
      const hourDisplay = skipHourMark ? (currentHour % 2 !== 0 ? '' : currentHour) : currentHour;
      timelineIncrements.push(
        <React.Fragment key={`${keyPrefix}-increment-${i}`}>
          <TimeIncrement
            key={`${keyPrefix}-half-hour-increment-${i}`}
            oneHourRemWidth={oneHourRemWidth}
            isFirst={i === 1}
          />
          <TimeIncrement
            key={`${keyPrefix}-hour-increment-${i}`}
            hour={hourDisplay}
            date={currentDate}
            oneHourRemWidth={oneHourRemWidth}
            onDateTimeChanged={onDateTimeChanged}
            observer={observer}
          />
        </React.Fragment>,
      );
    }

    return (
      <div className="timeline-axis" data-cy="timeline-axis">
        {timelineIncrements}
      </div>
    );
  },
);

export { TimelineBar as default, TimeIncrement };

TimeIncrement.propTypes = {
  hour: PropTypes.string,
  date: PropTypes.object,
  oneHourRemWidth: PropTypes.number.isRequired,
};

TimelineBar.propTypes = {
  timelineStartDateTime: PropTypes.string.isRequired,
  timelineHours: PropTypes.number.isRequired,
  oneHourRemWidth: PropTypes.number.isRequired,
  timeZone: PropTypes.string,
};
