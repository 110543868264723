import React, { useState } from 'react';
import './Toolbar.css';
import { ViewWindowType } from '../../../../../../lib/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as MinimizeIcon } from '../../../../../../assets/images/minimize.svg';
import { ReactComponent as MaximizeIcon } from '../../../../../../assets/images/maximize.svg';
import { ReactComponent as CloseWindowIcon } from '../../../../../../assets/images/close-window.svg';
import RenameViewModal from '../../../RenameViewModal/RenameViewModal';
import { useWindowManagerDispatch } from '../../../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import PropTypes from 'prop-types';

/**
 * Renders a toolbar for the view window
 * @param {string} props.variant - The variant of the toolbar
 * @param {string} props.title - The title of the toolbar
 * @param {function} props.onMaximize - The function to call when the maximize icon is clicked
 * @param {function} props.onMinimize - The function to call when the minimize icon is clicked
 * @param {function} props.onClose - The function to call when the close icon is clicked
 * @param {boolean} props.isMinimized - Indicates if the window is minimized
 * @param {number} props.currentActiveId - The id of the active view window
 * @returns
 */
function Toolbar({
  variant,
  title = "Today's Rotation",
  onMaximize,
  onMinimize,
  onClose,
  isMinimized,
  currentActiveId,
}) {
  let className = 'toolbar';
  switch (variant) {
    case ViewWindowType.SWAP_MODE:
      className += ' swap-mode-toolbar';
      break;
    case ViewWindowType.MULTI:
      className += ' multiview-toolbar';
      break;
    default:
      break;
  }
  const { renameViewWindow } = useWindowManagerDispatch();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [renameTitle, setRenameTitle] = useState(title);

  const handleClick = () => {
    setShowConfirmPopup(true);
  };

  const hideHandleConfirm = () => {
    setShowConfirmPopup(false);
  };

  const handleConfirmCancelClick = () => {
    setShowConfirmPopup(false);
  };

  const handleConfirmApplyClick = () => {
    renameViewWindow(currentActiveId, { viewTitle: renameTitle });
    setShowConfirmPopup(false);
  };

  const isSingle = variant === ViewWindowType.SINGLE;

  return (
    <div id="toolbar" className={className} data-cy="toolbar">
      <div className="toolbar-left-container">
        <div id="toolbar-icon" className="toolbar-icon" data-cy="toolbar-icon" onClick={handleClick}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
        <RenameViewModal
          showConfirmPopup={showConfirmPopup}
          handleHideClick={hideHandleConfirm}
          handleCancelClick={handleConfirmCancelClick}
          handleApplyClick={handleConfirmApplyClick}
          dataCyTag="rename-view-modal"
          placeholder={title}
          setRenameTitle={setRenameTitle}
        />
        <div id="toolbar-drag-area" data-cy="toolbar-drag-area" className="toolbar-drag-area">
          <div data-cy="toolbar-icon-title" className={`toolbar-title${isMinimized ? ' minimized' : ''}`}>
            {title}
          </div>
        </div>
      </div>
      {!isSingle && (
        <div className="toolbar-right-container">
          <div id="toolbar-icon-container" className="toolbar-icon-container">
            <div
              className={`toolbar-icon minimize-icon${isMinimized ? ' disabled' : ''}`}
              data-cy="minimize-icon"
              onClick={!isMinimized ? onMinimize : null}
              disabled={isMinimized}
            >
              <MinimizeIcon />
            </div>
            <div
              className={`toolbar-icon maximize-icon${isMinimized ? ' enabled' : ''}`}
              data-cy="maximize-icon"
              onClick={onMaximize}
            >
              <MaximizeIcon />
            </div>
            <div className="toolbar-icon close-window-icon" data-cy="close-window-icon" onClick={onClose}>
              <CloseWindowIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Toolbar.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string,
  onMaximize: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMinimized: PropTypes.bool.isRequired,
  currentActiveId: PropTypes.number.isRequired,
};

export default Toolbar;
