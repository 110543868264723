import { useState, useCallback } from 'react';
import Flights from './Flights/Flights';
import FlightNavigationBar from './FlightNavigationBar/FlightNavigationBar';
import MultipleViewsContainer from './MultipleViews/MultipleViewsContainer/MultipleViewsContainer';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';
import { useSelector } from 'react-redux';
import { ViewIdProvider } from '../../contexts/ViewIdContext/ViewIdContext';
import PropTypes from 'prop-types';
import './ManageOperationsPage.css';

function ManageOperationsPage({ onShowHideHeader, showHeader }) {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const currentActiveId = useSelector((state) => state?.viewManagerReducer?.viewOrder[0]);
  const viewType = useSelector(
    (state) => state?.viewManagerReducer?.viewWindows.find((view) => view.id === currentActiveId)?.viewType,
  );

  //sort state
  // Not going to store sort in session storage for now, as views are not persisted,
  // may not need to since will perist through configs
  // With Multiple Views default sort is defined and applied within OperationsView
  const [sort, setSort] = useState(null);

  const clearSort = () => {
    setSort(null);
  };

  /**
   * Event handler for when new table sort is applied. Saves the sort into session storage.
   * NOTE: Actual sort happens in flightlisttable.js dispatch
   */
  const handleSortClick = useCallback(
    (orderBy, orderDirection) => {
      setSort({
        ...sort,
        [currentActiveId]: {
          orderBy,
          orderDirection,
        },
      });
    },
    [sort],
  );

  if (showMultipleViews) {
    return (
      <div className="manage-operations-page-container">
        <ViewIdProvider id={currentActiveId}>
          <FlightNavigationBar
            onShowHideHeader={onShowHideHeader}
            showNavbar={showHeader}
            viewType={viewType}
            currentActiveId={currentActiveId}
            clearSort={clearSort}
          />
          <MultipleViewsContainer currentActiveId={currentActiveId} handleSortClick={handleSortClick} sort={sort} />
        </ViewIdProvider>
      </div>
    );
  }

  return (
    <ViewIdProvider id={0}>
      <Flights onShowHideHeader={onShowHideHeader} showHeader={showHeader} />
    </ViewIdProvider>
  );
}

export default ManageOperationsPage;

ManageOperationsPage.propTypes = {
  onShowHideHeader: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
};
