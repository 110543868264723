import { useRef, useEffect, useCallback } from 'react';
import { useDragDropDispatch } from '../useDragDropStore/useDragDropStore';

const PRINT_DEBUG_STATEMENTS = false;

/**
 *
 * Hook to connect a component to be a drop item.
 *
 * @param {Number} dropItemKey - key for this drop item
 * @param {Object} data - data stored for this drop item
 * @param {Function} onDrop - callback when item is dropped
 * @param {Boolean} canDrop - boolean to determine if puck can be dropped
 * @returns {Array} -
 * [0] - Ref to attach to element to be a drop item
 */
export const useDropItem = ({ dropItemKey, data, onDrop, canDrop = true }) => {
  const { hover } = useDragDropDispatch();
  const dropItemRef = useRef();

  /**
   * Callback when an item is dragged into this element
   * @param {*} e
   */
  const handleDragEnter = useCallback(
    (e) => {
      if (PRINT_DEBUG_STATEMENTS) console.log(`handleDragEnter() dropItemKey=${dropItemKey}`, e.target);

      e.preventDefault();
      hover({ dropItemKey, data });
    },
    [dropItemKey, data, hover],
  );

  /**
   * Callback when an item is dragging over this element
   * @param {*} e
   */
  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      hover({ dropItemKey, data });
      if (PRINT_DEBUG_STATEMENTS) console.log(`handleDragOver() dropItemKey=${dropItemKey}`, e.target);
    },
    [dropItemKey, data, hover],
  );

  /**
   * Callback when an item is dragged away from this element
   * @param {*} e
   */
  const handleDragLeave = useCallback(
    (e) => {
      const isStillOverDropZone = e.target.contains(e.relatedTarget);
      if (!isStillOverDropZone) {
        if (PRINT_DEBUG_STATEMENTS) console.log(`handleDragLeave() dropItemKey=${dropItemKey}`, e.target);
        hover(null);
      }
    },
    [dropItemKey, hover],
  );

  /**
   * Callback when an item has been drag and dropped into this element
   * @param {*} e
   */
  const handleDrop = useCallback(
    (e) => {
      if (PRINT_DEBUG_STATEMENTS) console.log(`handleDrop() dropItemKey=${dropItemKey}`, e.target);

      onDrop();
    },
    [dropItemKey, onDrop],
  );

  /**
   * Attaches drag event listeners to the dom element
   */
  useEffect(() => {
    const refCopy = dropItemRef?.current;
    if (refCopy && canDrop) {
      refCopy.addEventListener('dragenter', handleDragEnter);
      refCopy.addEventListener('dragover', handleDragOver);
      refCopy.addEventListener('dragleave', handleDragLeave);
      refCopy.addEventListener('drop', handleDrop);
    }

    return () => {
      if (refCopy && canDrop) {
        refCopy.removeEventListener('dragenter', handleDragEnter);
        refCopy.removeEventListener('dragover', handleDragOver);
        refCopy.removeEventListener('dragleave', handleDragLeave);
        refCopy.removeEventListener('drop', handleDrop);
      }
    };
  }, [data, handleDragEnter, handleDragOver, handleDragLeave, handleDrop, canDrop]);

  return [dropItemRef];
};
