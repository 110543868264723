// useSearchFlightActions.js
import { useDispatch } from 'react-redux';
import { SearchFlightAction } from '../../redux/actionTypes';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

const selectSearchFlight = (state) => state.searchFlightReducer;

export const useSearchFlightStore = () => {
  return useSelector(selectSearchFlight);
};

export const useSearchFlightDispatch = () => {
  const dispatch = useDispatch();

  const setSearchFlightNumber = (flightNumber) => {
    dispatch({ type: SearchFlightAction.SET_SEARCH_FLIGHT_NUMBER, payload: flightNumber });
  };

  const setSearchResults = (results) => {
    dispatch({ type: SearchFlightAction.SET_SEARCH_RESULTS, payload: results });
  };

  const setCurrentIndex = (index) => {
    dispatch({ type: SearchFlightAction.SET_CURRENT_INDEX, payload: index });
  };

  const setShowMatches = (show) => {
    dispatch({ type: SearchFlightAction.SET_SHOW_MATCHES, payload: show });
  };

  return {
    setSearchFlightNumber,
    setSearchResults,
    setCurrentIndex,
    setShowMatches,
  };
};
