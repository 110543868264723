import React from 'react';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';

import './UnauthorizedPage.css';

// Initialize the ApplicationInsights library code.
const ACCESS_REQUEST_LINK =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=1MVED7BCwkW_VdD-qEMNMzOGh_6L24VEv8rfv0yY2iZUMUtHRE5JNVdZVFlKREdUVjFTRU9EWVc0My4u';

/**
 * @description Page for when a user is not authorized for AirTrak
 * @returns Page with a message telling to request permission
 */
const Unauthorized = () => {
  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('UnauthorizedPage');

  return (
    <div data-cy="unauthorized-page-message" className="unauthorized">
      <span>You are not currently authorized to use this application.</span>
      <span>
        Contact <a href={ACCESS_REQUEST_LINK}>System Administrator</a> to request permission.
      </span>
    </div>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Unauthorized);
