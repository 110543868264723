import { useQuery } from 'react-query';
import { getAllAlertTypes, getSeatMaps } from '../../services/apiClient/referenceApi/referenceApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useReferenceAlertsQuery';

/**
 * Custom hook for retrieving data from Reference API
 */
export const useReferenceAlertsQuery = () => {
  const config = {
    // Setting stale time to 5 minutes because reference alert data rarely changes.
    // This means that when any component mounts using this hook within the "fresh" time,
    // it will not fetch new data.
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
    refetchOnWindowFocus: false,
    enabled: true,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getAllAlertTypes', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['alertTypes'], getAllAlertTypes, config);
};

/**
 * @description Custom hook for retrieving data from getSeatMaps
 * @param {string} airlineCode - airlineCode
 */
export const useSeatMapsQuery = (airlineCode) => {
  const config = {
    cacheTime: 5 * 60 * 1000, // override default 5 minutes
    staleTime: 5 * 60 * 1000,
    retry: appSettings.RETRY_QUERY_DELAY,
    refetchOnWindowFocus: false,
    onError: (error) => {
      logError(getApiErrorMessage(error), moduleName, 'getSeatMaps', createApiErrorTelemetryProperties(error));
    },
  };

  return useQuery(['SeatMaps', airlineCode], () => getSeatMaps(airlineCode), config);
};
