import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import { createRole, updateRole } from '../../../../services/apiClient/administrationApi/administrationApi';
import { useAuthentication } from '../../../../contexts/AuthenticationContext/AuthenticationContext';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../../lib/constants';
import DynamicCheckbox from '../../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';
import { getAssignmentTypeList } from '../../../../lib/utils';

import './RoleForm.css';

const airlineList = [
  { code: 'AS', key: 0 },
  { code: 'QX', key: 1 },
  { code: 'OO', key: 2 },
];

const RoleForm = ({ assignments = [], entitlements = [], existingRole = null, onSave, onCancel, ...props }) => {
  const initialFormData =
    existingRole === null
      ? {
          roleName: '',
          operatingAirline: '',
          assignmentRequired: false,
          assignmentEntitlementKeys: [],
          assignmentGroupKeys: [],
          defaultAssignmentTypeName: '',
        }
      : existingRole;

  // Hooks
  const [roleFormData, setRoleFormData] = useState(initialFormData);
  const [show, setShow] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [assignmentGroupDisabled, setAssignmentGroupDisabled] = useState(!initialFormData.assignmentRequired);
  const { userData } = useAuthentication();

  const assignmentTypeList = getAssignmentTypeList();

  const validateUserForm = (roleForm) => {
    const isRoleFormComplete =
      roleForm.roleName !== '' && roleForm.operatingAirline !== '' && roleForm.defaultAssignmentTypeName !== '';

    let isEditRoleFormUnchanged = false;
    if (existingRole !== null) {
      // check that edits have been made to the assignment Group
      const existingGroupKeys = [...existingRole.assignmentGroupKeys].sort((a, b) => a - b);
      const newGroupKeys = [...roleForm.assignmentGroupKeys].sort((a, b) => a - b);
      const sameGroupKeys = JSON.stringify(existingGroupKeys) === JSON.stringify(newGroupKeys);
      const sameRoleName = existingRole.roleName === roleForm.roleName;
      const sameOperatingAirline = existingRole.operatingAirline === roleForm.operatingAirline;
      const sameAssignmentRequired = existingRole.assignmentRequired === roleForm.assignmentRequired;
      const sameDefaultAssignmentType = existingRole.defaultAssignmentTypeName === roleForm.defaultAssignmentTypeName;
      const existingEntitlementKeys = [...existingRole.assignmentEntitlementKeys].sort((a, b) => a - b);
      const newEntitlementKeys = [...roleForm.assignmentEntitlementKeys].sort((a, b) => a - b);
      const sameRoleEntitlement = JSON.stringify(existingEntitlementKeys) === JSON.stringify(newEntitlementKeys);
      isEditRoleFormUnchanged =
        sameGroupKeys &&
        sameRoleName &&
        sameOperatingAirline &&
        sameAssignmentRequired &&
        sameRoleEntitlement &&
        sameDefaultAssignmentType;
    }
    setIsSaveDisabled(!isRoleFormComplete || isEditRoleFormUnchanged);
  };

  const handleTextInputChange = (e) => {
    let newRoleFormData = {};
    if (e.target.id === 'role-name') {
      newRoleFormData = { ...roleFormData, roleName: e.target.value };
    } else {
      newRoleFormData = { ...roleFormData, operatingAirline: e.target.value };
    }
    setRoleFormData(newRoleFormData);
    validateUserForm(newRoleFormData);
  };

  const handleAssignmentRequiredChange = (e) => {
    //Enable/disable assignment group keys based on assignment required value
    let assignmentGroupKeys = [...roleFormData.assignmentGroupKeys];
    if (!e.target.checked) {
      assignmentGroupKeys = [];
      setAssignmentGroupDisabled(true);
    } else {
      setAssignmentGroupDisabled(false);
    }
    const newRoleFormData = {
      ...roleFormData,
      assignmentRequired: e.target.checked,
      assignmentGroupKeys: assignmentGroupKeys,
    };
    setRoleFormData(newRoleFormData);
    validateUserForm(newRoleFormData);
  };

  const handleRoleAssignmentChange = (event) => {
    // current assignment keys
    let assignmentGroupKeys = [...roleFormData.assignmentGroupKeys];
    let assignmentGroupId = parseInt(event.target.value);
    if (event.target.checked) {
      assignmentGroupKeys.push(assignmentGroupId);
    } else {
      const index = assignmentGroupKeys.indexOf(assignmentGroupId);
      if (index > -1) {
        assignmentGroupKeys.splice(index, 1);
      }
    }
    const updatedFormData = { ...roleFormData, assignmentGroupKeys: assignmentGroupKeys };
    setRoleFormData(updatedFormData);
    validateUserForm(updatedFormData);
  };

  const handleRoleEntitlementChange = (event) => {
    // current role entitlement keys
    let assignmentEntitlementKeys = [...roleFormData.assignmentEntitlementKeys];
    let entitlementId = parseInt(event.target.value);
    if (event.target.checked) {
      assignmentEntitlementKeys.push(entitlementId);
    } else {
      const index = assignmentEntitlementKeys.indexOf(entitlementId);
      if (index > -1) {
        assignmentEntitlementKeys.splice(index, 1);
      }
    }
    const updatedFormData = { ...roleFormData, assignmentEntitlementKeys: assignmentEntitlementKeys };
    setRoleFormData(updatedFormData);
    validateUserForm(updatedFormData);
  };

  const handleSaveButtonClick = () => {
    const saveRoleForm = async () => {
      let result;
      // call API
      if (existingRole === null) {
        result = await createRole(roleFormData, userData.profile.name);
      } else {
        result = await updateRole(roleFormData, userData.profile.name);
      }
      // handle result
      if (result === null) {
        setStatusMessage('Unable to save changes.');
        setIsSaveDisabled(false);
      } else {
        setStatusMessage('');
        setShow(false);
        onSave();
      }
    };

    setStatusMessage('Saving...');
    setIsSaveDisabled(true);
    saveRoleForm();
  };

  const handleCancel = () => {
    setShow(false);
    onCancel();
  };

  const handleAirlineSelectChange = (code) => {
    let newRoleFormData = {};
    newRoleFormData = { ...roleFormData, operatingAirline: code };
    setRoleFormData(newRoleFormData);
    validateUserForm(newRoleFormData);
  };

  /**
   * Updates the default assignment type in roleFormData state and validates form data.
   * @param {string} assignmentType - default assignment type to be updated
   * @returns
   */
  const handleDefaultAssignmentTypeSelectChange = (assignmentType) => {
    let newRoleFormData = {};
    newRoleFormData = { ...roleFormData, defaultAssignmentTypeName: assignmentType };
    setRoleFormData(newRoleFormData);
    validateUserForm(newRoleFormData);
  };
  const isChecked = (code) => {
    return code === roleFormData.operatingAirline;
  };

  const formContent = (
    <>
      <div className="role-form-container" data-cy="role-form-container">
        <div>
          <TextField
            className="role-form-container text-field"
            id="role-name"
            label="Role Name"
            value={roleFormData.roleName}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'role-name-input' }}
          />
          <FormGroup className="role-form-default-assignment-type" data-cy="role-form-default-assignment-type">
            <FormLabel component="legend">Assignment Type</FormLabel>
            <div className="default-assignment-type-container">
              {assignmentTypeList.map(({ typeName }, index) => {
                return (
                  <DynamicCheckbox
                    key={`role-default-assignment-type-${typeName}-${index}`}
                    dataCyTag={`role-default-assignment-type-${typeName}`}
                    customCSSTag="role-default-assignment-type-item"
                    type={'radio'}
                    checked={typeName === roleFormData.defaultAssignmentTypeName}
                    label={typeName}
                    onChange={() => handleDefaultAssignmentTypeSelectChange(typeName)}
                  />
                );
              })}
            </div>
          </FormGroup>
          <FormGroup className="role-form-airline" data-cy="role-form-airline">
            <FormLabel component="legend">Airline</FormLabel>
            <div className="role-airline-container">
              {airlineList.map(({ code }, index) => {
                return (
                  <DynamicCheckbox
                    key={`airline-code-${code}-${index}`}
                    dataCyTag={`airline-code-${code}`}
                    customCSSTag="role-airline-item"
                    type="radio"
                    checked={isChecked(code)}
                    label={code}
                    onChange={() => handleAirlineSelectChange(code)}
                  />
                );
              })}
            </div>
          </FormGroup>
          <FormControlLabel
            key={`role-assignment-required-key`}
            label="Assignment Required"
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={roleFormData.assignmentRequired}
                onChange={handleAssignmentRequiredChange}
                data-cy={'assignment-required-input'}
                name="Assignment Required"
              />
            }
          />
        </div>
        <div className="role-form-sub-container">
          <FormGroup className="role-form-assignments" data-cy="role-form-assignments">
            <FormLabel component="legend">Assignments</FormLabel>
            {assignments.map((assignment) => (
              <FormControlLabel
                key={`assignmentKey-${assignment.assignmentID}`}
                label={`${assignment.name} (${assignment.criteria?.criteriaName})`}
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={roleFormData.assignmentGroupKeys.includes(assignment.assignmentID)}
                    onChange={handleRoleAssignmentChange}
                    id={`assignmentKey-${assignment.assignmentID}`}
                    data-cy={`assignmentKey-${assignment.assignmentID}`}
                    name={`assignment-${assignment.assignmentID}`}
                    value={assignment.assignmentID}
                    disabled={assignmentGroupDisabled}
                  />
                }
              />
            ))}
          </FormGroup>
          <FormGroup className="role-form-entitlements" data-cy="role-form-entitlements">
            <FormLabel component="legend">Entitlements</FormLabel>
            {entitlements.map((entitlement) => (
              <FormControlLabel
                key={`entitlementKey-${entitlement.entitlementID}`}
                label={`${entitlement.name}`}
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={roleFormData.assignmentEntitlementKeys.includes(entitlement.entitlementID)}
                    onChange={handleRoleEntitlementChange}
                    id={`entitlementKey-${entitlement.entitlementID}`}
                    data-cy={`entitlementKey-${entitlement.entitlementID}`}
                    name={`entitlement-${entitlement.entitlementID}`}
                    value={entitlement.entitlementID}
                  />
                }
              />
            ))}
          </FormGroup>
        </div>
      </div>
      <div className="status-message" data-cy="status-message">
        {statusMessage}
      </div>
    </>
  );

  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="role-form-save-button"
      onClick={handleSaveButtonClick}
      isDisabled={isSaveDisabled}
    >
      Save
    </Button>,
  ];

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <Modal
        show={show}
        title={existingRole === null ? 'Add Role' : 'Edit Role'}
        body={formContent}
        footerButtons={formButtons}
        showCloseButton
        size="thin"
        onHide={handleCancel}
        draggable={false}
        {...props}
      />
    </AocAuthAccessCheck>
  );
};

RoleForm.propTypes = {
  assignments: PropTypes.array.isRequired,
  existingRole: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RoleForm;
