import { CrosshairActionKeys } from '../actionTypes';
// initial state values
const initialState = {
  isCrosshairActive: false,
  crosshairCount: 1,
  activeCrosshair: 0,
  crosshairPosition: [],
};

/**
 * Given the previous state and action payload return the new state for crosshair mode
 * @returns - the initial state for the crosshair store
 */

const crosshairReducer = (state = initialState, action) => {
  switch (action.type) {
    case CrosshairActionKeys.UPDATE_CROSSHAIR_MODE:
      return {
        ...state,
        isCrosshairActive: action.payload.isCrosshairActive ?? initialState.isCrosshairActive,
      };
    case CrosshairActionKeys.UPDATE_CROSSHAIR_COUNT:
      return {
        ...state,
        crosshairCount: action.payload.crosshairCount ?? initialState.crosshairCount,
      };
    case CrosshairActionKeys.UPDATE_ACTIVE_CROSSHAIR:
      return {
        ...state,
        activeCrosshair: action.payload.activeCrosshair ?? initialState.activeCrosshair,
      };
    default:
      return state;
  }
};

export default crosshairReducer;
