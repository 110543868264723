import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import UnauthorizedPage from '../UnauthorizedPage/UnauthorizedPage';
import AppLoadFailPage from '../AppLoadFailPage/AppLoadFailPage';
import Dashboard from '../Dashboard/Dashboard';
import Configuration from '../Configuration/Configuration';
import ManageOperationsPage from '../ManageOperationsPage/ManageOperationsPage';
import AssignmentSession from '../AssignmentSession/AssignmentSession';
import ManageRoles from '../Administration/ManageRoles/ManageRoles';
import ManageUsers from '../Administration/ManageUsers/ManageUsers';
import ManageAssignments from '../Administration/ManageAssignments/ManageAssignments';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageTitle, RoleNames, SessionAction, Treatment } from '../../lib/constants';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import LoadingIndicator from '../Shared/LoadingIndicator/LoadingIndicator';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import AocAuthAccessCheck from '../AocAuthAccessCheck/AocAuthAccessCheck';
import { ReactQueryDevtools } from 'react-query/devtools';
import IdleTimeOutHandler from '../IdleTimeOutHandler/IdleTimeOutHandler';
import { AirportsProvider } from '../../contexts/AirportsContext/AirportsContext';
import { useFilterDispatch } from '../../hooks/useFilterStore/useFilterStore';
import { ThemesProvider } from '../../contexts/ThemeContext/ThemeContext';
import { NotificationProvider } from '../../contexts/NotificationsContext/NotificationsContext';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { ViewIdProvider } from '../../contexts/ViewIdContext/ViewIdContext';
import { useSelector } from 'react-redux';
import './App.css';
import appSettings from '../../appSettings';

/**
 * The App component is the main component of the application. It is rendered when the user
 * has been authenticated. The App component is responsible for authorizing the user,
 * assignment session info, and page routing.
 * @returns {App} - The App component
 */
function App() {
  // Custom hooks
  const { state: userContextState } = useUserContext();
  const { trackPageView } = useAppInsightsContext();
  const { roleAssignments, updateRoleAssignment } = useRoleAssignmentContext();
  const { clearFilter } = useFilterDispatch();
  const [showHeader, setShowHeader] = useState(true);

  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const currentActiveId = useSelector((state) => state?.viewManagerReducer?.viewOrder[0]);

  useEffect(() => {
    if (appSettings.environment === 'test') {
      document.title = PageTitle.AIRTRAK_TEST;
    } else if (appSettings.environment === 'qa') {
      document.title = PageTitle.AIRTRAK_QA;
    } else {
      document.title = PageTitle.AIRTRAK;
    }
    sessionStorage.setItem('ADO_PIPELINE_BUILD', appSettings.ADO_PIPELINE_BUILD);
  }, []);

  // Track page view with default page name 'AOC'
  trackPageView('AOC');

  /**
   * Event handler when role assignment form is completed
   * @param {Object[]} session - object with selected role and assignments
   */
  const handleConfirmRoleSelection = (session) => {
    // Clear stored filters when role is changed
    clearFilter();
    updateRoleAssignment(session);
  };

  const handleShowHideHeader = () => {
    setShowHeader((state) => !state);
  };

  /**
   * Event handler when role assignment change is canceled
   */
  const handleCancelRoleSelection = () => {};

  let contentToLoad = <></>;

  // Make sure the user is authorized
  if (userContextState.employeeID && !userContextState.error) {
    // The list of Roles the user can select from
    const roles = userContextState.authorizedRoles;

    if (roleAssignments.role != null && !roleAssignments.loading) {
      // The user has finished selecting their role and assignments

      contentToLoad = (
        <>
          {/* Standard routes */}
          <AocAuthAccessCheck deniedRoles={[RoleNames.SYSTEM_ADMIN]}>
            <Switch>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/flights">
                <AirportsProvider>
                  <NotificationProvider>
                    <ManageOperationsPage onShowHideHeader={handleShowHideHeader} showHeader={showHeader} />
                  </NotificationProvider>
                </AirportsProvider>
              </Route>
              <Route exact path="/configuration">
                <ViewIdProvider id={0}>
                  <Configuration />
                </ViewIdProvider>
              </Route>
              <Redirect from="/callback" to="/" />
              <Redirect from="/" to="/flights" />
              <Redirect from="*" to="/" />
            </Switch>
          </AocAuthAccessCheck>
          {/* System admin routes */}
          <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
            <Switch>
              <Route exact path="/users">
                <ManageUsers />
              </Route>
              <Route exact path="/roles">
                <ManageRoles />
              </Route>
              <Route exact path="/assignments">
                <ManageAssignments />
              </Route>
              <Redirect from="*" to="/users" />
            </Switch>
          </AocAuthAccessCheck>
        </>
      );
    } else if (roleAssignments.role === null && !roleAssignments.loading) {
      // The user is authenticated, but needs to select their role and assignments
      let rolesWithNoAssignment = roles
        .filter((x) => {
          return x.assignmentRequired === false;
        })
        .map((x) => x.roleID);
      if (
        (userContextState.authorizedAssignments != null && userContextState.authorizedAssignments.length > 0) ||
        rolesWithNoAssignment.length === roles.length
      ) {
        contentToLoad = (
          <AssignmentSession
            authorizedRoles={roles}
            authorizedAssignments={userContextState.authorizedAssignments}
            onConfirm={handleConfirmRoleSelection}
            onCancel={handleCancelRoleSelection}
            sessionAction={SessionAction.SELECT_ROLE}
          />
        );
      }
    } else {
      contentToLoad = <LoadingIndicator />;
    }
  } else if (userContextState.loading) {
    contentToLoad = <LoadingIndicator />;
  } else if (userContextState.error) {
    // Authentication API failed, so display a message
    contentToLoad = (
      <Route path="*">
        <AppLoadFailPage />
      </Route>
    );
  } else if (!userContextState.employeeID && !userContextState.loading) {
    // The user is not authorized to use the app, so display a message
    contentToLoad = (
      <Route path="*">
        <UnauthorizedPage />
      </Route>
    );
  }

  return (
    <React.Fragment>
      <ThemesProvider>
        <div className={showMultipleViews ? 'main-app-container' : 'main-app-container-legacy'}>
          {showHeader === true ? (
            <ViewIdProvider id={currentActiveId}>
              <Header
                userName={userContextState?.userName}
                role={roleAssignments.role}
                authorizedRoles={userContextState?.authorizedRoles}
                assignments={roleAssignments.assignments
                  ?.filter((x) => x.roleID === roleAssignments.role.roleID)
                  .map((a) => a.name)}
                authorizedAssignments={userContextState.authorizedAssignments}
              />
            </ViewIdProvider>
          ) : (
            <></>
          )}
          <div className={`page-content ${showHeader ? 'show-header' : 'hide-header'}`}>{contentToLoad}</div>
        </div>
      </ThemesProvider>
      <IdleTimeOutHandler />
      <ReactQueryDevtools initialIsOpen={false} />
    </React.Fragment>
  );
}

// Export and add AppInsights component tracking
export default withAppInsightsTracking(App);
