import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import DynamicCheckbox from '../../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';
import { KeyCodes } from '../../../../lib/constants';

import './MultiSelectDropdown.css';

const useStyles = makeStyles((theme) => ({
  selectStyle: {
    border: '2px solid var(--secondary-border-color)',
    borderRadius: '0.3rem',
    height: '4.4rem',
    width: '18.4rem',
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      background: 'var(--dropdown-menu-background)',
      borderRadius: '.3rem',
      border: '2px solid var(--secondary-border-color)',
      boxShadow: '0px 1px 2px 0px rgba(169, 169, 169, 0.5)',
      minHeight: '1rem',
      maxHeight: '21.2rem',
      height: 'auto',
      transform: 'translateX(0%) translateY(0%)',
    },
    'data-cy': 'multi-select-dropdown-menu',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};
const denseStyle = {
  lineHeight: '1rem',
  fontSize: '1.4rem',
};

// Chevron down icon for dropdown menu
const Icon = () => <FontAwesomeIcon className="menu-icon MuiSelect-icon" icon={faChevronDown} />;

/**
 * Dropdown is a Controlled Component and renders a drop-down select input
 * @param {string} displayText - the Text value on this drop down
 * @param {Array} displayList - a list of objects containing a label and value for each dropdown item
 * @param {func} onCheckChange - callback function when the input is updated
 * @param {bool} isDisabled - whether or not this input is disabled
 * @param {string} className - an additional custom class name
 * @param {string} dataCyTag - test id attribute
 */
const MultiSelectDropdown = ({ displayText, displayList = [], onCheckChange, isDisabled, className, dataCyTag }) => {
  const classes = useStyles();
  const menuItemRef = useRef();
  const containerProps = {
    className: `multisel-dropdown-container ${className ? ' ' + className : ''}`,
  };

  useEffect(() => {
    menuItemRef?.current?.focus();
  }, [displayList]);

  /**
   * @description handles the select keydown event from select field and triggers appropriate parent handler whether it is checkbox checked  true or false
   * * @param {e} onKeyDown event from text input field
   */
  const handleOnKeyDown = (event) => {
    let targetedValue = event.target.innerText;
    if (event.keyCode === KeyCodes.ENTER || event.keyCode === KeyCodes.SPACE) {
      onCheckChange(targetedValue);
    }
  };

  return (
    <div {...containerProps}>
      <Select
        multiple
        className={classes.selectStyle}
        disabled={isDisabled}
        value={[displayText]}
        disableUnderline="true"
        renderValue={(selected) => selected.join(', ')}
        IconComponent={Icon}
        MenuProps={MenuProps}
        inputProps={{
          'data-cy': `${dataCyTag}-input`,
        }}
        data-cy={dataCyTag}
      >
        {displayList.map((ele) => {
          const itemProps = ele.isFocus ? { ref: menuItemRef } : {};
          return (
            <MenuItem
              key={ele.key}
              index={ele.key}
              value={ele.name}
              style={denseStyle}
              onKeyDown={handleOnKeyDown}
              data-cy={`mid-${dataCyTag}-${ele.name}`}
              {...itemProps}
            >
              <DynamicCheckbox
                dataCyTag={`multi-dropdown-${ele.name}`}
                customCSSTag="multi-dropdown-flight-phase"
                label={ele.name}
                type={'checkbox'}
                checked={ele.checked}
                onChange={onCheckChange}
                isDisabled={ele.disable}
              />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  displayText: PropTypes.string,
  displayList: PropTypes.array,
  onCheckChange: PropTypes.func,
};

export default MultiSelectDropdown;
