import React, { createContext } from 'react';
import useDefaultRoleFilters from '../../hooks/useDefaultRoleFilters/useDefaultRoleFilters';

const ReduxDispatcherProviderContext = createContext(null);

/**
 * The ReduxDispatcherProvider component handles setting of the defaultAssignmentGroupTypeName & Timezone in filter store .
 * not set the value until roleAssignments and feature flags are loaded.
 * @param {Node} children - the components to be rendered.
 */
const ReduxDispatcherProvider = ({ children }) => {
  useDefaultRoleFilters();

  return (
    <ReduxDispatcherProviderContext.Provider
      value={
        {
          /* intentionally blank */
        }
      }
    >
      {children}
    </ReduxDispatcherProviderContext.Provider>
  );
};

export { ReduxDispatcherProvider };
