import { axiosInstance } from '../apiClient';
import {
  getApiErrorMessage,
  logError,
  logInformation,
  createApiErrorTelemetryProperties,
} from '../../../lib/appInsightsUtils';

const API_ROUTE = 'irropsqueuepublisher';
const moduleName = 'irregularOpsApi';

/**
 * @description Irregular Operations for all ops type
 * @param {object} flightData - flight object with current flight data
 * @returns {Object} ApiStatusResult with success or failure
 */
export const irregularOps = async (flightData) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    logInformation(
      `Submitting Irrops API POST for ${flightData?.opsType} - FlightNumber : ${flightData?.flightNumber}, Origin : ${flightData?.actualOrigin}, Destination : ${flightData?.actualDestination}, Departure date : ${flightData?.scheduledOperatingDateUTC}, Departure Count : ${flightData?.departureCount}`,
      moduleName,
      'irregularOps',
    );
    const url = `${API_ROUTE}/api/v1/irregularOperations/submit`;
    const response = await apiClient.post(url, flightData);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'irregularOps', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
