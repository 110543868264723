import { axiosInstance } from '../apiClient';

const API_ROUTE = 'irropscodes';

/**
 * Returns a list of reason codes
 * @param {string} airlineCode
 * @param {string} irropsCode
 */
export const getIrropsReasons = async (airlineCode, irropsCode) => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/v1/irropscodes?airlineCode=${airlineCode}&irropsCodes=${irropsCode}`;

  return await apiClient.get(url);
};
