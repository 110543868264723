import appSettings from '../../appSettings';
import { getGroundEventsV3 } from '../../services/apiClient/groundEventsApi/groundEventsApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import { useQuery } from 'react-query';
import { useAutoRefreshStore } from '../useAutoRefreshStore/useAutoRefreshStore';
import { DateFormat } from '../../lib/constants';
import { formatDateTime, getDatetimeUtcNowString } from '../../lib/dateTimeUtils';

const moduleName = 'useGroundEventsQuery';

let baseConfig = {
  cacheTime: 1 * 60 * 1000, // override default 5 minutes
  staleTime: 1 * 60 * 1000,
  retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
  refetchInterval: appSettings.REFRESH_INTERVAL,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  onError: (error) => {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getGroundEvents', createApiErrorTelemetryProperties(error));
  },
  onSuccess: () => {
    const time = getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss');
    console.log(`Ground Events API data refreshed or fetched at ${time}z`);
  },
};
/**
 * Custom hook for retrieving data from the Ground Events Information API
 * @param {string} startDate - operational start date utc formatted as yyyy-MM-DD
 * @param {string} endDate - operational end date utc formatted as yyyy-MM-DD
 * @param {boolean} enabled - enable/disable the query
 * @returns
 */
export const useGroundEventsQuery = (startDate, endDate, enabled = true) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const enabledAutoRefresh = autoRefreshFlags?.ganttChartRefresh;
  // feature flag

  const config = {
    ...baseConfig,
    enabled: enabled,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
  };

  let getGroundEventsApi;
  let startDateString;
  let endDateString;

  getGroundEventsApi = getGroundEventsV3;
  startDateString = formatDateTime(startDate, DateFormat.ISO_STANDARD);
  endDateString = formatDateTime(endDate, DateFormat.ISO_STANDARD);

  return useQuery(
    ['ground-events', startDateString, endDateString],
    () => getGroundEventsApi(startDateString, endDateString),
    config,
  );
};
