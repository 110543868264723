import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AircraftCertificateStatus } from '../../../lib/constants';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';

/**
 * AircraftCertificateIcon component
 * @param {Enum} - AircraftCertificateStatus enum
 * @returns  - Certificate Icon
 */
const AircraftCertificateIcon = ({ status }) => {
  return (
    <Fragment>
      {status === AircraftCertificateStatus.AIRCRAFT_CERTIFICATE && (
        <FontAwesomeIcon
          className={`fa-check-circle-icon icon ${status}`}
          icon={faCheckCircle}
          data-cy="aircraftCertificateIcon-icon"
          style={{ color: '#69CF96' }}
        />
      )}
      {status === AircraftCertificateStatus.MISSING_AIRCRAFT_CERTIFICATE && (
        <FontAwesomeIcon
          className={`fa-x-circle-icon icon ${status}`}
          icon={faTimesCircle}
          data-cy="missingAircraftCertificateIcon-icon"
          style={{ color: '#808399' }}
        />
      )}
      {status === AircraftCertificateStatus.TEMPORARY_AIRCRAFT_CERTIFICATE && (
        <FontAwesomeIcon
          className={`fa-check-circle-icon icon ${status}`}
          icon={faCheckCircle}
          data-cy="temporaryAircraftCertificate-icon"
          style={{ color: '#FFFFFF' }}
        />
      )}
    </Fragment>
  );
};

export default withAppInsightsTracking(AircraftCertificateIcon);
