import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';

/**
 *
 * @param {status} - status string check the icon list
 * @returns  - Certificate Icons
 */

const CertificateIcon = ({ status }) => {
  return (
    <Fragment>
      {status == 'certificateIcon' && (
        <FontAwesomeIcon
          className={`fa-check-circle-icon icon ${status}`}
          icon={faCheckCircle}
          data-cy="certificateIcon-icon"
          style={{ color: '#69CF96' }}
        />
      )}
      {status == 'missingCertificateIcon' && (
        <FontAwesomeIcon
          className={`fa-x-circle-icon icon ${status}`}
          icon={faTimesCircle}
          data-cy="missingCertificateIcon-icon"
          style={{ color: '#DF0B37' }}
        />
      )}
      {status == 'notApplicableIcon' && (
        <FontAwesomeIcon
          className={`fa-x-circle-icon icon ${status}`}
          icon={faTimesCircle}
          data-cy="notApplicableIcon-icon"
          style={{ color: '#808399' }}
        />
      )}
      {status == 'applicableIcon' && (
        <FontAwesomeIcon
          className={`fa-check-circle-icon icon ${status}`}
          icon={faCheckCircle}
          data-cy="applicableIcon-icon"
          style={{ color: '#FFFFFF' }}
        />
      )}
    </Fragment>
  );
};

export default withAppInsightsTracking(CertificateIcon);
