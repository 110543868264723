import React from 'react';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';
import checkMark from '../../../assets/images/check-mark.png';
import exclamationPoint from '../../../assets/images/exclamation-point.png';
import './NotificationToast.css';

/* EXAMPLE USAGE:  

import NotificationToast, { ToastType } from './Toast/Toast.js';

const [showToast, setShowToast] = useState(false);
const [toastMessage, setToastMessage] = useState('');
const [toastType, setToastType] = useState('');
const [autoHide, setAutoHide] = useState(true);

const handleClose = () => {
    setShowToast(false);
}

const handleSuccess = () => {
    setToastMessage('Cancel has been submitted for Flight xxx');
    setToastType(ToastType.SUCCESS);
    setShowToast(true);
    setAutoHide(true);
}

const handleError = () => {
    setToastMessage('Error canceling Flight xxx');
    setToastType(ToastType.ERROR);
    setShowToast(true);
    setAutoHide(false);
}

<NotificationToast showToast={showToast} message={toastMessage} toastType={toastType} autoHide={autoHide} onClose={handleClose} />
*/

// Toast Type
export const ToastType = {
  SUCCESS: 1,
  ERROR: 2,
};

/*
 * NotificationToast
 * Returns the notification icon and count (or nothing if no alerts)
 * @param {bool} showToast - True = Display the Toast
 * @param {string} message - The message to display in the Toast
 * @param {toastType} toastType - Display a SUCCESS or ERROR toast
 * @param {autoHide} autoHide - AutoHide toast or dont AutoHide
 * @param {func} onClose - Tell the parent the control should be closed
 */
const NotificationToast = ({
  showToast,
  message,
  toastType,
  autoHide,
  onClose,
  style = {
    toast: {
      minWidth: '30rem',
    },
    textStyle: {
      fontSize: '1.6rem',
      fontWeight: '700',
    },
  },
  isModal = false,
  className = '',
}) => {
  let displayBackgroundColor = '';
  let displayAlertColor = '';
  let toastImage = '';

  let autoHideFlag = autoHide ?? false;
  // Set the correct toast colors, icon and behavior
  if (toastType === ToastType.SUCCESS) {
    displayBackgroundColor = 'success-background';
    displayAlertColor = 'success-alert';
    toastImage = checkMark;
  } else {
    displayBackgroundColor = 'error-background';
    displayAlertColor = 'error-alert';
    toastImage = exclamationPoint;
  }

  const Wrapper = ({ children }) => {
    return isModal ? (
      <div>
        {children}
        <div class="toast-backdrop" hidden={!showToast}></div>{' '}
      </div>
    ) : (
      <div>{children}</div>
    );
  };

  return (
    <Wrapper>
      <Toast
        onClose={onClose}
        show={showToast}
        delay={3000}
        autohide={autoHideFlag}
        style={style.toast}
        data-cy="notification-toast-box"
        className={`notification-toast ${className}`}
      >
        <Toast.Header className={displayBackgroundColor}>
          <div className={`side-indicator ${displayAlertColor}`}>
            <img className="toast-image" src={toastImage} alt="toast" />
          </div>
          <div data-cy="notification-toast-text" className="message-text" style={style.textStyle}>
            {message.split('\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>
        </Toast.Header>
      </Toast>
    </Wrapper>
  );
};

NotificationToast.propTypes = {
  showToast: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  toastType: PropTypes.oneOf([ToastType.SUCCESS, ToastType.ERROR]).isRequired,
  autoHide: PropTypes.oneOf([true, false]),
  onClose: PropTypes.func.isRequired,
};

export default NotificationToast;
