import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import appSettings from '../../appSettings';

const browserHistory = createBrowserHistory({ basename: '' });

//console.debug("AI Key = " + appSettings.APPINSIGHTS_INSTRUMENTATIONKEY);
// Get the domain for Fed Auth so AppInsights knows to exclude it.
const fedAuthDomain = appSettings.OIDC_PROVIDER.replace('https://', '').replace('http://', '').replace('/', '');
//console.debug("Correlation excluded domain: " + fedAuthDomain);
const appInsightsReactPlugin = new ReactPlugin();
const appInsightsInitializer = new ApplicationInsights({
  config: {
    instrumentationKey: appSettings.APPINSIGHTS_INSTRUMENTATIONKEY,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    // List domains that can't access the request-id, and request-context headers.
    // https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation
    correlationHeaderExcludedDomains: [fedAuthDomain, , 'col.eum-appdynamics.com'],
    extensions: [appInsightsReactPlugin],
    extensionConfig: {
      [appInsightsReactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsightsInitializer.loadAppInsights();

export const appInsightsClient = appInsightsInitializer;

/**
 * HOC to wrap a component with App Insights tracking.
 * @param {React.Component} Component - component to wrap
 * @param {string} className - class name to add to the container div
 * @returns HOC with App Insights tracking
 */
export const withAppInsightsTracking = (Component, className = 'app-insights-tracking-container') =>
  withAITracking(appInsightsReactPlugin, Component, undefined, className);
