import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';

const useStyles = makeStyles((theme) => ({
  brickLabel: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '.3rem',
  },
  asteriskDisplay: {
    color: 'var(--error-color)',
  },
}));

/**
 * Generic Brick Container that can display content plus a title.
 * A custom CSS class can be applied if desired
 * @param {string} title - title of the brick container
 * @param {string} customCSSTag - optional CSS class that should be applied to the brick container
 * @param {string} dataCyTag - data-cy test id tag for testing purposes
 *  @param {boolean} isRequired - required field for the brick container
 * @returns {BrickContainer} the BrickContainer component.
 */
const BrickContainer = ({ title, customCSSTag = '', dataCyTag = '', isRequired = false, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.brickLabel} ${customCSSTag}`} data-cy={`${dataCyTag}-title`}>
        {title}
        {isRequired && (
          <span className={classes.asteriskDisplay} data-cy="asterisk-display">
            &nbsp;*
          </span>
        )}
      </div>
      <div className={customCSSTag} data-cy={`${dataCyTag}-value`}>
        {props.children}
      </div>
    </>
  );
};

BrickContainer.propTypes = {
  title: PropTypes.string.isRequired,
  customCSSTag: PropTypes.string,
  dataCyTag: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default withAppInsightsTracking(BrickContainer);
