import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';

const AircraftTab = (aircraftTabDetails) => {
  //TODO: implement tab skeleton
  return <div>Aircraft Details</div>;
};

AircraftTab.propTypes = {
  aircraftTabDetails: PropTypes.object,
};

export default withAppInsightsTracking(AircraftTab);
