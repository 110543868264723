import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { FilterActions } from '../../redux/actionTypes';
import { useCallback } from 'react';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Selector for the start and end dates from the filter store
 */
const selectFilterSearchDates = createSelector(
  (state) => state.filterReducer.filter,
  (filter) => ({ startDate: filter.startDate, endDate: filter.endDate, timezone: filter.timezone }),
);

/**
 * Selector for the entire filter store
 */
const selectFilter = (state) => state.filterReducer;

/**
 * Custom hook for retrieving the start and end dates from the filter store
 * @returns selector hook for retrieving filter search dates
 */
export const useFilterSearchDatesStore = () => {
  return useSelector(selectFilterSearchDates);
};

/**
 * Custom hook for retrieving the entire filter store
 * @returns selector hook for retrieving filter store
 */
export const useFilterStore = () => {
  return useSelector(selectFilter);
};

/**
 * Custom hook for dispatching actions to update or clear the filter store
 * @returns object of dispatch actions to interact with the filter store
 */
export const useFilterDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to update the filter store
   * @param {Object} filter - the updated filter
   */
  const updateFilter = useCallback(
    (filter) => {
      dispatch({ type: FilterActions.UPDATE_FILTER, payload: { ...filter } });
    },
    [dispatch],
  );

  const receivedOperatingAirlineRole = useCallback((payload) => {
    dispatch({ type: FilterActions.RECEIVED_OPERATING_AIRLINE_BY_ROLE, payload: { ...payload } });
  });

  /**
   * Callback to set the defaultAssignmentGroupTypeName in filter store
   * @param {Object} filter - filter with value of defaultAssignmentGroupTypeName
   */
  const receivedDefaultAssignmentFilter = useCallback(
    (payload) => {
      dispatch({ type: FilterActions.RECEIVED_DEFAULT_ASSIGNMENT_FILTER, payload: { ...payload } });
    },
    [dispatch],
  );

  /**
   * Callback to change timezone in filter
   * @param {String} timezone - filter with value of timezone
   */
  const receivedTimeZoneSetting = useCallback(
    (timezone) => {
      dispatch({ type: FilterActions.RECEIVED_TIME_ZONE_SETTING, payload: { ...timezone } });
    },
    [dispatch],
  );

  /**
   * Callback to clear the filter store
   */
  const clearFilter = useCallback(() => {
    dispatch({ type: FilterActions.CLEAR_FILTER });
  }, [dispatch]);

  return {
    updateFilter,
    receivedOperatingAirlineRole,
    clearFilter,
    receivedDefaultAssignmentFilter,
    receivedTimeZoneSetting,
  };
};
