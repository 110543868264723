import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { KeyCodes, WarningTypes } from '../../../lib/constants';
import { makeStyles } from '@material-ui/core/styles';
import './AirportCodeInput.css';

const NoResultsKey = 'unknown';
const MaxTextInputCharacters = 3;

const useStyles = makeStyles((theme) => ({
  rootStyle: {
    height: '4.4rem',
    borderRadius: '0.3rem',
    fontSize: 'var(--defaultFontSize)',
  },
  normalStyle: {
    border: '2px solid var(--secondary-border-color)',
    borderRadius: '0.3rem',
    fontSize: 'var(--defaultFontSize)',
    height: '4.4rem',
    backgroundColor: 'transparent',
    color: 'var(--page-container-text)',
  },
  warningStyle: {
    border: '2px solid var(--warning-color)',
    borderRadius: '0.3rem',
    fontSize: 'var(--defaultFontSize)',
    height: '4.4rem',
  },
  errorStyle: {
    border: '2px solid var(--error-color) !important',
    borderRadius: '0.3rem',
    fontSize: 'var(--defaultFontSize)',
    height: '4.4rem',
  },
  warningLabel: {
    fontSize: '1.15rem',
    position: 'relative',
    paddingTop: '2px',
  },
}));

/**
 * The AirportCodeInput component is a text input for airport codes.
 * It also provides search suggestions through a dropdown.
 * @param {Object} props -
 * @param {Object} airports - a list of airport objects
 * @param {function} onChange - callback func when text input changes
 * @param {string} testId - dataCy for textbox
 * @param {string} inputVal- value of textbox
 * @param {isAutoFocus} isAutoFocus - auto focus datepicker
 * @param {enum} warningType - "error" or "warning", will drive either the red or orange outline and message
 * @param {string} warningMessage - message to display under label  
 
 * @returns AirportCodeInput component
 */
const AirportCodeInput = (
  {
    airports = [],
    onChange,
    testId = 'airport-code-input',
    inputVal,
    isAutoFocus = false,
    warningType = WarningTypes.NONE,
    warningMessage = '',
    dataCyTag = '',
    className = '',
  },
  ref,
) => {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(isAutoFocus);
  const [showDropdown, setShowDropdown] = useState(false);

  const inputRef = useRef(null);

  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    focus: () => {
      setFocus(true);
    },
    setAirport: (value) => {
      setValue(value);
    },
  }));
  /**
   * @description get warning div style
   * @returns color style string
   */
  const getWarningStyle = () => {
    if (warningType === WarningTypes.WARNING) {
      return 'var(--warning-color)';
    } else {
      if (warningType === WarningTypes.ERROR) {
        return 'var(--error-color)';
      } else {
        return '';
      }
    }
  };

  /**
   * Event handler when a dropdown item is clicked
   * Closes the dropdown menu and disables autoFocus of this component
   * @param {String} eventKey - the airport code
   */
  const handleSelect = (eventKey, event) => {
    if (eventKey !== NoResultsKey) {
      const newValue = eventKey.toUpperCase();
      setValue(newValue);
      let target = inputRef.current.querySelector('input');
      onChange(newValue, event, target);
    }
    setShowDropdown(false);
    setFocus(false);
  };
  const handleKeyDown = (airportCode, event) => {
    if (event.keyCode === KeyCodes.TAB || event.keyCode === KeyCodes.ENTER) {
      handleSelect(airportCode, event);
    }
  };
  /**
   * Event handler when new text input has been made
   * @param {Object} e - event
   */
  const handleTextInputChange = (e) => {
    const removedSpChars = e.target.value.replace(/[\W_]+/g, '');
    //const removedDigits= removedSpChars.replace(/[0-9]/g, "");
    const newValue = removedSpChars.substring(0, MaxTextInputCharacters).toUpperCase();
    setValue(newValue);
    setFocus(true);
    setShowDropdown(newValue !== '');
    let target = inputRef.current.querySelector('input');
    onChange(newValue, e, target);
  };

  const handleTextOnKeyDown = (e) => {
    if (e.keyCode == KeyCodes.ENTER) {
      handleTextInputChange(e);
    }
  };
  /**
   * Event handler when focus has moved out of this component
   * Closes the dropdown menu and disables autoFocus of this component
   * @param {Object} e - event
   */
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowDropdown(false);
      setFocus(false);
    }
  };

  /**
   * Returns the formatted airport display for the dropdown menu
   * Example format: "SEA - Seattle-tacoma Intl. (Seattle, WA)"
   * @param {Object} airport - the airport object to format
   * @returns formatted JSX of dropdown menu item text
   */
  const getFormattedAirport = (airport) => {
    // Bold matching airport letters
    let airportCode = !!airport.airportCode ? airport.airportCode : '';
    let airportCodeMatch = '';
    let airportCodeNonMatch = airportCode;
    if (value !== '') {
      if (airportCode.startsWith(value)) {
        airportCodeMatch = airportCode.slice(0, value.length);
        airportCodeNonMatch = airportCode.slice(value.length, airportCode.length);
      } else if (airportCode === value) {
        airportCodeMatch = airportCode;
        airportCodeNonMatch = '';
      }
    }

    return (
      <>
        <span className="airport-code">
          <span className="match">{airportCodeMatch}</span>
          <span className="non-match">{airportCodeNonMatch}</span>
        </span>
        {' - '}
        <span className="airport-name">{airport.airportName}.</span>{' '}
        <span className="airport-city">
          ({airport.airportCity}, {airport.airportState})
        </span>
      </>
    );
  };

  const getClassStyle = (typeOfWarning) => {
    let classStyle = classes.normalStyle;
    if (typeOfWarning === WarningTypes.WARNING) {
      classStyle = classes.warningStyle;
    } else {
      if (typeOfWarning === WarningTypes.ERROR) {
        classStyle = classes.errorStyle;
      }
    }
    return classStyle;
  };

  /**
   * Custom text input component to override dropdown toggle
   */
  const TextInput = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <Form.Control
        autoFocus={focus}
        ref={ref}
        type="text"
        autoComplete="off"
        id={`airport-code-input-${testId}`}
        className={`airport-code-text-input ${getClassStyle(warningType)} ${className}`}
        data-cy={testId}
        onKeyDown={handleTextOnKeyDown}
        onChange={handleTextInputChange}
        value={inputVal ? inputVal : value.toUpperCase()}
      />
    );
  });

  let filteredAirports = airports.filter((a) => !value || a.airportCode?.startsWith(value.toUpperCase()));
  let dropdownItems =
    filteredAirports.length > 0 ? (
      filteredAirports.map((fa) => (
        <Dropdown.Item
          onSelect={handleSelect}
          onKeyDown={(event) => handleKeyDown(fa.airportCode, event)}
          eventKey={fa.airportCode}
          key={fa.airportCode}
          data-cy="airport-code-input-dropdown-item"
        >
          {getFormattedAirport(fa)}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item onSelect={handleSelect} eventKey={NoResultsKey}>
        <span className="no-results">No Results</span>
      </Dropdown.Item>
    );

  // prevent re-computing dropdown positioning
  const popperConfig = {
    modifiers: [
      {
        name: 'computeStyles',
        enabled: false,
      },
    ],
  };
  return (
    <>
      <Dropdown className="airport-code-input" show={showDropdown} onBlur={handleBlur} ref={inputRef}>
        <Dropdown.Toggle as={TextInput} />
        <Dropdown.Menu
          data-cy="airport-code-input-dropdown"
          className="airport-code-input-dropdown"
          popperConfig={popperConfig}
        >
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
      {warningType !== WarningTypes.NONE ? (
        <div
          data-cy={`${dataCyTag}-validation-message`}
          style={{ color: `${getWarningStyle()}` }}
          className={`${classes.warningLabel}`}
        >
          {' '}
          {warningMessage}{' '}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(AirportCodeInput);

AirportCodeInput.propTypes = {
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      airportCode: PropTypes.string.isRequired,
      airportName: PropTypes.string.isRequired,
      airportCity: PropTypes.string.isRequired,
      airportState: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};
