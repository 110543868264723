import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import SingleSelectDropdown from '../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import {
  useUserPreferencesData,
  useUserPreferencesFunctions,
} from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import './TimelineHoursDropdown.css';

const timelineHoursItems = [
  { key: '12', name: '12 Hours' },
  { key: '24', name: '24 Hours' },
  { key: '36', name: '36 Hours' },
  { key: '48', name: '48 Hours' },
  { key: '60', name: '60 Hours' },
  { key: '72', name: '72 Hours' },
  { key: '84', name: '84 Hours' },
  { key: '96', name: '96 Hours' },
];

/**
 * The Timeline Hours Dropdown component is Gantt Scaling - Timeline Hours Dropdown.
 * @returns timeline hours dropdown component
 */
const TimelineHoursDropdown = () => {
  const { state: userPreferencesState } = useUserPreferencesData();
  const { updateTimelineHourPreference } = useUserPreferencesFunctions();
  // Default time line hours
  const defaultTimeLineHours = !userPreferencesState.loading ? userPreferencesState.timelineHours.timelineHours : '12';
  const [timelineHours, setTimelineHours] = useState(defaultTimeLineHours);

  const TimelineHoursValues = useMemo(() => {
    return { items: timelineHoursItems, placeholder: timelineHours };
  }, [timelineHours]);

  /**
   * Event trigger on change event of Timeline Hours Dropdown.
   * @param {any} event - Event triggern when the input is updated
   */
  const handleTimelineHoursSelection = (event) => {
    setTimelineHours(event.target.value);

    // Save value to users' preferences
    updateTimelineHourPreference(event.target.value);
  };

  useEffect(() => {
    if (!userPreferencesState.loading) {
      const newTimeLineHours = userPreferencesState.timelineHours.timelineHours;
      if (newTimeLineHours !== timelineHours) {
        setTimelineHours(newTimeLineHours);
      }
    }
  }, [userPreferencesState.loading, userPreferencesState.timelineHours?.timelineHours, timelineHours]);

  return (
    <div className="timeline-hours-container" data-cy="timeline-hours-container">
      {
        <SingleSelectDropdown
          displayText={TimelineHoursValues.placeholder}
          displayList={TimelineHoursValues.items}
          className="timeline-hours-filter"
          dataCyTag="timeline-hours"
          isDisabled={false}
          onChange={handleTimelineHoursSelection}
        />
      }
    </div>
  );
};

TimelineHoursDropdown.propTypes = {
  onChange: PropTypes.func,
};

export default withAppInsightsTracking(TimelineHoursDropdown);
