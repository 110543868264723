import { createTheme } from '@material-ui/core/styles';

export const baseTheme = createTheme({
  typography: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    body1: {
      fontSize: '1.4rem',
    },
  },
  props: {
    MuiFilledInput: {
      disableunderline: 'true',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
});
