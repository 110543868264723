import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'airtrakmanager';
const moduleName = 'airtrakManagerApi';

/**
 * POST request to gantt config endpoint
 * @param {Object} payload
 * @returns
 */
export const getGanttConfigFilter = async (payload, showBlockPucksFeature = false) => {
  const apiClient = axiosInstance;
  const url = `${API_ROUTE}/api/${showBlockPucksFeature ? 'v2' : 'v1'}/Gantt/filter`;
  return await apiClient.post(url, payload);
};

/** * Returns get Gantt Config Filter Data for a given list of flight legs */
export const getFlightSummary = async (flightDetails) => {
  // Check if the required parameters are present.
  if (
    !flightDetails ||
    Object.keys(flightDetails).length === 0 ||
    Object.values(flightDetails).some((value) => value == undefined)
  ) {
    console.log('getFlightSummary: Required parameters are missing.');
    return;
  }

  const { airline, origin, destination, flightNumber, departureCount, scheduledOperatingDate } = flightDetails;

  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/Summary/flight/${flightNumber}?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${airline}&departureCount=${departureCount}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getFlightSummary', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

//** * Returns get Gantt Config Filter Data for Summary Details for a given flight legs */
export const getFlightSummaryDetails = async (flightDetails) => {
  const { airline, origin, destination, flightNumber, departureCount, scheduledOperatingDate } = flightDetails;

  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/DetailPanel/flight/${flightNumber}?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${airline}&departureCount=${departureCount}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'getFlightSummaryDetails',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result;
};

/** Returns get EquipmentChange Data for a given flight legs */
export const getEquipmentChange = async (flightLeg) => {
  const { operatingAirlineCode, origin, destination, flightNumber, departureCount, scheduledOperatingDate } = flightLeg;

  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/EquipmentChange/flight/${flightNumber}?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${operatingAirlineCode}&departureCount=${departureCount}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getEquipmentChange', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/** Creates an equipment change acknowledgement for the flight leg  */
export const postEquipmentChangeAck = async (flightLeg) => {
  const { flightNumber, operatingAirlineCode, origin, destination, departureCount, scheduledOperatingDate } = flightLeg;

  let result = null;
  const apiClient = axiosInstance;

  try {
    const url = `${API_ROUTE}/api/v1/EquipmentChange/acknowledgment/flight/${flightNumber}?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${operatingAirlineCode}&departureCount=${departureCount}`;
    const response = await apiClient.post(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'postEquipmentChangeAck', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/** Creates an equipment change acknowledgement for the flight leg  */
export const postEquipmentChangeAckTail = async (flightLeg) => {
  const { aircraftRegistration, startDateTimeUTC, endDateTimeUTC } = flightLeg;

  let result = null;
  const apiClient = axiosInstance;

  try {
    const url = `${API_ROUTE}/api/v1/EquipmentChange/acknowledgment/aircraft/${aircraftRegistration}?startDateTimeUTC=${startDateTimeUTC}&endDateTimeUTC=${endDateTimeUTC}`;
    const response = await apiClient.post(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'postEquipmentChangeAckTail',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result;
};

/** Deletes an equipment change acknowledgement for the flight leg  */
export const deleteEquipmentChangeAck = async (flightLeg) => {
  const { flightNumber, operatingAirlineCode, origin, destination, departureCount, scheduledOperatingDate } = flightLeg;

  let result = null;
  const apiClient = axiosInstance;

  try {
    const url = `${API_ROUTE}/api/v1/EquipmentChange/acknowledgment/flight/${flightNumber}?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${operatingAirlineCode}&departureCount=${departureCount}`;
    const response = await apiClient.delete(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'deleteEquipmentChangeAck',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result;
};

//** Aircraft Details By Flight Number */
export const getAircraftDetailsByFlightNumber = async (flightDetails) => {
  const { airline, origin, destination, flightNumber, departureCount, scheduledOperatingDate } = flightDetails;

  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/DetailPanel/flight/${flightNumber}/aircraft?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${airline}&departureCount=${departureCount}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'getAircraftDetailsByFlightNumber',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result;
};

// Function to get Aircraft details by aircraft registration number
export const getAircraftDetailsByRegistration = async (aircraftRegistration) => {
  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/detailpanel/aircraft/${aircraftRegistration}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getAircraftDetails', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

//** Aircraft Crews By Flight Number */
export const getAircraftCrewMembers = async (flightDetails) => {
  const { airline, origin, destination, flightNumber, departureCount, scheduledOperatingDate } = flightDetails;

  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/DetailPanel/flight/${flightNumber}/crew?scheduledOperatingDateUTC=${scheduledOperatingDate}&origin=${origin}&destination=${destination}&operatingAirline=${airline}&departureCount=${departureCount}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getAircraftCrewMembers', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw Error(error);
  }
  return result;
};
