import { FilterActions } from '../actionTypes';
import { SessionStorageKeys, TimeZones } from '../../lib/constants';
import {
  getDefaultFilter,
  jsonEqual,
  mapAirlineCodeToAirlineFilter,
  getDefaultFilterForSwapMode,
} from '../../lib/utils';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const isNullOrUndefined = (value) => value === null || value === undefined;

/**
 * Gets the initial state for the filters store.
 * It uses any saved filter in session storage if available, or returns the default filter.
 * Only exporting for unit tests
 * @returns the initial state for the filters store
 */
export const getInitialState = (id) => {
  // Default initial state
  let initialState = {
    filter: getDefaultFilter(),
    previousFilter: null,
    isDefaultFilter: true,
    isSwapModeFilter: false,
    defaultAssignmentFilter: null,
    timezone: TimeZones.UTC,
    operatingAirlineByRole: null,
  };

  // First try to get the initial state from session storage
  try {
    const savedFilterStates = getSessionStorageItem(SessionStorageKeys.FILTER);
    let savedFilterState;
    if (!isNullOrUndefined(id) && savedFilterStates && Object.keys(savedFilterStates).includes(id.toString())) {
      savedFilterState = savedFilterStates[id];
    } else if (isNullOrUndefined(id) && savedFilterStates && Object.keys(savedFilterStates).includes('filter')) {
      savedFilterState = savedFilterStates;
    }
    if (savedFilterState) {
      // Convert UTC ISO string to dayjs object
      const { startDate, endDate } = savedFilterState.filter;
      savedFilterState.filter.startDate =
        savedFilterState.timezone === TimeZones.UTC
          ? dayjs.utc(startDate).startOf('day')
          : dayjs.utc(startDate).tz(savedFilterState.timezone);

      savedFilterState.filter.endDate =
        savedFilterState.timezone === TimeZones.UTC
          ? dayjs.utc(endDate).startOf('day')
          : dayjs.utc(endDate).tz(savedFilterState.timezone);

      initialState = savedFilterState;
    }
  } catch (error) {
    console.error('Error parsing saved filter from session storage', error);
  }

  return initialState;
};

/**
 * Given the previous state and action payload, returns the new state for the filters store
 */
const filterReducer = (state = getInitialState(), action) => {
  let swapStartDate;
  let swapEndDate;

  if (!state || state.timezone === TimeZones.UTC) {
    swapStartDate = dayjs.utc().startOf('day');
    swapEndDate = dayjs.utc(swapStartDate).add(7, 'day');
  } else {
    swapStartDate = dayjs().tz(state.timezone, true).startOf('day');
    swapEndDate = dayjs().tz(state.timezone, true).add(7, 'day').endOf('day');
  }
  switch (action.type) {
    case 'INIT': {
      return getInitialState(action.id);
    }
    case FilterActions.UPDATE_FILTER:
      return {
        ...state,
        isDefaultFilter: state.isSwapModeFilter
          ? jsonEqual(
              action.payload,
              getDefaultFilterForSwapMode(state.defaultAssignmentFilter, state.timezone, state.operatingAirlineByRole),
            )
          : jsonEqual(action.payload, getDefaultFilter(state.defaultAssignmentFilter, state.timezone)),
        filter: { ...action.payload },
      };
    case FilterActions.CLEAR_FILTER: {
      return {
        ...state,
        isDefaultFilter: true,
        filter: state.isSwapModeFilter
          ? {
              ...getDefaultFilterForSwapMode(
                state.defaultAssignmentFilter,
                state.timezone,
                state.operatingAirlineByRole,
              ),
              startDate: swapStartDate,
              endDate: swapEndDate,
            }
          : getDefaultFilter(state.defaultAssignmentFilter, state.timezone),
      };
    }
    case FilterActions.RECEIVED_OPERATING_AIRLINE_BY_ROLE:
      return {
        ...state,
        operatingAirlineByRole: action.payload?.operatingAirlineRole,
      };
    case FilterActions.RECEIVED_DEFAULT_ASSIGNMENT_FILTER: {
      if (state.defaultAssignmentFilter !== action.payload.defaultAssignmentFilter) {
        // If the default assignment filter has changed, which can happen when the user is changing role,
        // reset the filter to the default values
        return {
          ...state,
          defaultAssignmentFilter: action.payload.defaultAssignmentFilter,
          isDefaultFilter: true,
          filter: getDefaultFilter(action.payload.defaultAssignmentFilter, state.timezone),
        };
      }
      return state;
    }
    case FilterActions.RECEIVED_TIME_ZONE_SETTING: {
      const { timezone } = action.payload;
      if (timezone !== state.timezone) {
        // If the timezone has changed, reset the start and end dates to default values
        const { startDate, endDate } = getDefaultFilter(state.defaultAssignmentFilter, timezone);
        return {
          ...state,
          timezone,
          filter: {
            ...state.filter,
            timezone,
            startDate,
            endDate,
          },
        };
      }
      return state;
    }
    case FilterActions.ENABLE_SWAP_MODE_FILTER: {
      return {
        ...state,
        isDefaultFilter: jsonEqual(state.filter, getDefaultFilter(state.defaultAssignmentFilter, state.timezone)),
        isSwapModeFilter: true,
        filter: {
          ...state.filter,
          startDate: swapStartDate,
          endDate: swapEndDate,
          airline: [mapAirlineCodeToAirlineFilter(state.operatingAirlineByRole)],
        },
        previousFilter: {
          ...state.filter,
        },
      };
    }
    case FilterActions.CLEAR_SWAP_MODE_FILTER: {
      return {
        ...state,
        isDefaultFilter: jsonEqual(
          state.previousFilter,
          getDefaultFilter(state.defaultAssignmentFilter, state.timezone),
        ),
        isSwapModeFilter: false,
        filter: {
          ...state.previousFilter,
        },
        previousFilter: null,
      };
    }
    default:
      return state;
  }
};

export default filterReducer;
