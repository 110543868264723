import IconButton from '@mui/material/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as CopySVG } from '../../../assets/images/copy-icon.svg';
import React from 'react';

/**
 * AirtrAK Logo component
 * @returns AirtrAK logo
 */
const CopyButtonIcon = ({ title, testId, onClick }) => {
  return (
    <Tooltip isClicked={title == 'Copied'} className="copy-tooltip" title={title}>
      <IconButton onClick={onClick} data-cy={`copy-${testId}-icon`}>
        <CopySVG className="copy-icon" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButtonIcon;
