import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faAngleLeft, faAngleRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { KeyCodes } from '../../../lib/constants';
import './SearchInput.css';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    searchContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      cursor: 'text',
    },
    inactiveColor: {
      color: 'var(--darkerGray)',
    },
    activeColor: {
      color: 'var(--flight-nav-bar-icon-fill)',
    },
    searchIcon: {
      color: 'var(--flight-nav-bar-icon-fill)',
      fontSize: '1.6rem',
      cursor: 'pointer',
    },
    searchClearIcon: {
      color: 'var(--flight-nav-bar-search-icon-fill)',
      fontSize: '1.4rem',
      cursor: 'pointer',
    },
    leftAngle: {
      marginLeft: '0.4rem',
    },
    leftAngleContainer: {
      padding: '5px',
      cursor: 'pointer',
    },
    rightAngle: {
      marginRight: '0.4rem',
    },
    rightAngleContainer: {
      padding: '5px',
      cursor: 'pointer',
    },
    matchesDiv: {
      display: 'flex',
      alignItems: 'center',
      fontSize: `1.4rem`,
      height: '100%',
      backgroundColor: `var(--flight-nav-bar-icon-background)`,
      paddingRight: '0.8rem',
      cursor: 'default',
    },
    matchesText: {
      marginRight: `0.6rem`,
      fontFamily: 'inherit',
      color: 'var(--flight-nav-bar-search-text)',
    },
    navSection: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
    focused: {},
    disabled: {},
  };
});

/**
 * @param {string} label
 * @param {string} value
 * @param {number} matchCount
 * @param {bool} hasPrevious
 * @param {bool} hasNext
 * @param {func} onChange
 * @param {func} onEnter
 * @param {func} onPreviousClick
 * @param {func} onNextClick
 * @param {func} onClear
 * @param {func} onSearchClick
 * @returns  {SearchInput} SearchInput component
 */
const SearchInput = ({
  label = 'Search',
  value = '',
  matchCount = null,
  hasPrevious = false,
  hasNext = false,
  onChange = () => {},
  onEnter = () => {},
  onPreviousClick = () => {},
  onNextClick = () => {},
  onClear = () => {},
  onSearchClick = () => {},
  ...props
}) => {
  const classes = useStyles();

  const isFullWidth = false;

  /**
   * descriptor - checks if keydown is ENTER key and calls onEnter handler
   * @param {any} event
   */
  const handleOnTextKeyDown = (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      onEnter(event);
    }

    if (event.keyCode == '27') {
      onClear();
    }
  };

  /**
   * renderSearchMatchCount - renders the match count and match string
   * @param {matchCount} - number of matches
   * @param {displayMatchString} - string to displayMatchString
   * @returns JSX element
   */
  const renderSearchMatchCount = (matchCount, displayMatchString) => {
    return (
      <div className={`${classes.inactiveColor} ${classes.matchesDiv}`}>
        <span className={`${classes.matchesText}`}>{`${matchCount || ''} ${displayMatchString || ''}`}</span>
        <FontAwesomeIcon
          className={`${classes.searchClearIcon}`}
          icon={faCircleXmark}
          onClick={onClear}
          data-cy="searchinput-clear-icon"
        />
      </div>
    );
  };
  /**
   * renderMatchCount - renders the match count and match string
   * @param {matchCount}- the number of matches
   * @returns JSX element
   */
  const renderMatchCount = (matchCount) => {
    if (matchCount === 0) {
      return renderSearchMatchCount(matchCount, 'No match');
    } else if (matchCount === 1) {
      return renderSearchMatchCount(matchCount, 'match');
    } else if (matchCount > 1) {
      return renderSearchMatchCount(matchCount, 'matches');
    }
    return <></>;
  };

  return (
    <div className={`searchinput ${classes.searchContainer}`} data-cy="searchinput">
      <TextField
        variant="outlined"
        autoComplete="outlined"
        name="name"
        value={value}
        required
        fullWidth={isFullWidth}
        onKeyDown={handleOnTextKeyDown}
        onChange={onChange}
        placeholder={label}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon
                className={`${classes.searchIcon}`}
                icon={faMagnifyingGlass}
                data-cy="searchinput-search-icon"
                onClick={onSearchClick}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {renderMatchCount(matchCount)}
              <div className={`${classes.navSection}`}>
                <div className={classes.leftAngleContainer} onClick={onPreviousClick}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    size="lg"
                    className={`${classes.leftAngle} ${hasPrevious ? classes.activeColor : classes.inactiveColor}`}
                  />
                </div>
                <div className={classes.rightAngleContainer} onClick={onNextClick}>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    size="lg"
                    className={`${classes.rightAngle} ${hasNext ? classes.activeColor : classes.inactiveColor}`}
                  />
                </div>
              </div>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

SearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  matchCount: PropTypes.number,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onClear: PropTypes.func,
  onSearchClick: PropTypes.func,
};

export default SearchInput;
