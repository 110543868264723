import { useQuery } from 'react-query';
import { getAirlineAssignments, getRoleAssignments } from '../../services/apiClient/assignmentsApi/assignmentsApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';

const moduleName = 'useAssignmentsQuery';
/**
 * @description Custom hook for retrieving airline assignment data from assignmentsAPI.
 * @param {string} airlineCode
 * @returns List of airlines the user is assigned to or an error if the API call has an error
 */
export const useAirlineAssignmentsQuery = (airlineCode) => {
  const config = {
    retry: appSettings.RETRY_QUERY_DELAY,
    refetchOnWindowFocus: false,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'getAirlineAssignments',
        createApiErrorTelemetryProperties(error),
      );
    },
  };
  return useQuery(['airline assignment', airlineCode], () => getAirlineAssignments(airlineCode), config);
};

/**
 * @description Custom hook for retrieving role assignment data from assignmentsAPI.
 * @param {string} roleIds
 * @returns List of roles the user is assigned to or an error if the API call has an error
 */
export const useRoleAssignmentsQuery = (roleIds) => {
  const config = {
    retry: appSettings.RETRY_QUERY_DELAY,
    refetchOnWindowFocus: false,
    enabled: roleIds?.length > 0,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'getRoleAssignments', createApiErrorTelemetryProperties(error));
    },
  };
  return useQuery(['role assignment', roleIds], () => getRoleAssignments(roleIds), config);
};
