import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';
import { formatDateTime } from '../../../lib/dateTimeUtils';

const API_ROUTE = 'score';
const moduleName = 'scoresApi';

/**
 * Returns revenue scores for a given list of flight legs
 *
 * @param {string} airlineCode
 * @param {Number} flightNumber
 * @param {string} scheduledOperatingDateUtc
 * @param {string} scheduledOrigin
 * @param {string} scheduledDestination
 */
export const getRevenueScores = async (flightLegs) => {
  let result = null;

  if (!flightLegs || flightLegs.length === 0) {
    return result;
  }

  const apiClient = axiosInstance;
  const payload = {
    flightScoreRequests: flightLegs.map((f) => {
      return {
        airlineCode: f.airline,
        flightNumber: f.flightNumber?.toString(),
        scheduledOperatingDateUTC: formatDateTime(f.std, 'YYYY-MM-DD'),
        scheduledOrigin: f.orig,
        scheduledDestination: f.scheduledDestination,
      };
    }),
  };

  try {
    const url = `${API_ROUTE}/api/v2/scores/flight`;
    const response = await apiClient.post(url, payload);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getRevenueScores', createApiErrorTelemetryProperties(error));
    console.error(error);
  }

  return result;
};
