import { axiosInstance } from '../apiClient';
import {
  getApiErrorMessage,
  logError,
  logInformation,
  createApiErrorTelemetryProperties,
} from '../../../lib/appInsightsUtils';

const API_ROUTE = 'swapsqueuepublisher';
const moduleName = 'swapsApi';

/**
 * @description Swaps API Call
 * @param {object} swapsPayload - payload with swaps
 * @returns {Object} ApiStatusResult with success or failure
 */
export const swapsApi = async (swapsPayload) => {
  const apiClient = axiosInstance;
  let result = null;
  const formattedLogPayload = swapsPayload.map((payload) => {
    return `flightNumber: ${payload.flightNumber}, 
        aircraftRegistration: ${payload.aircraftRegistration}, 
        flightLeg: ${payload.actualOrigin} - ${payload.actualDestination},
        operatingAirline: ${payload.operatingAirlineCode},
        scheduledOperatingDate: ${payload.scheduledOperatingDateUTC},
        departureCount: ${payload.departureCount},
        swapReason : ${payload.SwapReason}, 
        swapReasonComment: ${payload.SwapReasonComment}, 
        *******
        `;
  });

  try {
    logInformation(
      `Submitting Swaps API POST request for payload: \n${formattedLogPayload.join('\n')}`,
      moduleName,
      'swaps',
    );
    const url = `${API_ROUTE}/api/v1/swaps/submit`;
    const response = await apiClient.post(url, swapsPayload);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'swaps', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
