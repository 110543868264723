import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { NavLink, useLocation } from 'react-router-dom';
import './PageNavigationBar.css';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames, KeyCodes } from '../../../lib/constants';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';

/**
 * The Page Navigation Bar allows users to navigate between pages.
 * @return {PageNavigationBar} The Page Navigation component
 */
export const PageNavigationBar = () => {
  const location = useLocation();

  const { isSwapModeActive } = useSwapModeStore();

  /**
   * @description returns the class name to use for a nav bar item
   * @param {string} pathname - path of page i.e. /users or /flights
   * @returns class name to use whether the path is the current selected page or not
   */
  const getClassName = (pathname) => {
    if (pathname.startsWith('/dashboard') && isSwapModeActive) {
      return 'page-nav-bar-item-disabled';
    }
    if (location.pathname === '/configuration') {
      return 'page-nav-bar-item-hide';
    }

    return location.pathname === pathname ? 'page-nav-bar-item-selected' : 'page-nav-bar-item-unselected';
  };

  // Event trigger on handleKeyDown for flight list icon
  const handleKeyDown = (event) => {
    if (event.keyCode === KeyCodes.ENTER && isSwapModeActive) {
      event.preventDefault();
    }
  };

  return (
    <Nav data-cy="page-nav-bar" className="page-nav-bar">
      <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
        {/* System Admin links */}
        <NavLink className={`page-nav-bar-item ${getClassName('/users')}`} data-cy="page-nav-bar-item" to="/users">
          Manage Users
        </NavLink>
        <NavLink className={`page-nav-bar-item ${getClassName('/roles')}`} data-cy="page-nav-bar-item" to="/roles">
          Manage Roles
        </NavLink>
        <NavLink
          className={`page-nav-bar-item ${getClassName('/assignments')}`}
          data-cy="page-nav-bar-item"
          to="/assignments"
        >
          Manage Assignments
        </NavLink>
      </AocAuthAccessCheck>
      <AocAuthAccessCheck deniedRoles={[RoleNames.SYSTEM_ADMIN]}>
        {/* Standard links */}
        <NavLink
          className={`page-nav-bar-item ${getClassName('/dashboard')}`}
          data-cy="page-nav-bar-item"
          to="/dashboard"
          exact
          onKeyDown={(e) => handleKeyDown(e)}
        >
          Dashboard
        </NavLink>
        <NavLink className={`page-nav-bar-item ${getClassName('/flights')}`} data-cy="page-nav-bar-item" to="/flights">
          Manage Operations
        </NavLink>
      </AocAuthAccessCheck>
    </Nav>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(PageNavigationBar);
