import { useState } from 'react';
import PropTypes from 'prop-types';
import FlightListTableContainer from '../FlightListTableContainer/FlightListTableContainer';
import GanttChart from '../GanttChart/GanttChart';
import { SwapFooter } from '../SwapMode/SwapFooter/SwapFooter';
import { DateFormat, FlightView, SummaryPanelMode } from '../../../lib/constants';
import SummaryPanel from '../SummaryPanel/SummaryPanel';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { useFilterSearchDatesStore } from '../../../hooks/useFilterStore/useFilterStore';
import './OperationsView.css';

const OperationsView = ({ isSwapModeActive, viewMode, handleSortClick, sort }) => {
  const [summaryPanelMode, setSummaryPanelMode] = useState(SummaryPanelMode.MINI_SUMMARY);
  const [activityKey, setActivityKey] = useState('Flight');

  // TODO - will need to read from this view's store
  const { startDate, endDate } = useFilterSearchDatesStore();

  const viewType = viewMode;

  /**
   * Event handler to change the activity key
   * @param {string} activityKey
   */
  const handleChangeActivityKey = (activityKey) => {
    setActivityKey(activityKey);
    setSummaryPanelMode(activityKey == null ? SummaryPanelMode.MINI_SUMMARY : SummaryPanelMode.EXPANDED_DETAIL);
  };

  /**
   *
   * @returns {string} - class names for the operations view
   */
  const flightToolkitActiveClassHandler = () => {
    let classes = '';
    classes = isSwapModeActive ? ' swap-mode-active' : '';
    classes += summaryPanelMode === SummaryPanelMode.MINI_SUMMARY ? ' summary-panel-mini' : ' summary-panel-expanded';

    return classes;
  };

  /**
   * Renders the Gantt Chart
   * @returns {JSX.Element} - the Gantt Chart component
   */
  const renderGanttChart = () => {
    const startDateFormatted = formatDateTime(startDate, DateFormat.DEFAULT);
    const endDateFormatted = formatDateTime(endDate, DateFormat.DEFAULT);
    return (
      <GanttChart
        startDate={startDateFormatted}
        endDate={endDateFormatted}
        handleChangeActivityKey={handleChangeActivityKey}
      />
    );
  };

  /**
   * Renders the Flight List Table Container
   * @returns {JSX.Element} - the Flight List Table Container component
   */
  const renderFlightListTableContainer = () => {
    // Default sort for the table
    // Not reading in from session storage
    const defaultSort = {
      orderBy: 'etd',
      orderDirection: 'asc',
    };

    return (
      <FlightListTableContainer
        savedSort={sort || defaultSort}
        openDetailPane={() => {}}
        isPaneOpen={false}
        onSortClick={handleSortClick}
        handleChangeActivityKey={handleChangeActivityKey}
      />
    );
  };

  if (isSwapModeActive) {
    return (
      <div className={`operations-view${flightToolkitActiveClassHandler()}`}>
        <div className="swap-mode-border-overlay">
          <div className="operations-view-main-content">{renderGanttChart()}</div>
          <SwapFooter />
        </div>
        <SummaryPanel
          summaryPanelMode={summaryPanelMode}
          activityKey={activityKey}
          handleChangeActivityKey={handleChangeActivityKey}
        />
      </div>
    );
  }

  return (
    <div className={`operations-view${flightToolkitActiveClassHandler()}`}>
      <div className="operations-view-main-content">
        {viewType === FlightView.GANTT_VIEW ? renderGanttChart() : renderFlightListTableContainer()}
      </div>
      <SummaryPanel
        summaryPanelMode={summaryPanelMode}
        activityKey={activityKey}
        handleChangeActivityKey={handleChangeActivityKey}
      />
    </div>
  );
};

OperationsView.propTypes = {
  handleSortClick: PropTypes.func,
  sort: PropTypes.object,
  isSwapModeActive: PropTypes.bool,
  viewMode: PropTypes.string,
};

export default OperationsView;
