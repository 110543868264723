import React, { useMemo, memo } from 'react';
import FlightInfoTable from '../FlightInfoTable/FlightInfoTable';
import { Grid } from '@material-ui/core';

const FlightInfoTables = React.memo(({ flightInfo, trip, date, columnNames }) => {
  const gridItems = useMemo(() => {
    return Array.from({ length: 8 }).map((_, index) => (
      <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
        <FlightInfoTable flightInfo={flightInfo} trip={trip} date={date} columnNames={columnNames} />
      </Grid>
    ));
  }, [flightInfo, trip, date, columnNames]);

  return (
    <Grid container spacing={2} style={{ padding: '1rem' }}>
      {gridItems}
    </Grid>
  );
});

export default memo(FlightInfoTables);
