import React, { createContext, useContext, useEffect } from 'react';
import { useAuthentication } from '../AuthenticationContext/AuthenticationContext';
import { useRoleAssignmentContext } from '../RoleAssignmentContext/RoleAssignmentContext';
import * as appInsightsUtils from '../../lib/appInsightsUtils';

const AppInsightsContext = createContext(null);

/**
 * Wrapper provider component for App Insights
 * @param {Object} children
 * @returns functions for AI tracking and logging
 */
const AppInsightsProvider = ({ children }) => {
  const { userData } = useAuthentication();
  const { roleAssignments } = useRoleAssignmentContext();

  useEffect(() => {
    appInsightsUtils.setAuthenticatedUser(userData?.profile?.email);
  }, [userData]);

  /**
   * Writes a log to the App Insights PageViews table
   * @param {string} pageName - name of page
   */
  const trackPageView = (pageName) => {
    appInsightsUtils.trackPageView(pageName, roleAssignments.role);
  };

  /**
   * Writes to the App Insights customEvents table
   * Used to track any custom event, such as a button click
   * @param {string} eventName - name of event
   */
  const trackEvent = (eventName) => {
    appInsightsUtils.trackEvent(eventName, roleAssignments.role);
  };

  /**
   * Writes a log info message to the App Insights traces table
   * @param {string} message - log message
   * @param {string} moduleName - name of module (the JS filename)
   * @param {string} functionName - name of the function, hook, or component
   */
  const logInformation = (message, moduleName, functionName) => {
    appInsightsUtils.logInformation(message, moduleName, functionName);
  };

  /**
   * Writes a log error message to the App Insights traces table
   * @param {string} message - log message
   * @param {string} moduleName - name of module (the JS filename)
   * @param {string} functionName - name of the function, hook, or component
   * @param {*} customProperties - Optional. Object with string key/value pairs for custom properties to attach to the track trace. Defaults to null.
   */
  const logError = (message, moduleName, functionName, customProperties = null) => {
    appInsightsUtils.logError(message, moduleName, functionName, customProperties);
  };

  return (
    <AppInsightsContext.Provider value={{ trackPageView, trackEvent, logInformation, logError }}>
      {children}
    </AppInsightsContext.Provider>
  );
};

// Custom hook that exposes the AppInsightsContext
// Allows lower components to access AppInsightsProvider's trackPageView and trackEvent functions
const useAppInsightsContext = () => {
  const context = useContext(AppInsightsContext);

  if (!context) {
    throw new Error(
      'useAppInsightsContext must be used within a AppInsightsProvider. Wrap a parent component in <AppInsightsProvider> to fix this error.',
    );
  }

  return context;
};

export { AppInsightsProvider, useAppInsightsContext };
