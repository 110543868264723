import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import InfoBlock from '../InfoBlock/InfoBlock';
import '../../FlightDetailPane.css';
import '../FlightTab.css';
/**
 * Displays the revenue and guest scores
 * @param {object} scoreDetails - The response object from the AOC Scores API. Contains Revenue and Guest scores.
 * @returns FlightTabValueIndex
 */
const FlightTabValueIndex = ({ scoreDetails }) => {
  let count = scoreDetails?.flightScores?.length;
  let scores = count > 0 ? scoreDetails.flightScores[0]?.scores : null;
  return (
    <div>
      <div className="flight-header-row">
        <div className="flight-header-left">
          <div className="flight-header">FLIGHT VALUE INDEX </div>
        </div>
        <div className="flight-header-right">
          <a
            className="info-link-item"
            data-cy="info-link"
            href="https://alaskaair.sharepoint.com/:w:/r/sites/Ops360Program/_layouts/15/Doc.aspx?sourcedoc=%7B7ab9cea3-4529-4093-9ba6-ceeffc50e0b9%7D&action=default&mobileredirect=true"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Get more info
            <FontAwesomeIcon className="external-link-icon" data-cy="external-link-icon" icon={faExternalLinkAlt} />
          </a>
        </div>
      </div>
      <div className="flex-row-fill">
        <div className="flex-col-left" data-cy="revenue-score">
          <InfoBlock label="Revenue Score">{scores?.find((t) => t.type === 'Revenue')?.value ?? 'N/A'}</InfoBlock>
        </div>
        <div className="flex-col-right" data-cy="guest-score">
          <InfoBlock label="Guest Score">{scores?.find((t) => t.type === 'Guest')?.value ?? 'N/A'}</InfoBlock>
        </div>
      </div>
    </div>
  );
};

FlightTabValueIndex.propTypes = {
  scoreDetails: PropTypes.shape({
    flightScores: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withAppInsightsTracking(FlightTabValueIndex);
