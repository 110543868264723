import React, { useEffect, useState } from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import { rolesTableConfig } from './rolesTableConfig';
import Button from '../../Shared/Button/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import RoleForm from './RoleForm/RoleForm';
import lodashOrderBy from 'lodash/orderBy';
import lodashGet from 'lodash/get';
import { getAllRoles } from '../../../services/apiClient/administrationApi/administrationApi';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../lib/constants';

import '../administration.css';

const ManageRoles = () => {
  // Track page view
  const { trackPageView } = useAppInsightsContext();
  trackPageView('Admin - Manage Roles Page');

  const Ascending = 'asc';
  const Descending = 'desc';

  // State
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [sort, setSort] = useState({
    orderBy: 'roleName',
    orderDirection: Ascending,
  });
  const [roleForm, setRoleForm] = useState({
    show: false,
    existingRole: null,
  });

  useEffect(() => {
    const getData = async () => {
      const response = await getAllRoles();
      if (response) {
        const sortedRolesData = lodashOrderBy(response.roles, 'roleName', 'roleName');
        setData({
          roles: sortedRolesData,
          assignments: response.assignments,
          entitlements: response.entitlements,
        });
      }
      setLoading(false);
    };

    getData();
  }, []);

  /**
   * @description Handle a sort request by the user clicking a column header.
   * @param {string} columnKey - The key name of the column to be sorted.
   */
  const handleSort = (columnKey) => {
    const isSortedAscending = sort.orderBy === columnKey && sort.orderDirection === Ascending;

    // Change the sort direction if we aren't specifying that the sort should remain the same.
    const newOrderDirection = isSortedAscending ? Descending : Ascending;

    // Start sort
    const sortedData = lodashOrderBy(
      data.roles,
      [(row) => lodashGet(row, columnKey)?.toString()?.toLowerCase()],
      [newOrderDirection],
    );
    setData({ ...data, roles: sortedData });
    setSort({ orderBy: columnKey, orderDirection: newOrderDirection });
  };

  const handleAddRoleClick = () => {
    setRoleForm({ show: true, existingRole: null });
  };

  const handleEditClick = (row, col) => {
    if (col.columnKey === 'edit') {
      setRoleForm({ show: true, existingRole: row });
    }
  };

  const handleCancelClick = () => {
    setRoleForm({ show: false, existingRole: null });
  };

  //Reload role list after saving changes in form
  const handleRoleSave = () => {
    // call get all roles API again and refresh the table
    const getRoles = async () => {
      const result = await getAllRoles();
      if (result != null) {
        const sortedRolesData = lodashOrderBy(
          result.roles,
          [(row) => lodashGet(row, 'roleName')?.toLowerCase()],
          [Ascending],
        );
        setData({
          roles: sortedRolesData,
          assignments: result.assignments,
          entitlements: result.entitlements,
        });
      }
      setLoading(false);
    };
    // reset states
    setRoleForm({ show: false, existingRole: null });
    setLoading(true);

    // call get
    getRoles();
  };

  let contentToLoad;
  if (loading) {
    contentToLoad = <LoadingIndicator />;
  } else if (data) {
    contentToLoad = (
      <div className="manage-roles-content">
        {roleForm.show && (
          <RoleForm
            assignments={data.assignments}
            entitlements={data.entitlements}
            existingRole={roleForm.existingRole}
            onSave={handleRoleSave}
            onCancel={handleCancelClick}
          />
        )}
        <Button variant="primary" data-cy="add-role-button" onClick={handleAddRoleClick}>
          Add Role
        </Button>
        <TableContainer className="admin-table-container" data-cy="admin-roles-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* define table headers */}
                {rolesTableConfig.map((col) => (
                  <TableCell key={col.columnKey} data-cy="admin-roles-table-header">
                    <TableSortLabel
                      active={sort.orderBy === col.columnKey}
                      direction={sort.orderBy === col.columnKey ? sort.orderDirection : Ascending}
                      onClick={() => handleSort(col.columnKey)}
                      data-cy={
                        sort.orderBy === col.columnKey
                          ? col.columnKey + '-sort-' + sort.orderDirection
                          : col.columnKey + '-unsorted'
                      }
                    >
                      {col.columnDisplayName}&nbsp;
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-cy="admin-table-list-body">
              {/* render each row (user) */}
              {data.roles.map((row) => (
                <TableRow key={row.roleKey}>
                  {/* render each data cell in a row  */}
                  {rolesTableConfig.map((col, index) => (
                    <TableCell key={`${col.columnKey}-${index}`} onClick={() => handleEditClick(row, col)}>
                      {col.renderFn != null && col.renderFn(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    contentToLoad = (
      <div className="manage-roles-content" data-cy="manage-roles-nodata">
        Unable to retrieve roles. Please refresh.
      </div>
    );
  }

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <div className="manage-roles-page admin-page">{contentToLoad}</div>
    </AocAuthAccessCheck>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(ManageRoles);
