import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Shared/Button/Button';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import './UpdateIrropsReason.css';
import Modal from '../../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { EntitlementNames, FormDataBrickType, KeyCodes } from '../../../lib/constants';
import IrropsReasons from '../../Shared/IrropsReasons/IrropsReasons';
import HorizontalRule from '../../Shared/HorizontalRule/HorizontalRule';
import { isNullOrWhitespace } from '../../../lib/displayUtils';
import { updateIrropsReason, getFlightDetails } from '../../../services/apiClient/flightsApi/flightsApi';
import { useUserContext } from '../../../contexts/UserContext/UserContext';
import { getDatetimeUtcNowString, formatDateTime, getTimeDifference, getDatetimeUtc } from '../../../lib/dateTimeUtils';
import { getFlightDetailBrick } from '../../../lib/irropUtils';
import { focusNextElement } from '../../../lib/utils';
import TextAreaInput from '../../Shared/Inputs/TextAreaInput/TextAreaInput';
import BrickContainer from '../../Shared/BrickContainer/BrickContainer';
import CurrentFlightDetails from '../../Shared/CurrentFlightDetails/CurrentFlightDetails';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeaderGrid: {
    paddingTop: '0.125rem !important',
    paddingBottom: '0.34375rem !important',
  },
  sectionHeaderGrid2: {
    paddingTop: '0.625rem !important',
    paddingBottom: '0.3125rem !important',
  },
  sectionNewDocGrid: {
    paddingTop: '0.84375rem !important',
    paddingBottom: '0.375rem !important',
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  horizontalRule: {
    borderTop: '0.125rem solid var(--primary-border-color)',
  },
  detailsHzRule: {
    marginTop: '-11px',
  },
  brickLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '0rem',
    height: '1.9rem',
  },
  brickLabel2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '0.25rem',
  },
  brickLabelData: {
    fontSize: 14,
    fontWeight: 'normal',
    overflow: 'Auto',
    height: '2.5rem',
  },
  requiredStar: {
    color: 'rgb(237, 56, 56)',
  },
  brickMarginBottom: {
    marginBottom: '14px !important',
  },
}));

/**
 * The UpdateIrropsReason component handles the update irrops reason.
 * @param {func} onCommit - function to handle update irrops reason confirmation
 * @param {func} onClose - function to handle update irrops reason cancelation
 * @param {Object} selectedFlightLeg - selected flight Leg data form action menu
 */
const UpdateIrropsReason = ({ onCommit, onClose, selectedFlightLeg, ...props }) => {
  const {
    origin,
    destination,
    selirropsCode,
    flightNumber,
    flightDateUtc,
    aircraftRegistration,
    operatingAirlineCode,
    departureDate,
    departureCount,
  } = selectedFlightLeg;

  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState({ text: '', key: null });
  const [irropsCurrentDocumentationDetails, setIrropsCurrentDocumentationDetails] = useState(null);

  const {
    state: { userName },
  } = useUserContext();
  const [comment, setComment] = useState('');
  const currentDate = getDatetimeUtc(getDatetimeUtcNowString('YYYY-MM-DDTHH:mm:ssZ'));
  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];

  const { trackEvent } = useAppInsightsContext();

  // authorization access hook
  const hasUpdateReasonEntitlement = useAuthorizationAccess(null, [EntitlementNames.UPDATEIRROPSREASON]);
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);

  useEffect(() => {
    if (irropsCurrentDocumentationDetails === null) {
      getFlightDetails(flightNumber, departureDate, origin, destination, departureCount, operatingAirlineCode).then(
        (data) => {
          setIrropsCurrentDocumentationDetails({
            modifiedDate: data.modifiedDate,
            irregularOperationReason: data.irregularOperationReason,
            flightLegComment: data.flightLegComment,
          });
        },
      );
    }
  }, [selectedFlightLeg]);

  /**
   * @description "returns a text message for whether last modified datetime updated irrops reasone  "
   * @param {datetime} modifiedAt
   * @param {string} unit
   * @returns last updated {diffDateTime} {unit} ago at ${timeval} PST */
  const getLastModifiedDateFormated = (modifiedAt, unit = 'years') => {
    const diffDateTime = getTimeDifference(modifiedAt, currentDate, unit);
    const timeval = formatDateTime(modifiedAt, 'HH:mm');
    const modifiedMsg = `Updated ${diffDateTime} ${unit} ago at ${timeval} PST`; // Formated String
    // check diff of modified date and current date
    if (diffDateTime > 1 || unit === 'seconds') {
      return modifiedMsg; // return diff date time less then 1  like seconds,minutes etc
    } else if (diffDateTime !== 0 && unit === 'years') {
      return modifiedMsg; // return diff date time years
    }
    // recursive call funcation for check all units array
    return getLastModifiedDateFormated(modifiedAt, units[units.indexOf(unit) + 1]);
  };

  /**
   * @description handle on select reason form reason dropdown
   * @param {*} reason
   * @param {*} reasonKey
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description handle current flight detail blocks
   * @returns returns blocks in array */
  const getCurrentFlightDetailBlocks = () => {
    let cfd = [];
    cfd.push(getFlightDetailBrick('Date (Z)', flightDateUtc, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Flight', flightNumber, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Aircraft', aircraftRegistration, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Stations', origin, destination, FormDataBrickType.STATION_MULTIPLE));
    cfd.push(getFlightDetailBrick('Irrops Code', selirropsCode, null, FormDataBrickType.STRING));

    return cfd;
  };

  /** @description Handle api call on commit button for update reasone */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    trackEvent(`Updated Irrops Reason - Submit modal for flight number : ${flightNumber}`);

    let result;
    let flightRequestBody = {
      flightNumber: flightNumber,
      selectedReason: selectedReason.text,
      comment: comment,
      departureDateUTC: departureDate,
      userName: userName,
      origin: origin,
      destination: destination,
      airlineCode: operatingAirlineCode,
      modifiedDate: getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss'),
      departureCount: departureCount,
    };
    // Api call for update irrops reason
    result = await updateIrropsReason(flightRequestBody);
    trackEvent(
      `Updated Irrops Reason - API call to submit update Irrops reason details is complete. Status:${result?.status}, Flight number : ${flightNumber}`,
    );

    const updateFlightsIsSuccess = result?.status === 'Success';

    if (updateFlightsIsSuccess) {
      onCommit(true, 'Updated Irrops Reason');
    } else {
      onCommit(false, 'Updated Irrops Reason');
    }
    onClose();
  };

  /** @description on Change comment text set in hook state*/
  const onChangeHandler = (val) => {
    setComment(val);
  };

  /***
   * @description  check all required fields
   * @returns true or false */
  const isCommitEnabled = () => {
    let isFlightDetailsValid =
      !isNullOrWhitespace(selectedReason) &&
      !isNullOrWhitespace(selectedReason.key) &&
      ((selectedReason.isCommentsRequired && !!comment) ||
        //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectedReason.isCommentsRequired === false) &&
      !commitButtonClicked;
    return isFlightDetailsValid && hasUpdateReasonEntitlement;
  };

  const formHTML = (
    <div className="irrops-update-reason-modal">
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.sectionHeaderGrid}>
          <div className={classes.sectionHeader}>CURRENT FLIGHT DETAILS</div>
        </Grid>
        <Grid item xs={12}>
          <CurrentFlightDetails
            flightDetailBlocks={getCurrentFlightDetailBlocks()}
            customBrickCSSTag={classes.brickMarginBottom}
            dataCyTag="ur-cfd"
          />
        </Grid>
        <Grid item xs={12} className={classes.detailsHzRule}>
          <HorizontalRule />
        </Grid>
        <Grid item sm={6} xs={7} md={6} className={classes.sectionHeaderGrid2}>
          <div className={classes.sectionHeader}>CURRENT DOCUMENTATION</div>
        </Grid>
        <Grid item sm={5} xs={5} md={6}>
          <div className="last-modified-date" data-cy="irrops-update-current-modifiedDate">
            {' '}
            {irropsCurrentDocumentationDetails === null ||
            isNullOrWhitespace(irropsCurrentDocumentationDetails.modifiedDate)
              ? ''
              : getLastModifiedDateFormated(getDatetimeUtc(irropsCurrentDocumentationDetails.modifiedDate))}{' '}
          </div>
        </Grid>
        <Grid container item xs={12} className="textarea-comment">
          <Grid item xs={12}>
            <BrickContainer title="Reason" customCSSTag={classes.brickLabel}>
              <div className={`${classes.brickLabelData}`} data-cy="irrops-update-current-reason">
                {irropsCurrentDocumentationDetails === null ||
                isNullOrWhitespace(irropsCurrentDocumentationDetails.irregularOperationReason)
                  ? 'N/A'
                  : irropsCurrentDocumentationDetails.irregularOperationReason}
              </div>
            </BrickContainer>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <BrickContainer title="Comment" customCSSTag={classes.brickLabel}>
              <div className={`${classes.brickLabelData}`} data-cy="irrops-update-current-comment">
                {irropsCurrentDocumentationDetails === null ||
                isNullOrWhitespace(irropsCurrentDocumentationDetails.flightLegComment)
                  ? 'N/A'
                  : irropsCurrentDocumentationDetails.flightLegComment}
              </div>
            </BrickContainer>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.sectionHeaderGrid2}>
          <div className={classes.horizontalRule}></div>
        </Grid>
        <Grid item xs={12} className={classes.sectionNewDocGrid}>
          <div className={classes.sectionHeader}>NEW DOCUMENTATION</div>
        </Grid>

        <Grid container item md={6} sm={12} xs={12}>
          <Grid item xs={12}>
            <BrickContainer title={`Reason`} customCSSTag={classes.brickLabel2} isRequired={true}>
              <IrropsReasons
                onSelect={handleOnSelectReason}
                airlineCode={operatingAirlineCode}
                irropsCode={selirropsCode}
                selectedReason={selectedReason.text}
                isRequired={true}
              />
            </BrickContainer>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={`${classes.sectionHeaderGrid2}`}>
          <Grid item xs={12}>
            <BrickContainer
              title="Comment"
              customCSSTag={classes.brickLabel2}
              isRequired={selectedReason.isCommentsRequired}
            >
              <TextAreaInput
                value={comment}
                rows={4}
                onChange={onChangeHandler}
                dataCyTag="irrops-update-reason-comments-textarea"
              />
            </BrickContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  /**
   * @description hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled()) {
      handleCommitButtonClick();
    }
  };
  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="update-irrops-reason-save-button"
      isDisabled={!isCommitEnabled()}
      onClick={handleCommitButtonClick}
      onKeyPress={handleKeypress}
      className="btn-irrops-commit"
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <Modal
      show={true}
      title="Update Irrops Reason"
      body={formHTML}
      footerButtons={formButtons}
      onHide={onClose}
      customCSSTag="irrop-update-reason"
    />
  );
};
UpdateIrropsReason.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(UpdateIrropsReason);
