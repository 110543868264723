import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

//API Route
const API_ROUTE = 'aircraft';
const moduleName = 'aircraftInformationApi';

/**
 * Returns a list of fleet types
 * @param {*} airlineCode
 */
export const getFleetTypes = async (airlineCode) => {
  const apiClient = axiosInstance;
  let result = null;
  const apiUrl = `${API_ROUTE}/api/v1/Fleet/FleetTypes`;
  try {
    const url = airlineCode ? `${apiUrl}?AirlineCode=${airlineCode}` : apiUrl;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getFleetTypes', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  // console.debug(result);
  return result;
};

/**
 * Returns all the aircraft details
 * @param {*} airlineCode
 * @param {*} aircraftRegistration
 */
export const getAircraftDetails = async (airlineCode, aircraftRegistration) => {
  const apiClient = axiosInstance;
  let result = null;

  try {
    const url = `${API_ROUTE}/api/v1/orm/AircraftInformation/aircraft?airlineCode=${airlineCode}&aircraftRegistration=${aircraftRegistration}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getAircraftDetails', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  // console.debug(result);
  return result;
};

/**
 * Returns a list of available aircraft tail numbers.
 * @param {*} FlightDateUTC
 * @param {*} Origin
 * @param {*} EstimatedOut
 * @param {*} EstimatedIn
 * @param {*} FleetType
 */
export const getAvailableTailNumbers = async (
  flightDateUTC,
  estimatedOut,
  estimatedIn,
  fleetType,
  OperatingAirline,
) => {
  const apiClient = axiosInstance;
  let result = null;
  const version = 'v2';
  const apiUrl = `${API_ROUTE}/api/${version}/AircraftTailNumbers/Tailnumbers?FlightDateUTC=${flightDateUTC}&EstimatedOut=${estimatedOut}&EstimatedIn=${estimatedIn}&OperatingAirline=${OperatingAirline}`;

  try {
    const url = fleetType ? `${apiUrl}&FleetType=${fleetType}` : apiUrl;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'getAvailableTailNumbers',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result;
};

/**
 * Returns boolean value based on aircraft avaiability of FlightDateUTC, EstimatedOut and EstimatedIn params.
 * @param {*} aircraft
 * @param {*} FlightDateUTC
 * @param {*} EstimatedOut
 * @param {*} EstimatedIn
 */
export const getIsTailNumberAvailable = async (aircraft, flightDateUTC, estimatedOut, estimatedIn) => {
  const apiClient = axiosInstance;
  let result = null;
  const vesion = 'v2';
  const url = `${API_ROUTE}/api/${vesion}/AircraftTailNumbers/${aircraft}/available?flightDateUTC=${flightDateUTC}&estimatedOut=${estimatedOut}&estimatedIn=${estimatedIn}`;

  try {
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), module, 'getIsTailNumberAvailable', createApiErrorTelemetryProperties(error));
    console.error(error);
  }

  return result;
};
