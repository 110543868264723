import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FlightTabStationTimes from '../FlightTabStationTimes/FlightTabStationTimes';
import ZuluOffsetDisplay from '../../../../../Shared/ZuluOffsetDisplay/ZuluOffsetDisplay';
import { withAppInsightsTracking } from '../../../../../../services/appInsightsFactory/appInsightsFactory';

import '../../../../FlightDetailPane/FlightDetailPane.css';
import '../../../../FlightDetailPane/FlightTab/FlightTab.css';

const useStyles = makeStyles((theme) => ({
  firstRowHeight: {
    [theme.breakpoints.only('md')]: {
      height: '2rem',
      marginTop: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      height: '4.8rem',
    },
  },
  thirdRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '8.533rem',
    },
    [theme.breakpoints.only('xl')]: {
      height: 'auto',
    },
  },
}));

/**
 * Displays the time/event information of a specified station
 * @param {string} title - Name of event (e.g Departure, Arrival, etc)
 * @param {string} station - Station of the event (e.g arrival station)
 * @param {Object[]} times - times (scheduled, estimated, projected, and actual) of events of interest (OOOI events)
 * @param {string} event1 - first event of interest
 * @param {string} event2 - second event of interest
 * @param {string} gateInfo - Gate information at the specified station. Defaults to "--".
 * @returns FlightTabStations
 */
const FlightTabStations = ({ title, station, times, event1, event2, gateInfo = '--' }) => {
  let includeTimesEvent1 = event1.toLowerCase() + 'Times'; // e.g. outTimes or inTimes to match the times object keys
  let timeEvent1 = Object.keys(times || {}).includes(includeTimesEvent1) ? times[includeTimesEvent1] : [];

  let includeTimesEvent2 = event2.toLowerCase() + 'Times'; // e.g. outTimes or inTimes to match the times object keys
  let timeEvent2 = Object.keys(times || {}).includes(includeTimesEvent2) ? times[includeTimesEvent2] : [];

  let scheduledUtc = timeEvent1?.scheduledTimeUTC;
  const classes = useStyles();

  return (
    <>
      <Grid container item spacing={1} className={classes.firstRowHeight}>
        <Grid item xs={6}>
          <div className="flight-header-rows">
            <span className="flight-header">{title.toUpperCase()} DETAILS </span>
            <span className="flight-header-span">
              &nbsp;|&nbsp;<span data-cy={`${title.toLowerCase()}-info-block`}>{`${station} (${gateInfo})`}</span>
            </span>
          </div>
        </Grid>
        <Grid item xs={6} spacing={1}>
          <div className="flight-zulu-offsets">
            Zulu{' '}
            <ZuluOffsetDisplay
              utcDateTime={scheduledUtc}
              dataCyTag={`${event1.toLowerCase()}-zulu-offset`}
              station={station}
              format="HH"
            />{' '}
            hours
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <div className="flex-col-left " data-cy={`${event1.toLowerCase()}-time-schedule-block`}>
            <FlightTabStationTimes
              event={event1}
              utcTime={timeEvent1?.scheduledTimeUTC}
              comparisonUtcTime={null}
              DelayMinutes={0}
              station={station}
              itemLabel="Scheduled"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy={`${event2.toLowerCase()}-time-schedule-block`}>
            <FlightTabStationTimes
              event={event2}
              utcTime={timeEvent2?.scheduledTimeUTC}
              comparisonUtcTime={null}
              DelayMinutes={0}
              station={station}
              itemLabel="Scheduled"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1} className={classes.thirdRowHeight}>
        <Grid item xs={6}>
          <div className="flex-col-left " data-cy={`${event1.toLowerCase()}-time-estimated-block`}>
            <FlightTabStationTimes
              event={event1}
              utcTime={timeEvent1?.estimatedTimeUTC}
              comparisonUtcTime={timeEvent1?.scheduledTimeUTC}
              delayMinutes={timeEvent1?.estimatedDelayMinutes}
              station={station}
              itemLabel="Estimated"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy={`${event2.toLowerCase()}-time-estimated-block`}>
            <FlightTabStationTimes
              event={event2}
              utcTime={timeEvent2?.estimatedTimeUTC}
              comparisonUtcTime={timeEvent2?.scheduledTimeUTC}
              delayMinutes={timeEvent2?.estimatedDelayMinutes}
              station={station}
              itemLabel="Estimated"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <div className="flex-col-left " data-cy={`${event1.toLowerCase()}-time-schedule-block`}>
            <FlightTabStationTimes
              event={event1}
              utcTime={timeEvent1?.actualTimeUTC}
              comparisonUtcTime={timeEvent1?.scheduledTimeUTC}
              delayMinutes={timeEvent1?.actualDelayMinutes}
              station={station}
              itemLabel="Actual"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy={`${event2.toLowerCase()}-time-schedule-block`}>
            <FlightTabStationTimes
              event={event2}
              utcTime={timeEvent2?.actualTimeUTC}
              comparisonUtcTime={timeEvent2?.scheduledTimeUTC}
              delayMinutes={timeEvent2?.actualDelayMinutes}
              station={station}
              itemLabel="Actual"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

FlightTabStations.propTypes = {
  title: PropTypes.string,
  station: PropTypes.string,
  stationOffset: PropTypes.number,
  times: PropTypes.arrayOf(PropTypes.object),
  event1: PropTypes.string,
  event2: PropTypes.string,
};

export default withAppInsightsTracking(FlightTabStations);
