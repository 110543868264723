import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Shared/Button/Button';

import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import {
  IrropsCode,
  EntitlementNames,
  FormDataBrickType,
  IrropsMenuItemAction,
  IrropFlightStatusErrorMessage,
  KeyCodes,
  Treatment,
} from '../../../../lib/constants';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { irregularOps } from '../../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import Modal from '../../../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IrropsReasons from '../../../Shared/IrropsReasons/IrropsReasons';
import { canPerformIrrop, getCancelFlightSubmitBody } from '../../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../../lib/utils';
import { getFlightDetails, headCheckFlightExists } from '../../../../services/apiClient/flightsApi/flightsApi';
import TextAreaInput from '../../../Shared/Inputs/TextAreaInput/TextAreaInput';
import BrickContainer from '../../../Shared/BrickContainer/BrickContainer';
import CurrentFlightDetails from '../../../Shared/CurrentFlightDetails/CurrentFlightDetails';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup/ConfirmationPopup';
import './CancelFlightForm.css';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeader: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  DocumentationHeader: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  horizontalRule: {
    borderTop: '2px solid var(--primary-border-color)',
  },
  brickLabel: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: 'bold',
    marginBottom: '0.218rem',
  },
  brickLabelData: {
    fontSize: 'var(--defaultFontSize)',
    height: 'var(--defaultFontSize)',
    fontWeight: 'normal',
  },
  sectionHeaderGrid: {
    paddingBottom: '0.6rem !important',
    paddingTop: '.4px !important',
  },
  HeaderGrid: {
    paddingBottom: '.6rem !important',
    paddingTop: '1rem !important',
  },
  ReasonContainer: {
    paddingTop: '3.3rem !important',
  },
}));

/**
 * The CancelFlightForm component is build the cancel flight form.
 * @param {Object} props -
 *  currentFlightCancel - current flight details object.
 *  onSave - callback func when save the cancel form.
 *  onCancel - callback func when on cancel the form.
 * @returns CancelFlightForm component
 */

const CancelFlightForm = ({ currentFlightCancel = null, onSave, onCancel, ...props }) => {
  const initialFormData =
    currentFlightCancel === null
      ? {
          operatingAirlineCode: '',
          flightNumber: '',
          origin: '',
          destination: '',
          scheduledDestination: '',
          flightDateUtc: '',
          scheduledOperatingDateUTC: '',
          aircraftRegistrationNumber: '',
          legNumber: '',
          cxlKey: '',
          comments: '',
        }
      : currentFlightCancel;
  const selectedReasonPlaceholder = '';
  // Hooks
  const classes = useStyles();
  const [cancelFlightFormData] = useState(initialFormData);
  const [show, setShow] = useState(true);
  const [isOpenList, setIsOpenList] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedReason, setSelectedReason] = useState({
    text: selectedReasonPlaceholder,
    cxlKey: null,
    cannedComment: '',
    isCommentsRequired: false,
  });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);
  const [userComments, setUserComments] = useState('');
  const { trackEvent } = useAppInsightsContext();

  // Feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  // Authorization access hook
  const hasCancelEntitlement = useAuthorizationAccess([], [EntitlementNames.CANCEL]);

  /**
   * @description Checks or handles commit button disable or enable logic.
   * @return true or false
   */
  const isCommitEnabled = () => {
    let isFormDataComplete =
      cancelFlightFormData !== null &&
      cancelFlightFormData.flightDateUtc &&
      cancelFlightFormData.aircraftRegistrationNumber &&
      cancelFlightFormData.origin &&
      cancelFlightFormData.destination &&
      cancelFlightFormData.flightNumber;
    return (
      isFormDataComplete &&
      ((selectedReason.isCommentsRequired && !!userComments) ||
        // isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectedReason.isCommentsRequired === false) &&
      selectedReason.cxlKey &&
      hasCancelEntitlement &&
      !commitButtonClicked
    );
  };

  const submitCancelFormV2 = async () => {
    let isExistIrrops;

    trackEvent(`Cancel - Submit modal for flight number : ${cancelFlightFormData.flightNumber.toString()}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    // Restructure cancel flight form data for post to API.

    const flightData = getCancelFlightSubmitBody(
      cancelFlightFormData.origin,
      cancelFlightFormData.scheduledDestination,
      cancelFlightFormData.origin,
      cancelFlightFormData.destination,
      cancelFlightFormData.flightNumber.toString(),
      '',
      cancelFlightFormData.operatingAirlineCode,
      cancelFlightFormData.scheduledOperatingDateUTC,
      userComments,
      selectedReason.text,
      cancelFlightFormData.departureCount,
      sessionId,
    );

    // Get Current Flight Leg Data
    const response = await getFlightDetails(
      cancelFlightFormData.flightNumber,
      cancelFlightFormData.scheduledOperatingDateUTC,
      cancelFlightFormData.origin,
      cancelFlightFormData.destination,
      cancelFlightFormData.departureCount,
      cancelFlightFormData.operatingAirlineCode,
    );
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.CANCEL_FLIGHT);
      if (isExistIrrops) {
        // Call the cancel flights API .
        let cancelResult = await irregularOps(flightData);

        // Call to check if Irrop is completed. we will use this in FlightActionMenu to refresh gantt chart after Irrop is known to be completed.
        // We are not awaiting for this call here. it will be awaited in FlightActionMenu
        let completeCheckCall = headCheckFlightExists(
          cancelFlightFormData.flightNumber,
          cancelFlightFormData.scheduledOperatingDateUTC,
          cancelFlightFormData.origin,
          cancelFlightFormData.destination,
          cancelFlightFormData.departureCount,
          cancelFlightFormData.operatingAirlineCode,
          IrropsCode.CANCELLED_FLIGHT,
        );

        trackEvent(
          `Cancel - API call to submit cancel flight details is complete. Status:${cancelResult?.status}, Flight number : ${cancelFlightFormData.flightNumber}`,
        );

        // Handle result   // Pass result flight action menu for handle notification
        if (cancelResult !== null && cancelResult.status === 'SUCCESS') onSave(true, 'Cancel', '', [completeCheckCall]);
        // Handle toast notification SUCCESS
        else onSave(false, 'Cancel', '', []); //handle toast notification ERROR
      } else {
        trackEvent(
          `Cancel - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${cancelFlightFormData.flightNumber}`,
        );
        onSave(false, 'Cancel', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Cancel - Failed to get flight details for flight number : ${cancelFlightFormData.flightNumber}`);
      onSave(false, 'Cancel', '', []);
    }
  };
  /**
   * @description Handle the save fuctionality of cancel flight form and calls the callbacks.
   * Calls the irregularOps API.
   */

  /**
   * @description Handle save button click event and call submitCancelFormV1 function.
   */
  const handleSubmitButtonClick = async () => {
    setCommitButtonClicked(true);
    setShowConfirmPopup(true); // Show confirm popup
    setShow(false); // Hide The Modal
    //setIsSaveDisabled(true); // Disabled Save Button
  };

  /**
   * @description Handle the text input changes for user comments
   */
  const handleTextInputChange = (value) => {
    setUserComments(value);
  };

  /**
   * @description Handle the on selecting the reason dropdown and set the state.
   * @param {string} reason Selected reason value.
   * @param {any} reasonKey Reason key.
   * @param {string} commentsTemplate User comments.
   * @param {boolean} commentRequired Boolean value for checking comments required.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentRequired, event, target) => {
    setSelectedReason({
      text: reason,
      cxlKey: reasonKey,
      cannedComment: commentsTemplate,
      isCommentsRequired: commentRequired,
    });
    focusNextElement(event, target);
    setIsOpenList(false);
  };

  /**
   * @description Handle on cancel of cancel form.
   */

  const handleCancel = () => {
    setShow(false);
    onCancel(IrropsCode.CANCELLED_FLIGHT);
  };

  /**
   * @description Hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    //It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled()) {
      handleSubmitButtonClick();
    }
  };
  /**
   * @description Hitting tab on submit button set focus on reason dropdown.
   */
  const handleOnBlur = (e) => {
    document.getElementById('irrops-code-reason').focus();
    setIsOpenList(true);
  };
  /**
   * @description Build the current flight details in array format.
   * @returns Current flight detail blocks in array
   */
  const getCurrentFlightDetailBlocks = () => {
    let cfd = [];
    cfd.push(getFlightDetailBrick('Date (Z)', cancelFlightFormData.flightDateUtc, null, FormDataBrickType.STRING));
    cfd.push(getFlightDetailBrick('Flight', cancelFlightFormData.flightNumber, null, FormDataBrickType.STRING));
    cfd.push(
      getFlightDetailBrick('Aircraft', cancelFlightFormData.aircraftRegistrationNumber, null, FormDataBrickType.STRING),
    );
    cfd.push(
      getFlightDetailBrick(
        'Flight Leg',
        cancelFlightFormData.origin,
        cancelFlightFormData.destination,
        FormDataBrickType.STATION_MULTIPLE,
      ),
    );
    return cfd;
  };

  /**
   * @description Build object with passed parameters.
   * @returns Object with current flight details.
   */
  const getFlightDetailBrick = (title, value, value2, brickType, showActive = true) => {
    return { title, value, value2, brickType, isActive: showActive };
  };

  // Build modal body for cancel flight modal.

  const formHTML = (
    <Fragment>
      <div className="cancel-flight-form-modal">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>CURRENT FLIGHT DETAILS</div>
          </Grid>
          <Grid spacing={2} item>
            <CurrentFlightDetails flightDetailBlocks={getCurrentFlightDetailBlocks()} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.horizontalRule}></div>
          </Grid>
          <Grid item xs={12} className={classes.HeaderGrid}>
            <div className={classes.sectionHeader}>DOCUMENTATION</div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <BrickContainer title={`Reason`} customCSSTag={classes.brickLabel} isRequired={true}>
              <IrropsReasons
                onSelect={handleOnSelectReason}
                airlineCode={currentFlightCancel.operatingAirlineCode}
                irropsCode={IrropsCode.CANCELLED_FLIGHT}
                selectedReason={selectedReason.text}
                isAutoFocus={true}
                isOpenList={isOpenList}
              />
            </BrickContainer>
          </Grid>
          <Grid item xs={12} className={classes.ReasonContainer}>
            <BrickContainer
              title={`Comment`}
              customCSSTag={classes.brickLabel}
              isRequired={selectedReason.isCommentsRequired}
            >
              <TextAreaInput
                value={userComments}
                placeholder={selectedReason.cannedComment}
                rows={4}
                onChange={handleTextInputChange}
                dataCyTag="cancel-flight-form-comments-textarea"
              />
            </BrickContainer>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  // Footer buttons for cancel flight modal.

  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="cancel-flight-form-save-button"
      onClick={handleSubmitButtonClick}
      onKeyPress={handleKeypress}
      onBlur={handleOnBlur}
      isDisabled={!isCommitEnabled()}
    >
      {commitButtonClicked ? 'Submitting...' : 'Submit'}
    </Button>,
  ];

  /**
   * @description Handle confirm button click event and call submitCancelFormV1 function.
   */
  const handleSaveButtonClick = async () => {
    setConfirmButtonClicked(true);
    await submitCancelFormV2();

    setShowConfirmPopup(false); // Show confirm popup
  };

  /**
   * @description Hitting Enter on Commit button triggers save
   */
  const handleConfirmKeypress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      handleSaveButtonClick();
    }
  };

  /**
   * @description Handle on cancel of cancel form.
   */
  const hideHandleConfirmCancel = () => {
    setShowConfirmPopup(false); // hide confirm popup
    onCancel(IrropsCode.CANCELLED_FLIGHT);
  };

  /**
   * @description Handle popup container text to show confirmation message
   * @returns Cancelling flight message
   */
  const popupContainerText = () => {
    if (cancelFlightFormData.origin != null && cancelFlightFormData.destination != null) {
      return `Cancelling flight ${cancelFlightFormData.flightNumber.toString()}, 
                ${cancelFlightFormData.origin}-
                ${cancelFlightFormData.destination}?`;
    }
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.CANCEL, EntitlementNames.SUPPORTVALIDATION]}>
      <Modal
        show={show}
        title="Cancel Flight"
        body={formHTML}
        footerButtons={formButtons}
        onHide={handleCancel}
        customCSSTag="cancel-flight-form"
        dataCyTag="cancel-flight-form"
      />
      <ConfirmationPopup
        showConfirmPopup={showConfirmPopup}
        handleCommitClick={handleSaveButtonClick}
        handleOnKeyPress={handleConfirmKeypress}
        handleHideClick={hideHandleConfirmCancel}
        dataCyTag="cancel-flight"
        title="Confirm Cancel"
        containerText={popupContainerText()}
        disableButton={confirmButtonClicked}
      />
    </AocAuthAccessCheck>
  );
};

CancelFlightForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(CancelFlightForm);
