import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'OperationalNotes';
const moduleName = 'notesApi';

/**
 * @description Request to create operational notes API.
 * @returns {any} - status code and message
 */
export const saveNote = async (payload) => {
  const apiClient = axiosInstance;
  let result = null;
  if (!payload) {
    return result;
  }
  try {
    const url = `note/api/v1/${API_ROUTE}/flights`;
    const response = await apiClient.post(url, payload);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'saveNote', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @description Request to DELETE operational notes API.
 * @returns {any} - status code and message
 */
export const deleteNote = async (operationalNoteKey) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `note/api/v1/${API_ROUTE}/${operationalNoteKey}`;
    const response = await apiClient.delete(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'deleteNote', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * Returns operational notes for a flight leg
 * @param {*} flightNumber
 * @param {*} departureDateUTC
 * @param {*} origin
 * @param {*} destination
 * @param {*} departureCount
 * @param {*} operatingAirline
 * @param {*} includeClosed
 */

export const getFlightLegOperationalNotes = async (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  operatingAirline,
  includeClosed,
) => {
  const apiClient = axiosInstance;
  let result = null;

  if (flightNumber === undefined) {
    return null;
  }
  try {
    const url = `note/api/v1/${API_ROUTE}/flights/${flightNumber}?departureDateUTC=${departureDateUTC}&origin=${origin}&destination=${destination}&departureCount=${departureCount}&operatingAirline=${operatingAirline}&includeClosed=${includeClosed}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'getFlightLegOperationalNotes',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
    throw error;
  }
  //console.debug(result);
  return result;
};
