import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { getTimeDifference } from '../../../../../lib/dateTimeUtils';
import EarlyDelayDisplay from '../../../../Shared/EarlyDelayDisplay/EarlyDelayDisplay';
import '../../FlightDetailPane.css';
import '../FlightTab.css';

/**
 * Displays the block time of the given flight
 * @param {string} startTime - start date time of block
 * @param {string} endTime - end date time of block
 * @param {string} scheduledStartTime - start time of time to compare
 * @param {string} scheduledEndTime - end time of time to compare
 * @param {bool} showBlockInMinutes - indicator to display block in minutes
 * @returns FlightTabBlockTime - formatted block time in hours and minutes
 */
const FlightTabBlockTime = ({
  startTime,
  endTime,
  scheduledStartTime,
  scheduledEndTime,
  showBlockInMinutes = false,
}) => {
  const formatDuration = (duration) => {
    if (duration == null || typeof duration === 'undefined') return null;

    const hours = Math.floor(Math.abs(duration / 60));
    const minutes = Math.floor(Math.abs(duration % 60));
    return duration < 60 ? `${duration} mins` : `${hours} hrs ${minutes} mins`;
  };

  let scheduledBlockTimeMinutes = getTimeDifference(scheduledStartTime, scheduledEndTime);
  let actualBlockTimeMinutes = getTimeDifference(startTime, endTime);

  return (
    <div>
      <div className="basic-cell-left" data-cy="block-time-hours-minutes">
        {formatDuration(actualBlockTimeMinutes)}
      </div>
      {showBlockInMinutes && (
        <div className="basic-cell" data-cy="block-time-minutes-only">
          ({actualBlockTimeMinutes} mins)
        </div>
      )}
      <div className="basic-cell" data-cy="block-time-early-delay-indicator">
        <EarlyDelayDisplay difference={actualBlockTimeMinutes - scheduledBlockTimeMinutes} label="mins" />
      </div>
    </div>
  );
};

FlightTabBlockTime.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  scheduledStartTime: PropTypes.string,
  scheduledEndTime: PropTypes.string,
  showBlockInMinutes: PropTypes.bool,
};

export default withAppInsightsTracking(FlightTabBlockTime);
