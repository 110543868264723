import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Button from '../../../Shared/Button/Button';
import Modal from '../../../Shared/Modal/Modal';
import {
  createAuthorizedUser,
  updateAuthorizedUser,
  deleteAuthorizedUser,
} from '../../../../services/apiClient/administrationApi/administrationApi';
import { useAuthentication } from '../../../../contexts/AuthenticationContext/AuthenticationContext';
import AocAuthAccessCheck from '../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { RoleNames } from './../../../../lib/constants';
import orderBy from 'lodash/orderBy';

import './UserForm.css';

const UserForm = ({ roles = [], existingUser = null, onSave, onCancel, ...props }) => {
  const allRolesOrdered = orderBy(roles, ['operatingAirline', 'roleName'], ['asc', 'asc']);

  const initialFormData =
    existingUser === null
      ? {
          userName: '',
          userID: '',
          roleKeys: [],
        }
      : existingUser;

  // Hooks
  const [userFormData, setUserFormData] = useState(initialFormData);
  const [show, setShow] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [showDeleteUserConfirmModal, setShowDeleteUserConfirmModal] = useState(false);
  const { userData } = useAuthentication();

  const handleSaveButtonClick = () => {
    const saveUserForm = async () => {
      let result;

      // call API
      if (existingUser === null) {
        result = await createAuthorizedUser(
          userFormData.userID,
          userFormData.userName,
          userData.profile.name,
          userFormData.roleKeys,
        );
      } else {
        result = await updateAuthorizedUser(
          userFormData.userKey,
          userFormData.userID,
          userFormData.userName,
          userData.profile.name,
          userFormData.roleKeys,
        );
      }

      // handle result
      if (result === null) {
        setStatusMessage('Unable to save changes.');
        setIsSaveDisabled(false);
      } else {
        setStatusMessage('');
        setShow(false);
        onSave();
      }
    };

    setStatusMessage('Saving...');
    setIsSaveDisabled(true);
    saveUserForm();
  };

  const handleCancel = () => {
    setShow(false);
    onCancel();
  };

  const validateUserForm = (userForm) => {
    const isUserFormComplete = userForm.userName !== '' && userForm.userID !== '' && userForm.roleKeys.length > 0;

    let isEditUserFormUnchanged = false;
    if (existingUser !== null) {
      // check that edits have been made to this user
      const existingRoleKeys = [...existingUser.roleKeys].sort((a, b) => a - b);
      const newRoleKeys = [...userForm.roleKeys].sort((a, b) => a - b);
      const sameRoleKeys = JSON.stringify(existingRoleKeys) === JSON.stringify(newRoleKeys);
      const sameUserName = existingUser.userName === userForm.userName;
      const sameUserID = existingUser.userID === userForm.userID;
      isEditUserFormUnchanged = sameRoleKeys && sameUserName && sameUserID;
    }

    setIsSaveDisabled(!isUserFormComplete || isEditUserFormUnchanged);
  };

  const handleRoleCheck = (event) => {
    // convert string to integer.
    let roleKey = parseInt(event.target.name);

    // copy of existing role keys
    let newRoleKeys = [...userFormData.roleKeys];

    if (!event.target.checked) {
      newRoleKeys = newRoleKeys.filter((ur) => ur !== roleKey);
    } else {
      newRoleKeys.push(roleKey);
    }

    const newUserFormData = { ...userFormData, roleKeys: newRoleKeys };
    setUserFormData(newUserFormData);
    validateUserForm(newUserFormData);
  };

  const handleTextInputChange = (e) => {
    let newUserFormData = {};
    if (e.target.id === 'user-name') {
      newUserFormData = { ...userFormData, userName: e.target.value };
    } else {
      // user-id
      newUserFormData = { ...userFormData, userID: e.target.value };
    }

    setUserFormData(newUserFormData);
    validateUserForm(newUserFormData);
  };

  let deleteUserConfirmContent = (
    <>
      <div className="delete-user-modal-text">
        <span data-cy="delete-user-confirmation-text">Are you sure you want to delete this user?</span>
      </div>
    </>
  );

  // handles when delete user button is clicked
  const handleDeleteUserClick = () => {
    setShowDeleteUserConfirmModal(true);
  };

  // handles when delete user cancel button is clicked
  const handleDeleteUserCancelClick = () => {
    setShowDeleteUserConfirmModal(false);
  };

  // handles when delete user confirm button is clicked
  const handleDeleteUserConfirmClick = (event) => {
    const deleteUser = async () => {
      let result;
      // call API
      if (existingUser) {
        result = await deleteAuthorizedUser(userFormData.userID);
      }

      // handle result
      if (result === null) {
        setStatusMessage('Unable to delete user.');
        setIsSaveDisabled(false);
      } else {
        setStatusMessage('');
        setShow(false);
        onSave();
      }
    };

    setStatusMessage('Deleting user and user role(s)...');
    setIsSaveDisabled(true);
    deleteUser();
  };

  const deleteUserConfirmButton = (
    <Button variant="primary" onClick={handleDeleteUserConfirmClick}>
      Confirm
    </Button>
  );

  const deleteUserCancelButton = (
    <Button variant="secondary" onClick={handleDeleteUserCancelClick}>
      Cancel
    </Button>
  );

  let deleteUserButton;
  if (existingUser) {
    deleteUserButton = (
      <Button variant="primary" onClick={handleDeleteUserClick}>
        Delete User
      </Button>
    );
  }

  let deleteUserFooterContent = [deleteUserCancelButton, deleteUserConfirmButton];

  const formContent = (
    <>
      <div className="user-form-container" data-cy="user-form-container">
        <div className="user-info">
          <TextField
            className="text-field"
            id="user-name"
            label="User's Name"
            value={userFormData.userName}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'user-name-input' }}
          />
          <TextField
            className="text-field"
            id="user-id"
            label="Peoplesoft ID"
            value={userFormData.userID}
            onChange={handleTextInputChange}
            inputProps={{ 'data-cy': 'user-id-input' }}
          />
        </div>

        <div className="user-roles">
          <FormControl component="fieldset">
            <FormGroup>
              <FormLabel component="legend">Roles</FormLabel>
              {allRolesOrdered.map((role) => (
                <FormControlLabel
                  key={`roleKey-${role.roleKey}`}
                  label={`${role.operatingAirline} - ${role.roleName}`}
                  labelPlacement="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={userFormData.roleKeys.includes(role.roleKey)}
                      id={`roleKey-${role.roleKey}`}
                      name={`${role.roleKey}`}
                      onClick={handleRoleCheck}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </div>
      <div className="status-message" data-cy="status-message">
        {statusMessage}
      </div>
    </>
  );

  const formButtons = [
    deleteUserButton,
    <Button
      variant="primary"
      onClick={handleSaveButtonClick}
      tabIndex="0"
      isDisabled={isSaveDisabled}
      data-cy="user-form-save-button"
    >
      Save
    </Button>,
  ];

  return (
    <AocAuthAccessCheck approvedRoles={[RoleNames.SYSTEM_ADMIN]}>
      <Modal
        show={show}
        title={existingUser === null ? 'Add User' : 'Edit User'}
        body={formContent}
        footerButtons={formButtons}
        showCloseButton
        size="thin"
        onHide={handleCancel}
        dataCyTag={props['data-cy']}
        draggable={false}
        {...props}
      />

      <Modal
        show={showDeleteUserConfirmModal}
        title="Delete user"
        body={deleteUserConfirmContent}
        footerButtons={deleteUserFooterContent}
        showCloseButton
        size="thin"
        onHide={handleDeleteUserCancelClick}
        dataCyTag="delete-user-confirm-modal"
        draggable={false}
      />
    </AocAuthAccessCheck>
  );
};

UserForm.propTypes = {
  roles: PropTypes.array.isRequired,
  existingUser: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UserForm;
