import { useQuery, useQueryClient } from 'react-query';
import { getFlightLegOperationalNotes } from '../../services/apiClient/notesApi/notesApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import appSettings from '../../appSettings';
import usePrevious from '../usePrevious/usePrevious';

const moduleName = 'useNotesQuery';

let config = {
  cacheTime: 1 * 60 * 1000, // override default 5 minutes
  staleTime: 1 * 60 * 1000,
  retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
  refetchInterval: appSettings.REFRESH_INTERVAL,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  onError: (error) => {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'useNotesQuery', createApiErrorTelemetryProperties(error));
  },
};

/**
 * Custom hook for retrieving data from the Operational Notese API
 */
export const useFlightOperationalNotesQuery = (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
  isClosed,
  enabledAutoRefresh,
) => {
  const previousData = usePrevious([flightNumber, departureDateUTC, origin, destination, departureCount, airlineCode]);

  const queryClient = useQueryClient();
  config = {
    ...config,
    enabled: enabledAutoRefresh,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'getFlightLegOperationalNotes',
        createApiErrorTelemetryProperties(error),
      );
    },
  };
  // if the data have changed from the previous, we want to trigger
  // a refresh and remove the previous data from the cache.
  // if they haven't changed, it means another component has mounted (i.e. aircraft filter).
  // in that case we can return from the cache.
  if (!!previousData?.length) {
    const [prevFlightNumber, prevDepartureDateUTC, prevOrigin, prevDestination, prevDepartureCount, prevAirlineCode] =
      previousData;

    if (
      prevFlightNumber !== flightNumber ||
      prevDepartureDateUTC !== departureDateUTC ||
      prevOrigin !== origin ||
      prevDestination !== destination ||
      prevDepartureCount !== departureCount ||
      prevAirlineCode !== airlineCode
    ) {
      queryClient.removeQueries('flightOperationalNotes');
    }
  }
  return useQuery(
    ['flightOperationalNotes', flightNumber, departureDateUTC, origin, destination, departureCount, airlineCode],
    () =>
      getFlightLegOperationalNotes(
        flightNumber,
        departureDateUTC,
        origin,
        destination,
        departureCount,
        airlineCode,
        isClosed,
      ),
    config,
  );
};
