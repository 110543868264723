import React, { useState, useEffect } from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import IrropsFlightModal from '../../Shared/IrropsFlightModal/IrropsFlightModal';
import { getFlightDetails, headCheckFlightExists } from '../../../services/apiClient/flightsApi/flightsApi';
import {
  IrropsCode,
  EntitlementNames,
  IrropsMenuItemAction,
  IrropFlightStatusErrorMessage,
  IrropsModalFieldType,
  WarningTypes,
  Treatment,
} from '../../../lib/constants';
import PropTypes from 'prop-types';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import {
  canPerformIrrop,
  getCalculatedIrropDataEstimates,
  getDetailsBlocks,
  isCommitEnabled,
  getBodyWithSessionId,
} from '../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../lib/utils';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * The BlockTurnBack component is build the block turn back form.
 * @param {Object} props -
 *  existingBlockTurnBackDetails - existingBlockTurnBackDetails object.
 *  onCommit - callback func when commit add block turn back form.
 *  onClose - callback func when close the form.
 *  blockTurnBackDetails - blockTurnBackDetails object.
 * @returns BlockTurnBack component
 */

const BlockTurnBack = ({
  existingBlockTurnBackDetails = null,
  onCommit,
  onClose,
  blockTurnBackDetails,
  getFlightLineForFlightPuck,
  ...props
}) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    scheduledDestination,
    flightDateUtc,
    outTime,
    eta,
    isEtopsFlight,
    scheduledOperatingDateUTC,
    aircraftRegistration,
    legNumber,
    departureCount,
  } = blockTurnBackDetails;

  // turnTimeMinutes from contStation state comes from server.
  const [irropStation, setIrropStation] = useState({
    station: '',
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
  });
  const [irropETA, setIrropETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  // turnTimeMinutes state is user input.
  const [turnTimeMinutes, setTurnTimeMinutes] = useState({ value: '', isError: false, error: '' });
  const [contETD, setContETD] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [contETA, setContETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [userComments, setUserComments] = useState('');
  const [selectedReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [show, setShow] = useState(true);

  const { trackEvent } = useAppInsightsContext();

  // RoleAssignment hook
  const hasAirTurnBackEntitlement = useAuthorizationAccess(null, [EntitlementNames.BLOCKTURNBACK]);

  //feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  const irropStationVar = origin;
  const checkedContFlightNeeded = true;
  /**
   * calls updateIrropDataEstimates from IrropUtils.js and updates localstate
   * @param {String} dirtyField IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyValue value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyValue) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      legNumber,
      aircraftRegistration,
      formatDateTime(outTime, 'YYYY-MM-DDTHH:mm:ss'),
      origin,
      destination,
      irropStation,
      irropETA,
      false,
      checkedContFlightNeeded,
      turnTimeMinutes,
      contETD,
      contETA,
      dirtyField,
      dirtyValue,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      setIrropStation(result[IrropsModalFieldType.STATIONS]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_TURNTIME] != null) {
      setTurnTimeMinutes(result[IrropsModalFieldType.CONTINUATION_TURNTIME]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETD] != null) {
      setContETD(result[IrropsModalFieldType.CONTINUATION_ETD]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETA] != null) {
      setContETA(result[IrropsModalFieldType.CONTINUATION_ETA]);
    }
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleTurnTimeTextInputChange = (val) => {
    let inputNums = val.replace(/[^0-9]/g, '');
    if (inputNums.length > 3) {
      return;
    }
    setTurnTimeMinutes({ value: inputNums, isError: false, error: '' });
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleETAInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.IRROP_ETA, val);
    } else {
      setIrropETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description handle on blur of input field.
   * @param {any} - event - event object.
   */
  const handleTurnTimeOnBlur = (e) => {
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_TURNTIME, turnTimeMinutes.value);
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETATextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETA, val);
    } else {
      setContETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETDTextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETD, val);
    } else {
      setContETD({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description handle the on selecting the reason dropdown and set the state.
   * @param {string} reason selected reason value.
   * @param {any} reasonKey reason key.
   * @param {any} commentsTemplate template/placeholder for comments.
   * @param {any} commentsRequired is comments required for reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description handle the on hide the DIVERT MODAL
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.BLOCK_TURN_BACK);
  };

  /**
   * @description handling the commit button click
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    setShow(false); //HIDE THE MODAL
    await submitBlockTurnBackV2();
  };

  /**
   * @description save the block turn back form.
   * calling irregularOps API. and call the callbacks.
   */
  const submitBlockTurnBackV2 = async () => {
    let btbResult, isExistIrrops;

    trackEvent(`Block Turn Back (Return) - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    // let result, dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    //let estimatedIn= eta;
    let btbFlightRequestBody = getBodyWithSessionId(
      {
        //RESTRUCTURE BLOCK TURN BACK FLIGHT FORM DATA FOR POST TO API.
        operatingAirlineCode: operatingAirlineCode,
        flightNumber: flightNumber.toString(),
        opsType: IrropsCode.RETURN_TO_GATE,
        actualOrigin: origin,
        actualDestination: origin,
        scheduledOrigin: origin,
        scheduledDestination: scheduledDestination,
        scheduledOperatingDateUTC: scheduledOperatingDateUTC,
        aircraftRegistration: aircraftRegistration,
        actualIn: irropETA.value,
        estimatedOut: contETD.value,
        estimatedIn: contETA.value,
        isEtopsFlight: isEtopsFlight,
        legNumber: '', //legNumber,
        comments: userComments,
        opsReason: selectedReason.text,
        departureCount,
      },
      sessionId,
    );

    const response = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.BLOCK_TURN_BACK);
      if (isExistIrrops) {
        //CALL THE BLOCK Turn BACK FLIGHTS API .
        btbResult = await irregularOps(btbFlightRequestBody);
        trackEvent(
          `Block Turn Back (Return) - API call to submit block turn back flight details is complete. Status:${btbResult?.status}, Flight number : ${flightNumber}`,
        );

        let btbOperationCompleteCall = headCheckFlightExists(
          flightNumber,
          btbFlightRequestBody.scheduledOperatingDateUTC,
          btbFlightRequestBody.actualOrigin,
          btbFlightRequestBody.actualDestination,
          btbFlightRequestBody.departureCount,
          btbFlightRequestBody.operatingAirlineCode,
          IrropsCode.RETURN_TO_GATE,
        );

        //HANDLE RESULT
        if (btbResult !== null && btbResult.status === 'SUCCESS')
          // PASS RESULT FLIGHT ACTION MENU FOR HANDLE NOTIFACTION
          onCommit(true, 'Block Turn Back (Return)', '', [btbOperationCompleteCall]);
        //HANDLE TOAST NOTIFACTION SUCCESS
        else onCommit(false, 'Block Turn Back (Return)', '', []); //HANDLE TOAST NOTIFACTION ERROR
      } else {
        trackEvent(`Block Turn Back (Return) - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`);
        onCommit(false, 'Block Turn Back (Return)', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Block Turn Back (Return) - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Block Turn Back (Return)', '', []); //HANDLE TOAST NOTIFACTION ERROR
    }
  };

  /**
   * @description save the block turn back form.
   * calling irregularOps API. and call the callbacks.
   */

  useEffect(() => {
    updateWithEstimates(IrropsModalFieldType.STATIONS, irropStationVar);
  }, []);

  /**
   * @description build array eith btd current flight details.
   * @returns array of btd current flight details object.
   */
  const getBtbCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.BLOCK_TURN_BACK,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      outTime,
      null,
      eta,
    );
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.BLOCKTURNBACK, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle="Block Turn Back (Return)"
        irropStationLabel="In Time"
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.RETURN_TO_GATE}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getBtbCurrentFlightDetailBlocks()}
        showIrropSection={true}
        irropTitle="BLOCK TURN BACK STATION (RETURN)"
        irropStations={{
          origin,
          destination: irropStationVar,
          isDestinationEditable: false,
          onChangeDestination: () => {},
        }}
        irropETA={{
          value: irropETA.hhmm,
          onChange: handleETAInputChange,
          warningType: irropETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: irropETA.error,
        }}
        irropCheckbox={{
          show: false,
          label: '',
          value: false,
          onChange: () => {},
        }}
        showFollowFlightSection={true}
        followFlightTitle="ORIGINAL FLIGHT"
        followFlightStations={{ origin: irropStationVar, destination }}
        followFlightTurnTime={{
          value: turnTimeMinutes.value,
          onChange: handleTurnTimeTextInputChange,
          onBlur: handleTurnTimeOnBlur,
          warningType: turnTimeMinutes.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: turnTimeMinutes.error,
        }}
        followFlightETD={{
          value: contETD.hhmm,
          onChange: handleContinuationETDTextInputChange,
          warningType: contETD.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETD.error,
        }}
        followFlightETA={{
          value: contETA.hhmm,
          onChange: handleContinuationETATextInputChange,
          warningType: contETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETA.error,
        }}
        showCanceledFlightSection={false}
        reason={{
          placeholderText: '-Select Reason-',
          reasonText: selectedReason.text,
          commentsTemplate: selectedReason.template,
          isCommentsRequired: selectedReason.isCommentsRequired,
          onSelect: handleOnSelectReason,
        }}
        comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
        isCommitEnabled={isCommitEnabled(
          irropETA,
          turnTimeMinutes,
          contETD,
          contETA,
          selectedReason,
          userComments,
          hasAirTurnBackEntitlement,
          commitButtonClicked,
        )}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
    </AocAuthAccessCheck>
  );
};

BlockTurnBack.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(BlockTurnBack);
