import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import TimeDisplay from '../../../../Shared/TimeDisplay/TimeDisplay';
import '../../FlightDetailPane.css';
import '../FlightTab.css';

/**
 * Displays the scheduled, estimated and actual times
 * of an event of interest in UTC and local time
 * @param {string} event - event of interest
 * @param {array} times - scheduled, estimated and actual times of all OOOI events of the flight
 * @param {string} station - the station for this schedule event.
 * @returns FlightTabTimeSchedule
 */
const FlightTabTimeSchedule = ({ event, times, station }) => {
  const time = times.find((t) => t.name === event);
  const scheduledUtc = time?.scheduledTimeUTC;
  const estimatedUtc = time?.estimatedTimeUTC;
  const actualUtc = time?.actualTimeUTC;
  const defaultDisplay = <>&#8212;&#8212;&#8212;</>;
  return (
    <div data-cy="flight-tab-schedule-table">
      <div className="section-sub-header">{event}</div>
      <div className="item-label">Scheduled</div>
      <div className="item-cell" data-cy={`${event.toLowerCase()}-scheduled-times`}>
        <TimeDisplay utcTime={scheduledUtc} dataCyTag={`${event.toLowerCase()}-scheduled-timedisplay-zulu`} />
        &nbsp; / &nbsp;
        <TimeDisplay
          utcTime={scheduledUtc}
          dataCyTag={`${event.toLowerCase()}-scheduled-timedisplay-local`}
          station={station}
        />
      </div>

      <div className="item-label">Estimated</div>
      <div className="item-cell" data-cy={`${event.toLowerCase()}-estimated-times`}>
        <TimeDisplay utcTime={estimatedUtc} dataCyTag={`${event.toLowerCase()}-estimated-timedisplay-zulu`} />
        &nbsp; / &nbsp;
        <TimeDisplay
          utcTime={estimatedUtc}
          dataCyTag={`${event.toLowerCase()}-estimated-timedisplay-local`}
          station={station}
          comparisonUtcTime={scheduledUtc}
          label="mins"
        />
      </div>

      <div className="item-label">Actual</div>
      {actualUtc !== null ? (
        <div className="item-cell" data-cy={`${event.toLowerCase()}-actual-times`}>
          <TimeDisplay utcTime={actualUtc} dataCyTag={`${event.toLowerCase()}-actual-timedisplay-zulu`} />
          &nbsp; / &nbsp;
          <TimeDisplay
            utcTime={actualUtc}
            dataCyTag={`${event.toLowerCase()}-actual-timedisplay-local`}
            station={station}
            comparisonUtcTime={scheduledUtc}
            label="mins"
          />
        </div>
      ) : (
        <div>{defaultDisplay}</div>
      )}
    </div>
  );
};

FlightTabTimeSchedule.propTypes = {
  event: PropTypes.string.isRequired,
  times: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scheduledTimeUTC: PropTypes.string.isRequired,
      estimatedTimeUTC: PropTypes.string.isRequired,
      actualTimeUTC: PropTypes.string,
    }),
  ),
  station: PropTypes.string.isRequired,
};

export default withAppInsightsTracking(FlightTabTimeSchedule);
