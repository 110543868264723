import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import './DatePicker.css';

/**
 * A custom date picker component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.value] - The selected date value.
 * @param {string} [props.format='MMMM DD, YYYY'] - The format of the displayed date.
 * @param {boolean} [props.isDisablePast] - Whether to disable past dates.
 * @param {boolean} [props.isDisabled] - Whether the date picker is disabled.
 * @param {string} [props.className] - The CSS class name for the date picker.
 * @param {Function} [props.onChange] - The callback function triggered when the date value changes.
 * @param {Function} [props.onKeyUp] - The callback function triggered when a key is released.
 * @param {Function} [props.onKeyDown] - The callback function triggered when a key is pressed.
 * @param {string} [props.dataCyTag] - The data-cy attribute value for testing.
 * @param {Object} [props.minDate] - The minimum selectable date.
 * @param {Object} [props.maxDate] - The maximum selectable date.
 * @param {Object} [props.defaultValue] - The default date value.
 * @param {boolean} [props.isAutoFocus=false] - Whether the date picker should be auto-focused.
 * @returns {JSX.Element} The DatePicker component.
 */

// theme for date picker
const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
  },
});

const DatePicker = ({
  value,
  format = 'MMMM DD, YYYY',
  isDisablePast,
  isDisabled,
  className,
  onChange,
  onKeyUp,
  onKeyDown,
  dataCyTag,
  minDate = null,
  maxDate = null,
  isAutoFocus = false,
}) => {
  const slotProps = {
    calendarHeader: {
      sx: {
        position: 'relative',
        '& .MuiPickersArrowSwitcher-root': {
          width: 0,
        },
        '& .MuiPickersCalendarHeader-labelContainer': {
          margin: 'auto',
        },
        '& .MuiIconButton-edgeEnd': {
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
        },
        '& .MuiIconButton-edgeStart': {
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
    },
    nextIconButton: {
      'data-cy': `${dataCyTag}-next-button`,
    },
    previousIconButton: {
      'data-cy': `${dataCyTag}-previous-button`,
    },
    leftArrowIcon: {
      sx: { color: '#fff', fontSize: '2rem' },
      'data-cy': `${dataCyTag}-left-arrow`,
    },
    rightArrowIcon: {
      sx: { color: '#fff', fontSize: '2rem' },
      'data-cy': `${dataCyTag}-right-arrow`,
    },
    textField: { inputProps: { 'data-cy': dataCyTag, className: className } },
    openPickerButton: { tabIndex: '-1', 'data-cy': `${dataCyTag}-button` },
    day: { tabIndex: '-1', 'data-cy': `${dataCyTag}-day` },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <DesktopDatePicker
          autoFocus={isAutoFocus}
          value={dayjs(value)}
          disabled={isDisabled}
          disablePast={isDisablePast}
          slotProps={slotProps}
          format={format}
          views={['day']}
          className={`as-date-picker ${className}`}
          maxDate={dayjs(maxDate)}
          minDate={dayjs(minDate)}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default DatePicker;

/**
 * The prop types for the DatePicker component.
 */
DatePicker.propTypes = {
  isDisablePast: PropTypes.bool,
  dataCyTag: PropTypes.string,
  maxDate: PropTypes.object,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any,
  format: PropTypes.any,
};
