import { useRef, useEffect, useCallback } from 'react';
import { useDragDropDispatch } from '../useDragDropStore/useDragDropStore';

const PRINT_DEBUG_STATEMENTS = false;

/**
 * Hook to connect a component to be a drag item.
 *
 * @param {Number} dragItemKey - key for this drag item
 * @param {Object} data - data stored for this drag item
 * @param {function} getFlightLine - function to get flight line for this drag item
 * @param {boolean} ctrlKeyOverride - override for ctrl key - needed for tests, unable to get jests to recognize ctrlKey event property
 * @returns {Array} -
 * [0] - Ref to attach to element to be dragged
 */
export function useDragItem({ dragItemKey, getDragData, canDrag = true, ctrlKeyOverride = false }) {
  const { startDrag, endDrag } = useDragDropDispatch();
  const dragItemRef = useRef();

  /**
   * Callback when this item begins dragging
   * @param {*} e
   */
  const handleDragStart = useCallback(
    (e) => {
      const ctrlKey = e.ctrlKey || e.metaKey || ctrlKeyOverride;

      if (PRINT_DEBUG_STATEMENTS) {
        console.log(`handleDragStart() dragItemKey=${dragItemKey}`, e.target);
      }

      startDrag({ dragItemKey, data: getDragData(ctrlKey), ctrlKey });
    },
    [startDrag, dragItemKey, getDragData],
  );

  /**
   * Callback when this item ends dragging
   * @param {*} e
   */
  const handleDragEnd = useCallback(
    (e) => {
      if (PRINT_DEBUG_STATEMENTS) {
        console.log(`handleDragEnd() dragItemKey=${dragItemKey}`, e.target);
      }
      endDrag();
    },
    [endDrag, dragItemKey],
  );

  /**
   * Attaches drag event listeners to the dom element
   */
  useEffect(() => {
    const refCopy = dragItemRef?.current;
    if (refCopy && canDrag) {
      // set element to be draggable

      refCopy.setAttribute('draggable', true);

      // add drag event listeners
      refCopy.addEventListener('dragstart', handleDragStart);
      refCopy.addEventListener('dragend', handleDragEnd);
    }

    return () => {
      if (refCopy && canDrag) {
        refCopy.removeEventListener('dragstart', handleDragStart);
        refCopy.removeEventListener('dragend', handleDragEnd);
      }
    };
  }, [handleDragStart, handleDragEnd, canDrag]);

  return [dragItemRef];
}
