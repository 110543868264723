import React, { useMemo } from 'react';
import { useCrosshairDispatch, useCrosshairStore } from '../../../../../hooks/useCrosshairStore/useCrosshairStore';
import SingleSelectDropdown from '../../../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import './CrosshairCountButton.css';
import { CrosshairSessionStorageId } from '../../../../../lib/constants';

/**
 * The CrosshairCountButton component is single select menu for number of crosshairs to display.
 * @returns CrosshairCountButton component
 */
const CrosshairCountButton = () => {
  const { isCrosshairActive, crosshairCount } = useCrosshairStore();
  const { updateCrosshair, updateCrosshairCount, updateActiveCrosshair } = useCrosshairDispatch();

  const onClickCountButton = (e) => {
    const value = parseInt(e.target.value);

    const crosshairSessionStorage = JSON.parse(sessionStorage.getItem(CrosshairSessionStorageId.CROSSHAIR)) ?? {};
    const { crosshairPositions: sessionStoragePositions, crosshairCount: sessionStorageCount } =
      crosshairSessionStorage ?? [];

    if (sessionStorageCount > value) {
      // Remove crosshair positions from local storage
      crosshairSessionStorage.crosshairPositions = sessionStoragePositions.slice(0, value);
      crosshairSessionStorage.activeCrosshair = 0;
    }

    crosshairSessionStorage.crosshairCount = value;

    updateCrosshairCount({ crosshairCount: value });

    // If crosshair is inactive, set active and current
    if (!isCrosshairActive) {
      crosshairSessionStorage.isCrosshairActive = true;
      crosshairSessionStorage.activeCrosshair = 0;
      updateCrosshair({ isCrosshairActive: true });
      updateActiveCrosshair({ activeCrosshair: 0 });
    }
    sessionStorage.setItem(CrosshairSessionStorageId.CROSSHAIR, JSON.stringify(crosshairSessionStorage));
  };

  const displayList = useMemo(() => [
    { key: '1', name: '1' },
    { key: '2', name: '2' },
    { key: '3', name: '3' },
    { key: '4', name: '4' },
  ]);

  return (
    <SingleSelectDropdown
      displayText={crosshairCount}
      displayList={displayList}
      className="crosshair-count-select"
      dataCyTag="crosshair-count-select"
      isDisabled={false}
      onChange={onClickCountButton}
    />
  );
};

export default CrosshairCountButton;
