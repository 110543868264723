import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import {
  IrropsCode,
  EntitlementNames,
  WarningTypes,
  IrropsModalFieldType,
  Treatment,
  IrropsMenuItemAction,
} from '../../../lib/constants';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import {
  isNullOrWhitespace,
  isNullOrNotNumber,
  getCalculatedIrropDataEstimates,
  getDetailsBlocks,
  getBodyWithSessionId,
  getDepartureCount,
} from '../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../lib/utils';
import IrropsFlightModal from '../../Shared/IrropsFlightModal/IrropsFlightModal';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import {
  getFlightDetails,
  headCheckFlightExists,
  getFlightsFiltered,
} from '../../../services/apiClient/flightsApi/flightsApi';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import './AddContinueFlight.css';
import { useFlightExistsCheck } from '../../../react-query/useFlightsQuery/useFlightsQuery';
import { useNotificationState } from '../../../contexts/NotificationsContext/NotificationStateContext';
import { ToastType } from '../../../components/Shared/NotificationToast/NotificationToast';

/**
 * The Add Continue For Single Leg Flights component is build the Add Continue For Single Leg Flights form.
 * @param {Object} props -
 *  addContinueFlight - addContinueFlight object.
 *  onCommit - callback func when save the Add Continue For Single Leg Flights form.
 *  onClose - callback func when close the Add Continue For Single Leg Flights the form.
 *  nextFlightLeg - nextFlightLeg object.
 * @returns AddContinueFlight component
 */
const AddContinueFlight = ({
  onCommit,
  onClose,
  addContinueFlight,
  nextFlightLeg,
  irropsCode,
  getFlightLineForFlightPuck,
  ...props
}) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    flightDateUtc,
    outTime,
    eta,
    etd,
    isEtopsFlight,
    scheduledOperatingDateUTC,
    aircraftRegistration,
    legNumber,
    departureCount,
  } = addContinueFlight;
  const [turnTimeMinutes, setTurnTimeMinutes] = useState({ value: '', isError: false, error: '' });
  const [contETD, setContETD] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [contETA, setContETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [continuationAddDestination, setContinuationAddDestination] = useState({
    value: '',
    isError: false,
    error: '',
  });
  const [userComments, setUserComments] = useState('');
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [showNextFlight, setshowNextFlight] = useState(false);
  const [nextFlightData, setNextFlightData] = useState({
    etd: '',
    eta: '',
    scheduledOrigin: '',
    scheduledDestination: '',
  });
  const [show, setShow] = useState(true);
  const { trackEvent } = useAppInsightsContext();
  const [selectReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [irropETA, setIrropETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const commentsRequiredByAirline = {
    Airline: 'AS',
    Reason: 'Schedule Change',
    CommentTemplate: 'Describe details',
  };

  const {
    found: flightFound,
    notFound: flightNotFound,
    failed: flightCheckFailed,
    refetch: checkFlightExists,
  } = useFlightExistsCheck(
    flightNumber,
    scheduledOperatingDateUTC,
    destination,
    continuationAddDestination.value,
    departureCount,
    operatingAirlineCode,
    null,
    null,
    false,
    {
      timeout: 10000, // 10 seconds
    },
  );

  //feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  const hasSupportValidationEntitlement = useAuthorizationAccess(null, [EntitlementNames.SUPPORTVALIDATION]);
  const notificationState = useNotificationState();
  // turnTimeMinutes from contStation state comes from server.
  const [addContinuationStation, setAddContinuationStation] = useState({
    station: destination,
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
  });
  /**
   * Calls updateIrropDataEstimates from IrropUtils.js and updates local state.
   * @param {String} dirtyField IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyVal Value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyVal) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      legNumber,
      aircraftRegistration,
      formatDateTime(etd, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      addContinuationStation,
      irropETA,
      false, //checkedIrropFlightNeeded,
      true, //checkedContFlightNeeded,
      turnTimeMinutes,
      contETD,
      contETA,
      dirtyField,
      dirtyVal,
    );

    if (result == null) return null;

    if (result[IrropsModalFieldType.CONTINUATION_ETD] != null) {
      setContETD(result[IrropsModalFieldType.CONTINUATION_ETD]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETA] != null) {
      setContETA(result[IrropsModalFieldType.CONTINUATION_ETA]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_TURNTIME] != null) {
      setTurnTimeMinutes(result[IrropsModalFieldType.CONTINUATION_TURNTIME]);
    }
    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      let stations = result[IrropsModalFieldType.STATIONS];
      setAddContinuationStation(stations);
      setContinuationAddDestination({ value: dirtyVal, isError: false, error: '' });
      checkFlightExists();
    }
  };
  const airportCodeInputRef = useRef(null);
  notificationState.onCloseNotification(() => {
    if (airportCodeInputRef.current) {
      airportCodeInputRef.current.setAirport('');
      setContinuationAddDestination({ value: '', isError: false, error: '' });
      cleanInputs();
      airportCodeInputRef.current.focus();
    }
  });

  useEffect(() => {
    updateWithEstimates(IrropsModalFieldType.IRROP_ETA, eta);
  }, [eta]);

  useEffect(() => {
    if (flightCheckFailed) {
      notificationState.setNotification(
        ToastType.ERROR,
        'Unable to check for duplicate flight, close and try again.\nUpon failed attempts, please contact ITS Service Desk.',
        true,
        false,
      );
    }
  }, [flightCheckFailed]);

  /**
   * @description handle on blur of input field.
   * @param {any} - event - event object.
   */
  const handleTurnTimeOnBlur = () => {
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_TURNTIME, turnTimeMinutes.value);
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETDTextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETD, val);
    } else {
      setContETD({ value: '', hhmm: val, isError: false, error: '' });
    }
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleTurnTimeTextInputChange = (val) => {
    let inputNums = val.replace(/\D/g, '');
    if (inputNums.length > 3) {
      return;
    }
    setTurnTimeMinutes({ value: inputNums, isError: false, error: '' });
  };
  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETATextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETA, val);
    } else {
      setContETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  /**
   * @description handle the on selecting the reason dropdown and set the state.
   * @param {string} reason selected reason value.
   * @param {any} reasonKey reason key.
   * @param {any} commentTemplate template/placeholder for comments.
   * @param {any} commentsRequired is comments required for reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentTemplate, commentsRequired, event, target) => {
    if (operatingAirlineCode === commentsRequiredByAirline.Airline && reason === commentsRequiredByAirline.Reason) {
      commentsRequired = true;
      commentTemplate = commentsRequiredByAirline.CommentTemplate;
    }
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description Handle the commit button click and show and hide the modal.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);

    await submitAddContinueFlight();

    setShow(false); // Hide The Modal
  };

  const submitAddContinueFlight = async () => {
    trackEvent(`Add Continue Flight (Return) - Submit modal for flight number : ${flightNumber}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    // Get Current Flight Leg Data
    const response = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (response !== null) {
      let flightLegs = null;
      // const flightLine = getFlightLineForFlightPuck(null, true);
      if (getFlightLineForFlightPuck != null) {
        // getFlightLineForFlightPuck is available when the modal is opened from Gant view.

        flightLegs = getFlightLineForFlightPuck(null, true);
      } else {
        // if the modal is opened from table view, we need to make a call to get flight legs to determine new departureCount

        let flightLegsData = await getFlightsFiltered(
          scheduledOperatingDateUTC,
          scheduledOperatingDateUTC,
          [],
          [flightNumber],
          [destination],
          [],
        );
        flightLegs = flightLegsData.flattenedFlightLegs;
      }

      const newDepartureCount = getDepartureCount(flightLegs, flightNumber, destination, scheduledOperatingDateUTC) + 1;

      const addContinueFlightRequestBody = getBodyWithSessionId(
        {
          operatingAirlineCode: operatingAirlineCode,
          flightNumber: flightNumber.toString(),
          opsType: IrropsCode.ADD_FLIGHT,
          actualOrigin: destination,
          actualDestination: continuationAddDestination.value, //irropStation.station,
          scheduledOrigin: destination,
          scheduledDestination: continuationAddDestination.value, // irropStation.station,
          scheduledOperatingDateUTC: scheduledOperatingDateUTC,
          aircraftRegistration: aircraftRegistration,
          scheduledOut: contETD.value,
          scheduledIn: contETA.value,
          estimatedOut: contETD.value,
          estimatedIn: contETA.value,
          isEtopsFlight: isEtopsFlight,
          legNumber: '', //legNumber,
          comments: userComments,
          opsReason: selectReason.text,
          departureCount: newDepartureCount,
        },
        sessionId,
      );
      const continueResult = await irregularOps(addContinueFlightRequestBody);
      trackEvent(
        `Add Continue Flight - API call to submit continue flight details is complete. Status:${continueResult?.status}, Flight number : ${flightNumber}`,
      );
      let headCallsArr = [];
      let continuationOperationCompleteCall = headCheckFlightExists(
        flightNumber,
        addContinueFlightRequestBody.scheduledOperatingDateUTC,
        addContinueFlightRequestBody.actualOrigin,
        addContinueFlightRequestBody.actualDestination,
        newDepartureCount,
        addContinueFlightRequestBody.operatingAirlineCode,
        addContinueFlightRequestBody.opsType,
      );
      headCallsArr.push(continuationOperationCompleteCall);
      if (continueResult !== null && continueResult?.status === 'SUCCESS') {
        onCommit(true, 'Add Continue Flight', '', headCallsArr); //handle toast notifaction SUCCESS
      } else {
        onCommit(false, 'Add Continue Flight', '', []); //handle toast notifaction ERROR
      }
    } else {
      trackEvent(`Add Continue Flight - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Add Continue Flight', '', []);
    }
  };

  /**
   * @description handle the on hide the Add Single Continuation MODAL
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.ADD_FLIGHT);
  };

  useEffect(() => {
    //Check Next Flight Is Multileg
    if (
      nextFlightLeg !== null &&
      nextFlightLeg.flightNumber === flightNumber.toString() &&
      nextFlightLeg.aircraft.aircraftRegistration === aircraftRegistration &&
      nextFlightLeg.scheduledOperatingDateUTC === scheduledOperatingDateUTC
    ) {
      setNextFlightData((prevState) => {
        return {
          ...prevState,
          nextFlightETD: `${nextFlightLeg.times.find((t) => t.name === 'OUT')?.estimatedTimeUTC}`,
          nextFlightETA: `${nextFlightLeg.times.find((t) => t.name === 'IN')?.estimatedTimeUTC}`,
          // Save properties needed to submit a cancel
          nextFlightScheduledOrigin: `${nextFlightLeg.origin.scheduled}`,
          nextFlightScheduledDestination: `${nextFlightLeg.destination.scheduled}`,
          actualOrigin: nextFlightLeg.origin.actual,
          actualDestination: nextFlightLeg.destination.actual,
          flightNumber: nextFlightLeg.flightNumber,
          operatingAirlineCode: nextFlightLeg.operatingAirline,
          scheduledOperatingDateUTC: nextFlightLeg.scheduledOperatingDateUTC,
          departureCount: nextFlightLeg.departureCount,
        };
      });
      setshowNextFlight(true);
    }
  }, [nextFlightLeg]);

  /**
   * @description build the current flight details in array format.
   * @returns current flight detail blocks in array
   */
  const getCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      irropsCode,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      outTime,
      etd,
      eta,
      IrropsMenuItemAction.ADD_CONTINUE_FLIGHT,
    );
  };

  /**
   * @description build the next flight details in array format.
   * @returns next flight detail blocks in array
   */
  const getNextFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.ADD_FLIGHT,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      nextFlightData.nextFlightScheduledOrigin,
      nextFlightData.nextFlightScheduledDestination,
      null,
      nextFlightData.nextFlightETD,
      formatDateTime(nextFlightData.nextFlightETA, 'HH:mm'),
    );
  };

  /**
   * Clears all input variable state
   */
  const cleanInputs = () => {
    setTurnTimeMinutes({ value: '', isError: false, error: '' });
    setContETD({ value: '', isError: false, error: '', hhmm: '' });
    setContETA({ value: '', isError: false, error: '', hhmm: '' });
  };

  /**
   * Event handler when the continue flight airport code has changed.
   * @param {String} value - three-letter, capitalized airport code
   */
  const handleAirportCodeInputChange = (value, event, target) => {
    if (value.length < 3) {
      setContinuationAddDestination({ value: '', isError: false, error: '' });
      cleanInputs();
      return;
    }

    if (!isNullOrWhitespace(value) && value.length === 3) {
      updateWithEstimates(IrropsModalFieldType.STATION_CONTINUATION_ADD, value); // The station of continue flight user input destination
    } else {
      setContinuationAddDestination({ value: '', isError: false, error: '' });
    }
    focusNextElement(event, target);
  };

  const isCommitEnabled = () => {
    return (
      !addContinuationStation.isError &&
      !continuationAddDestination.isError &&
      !isNullOrNotNumber(turnTimeMinutes.value) &&
      !isNullOrWhitespace(contETD.hhmm) &&
      !isNullOrWhitespace(contETD.value) &&
      !isNullOrWhitespace(contETA.hhmm) &&
      !isNullOrWhitespace(contETA.value) &&
      !isNullOrWhitespace(selectReason) &&
      !isNullOrWhitespace(selectReason.key) &&
      ((selectReason.isCommentsRequired && !!userComments) ||
        //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectReason.isCommentsRequired === false) &&
      !hasSupportValidationEntitlement &&
      !commitButtonClicked &&
      flightNotFound
    );
  };

  return (
    <IrropsFlightModal
      showModal={show}
      modalTitle="Add Continuation Flight"
      operatingAirlineCode={operatingAirlineCode}
      irropsCode={IrropsCode.ADD_FLIGHT}
      showFlightDetailsSection={true}
      flightDetailsTitle="CURRENT FLIGHT DETAILS"
      flightDetails={getCurrentFlightDetailBlocks()}
      showIrropSection={false}
      showFollowFlightSection={true}
      followFlightTitle="CONTINUATION FLIGHT"
      followFlightStations={{
        origin: destination,
        destination,
        isDestinationEditable: true,
        onChangeDestination: (value, event, target) => handleAirportCodeInputChange(value, event, target),
        warningType: flightFound || flightCheckFailed ? WarningTypes.ERROR : WarningTypes.NONE,
        warningMessage: flightFound ? 'Duplicate Flight' : '',
      }}
      followFlightTurnTime={{
        value: turnTimeMinutes.value,
        onChange: handleTurnTimeTextInputChange,
        onBlur: handleTurnTimeOnBlur,
        warningType: turnTimeMinutes.isError ? WarningTypes.WARNING : WarningTypes.NONE,
        errorMessage: turnTimeMinutes.error,
      }}
      followFlightETD={{
        value: contETD.hhmm,
        onChange: handleContinuationETDTextInputChange, //handleAddETDTextInputChange,
        warningType: contETD.isError ? WarningTypes.WARNING : WarningTypes.NONE,
        errorMessage: contETD.error,
      }}
      followFlightETA={{
        value: contETA.hhmm,
        onChange: handleContinuationETATextInputChange, //handleAddETATextInputChange,
        warningType: contETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
        errorMessage: contETA.error,
      }}
      // Use show cancel flight section to show next flight details
      showCanceledFlightSection={showNextFlight}
      canceledFlightTitle="NEXT FLIGHT DETAILS"
      canceledFlightDetails={getNextFlightDetailBlocks()}
      canceledFlightCheckbox={{
        label: 'Continue flight',
        value: null,
        onChange: () => {},
        hide: true,
      }}
      reason={{
        placeholderText: '',
        reasonText: selectReason.text,
        commentsTemplate: selectReason.template,
        isCommentsRequired: selectReason.isCommentsRequired,
        onSelect: handleOnSelectReason,
      }}
      comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
      isCommitEnabled={isCommitEnabled()}
      commitButtonClicked={commitButtonClicked}
      onClose={handleOnHideIrropsModal}
      onCommit={handleCommitButtonClick}
      airportCodeInputRef={airportCodeInputRef}
    />
  );
};

AddContinueFlight.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(AddContinueFlight);
