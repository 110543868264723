import React from 'react';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import './Counter.css';

const useStyles = makeStyles((_theme) => ({
  blueLink: {
    color: 'var(--interaction-link-color)',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
}));
/** Counter component to display the counter with label, value and increment/decrement buttons
 * @param {string} label - Label for the counter
 * @param {number} value - Value for the counter
 * @param {function} onIncrement - Function to increment the value
 * @param {function} onDecrement - Function to decrement the value
 *  @param {string} dataCy - Sets testid of the counter
 *  @param {function} onInputchange - Function to handle input for the value
 *  @param {boolean} autoFocus - Set focus of the value
 *  @param {string} isDisabledMinus - Flag to disable decrement counter
 * @param {string} isDisabledPlus - Flag to enable decrement counter
 * @returns Counter component with label, value and increment/decrement buttons
 */
const Counter = ({
  label,
  value,
  inputname,
  onIncrement,
  onDecrement,
  dataCy,
  onInputchange,
  autoFocus = 'false',
  isDisabledMinus,
  isDisabledPlus,
  inputRef,
}) => {
  const classes = useStyles();

  return (
    <div className="counter-flex-container-column" data-cy={dataCy}>
      <div className="text-container">{label}</div>
      <div className="flex-container-row">
        <div className={classes.blueLink} onClick={onDecrement}>
          <FontAwesomeIcon
            className={`scaling-counter-${isDisabledMinus}`}
            icon={faMinus}
            data-cy={`${dataCy}-minus`}
          />
        </div>
        <div className="divider-line"> </div>
        <div data-cy={`${dataCy}-value`}>
          <input
            className="text-box"
            type="text"
            pattern="[0-9]{}"
            name={inputname}
            ref={inputRef}
            value={value}
            onChange={onInputchange}
            autoFocus={autoFocus}
            onFocus={(e) => e.target.select()}
          ></input>
        </div>
        <div className="divider-line"></div>
        <div className={classes.blueLink} onClick={onIncrement}>
          <FontAwesomeIcon className={`scaling-counter-${isDisabledPlus}`} icon={faPlus} data-cy={`${dataCy}-plus`} />
        </div>
      </div>
    </div>
  );
};

export default Counter;
