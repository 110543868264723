import React, { useState } from 'react';
import PageNavigationBar from './PageNavigationBar/PageNavigationBar';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { ReactComponent as AirtrAKLogo } from '../../assets/images/AirtrAK-logo.svg';
import { ReactComponent as AirtrAKTestLogo } from '../../assets/images/AirtrAK-test-logo.svg';
import { ReactComponent as AirtrAKQALogo } from '../../assets/images/AirtrAK-qa-logo.svg';
import AssignmentSession from '../AssignmentSession/AssignmentSession';
import UserMenu from './UserMenu/UserMenu';
import { SessionAction, Treatment } from '../../lib/constants';
import { Link } from 'react-router-dom';
import { useSwapModeDispatch, useSwapModeStore } from '../../hooks/useSwapModeStore/useSwapModeStore';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { useFilterDispatch } from '../../hooks/useFilterStore/useFilterStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import appSettings from '../../appSettings';
import './Header.css';

// Text separator, renders " | "
const separator = <span data-cy="nav-user-info-separator">&nbsp;&#124;&nbsp;</span>;

const commaSeparator = <span data-cy="nav-user-info-comma-separator">&#44;&nbsp;</span>;

/**
 * AirtrAK Logo component
 * @returns AirtrAK logo
 */
const AirtrAKIcon = () => {
  if (appSettings.environment === 'test') {
    return <AirtrAKTestLogo data-cy="airtrak-test-logo" className="alaska-logo" />;
  } else if (appSettings.environment === 'qa') {
    return <AirtrAKQALogo data-cy="airtrak-qa-logo" className="alaska-logo" />;
  } else {
    return <AirtrAKLogo data-cy="airtrak-logo" className="alaska-logo" />;
  }
};

/**
 * The AOC Header bar which displays information about the user and their active session.
 * @param {string} userName - the user's name
 * @param {string} role - the user's role
 * @param {func} onRoleClick - function to call when user's role is clicked
 * @param {string[]} assignments - the user's assignments list
 * @param {func} onAssignmentClick - function to call when user's assignment is clicked
 * @return {Header} The Header component
 */
const Header = () => {
  // component states
  const [editRole, setEditRole] = useState(false);
  const [editAssignment, setEditAssignment] = useState(false);

  // hooks
  const {
    state: { userName, authorizedRoles, authorizedAssignments },
  } = useUserContext();
  const { roleAssignments, updateRoleAssignment } = useRoleAssignmentContext();
  const { clearFilter } = useFilterDispatch();

  const handleEditRoleClick = () => {
    setEditRole(true);
  };

  const handleEditAssignmentClick = () => {
    setEditAssignment(true);
  };

  // For clearing swap mode when role or assignment change
  const { clearSwapMode } = useSwapModeDispatch();
  const { isSwapModeActive } = useSwapModeStore();

  /**
   * Event handler when role and assignment change is confirmed
   * @param {Object} session role and assignments object
   */
  const handleEditConfirmRoleAssignment = (session) => {
    updateRoleAssignment(session);
    clearFilter();
    setEditRole(false);
    setEditAssignment(false);
    if (isSwapModeActive) clearSwapMode();
  };

  const handleEditCancelRoleAssignment = () => {
    setEditRole(false);
    setEditAssignment(false);
  };

  // FEATURE FLAG:
  // This determines if the user should see a feature or not
  const { showFeature } = useFeatureFlag();
  const betaFlag = showFeature(Treatment.BETA_Label);

  const userNameRoleContent = (
    <div className="nav-bar-username-role-container">
      <span data-cy="nav-user-text">{userName}</span>
      {separator}
      {authorizedRoles.length > 1 ? (
        <>
          <span data-cy="nav-role-text">{roleAssignments.role?.name}</span>
          &nbsp;&nbsp;
          <span
            className={!isSwapModeActive ? 'nav-bar-role-edit' : 'nav-bar-role-edit nav-bar-disable'}
            data-cy="nav-bar-role-edit"
            onClick={handleEditRoleClick}
          >
            Edit
          </span>
        </>
      ) : (
        <span data-cy="nav-role-text">{roleAssignments.role?.name}</span>
      )}
    </div>
  );

  let assignmentContent = <></>;
  if (roleAssignments.assignments && roleAssignments.assignments.length > 0) {
    assignmentContent = (
      <div className="nav-bar-assignment-container" data-cy="nav-assignment-text">
        <>Assignments:&nbsp;</>
        {roleAssignments.assignments.map((assignment, index) => {
          return (
            <span className="nav-bar-assignment" key={`assignment-${index}`}>
              {index ? commaSeparator : ''}
              {assignment.name}
            </span>
          );
        })}
        {authorizedAssignments.filter((x) => x.roleID === roleAssignments.role.roleID).length > 1 ? (
          <>
            &nbsp;&nbsp;
            <span
              className={!isSwapModeActive ? 'nav-bar-assignment-edit' : 'nav-bar-assignment-edit nav-bar-disable'}
              data-cy="nav-bar-assignment-edit"
              onClick={handleEditAssignmentClick}
            >
              Edit
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  /**
   * Environment banner component
   * @returns {Component} - banner for TEST and QA
   */
  const EnvironmentBanner = () => {
    // No banner if not TEST or QA
    if (appSettings.environment !== 'test' && appSettings.environment !== 'qa') {
      return <></>;
    }

    let environmentLabel = '';
    if (appSettings.environment === 'test') {
      environmentLabel = 'Test';
    } else if (appSettings.environment === 'qa') {
      environmentLabel = 'QA';
    }

    let bannerText = `You are in the ${environmentLabel} Environment. No operational impact.`;
    if (betaFlag) {
      bannerText = `You are a Beta user in the ${environmentLabel} Environment. No operational impact.`;
    }

    return (
      <Nav className="nav-bar-center-container ml-auto">
        <div data-cy="environment-banner" className="nav-bar-env-info-container">
          <FontAwesomeIcon icon={faInfoCircle} className="nav-bar-info-container" />
          <span>{bannerText}</span>
        </div>
      </Nav>
    );
  };

  return (
    <>
      <Navbar className="header-container">
        <Navbar.Brand className="header-brand-container" as={Link} to="/">
          <AirtrAKIcon />
        </Navbar.Brand>
        {userName && roleAssignments.role && (
          // Only add the Page Navigation Bar and the User Drop Down if we have an authorized user
          <>
            <PageNavigationBar />
            <EnvironmentBanner />
            <Nav className="nav-bar-right-container ml-auto">
              <div className="nav-bar-user-info-container">
                {userNameRoleContent}
                {assignmentContent}
              </div>
              <UserMenu assignments={roleAssignments.assignments} />
            </Nav>
          </>
        )}
      </Navbar>
      {(editRole || editAssignment) && (
        <AssignmentSession
          authorizedRoles={authorizedRoles}
          authorizedAssignments={authorizedAssignments}
          onConfirm={handleEditConfirmRoleAssignment}
          onCancel={handleEditCancelRoleAssignment}
          sessionAction={editRole ? SessionAction.CHANGE_ROLE : SessionAction.CHANGE_ASSIGNMENT}
          prevAssignmentSession={{ role: roleAssignments.role, assignments: roleAssignments.assignments }}
        />
      )}
    </>
  );
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Header);
