import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import './NotificationAlert.css';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 7,
    fontSize: '1.2rem',
  },
}))(Badge);

/**
 * Returns the notification icon and count (or nothing if no alerts)
 * @param {string} alertsCount
 * @param {array} notifications array
 */
export const NotificationAlert = ({ notifications }) => {
  if (notifications === null || notifications === undefined || notifications.length === 0) {
    return <></>;
  }

  const tooltipText = notifications.map((n) => n.notificationTitle).join(', ');

  return (
    <span className="alert-column-item" data-cy="alert-column-item">
      <Tooltip title={tooltipText} placement="right">
        <StyledBadge badgeContent={notifications.length} color="error">
          <NotificationsNoneIcon className="alert-icon" />
        </StyledBadge>
      </Tooltip>
    </span>
  );
};

NotificationAlert.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      notificationTitle: PropTypes.string.isRequired,
    }),
  ),
};

export default NotificationAlert;
