import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'reference';
const moduleName = 'referenceApi';

/**
 * @description Request all types of alerts from the Reference API.
 * @returns {Array} - An array of objects representing alerts.
 */
export const getAllAlertTypes = async () => {
  const apiClient = axiosInstance;

  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/alerts`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getAllAlertTypes', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  return result;
};

/**
 * Returns a list of reason codes
 * @param {*} airlineCode
 * @param {*} irropsCode
 */
export const getSwapsReasons = async (airlineCode) => {
  const apiClient = axiosInstance;
  let result = null;

  try {
    const url = `${API_ROUTE}/api/v1/swapreasons/${airlineCode}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getSwapsReasons', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * Returns a list of reason codes
 * @param {*} airlineCode
 */
export const getSeatMaps = async (airlineCode) => {
  const apiClient = axiosInstance;
  let result = null;

  try {
    const url = `${API_ROUTE}/api/v1/subfleet/seatmap?airlineCode=${airlineCode}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getSeatmaps', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
