/**
 * Sets a value in sessionStorage
 * @param {string} key
 * @param {Object} value
 */
function setSessionStorageItem(key, value) {
  try {
    // Convert the value to a JSON string before storing it
    const jsonValue = JSON.stringify(value);
    sessionStorage.setItem(key, jsonValue);
  } catch (error) {
    console.error(`Error setting sessionStorage item: ${error.message}`);
  }
}

/**
 * Gets a value from sessionStorage
 * @param {string} key
 * @returns
 */
function getSessionStorageItem(key) {
  try {
    // Retrieve the JSON string and parse it back to an object
    const jsonValue = sessionStorage.getItem(key);
    if (jsonValue === null) {
      return null;
    }
    return JSON.parse(jsonValue);
  } catch (error) {
    console.error(`Error getting sessionStorage item: ${error.message}`);
    return null;
  }
}

/**
 * Removes a value from sessionStorage
 * @param {string} key
 */
function removeSessionStorageItem(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing sessionStorage item: ${error.message}`);
  }
}

export { setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem };
