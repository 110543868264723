import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import './SwapWarningBadge.css';
/**
 *
 * @param {status} - status string check the icon list
 * @returns  - Certificate Icons
 */

const SwapWarningBadge = ({ messages }) => {
  return (
    <Fragment>
      {messages?.map((message, index) => (
        <div key={index} className="swap-warning-badge-container" data-cy="swap-warning-badge-container">
          <Grid item>
            <div className="swap-warning-badge-icon" data-cy="swap-warning-badge-icon">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          </Grid>
          <Grid item>
            <div className="swap-warning-badge-message" data-cy="swap-warning-badge-message">
              {message.swapWarningDescription}
            </div>
          </Grid>
        </div>
      ))}
    </Fragment>
  );
};

export default withAppInsightsTracking(SwapWarningBadge);
