import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Shared/Button/Button';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import './WatchFlight.css';
import Modal from '../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextInput from '../Shared/Inputs/TextInput/TextInput';
import { EntitlementNames, KeyCodes } from '../../lib/constants';
import IrropsReasons from '../Shared/IrropsReasons/IrropsReasons';
import HorizontalRule from '../Shared/HorizontalRule/HorizontalRule';
import { isNullOrWhitespace } from '../../lib/displayUtils';
import { saveNote } from '../../services/apiClient/notesApi/notesApi';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { getDetailsBlocks } from '../../lib/irropUtils';
import BrickContainer from '../Shared/BrickContainer/BrickContainer';
import CurrentFlightDetails from '../Shared/CurrentFlightDetails/CurrentFlightDetails';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';
import useAuthorizationAccess from '../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  horizontalRule: {
    borderTop: '0.125rem solid var(--primary-border-color)',
  },
  detailsHzRule: {
    marginTop: '-11px',
  },
  brickMarginBottom: {
    marginBottom: '14px !important',
    paddingRight: '49px !important',
  },
  sectionNewDocGrid: {
    paddingTop: '0.84375rem !important',
    paddingBottom: '0.375rem !important',
  },
  brickLabel2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '0.25rem',
  },
  sectionHeaderGrid: {
    paddingTop: '0.125rem !important',
    paddingBottom: '0.34375rem !important',
  },
  sectionHeaderGrid2: {
    paddingTop: '0.625rem !important',
    paddingBottom: '0.3125rem !important',
  },
}));

const reasonsList = [
  { value: 'Charter', name: 'Charter' },
  { value: 'Crew', name: 'Crew' },
  { value: 'Extras', name: 'Extras' },
  { value: 'Fallen Soldier/Honor Flight', name: 'Fallen Soldier/Honor Flight' },
  { value: 'FCF', name: 'FCF' },
  { value: 'High-Mins Captain', name: 'High-Mins Captain' },
  { value: 'International', name: 'International' },
  { value: 'Marketing', name: 'Marketing' },
  { value: 'MX Drop Dead Last Flight', name: 'MX Drop Dead Last Flight' },
  { value: 'Pre-Cancel', name: 'Pre-Cancel' },
  { value: 'Repo/Ferries', name: 'Repo/Ferries' },
  { value: 'Significant Event', name: 'Significant Event' },
  { value: 'Subs', name: 'Subs' },
  { value: 'VIP/Board of Director', name: 'VIP/Board of Director' },
  { value: 'Weather', name: 'Weather' },
  { value: 'Other', name: 'Other' },
];

/**
 * The WatchFlight component handles the Watch flight.
 * @param {func} onCommit - function to handle watch flight confirmation
 * @param {func} onClose - function to handle watch flight cancellation
 * @param {Object} selectedFlightLeg - selected flight Leg data form action menu,
 */
const WatchFlight = ({ onCommit, onClose, selectedFlightLeg, ...props }) => {
  const {
    origin,
    destination,
    selirropsCode,
    flightNumber,
    flightDateUtc,
    aircraftRegistration,
    operatingAirlineCode,
    departureCount,
    scheduledOperatingDateUTC,
  } = selectedFlightLeg;

  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [selectedReason, setSelectedReason] = useState({ text: '', key: null });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const {
    state: { userKey },
  } = useUserContext();

  const [ownReason, setOwnReason] = useState('');
  const { trackEvent } = useAppInsightsContext();

  // authorization access hook
  const hasWatchFlightEntitlement = useAuthorizationAccess(null, [EntitlementNames.EDITWATCHFLIGHT]);

  /**
   * @description handle on select reason from reason dropdown
   * @param {*} reason
   * @param {*} reasonKey
   */
  const handleOnSelectReason = (reason, reasonKey) => {
    setSelectedReason({ text: reason, key: reasonKey });
  };

  /**
   * @description handle current flight detail blocks
   * @returns returns blocks in array */
  const getCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      null,
      false,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      null,
      null,
      null,
    );
  };

  /** @description Handle API call on commit button for watch flight */
  const handleCommitButtonClick = async () => {
    trackEvent(`Watch Flight - Commit modal for flight number : ${flightNumber}`);

    let result;
    setCommitButtonClicked(true);
    let requestBody = {
      noteTag: 'WatchFlight',
      operationalNote: selectedReason.text === 'Other' ? ownReason : selectedReason.text,
      flightLeg: {
        flightNumber: flightNumber,
        operatingAirline: operatingAirlineCode,
        origin: origin,
        destination: destination,
        departureCount: departureCount,
        scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      },
      assignmentUserKey: userKey?.toString(),
      assignmentRoleKey: '',
    };
    // API call for save note
    result = await saveNote(requestBody);
    trackEvent(`Watch Flight - API call to save note is complete. Flight number : ${flightNumber}`);

    const isSuccess = result?.status === 'Success';

    if (isSuccess) {
      onCommit(true, 'Watch Flight');
    } else {
      onCommit(false, 'Watch Flight');
    }
    setShow(false);
  };

  /** @description on Change comment text set in hook state*/
  const onChangeHandler = (val) => {
    setOwnReason(val);
  };

  /***
   * @description check all required fields
   * @returns true or false */
  const isCommitEnabled = () => {
    const isOtherReason = selectedReason.text === 'Other';
    const isReasonPresent = reasonsList.find((x) => x.value === selectedReason.text);
    let isValid =
      !isNullOrWhitespace(selectedReason) && !isNullOrWhitespace(selectedReason.key) && isOtherReason
        ? !isNullOrWhitespace(ownReason)
        : !isNullOrWhitespace(selectedReason.key) && isReasonPresent !== undefined;
    return isValid && hasWatchFlightEntitlement && !commitButtonClicked;
  };

  const formHTML = (
    <div className="watch-flight-modal-form">
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.sectionHeaderGrid}>
          <div className={classes.sectionHeader}>CURRENT FLIGHT DETAILS</div>
        </Grid>
        <Grid item xs={12}>
          <CurrentFlightDetails
            flightDetailBlocks={getCurrentFlightDetailBlocks()}
            customBrickCSSTag={classes.brickMarginBottom}
            dataCyTag="ur-cfd"
          />
        </Grid>
        <Grid item xs={12} className={classes.detailsHzRule}>
          <HorizontalRule />
        </Grid>
        <Grid item xs={12} className={classes.sectionNewDocGrid}>
          <div className={classes.sectionHeader}>DOCUMENTATION</div>
        </Grid>

        <Grid container item md={6} sm={12} xs={12}>
          <Grid item xs={12}>
            <BrickContainer title={`Reason`} customCSSTag={classes.brickLabel2} isRequired={true}>
              <IrropsReasons
                onSelect={handleOnSelectReason}
                airlineCode={operatingAirlineCode}
                irropsCode={selirropsCode}
                selectedReason={selectedReason.text}
                reasonsList={reasonsList}
                fetchReasonsApi={false}
              />
            </BrickContainer>
          </Grid>
        </Grid>
        {selectedReason.text === 'Other' ? (
          <Grid container item xs={12} className={`${classes.sectionHeaderGrid2}`}>
            <Grid item xs={12}>
              <TextInput
                placeholderText="Please type in your own reason"
                dataCyTag="watch-flight-reason-text"
                onChange={onChangeHandler}
                maxLengthValue={25}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );

  /**
   * @description hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled()) {
      functionDebounce();
    }
  };
  /**
   * @description This function is triggered after the user types within an interval of 300 milliseconds
   */
  const functionDebounce = debounce(() => handleCommitButtonClick(), 300);
  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="watch-flight-save-button"
      isDisabled={!isCommitEnabled()}
      onClick={handleCommitButtonClick}
      onKeyPress={handleKeypress}
      className="btn-irrops-commit"
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <Modal
      size={`responsive watch-flight-modal`}
      dataCyTag="watch-flight-modal"
      show={show}
      title="Watch Flight"
      body={formHTML}
      footerButtons={formButtons}
      onHide={onClose}
      customCSSTag="watch-flight-modal"
      draggable={false}
    />
  );
};

WatchFlight.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(WatchFlight);
