import windowManagerReducer from './windowManagerReducer';
import rootReducer from './rootReducer';
import configReducer from './configReducer';
import { RootAction } from '../actionTypes';

const multipleViewsRootReducer = (state, action) => {
  const { type, payload } = action;
  if (type === RootAction.UPDATE_ROOTSTATE) {
    sessionStorage.removeItem('windowManagerStore');
    const defaultState = multipleViewsRootReducer({}, { type: 'INIT' });
    return { ...defaultState, id: state.id, ...payload.rootState };
  }
  const activeId = state?.viewManagerReducer?.viewOrder[0] || 0;
  const updatedWindowState = windowManagerReducer(state?.viewManagerReducer, action);
  const update = {
    configReducer: configReducer(state?.configReducer, action),
    viewManagerReducer: updatedWindowState,
    viewWindowData:
      updatedWindowState?.viewWindows.map((viewWindow) => {
        const currentId = viewWindow.id;
        let currentIdState = state?.viewWindowData?.find((viewData) => viewData.id === currentId);
        if (!currentIdState) {
          currentIdState = rootReducer({ id: currentId }, { type: 'INIT', id: currentId });
        }
        return currentId === activeId ? rootReducer(currentIdState, { ...action }) : currentIdState;
      }) || [],
  };
  return update;
};

export default multipleViewsRootReducer;
