import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { FocusedFlightLegActions } from '../../redux/actionTypes';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Custom hook for retrieving the focused flight leg
 * @returns {Element} the focused flight leg and a boolean to show if it's focused
 */
export const useFocusedFlightLegStore = () => {
  return useSelector((state) => state.focusedFlightLegReducer);
};

/**
 * Custom hook to check if given flight leg is present in store or not
 * @returns {Element} Returns true if the given flight leg parameters matches the focused flight leg in the store
 */
export const useIsFocusedFlightLegStore = (flightData) => {
  const storeData = useSelector((state) => state.focusedFlightLegReducer);
  return (
    flightData.flightNumber == storeData.focusedFlightLeg.flightNumber &&
    flightData.departureDate == storeData.focusedFlightLeg.departureDate &&
    flightData.airline == storeData.focusedFlightLeg.airline &&
    flightData.orig == storeData.focusedFlightLeg.orig &&
    flightData.dest == storeData.focusedFlightLeg.dest &&
    flightData.departureCount == storeData.focusedFlightLeg.departureCount
  );
};

/**
 * Custom hook for dispatching actions to focus flight leg
 * @returns object of dispatch actions to interact with focused flight leg store
 */
export const useFocusedFlightLegDispatch = () => {
  const dispatch = useDispatch();

  /**
   * sets focused flight leg to the store
   * @param {Number} flightLeg
   */
  const setFocusedFlightLeg = useCallback(
    (flightLeg) => {
      dispatch({
        type: FocusedFlightLegActions.SET_FOCUSED_FLIGHT_LEG,
        payload: {
          flightLeg,
        },
      });
    },
    [dispatch],
  );

  /**
   * sets the boolean to know if selected flight leg is focused
   * @param {boolean} isFocused
   */
  const setIsFocused = useCallback(
    (isFocused) => {
      dispatch({
        type: FocusedFlightLegActions.SET_IS_FOCUSED,
        payload: {
          isFocused,
        },
      });
    },
    [dispatch],
  );

  /**
   * used to determine if search overlay should highlight the focused flight leg
   * @param {boolean} highlightFlightLeg
   */
  const highlightFocusedFlightLeg = useCallback(
    (highlightFlightLeg) => {
      dispatch({
        type: FocusedFlightLegActions.HIGHLIGHT_FOCUSED_FLIGHT_LEG,
        payload: {
          highlightFlightLeg,
        },
      });
    },
    [dispatch],
  );

  /**
   * clears the data in focusedFlightLeg store
   */
  const clearFocusedFlightLeg = useCallback(() => {
    dispatch({
      type: FocusedFlightLegActions.CLEAR_FOCUSED_FLIGHT_LEG,
    });
  }, [dispatch]);

  return { setFocusedFlightLeg, setIsFocused, clearFocusedFlightLeg, highlightFocusedFlightLeg };
};
