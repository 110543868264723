import React from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import TabSkeletonSection from '../TabSkeletonSection/TabSkeletonSection';

/**
 * TabSkeleton renders the skeleton for flight detail pane
 * @returns
 */
const TabSkeleton = () => {
  //render the content 5 times
  const contentToRender = [...Array(5)].map((e, i) => <TabSkeletonSection />);

  return (
    <div className="flight-detail-panel-skeleton" data-cy="flight-detail-panel-skeleton">
      {contentToRender}
    </div>
  );
};

TabSkeleton.propTypes = {};

export default withAppInsightsTracking(TabSkeleton);
