import { baseTheme } from './baseTheme';

export const lightTheme = {
  ...baseTheme,
  palette: {
    type: 'light',
    background: {
      default: '#ffffff',
      //Background color for mui-popover such as calendar
      paper: '#ffffff', //var(--page-container-background)
    },
    text: {
      primary: '#000000', //var(--page-container-text)
    },
  },
  overrides: {
    MuiTableContainer: {
      root: {
        backgroundColor: '#ffffff', //var(--page-container-background)
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f7f7f7', //var(--table-odd-stripe)
        },
      },
    },
    MuiTableCell: {
      body: {
        color: '#000',
      },
      root: {
        fontSize: '1.4rem',
      },
    },
    //Icon button such as prev-next in calendar
    MuiIconButton: {
      root: {
        color: '#0000008a', // This is the default value used currently
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        '&$focused': {
          backgroundColor: '#ffffff',
          border: '1px solid rgb(200, 201, 199)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          borderColor: 'var(--brightBlue)',
        },
        '&$focused': {
          borderColor: 'var(--brightBlue)',
        },
      },
    },
    // Calendar picker styles
    MuiTypography: {
      caption: {
        fontSize: '1.2rem',
      },
      body2: {
        fontSize: '1.4rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '2.4rem',
      },
    },
  },
};
