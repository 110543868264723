import dayjs from 'dayjs';
import { getBlockMinutes } from '../../../services/apiClient/flightReferenceTimesApi/flightReferenceTimesApi';
import { isNullOrWhitespace } from '../../../lib/displayUtils';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { TimeZonePreference, WarningTypes } from '../../../lib/constants';
import { isNullOrNotNumber, isTimeDiffAfterWithin8Hours, timeIsAfter } from '../../../lib/irropUtils';

/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const formatZulu = 'YYYY-MM-DDTHH:mm:ssZ';

// main methods should return utc value, hhmm, timezoneidentifier

export const isStationStr = (station) => {
  if (isNullOrWhitespace(station) || station.length !== 3) {
    return false;
  }
  return true;
};

const TimeObject = (utcValue, hhmm, tzDisplay) => {
  return { utcValue, hhmm, tzDisplay };
};

const setTimeOnDate = (utcDate, hhmm, timezoneIdentifier = 'Etc/UTC', timezonePreference = TimeZonePreference.UTC) => {
  let refDate = null;
  if (timezonePreference === TimeZonePreference.UTC) {
    refDate = dayjs.utc(utcDate);
  } else {
    if (utcDate.length <= 10) {
      refDate = dayjs.tz(utcDate, timezoneIdentifier);
    } else {
      refDate = dayjs.utc(utcDate).tz(timezoneIdentifier);
    }
  }
  let hours = '';
  let minutes = '';
  if (hhmm.length === 5) {
    hours = hhmm.substr(0, 2);
    minutes = hhmm.substr(3, 2);
  }
  let preCorrectedSameDayDateTime = refDate.clone().set('hour', hours).set('minute', minutes).set('second', 0);
  if (!preCorrectedSameDayDateTime.isBefore(refDate)) {
    return preCorrectedSameDayDateTime.utc().format(formatZulu);
  }
  let correctedToNextDate = preCorrectedSameDayDateTime.clone().add(1, 'day');
  return correctedToNextDate.utc().format(formatZulu);
};

const addTimeOnDate = (
  utcRefDate,
  minutes,
  timezoneIdentifier = 'Etc/UTC',
  timezonePreference = TimeZonePreference.UTC,
) => {
  let utcdate = dayjs.utc(utcRefDate).add(minutes, 'minute');
  let utcFormattedDate = utcdate.utc().format(formatZulu);
  if (timezonePreference === TimeZonePreference.UTC) {
    return { utcValue: utcFormattedDate, hhmm: utcdate.utc().format('HH:mm'), tzDisplay: 'zulu' };
  }
  let hhmmVal = dayjs.utc(utcFormattedDate).tz(timezoneIdentifier).format('HH:mm');
  return { utcValue: utcFormattedDate, hhmm: hhmmVal, tzDisplay: timezoneIdentifier };
};

/**
 * get the time zone lable for station
 * @param {string} utcRefDate
 * @param {string} hhmm
 * @param {number} blockTime
 * @param {string} tzIdentifier
 * @param {string} timezonePreference TimeZonePreference enum stirng to represent whether use UTC or station local or station specific time zones for calculating dates and times
 * @returns timeZone Lable
 */
const getStationTimeZoneLable = (utcRefDate, hhmm, blockmin, tzIdentifier, timezonePreference) => {
  if (!isNullOrWhitespace(hhmm) && isNullOrNotNumber(blockmin)) {
    utcRefDate = setTimeOnDate(utcRefDate, hhmm, tzIdentifier, timezonePreference);
  } else {
    let timeDates = addTimeOnDate(utcRefDate, blockmin, tzIdentifier, timezonePreference);
    utcRefDate = timeDates.utcValue;
  }
  return dayjs(utcRefDate).tz(tzIdentifier).format('z');
};

export const getEstimatedETD = async (
  utcRefDate,
  hhmm,
  timezoneIdentifier = '',
  timezonePreference = TimeZonePreference.UTC,
  turnTime = null,
  scheduledOperatingDate,
) => {
  let tzIdentifier = timezonePreference === TimeZonePreference.UTC ? 'Etc/UTC' : timezoneIdentifier;
  if (isNullOrWhitespace(tzIdentifier)) {
    return TimeObject('', '', '');
  }
  let timezone = getStationTimeZoneLable(
    isNullOrWhitespace(utcRefDate) ? scheduledOperatingDate : utcRefDate,
    hhmm,
    null,
    tzIdentifier,
    timezonePreference,
  );
  let tzDisplay = timezonePreference === TimeZonePreference.UTC || timezone === 'UTC' ? 'zulu' : timezone;
  if (isNullOrWhitespace(utcRefDate) || (isNullOrWhitespace(hhmm) && isNullOrNotNumber(turnTime))) {
    return TimeObject('', '', tzDisplay);
  }
  if (turnTime == null || isNullOrNotNumber(turnTime)) {
    return { utcValue: setTimeOnDate(utcRefDate, hhmm, tzIdentifier, timezonePreference), hhmm: hhmm, tzDisplay };
  }
  return { ...addTimeOnDate(utcRefDate, turnTime, tzIdentifier, timezonePreference), tzDisplay };
};

/**
 * Get the current date object
 * @param {string} format
 * @returns date object
 */
export const getScheduledOperatingDate = (date = new Date()) => {
  return dayjs(date).format('YYYY-MM-DD');
};

/**
 *
 * @param {string} utcEtdDate
 * @param {string} hhmm
 * @param {string} destTimezoneIdentifier
 * @param {string} timezonePreference TimeZonePreference enum stirng to represent whether use UTC or station local or station specific time zones for calculating dates and times
 * @param {number} blockTime
 * @returns
 */
export const getEstimatedETA = async (
  utcEtdDate,
  hhmm,
  destTimezoneIdentifier = '',
  timezonePreference = TimeZonePreference.UTC,
  blockTime = null,
  scheduledOperatingDate,
) => {
  let tzIdentifier = timezonePreference === TimeZonePreference.UTC ? 'Etc/UTC' : destTimezoneIdentifier;
  if (isNullOrWhitespace(tzIdentifier)) {
    return TimeObject('', '', '');
  }
  let timezone = getStationTimeZoneLable(
    isNullOrWhitespace(utcEtdDate) ? scheduledOperatingDate : utcEtdDate,
    hhmm,
    blockTime,
    tzIdentifier,
    timezonePreference,
  );
  let tzDisplay = timezonePreference === TimeZonePreference.UTC || timezone === 'UTC' ? 'zulu' : timezone;
  if (isNullOrWhitespace(utcEtdDate) || (isNullOrWhitespace(hhmm) && isNullOrNotNumber(blockTime))) {
    return TimeObject('', '', tzDisplay);
  }
  if (blockTime == null || isNullOrNotNumber(blockTime)) {
    return { utcValue: setTimeOnDate(utcEtdDate, hhmm, tzIdentifier, timezonePreference), hhmm: hhmm, tzDisplay };
  }
  return { ...addTimeOnDate(utcEtdDate, blockTime, tzIdentifier, timezonePreference), tzDisplay };
};

/**
 * @description checks for trigger API for get flight number availability.
 * @param {string} scheduledOperatingDateUtc
 * @param {string} flightNumber
 * @returns returns true if flight date, flightNum not empty.
 */
export const canCheckFlightNumberAvailability = (scheduledOperatingDateUtc, flightNumber) => {
  return !isNullOrWhitespace(scheduledOperatingDateUtc) && !isNullOrWhitespace(flightNumber);
};

/**
 * get updated state object based on key property
 * @param {*} state
 * @param {string} key
 * @param {number} index
 * @param {*} value
 * @returns
 */
export const updateLegsProperties = (state, key, index, value) => {
  if (index == null || index >= state.length) {
    return;
  }
  let tempLegs = [...state];
  switch (key) {
    case 'origin':
      tempLegs[index].origin = value;
      break;
    case 'destination':
      tempLegs[index].destination = value;
      break;
    case 'etd':
      tempLegs[index].etd = value;
      break;
    case 'eta':
      tempLegs[index].eta = value;
      break;
    case 'dispatch':
      tempLegs[index].dispatchDesk = value;
      break;
    case 'blockMinutes':
      tempLegs[index].blockMinutes = value;
      break;
    case 'isExpanded':
      tempLegs[index].isExpanded = value;
      break;
    default:
      break;
  }
  return tempLegs;
};

export const ChangedField = {
  NONE: 'none',
  ETD: 'etd',
  ETA: 'eta',
  ORIGIN: 'origin',
  DESTINATION: 'destination',
};
export const WarningMessages = {
  FLIGHT_WARNING: 'Unavailable Flight Number',
  AIRCRAFT_WARNING: 'Unavailable Aircraft Number',
  ESTIMATES_WARNING: 'Aircraft is unavailable at the time',
  BLOCK_TIME_WARNING: `Block time is $time hours`,
  AIRCRAFT_INVALID_WARNING: 'Invalid Aircraft Number',
  SHOULD_BEAFTER_WARNING: `Should be after $time`,
};

/**
 * get block minutes of a particular origin/destination/airline station combination
 * @param {string} operatingAirline
 * @param {string} origin
 * @param {string} destination
 * @returns
 */
export const getStationsBlockMinutes = async (operatingAirline, origin, destination) => {
  if (isStationStr(origin) && isStationStr(destination)) {
    let blockMinutes = await getBlockMinutes(operatingAirline, origin, destination);
    if (!isNullOrNotNumber(blockMinutes)) {
      return blockMinutes;
    }
  }
  return null;
};
/**
 * get updated ETD value of the field
 * @param {number} index
 * @param {*} state
 * @param {string} scheduledOperatingDate
 * @param {string} refDateUtc
 * @param {string} originTz
 * @param {string} hhmm
 * @param {number} turnTime
 * @param {string} tzPreference TimeZonePreference enum stirng to represent whether use UTC or station local or station specific time zones for calculating dates and times
 * @returns
 */
export const getUpdatedETDValue = async (
  index,
  state,
  scheduledOperatingDate,
  refDateUtc = null,
  originTz = null,
  hhmm = null,
  turnTime = null,
  tzPreference,
) => {
  if (index >= state.length) {
    return null;
  }
  let origTz = originTz ?? state[index].origin?.tz;
  let refDate = refDateUtc ?? (index == 0 ? scheduledOperatingDate : state[index - 1].eta?.utcValue);
  let tt = index === 0 ? null : turnTime;
  let hhmmVal = hhmm ?? state[index].etd?.hhmm;
  let etd = await getEstimatedETD(refDate, hhmmVal, origTz, tzPreference, tt, scheduledOperatingDate);
  return etd;
};
/**
 * get Updated ETA value
 * @param {number} index
 * @param {*} state
 * @param {string} refEtdUtc
 * @param {string} destinationTz
 * @param {string} hhmm
 * @param {number} blockTime
 * @param {string} tzPreference TimeZonePreference enum stirng to represent whether use UTC or station local or station specific time zones for calculating dates and times
 * @returns
 */
export const getUpdatedETAValue = async (
  index,
  state,
  scheduledOperatingDate,
  refEtdUtc = null,
  destinationTz = null,
  hhmm = null,
  blockTime = null,
  tzPreference,
) => {
  if (index >= state.length) {
    return null;
  }
  let destTz = destinationTz ?? state[index].destination?.tz;
  let refDate = refEtdUtc ?? state[index].etd?.utcValue;
  let hhmmVal = hhmm ?? state[index].eta?.hhmm;
  let eta = await getEstimatedETA(refDate, hhmmVal, destTz, tzPreference, blockTime, scheduledOperatingDate);
  return eta;
};

/**
 * get updated state after updating all the fields and legs recursively depending on what changed now on the first call
 * @param {*} state
 * @param {func} updateState
 * @param {string} operatingAirline
 * @param {string} scheduledOperatingDate
 * @param {number} index
 * @param {string} refTimeUtc
 * @param {string} changedField
 * @param {string} hhmm
 * @param {string} station
 * @param {number} addTimeMinutes
 * @param {string} tzPreference TimeZonePreference enum stirng to represent whether use UTC or station local or station specific time zones for calculating dates and times
 * @param {func} getStationDetails
 * @returns
 */
export const updateStateValues = async (
  state,
  updateState,
  operatingAirline,
  scheduledOperatingDate,
  index,
  refTimeUtc,
  changedField = ChangedField.NONE,
  hhmm = null,
  station = null,
  addTimeMinutes = null,
  tzPreference,
  getStationDetails,
) => {
  if (state == null) {
    return state;
  }
  if (index >= state.length) {
    return state;
  }
  if (updateState === null) {
    updateState = updateLegsProperties;
  }
  let myState = [...state];
  if (changedField === ChangedField.ORIGIN) {
    let origin = await getStationDetails(index, station, null, false);
    myState = updateState(myState, 'origin', index, { ...origin });
    let blockTime = await getStationsBlockMinutes(operatingAirline, origin.station, myState[0].destination?.station);
    if (blockTime != null) {
      myState = updateState(myState, 'blockMinutes', index, blockTime);
    }
    if (index === 0) {
      if (myState[0].destination.station.length > 0) {
        myState = await updateStateValues(
          myState,
          updateState,
          operatingAirline,
          scheduledOperatingDate,
          0,
          scheduledOperatingDate,
          ChangedField.ETD,
          myState[0].etd.hhmm,
          null,
          null,
          tzPreference,
          getStationDetails,
        );
      }
    } else {
      myState = await updateStateValues(
        myState,
        updateState,
        operatingAirline,
        scheduledOperatingDate,
        index,
        null,
        ChangedField.ETD,
        null,
        null,
        myState[index].destination.turnTime,
        tzPreference,
        getStationDetails,
      );
    }
    return myState;
  } else if (changedField === ChangedField.DESTINATION) {
    let destination = await getStationDetails(index, station, null, myState.length === 1 ? false : true);
    myState = updateState(myState, 'destination', index, { ...destination });
    let blockTime = await getStationsBlockMinutes(operatingAirline, myState[index].origin?.station, station);
    myState = updateState(myState, 'blockMinutes', index, blockTime);
    // set origin of next leg now
    if (myState.length > index + 1) {
      myState = updateState(myState, 'origin', index + 1, { ...destination });
      let blockT = await getStationsBlockMinutes(
        operatingAirline,
        destination.station,
        myState[index + 1].destination?.station,
      );
      myState = updateState(myState, 'blockMinutes', index + 1, blockT);
    }
    if (index > 0) {
      let turnTime = null;
      if (myState[index].etd.hhmm?.length > 0) {
        turnTime = null;
      } else {
        turnTime = myState[index].destination.turnTime;
      }
      return updateStateValues(
        myState,
        updateState,
        operatingAirline,
        scheduledOperatingDate,
        index,
        null,
        ChangedField.ETD,
        null,
        null,
        turnTime,
        tzPreference,
        getStationDetails,
      );
    } else {
      if (blockTime == null) {
        myState = updateState(myState, 'eta', index, {
          ...myState[index].eta,
          hhmm: '',
          utcValue: '',
          warningType: WarningTypes.NONE,
          message: '',
        });
      }
      return updateStateValues(
        myState,
        updateState,
        operatingAirline,
        scheduledOperatingDate,
        index,
        null,
        ChangedField.ETA,
        null,
        null,
        blockTime,
        tzPreference,
        getStationDetails,
      );
    }
  } else if (changedField === ChangedField.ETD) {
    myState = await helperUpdateStateETD(
      refTimeUtc,
      index,
      scheduledOperatingDate,
      myState,
      updateState,
      hhmm,
      addTimeMinutes,
      tzPreference,
    );
    return updateStateValues(
      myState,
      updateState,
      operatingAirline,
      scheduledOperatingDate,
      index,
      myState[index].etd.utcValue,
      ChangedField.ETA,
      null,
      null,
      myState[index].blockMinutes,
      tzPreference,
      getStationDetails,
    );
  } else if (changedField === ChangedField.ETA) {
    myState = await helperUpdateStateETA(
      refTimeUtc,
      index,
      myState,
      scheduledOperatingDate,
      updateState,
      hhmm,
      addTimeMinutes,
      tzPreference,
    );
    return updateStateValues(
      myState,
      updateState,
      operatingAirline,
      scheduledOperatingDate,
      index + 1,
      myState[index].eta.utcValue,
      ChangedField.ETD,
      null,
      null,
      myState[index].destination.turnTime,
      tzPreference,
      getStationDetails,
    );
  }
};

/**
 * @param {*} dateVal
 * @param {*} dateBase
 * @returns {Time} "HH:mm" time difference is within dateVal, dateBase
 */
export const getTimeDiffHours = (dateVal, dateBase) => {
  let hoursDiff = dayjs.duration(dayjs.utc(dateVal).diff(dayjs.utc(dateBase)));
  return hoursDiff.format('HH:mm');
};

/** get etd time zone from State object with index validate the returns current zone time and is Warning true/false
 * @param {*} refEtdDate
 * @param {*} myState
 * @returns {Time} retune the curretTzTime and isWarning true/false
 */
export const getCurretEtdZoneTime = (refEtdDate, myState) => {
  let originStation = myState[0]?.origin;
  let curretTzTime = !isNullOrWhitespace(originStation.tz) ? dayjs.utc().tz(originStation.tz) : dayjs.utc();

  if (!isNullOrWhitespace(originStation.station) && !isNullOrWhitespace(refEtdDate)) {
    return { isETDWarning: !timeIsAfter(refEtdDate, dayjs.utc()), tzCurretTime: curretTzTime.format('HH:mm') };
  }
  return { isETDWarning: false, tzCurretTime: curretTzTime.format('HH:mm') };
};

const helperUpdateStateETD = async (
  refTimeUtc,
  index,
  scheduledOperatingDate,
  myState,
  updateState,
  hhmm,
  addTimeMinutes,
  tzPreference,
) => {
  let utcRefDateTime = refTimeUtc ?? (index == 0 ? scheduledOperatingDate : myState[index - 1].eta?.utcValue);

  let etd = await getUpdatedETDValue(
    index,
    myState,
    scheduledOperatingDate,
    utcRefDateTime,
    null,
    hhmm,
    addTimeMinutes,
    tzPreference,
  );
  // get current time zone from State object with index validate the returns current-time-Zone
  let etdTzTime = getCurretEtdZoneTime(etd.utcValue, myState);
  let isWarning =
    index == 0
      ? etdTzTime.isETDWarning
      : isNullOrWhitespace(etd.utcValue) || isNullOrWhitespace(utcRefDateTime)
      ? false
      : !timeIsAfter(etd.utcValue, utcRefDateTime) || !isTimeDiffAfterWithin8Hours(etd.utcValue, utcRefDateTime);
  let currentTzTime = etdTzTime.isETDWarning ? etdTzTime.tzCurretTime : myState[index - 1]?.eta.hhmm;
  return updateState(myState, 'etd', index, {
    ...etd,
    warningType: isWarning ? WarningTypes.WARNING : WarningTypes.NONE,
    message: isWarning ? WarningMessages.SHOULD_BEAFTER_WARNING.replace('$time', currentTzTime) : '',
  });
};
const helperUpdateStateETA = async (
  refTimeUtc,
  index,
  myState,
  scheduledOperatingDate,
  updateState,
  hhmm,
  addTimeMinutes,
  tzPreference,
) => {
  let utcRefDateTime = refTimeUtc ?? myState[index].etd?.utcValue;
  let eta = await getUpdatedETAValue(
    index,
    myState,
    scheduledOperatingDate,
    utcRefDateTime,
    null,
    hhmm,
    addTimeMinutes,
    tzPreference,
  );

  // get time diff with current ETA/ETD to show Warning time
  let hoursDiff = getTimeDiffHours(eta.utcValue, utcRefDateTime);
  let isWarning =
    isNullOrWhitespace(eta.utcValue) || isNullOrWhitespace(utcRefDateTime)
      ? false
      : !timeIsAfter(eta.utcValue, utcRefDateTime) || !isTimeDiffAfterWithin8Hours(eta.utcValue, utcRefDateTime);
  return updateState(myState, 'eta', index, {
    ...eta,
    warningType: isWarning ? WarningTypes.WARNING : WarningTypes.NONE,
    message: isWarning ? WarningMessages.BLOCK_TIME_WARNING.replace('$time', hoursDiff) : '',
  });
};
