import { FlightPuckRefAction } from '../../actionTypes';

/**
 * Gets the initial state for the flight puck refs store.
 * @returns the initial state for the flight puck refs store
 */
export const getInitialState = () => {
  return {};
};

const initialState = getInitialState();

/**
 * Given the previous state and action payload, returns the new state for the store
 */
const flightPuckRefReducer = (state = initialState, action) => {
  switch (action.type) {
    case FlightPuckRefAction.ADD_FLIGHT_PUCK_REF:
      const { flightLegKey, ref } = action.payload;
      return { [flightLegKey]: ref };
    case FlightPuckRefAction.REMOVE_FLIGHT_PUCK_REF:
      return getInitialState();
    default:
      return state;
  }
};

export default flightPuckRefReducer;
