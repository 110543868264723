import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import HorizontalRule from '../HorizontalRule/HorizontalRule';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import Grid from '@material-ui/core/Grid';
import CurrentFlightDetails from '../CurrentFlightDetails/CurrentFlightDetails';
import { makeStyles } from '@material-ui/core/styles';
import TimeInput from '../Inputs/TimeInput/TimeInput';
import TextInput from '../Inputs/TextInput/TextInput';
import TextAreaInput from '../Inputs/TextAreaInput/TextAreaInput';
import './IrropsFlightModal.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BrickContainer from '../BrickContainer/BrickContainer';
import AirportCodeInput from '../AirportCodeInput/AirportCodeInput';
import { useAirportsQuery } from '../../../hooks/useAirportsQuery/useAirportsQuery';
import IrropsReasons from '../IrropsReasons/IrropsReasons';
import { KeyCodes, WarningTypes } from '../../../lib/constants';
import { useNotificationState } from '../../../contexts/NotificationsContext/NotificationStateContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeaderGrid: {
    fontSize: '1.4rem',
    fontWeight: 800,
    marginBottom: '1.4rem',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightArrow: {
    color: 'var(--gray)',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    height: '1.3rem',
  },
  irropsEditableStationArrow: {
    marginTop: '1.6rem',
  },
  irropsEditableStation: {
    marginTop: '1.25rem',
  },
  marginAuto: {
    margin: 'auto',
  },
  cancelFlightCheckboxContainer: {
    marginBottom: '0.1rem',
    marginTop: '0.2rem',
  },
  followLegDetails: {
    paddingTop: '1.1rem',
  },
  detailsHzRule: {
    marginTop: '0.5rem',
    marginBottom: '1.9rem',
  },
  flightStationHzRule: {
    marginTop: '0.6rem',
    marginBottom: '1.8rem',
  },
  hideOnSmallscreen: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  noCheckAddBottomMarginSmallScreen: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  irropHzRule: {
    marginTop: '2.4rem !important',
    marginBottom: '1.9rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.1rem !important',
    },
  },
  followFlightHzRule: {
    marginTop: '2.4rem',
    marginBottom: '2rem',
  },
  canceledFlightHzRule: {
    marginTop: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  pullUp: {
    marginTop: '-0.5rem',
  },
  commentsContainer: {
    marginTop: '1.8rem',
  },
  irropsEtaLable: {
    marginTop: '1.5rem',
  },
  smallScreenAdjustPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0.6rem !important',
    },
  },
  largeScreenAdjustPadding: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '2rem !important',
      paddingRight: '10rem !important',
    },
  },
  brickMarginBottom: {
    marginBottom: '1.4rem !important',
  },
  zuluLabel: {
    color: 'var(--gray)',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    marginLeft: '1px',
  },
}));

const IrropsFlightModal = ({
  showModal = false,
  modalTitle = '',
  // formDataBricksCol = 3,
  operatingAirlineCode = '',
  irropsCode = '',
  irropsAutoPopulateStationsLabel = 'Stations',
  irropStationLabel = 'ETA',
  showFlightDetailsSection = false,
  flightDetailsTitle = '',
  flightDetails = [],
  showIrropSection = false,
  showIrropFooterLine = true,
  irropTitle = '',
  irropStationsRequired = true,
  irropStations = {
    origin: '',
    destination: '',
    isDestinationEditable: false,
    onChangeDestination: () => {},
    warningType: WarningTypes.NONE,
    warningMessage: '',
  },
  irropETA = {
    value: '',
    onChange: () => {},
    onBlur: () => {},
    showError: false,
    inputTypeLable: false,
    warningType: WarningTypes.NONE,
    errorMessage: '',
  },
  irropCheckbox = {
    show: false,
    label: '',
    value: false,
    onChange: () => {},
  },
  showFollowFlightSection = false,
  followFlightTitle = '',
  followFlightStations = {
    origin: '',
    destination: '',
    isDestinationEditable: false,
    onChangeDestination: () => {},
    warningType: WarningTypes.NONE,
    warningMessage: '',
  },
  followFlightTurnTime = {
    value: 0,
    onChange: () => {},
    onBlur: () => {},
    warningType: WarningTypes.NONE,
    errorMessage: '',
  },
  followFlightETD = {
    value: '',
    onChange: () => {},
    onBlur: () => {},
    warningType: WarningTypes.NONE,
    errorMessage: '',
  },
  followFlightETA = {
    value: '',
    onChange: () => {},
    onBlur: () => {},
    warningType: WarningTypes.NONE,
    errorMessage: '',
  },
  showCanceledFlightSection = false,
  canceledFlightTitle = '',
  canceledFlightDetails = [],
  canceledFlightCheckbox = {
    label: 'Cancel flight',
    value: true,
    onChange: () => {},
  },
  showDocumentationSection = true,
  reason = {
    placeholderText: '-Select Reason-',
    reasonText: '',
    commentsTemplate: '',
    isCommentsRequired: false,
    onSelect: () => {},
  },
  comments = {
    value: '',
    onChange: () => {},
  },
  showFlightStationSection = false,
  flightStationDetails = [],
  flighSationDetailsTitle = '',
  isCommitEnabled = false,
  commitButtonClicked = false,
  onCommit,
  onClose,
  airportCodeInputRef,
  ...props
}) => {
  const classes = useStyles();
  const { isLoading: isLoadingAirportsData, data: airportsData, error: airportsDataError } = useAirportsQuery();
  const { closeNotification, RenderNotification } = useNotificationState();

  const handleOnSelectReason = (
    irregularOperationsReason,
    irregularOperationReasonsKey,
    irregularOperationCommentsTemplate,
    irregularOperationCommentsRequired,
    event,
    target,
  ) => {
    reason.onSelect(
      irregularOperationsReason,
      irregularOperationReasonsKey,
      irregularOperationCommentsTemplate,
      irregularOperationCommentsRequired,
      event,
      target,
    );
  };

  /**
   * @description get airports data
   */
  const getAirports = () => {
    return isLoadingAirportsData || airportsDataError || !airportsData?.data?.length ? [] : airportsData.data;
  };

  /**
   * @description hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled) {
      onCommit();
    }
  };

  // new code for IrropsFlightModal
  const formHTML = (
    <Grid container className={classes.pullUp}>
      <RenderNotification
        style={{
          toast: {
            minWidth: '38rem',
          },
          textStyle: {
            fontSize: '1.1rem',
            fontWeight: '700',
          },
        }}
      />
      {showFlightDetailsSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>{flightDetailsTitle}</div>
          </Grid>
          <Grid xs={12} item>
            <CurrentFlightDetails
              flightDetailBlocks={flightDetails}
              customBrickCSSTag={classes.brickMarginBottom}
              dataCyTag="irrop-cfd"
            />
          </Grid>
          <Grid item xs={12}>
            <HorizontalRule className={classes.detailsHzRule} />
          </Grid>
        </>
      ) : (
        <></>
      )}
      {showFlightStationSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>{flighSationDetailsTitle}</div>
          </Grid>
          <Grid xs={12} item>
            <CurrentFlightDetails
              flightDetailBlocks={flightStationDetails}
              customBrickCSSTag={classes.brickMarginBottom}
              dataCyTag="irrop-fst"
            />
          </Grid>
          <Grid item xs={12}>
            <HorizontalRule className={classes.flightStationHzRule} />
          </Grid>
        </>
      ) : (
        <>
          <div></div>
        </>
      )}

      {showIrropSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>{irropTitle}</div>
          </Grid>
          <Grid container spacing={3}>
            {/* Irrops Station field */}
            <Grid item xs={2} sm={6} md={3} lg={1} className={classes.smallScreenAdjustPadding}>
              <BrickContainer title={'Origin'} isRequired={false} customCSSTag="stationlabelinput-container">
                <div data-cy="irrop-org-station" className={classes.irropsEditableStation}>
                  {irropStations.origin}
                </div>
              </BrickContainer>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2} className={classes.smallScreenAdjustPadding}>
              <BrickContainer
                title={'Destination'}
                isRequired={irropStations.isDestinationEditable}
                customCSSTag="stationlabelinput-container"
              >
                <div className={irropStations.isDestinationEditable ? '' : classes.irropsEditableStation}>
                  {irropStations.isDestinationEditable ? (
                    <AirportCodeInput
                      airports={getAirports()}
                      onChange={irropStations.onChangeDestination}
                      isAutoFocus={true}
                      warningType={irropStations.warningType}
                      warningMessage={irropStations.warningMessage}
                      dataCyTag="destination-station"
                      ref={airportCodeInputRef}
                    />
                  ) : (
                    <span data-cy="irrop-dest-station">{irropStations.destination}</span>
                  )}
                </div>
              </BrickContainer>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className={`${classes.smallScreenAdjustPadding} ${
                irropCheckbox.show ? '' : classes.noCheckAddBottomMarginSmallScreen
              }`}
            >
              {irropETA.inputTypeLable ? (
                <BrickContainer title={irropStationLabel} isRequired={true} dataCyTag="Irrops-flight-input-eta">
                  <div dataCyTag="Irrops-flight-input-eta" className={`${classes.irropsEtaLable}`}>
                    {irropETA.value}
                    <span className={`${classes.zuluLabel}`}> zulu</span>
                  </div>
                </BrickContainer>
              ) : (
                <TimeInput
                  label={irropStationLabel}
                  value={irropETA.value}
                  isRequired={true}
                  onChange={irropETA.onChange}
                  onBlur={irropETA.onBlur}
                  warningType={irropETA.warningType}
                  warningMessage={irropETA.errorMessage}
                  dataCyTag="Irrops-flight-input-eta"
                  isAutoFocus={!irropStations.isDestinationEditable}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={`irrops-station-addcontinuation-check ${irropCheckbox.show ? '' : classes.hideOnSmallscreen}`}
            >
              {irropCheckbox.show ? (
                <BrickContainer title={' '} isRequired={false} customCSSTag="add-continuation-checkbox">
                  <FormControlLabel
                    label={irropCheckbox.label}
                    control={
                      <Checkbox
                        data-cy="check-irrop-contflightneeded"
                        checked={irropCheckbox.value}
                        onChange={irropCheckbox.onChange}
                      />
                    }
                  />
                </BrickContainer>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {showIrropFooterLine ? (
            <Grid item xs={12}>
              <HorizontalRule className={classes.irropHzRule} />
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}

      {showFollowFlightSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>{followFlightTitle}</div>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={2} lg={1} className={classes.smallScreenAdjustPadding}>
              <BrickContainer title="Origin" isRequired={false}>
                <div className={`${classes.flexContainer} ${classes.followLegDetails}`}>
                  <div data-cy="Irrops-flight-input-continuation-Origin">{followFlightStations.origin}</div>
                </div>
              </BrickContainer>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={followFlightStations.isDestinationEditable ? 2 : 1}
              className={
                followFlightStations.isDestinationEditable
                  ? classes.smallScreenAdjustPadding
                  : classes.largeScreenAdjustPadding
              }
            >
              <BrickContainer title="Destination" isRequired={false}>
                {followFlightStations.isDestinationEditable ? (
                  <AirportCodeInput
                    airports={getAirports()}
                    onChange={followFlightStations.onChangeDestination}
                    isAutoFocus={true}
                    warningType={followFlightStations.warningType}
                    warningMessage={followFlightStations.warningMessage}
                    dataCyTag="destination-station"
                    ref={airportCodeInputRef}
                  />
                ) : (
                  <div className={`${classes.flexContainer} ${classes.followLegDetails}`}>
                    <div data-cy="irrop-cont-dest-station">{followFlightStations.destination}</div>
                  </div>
                )}
              </BrickContainer>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} className={classes.smallScreenAdjustPadding}>
              <BrickContainer title="Turn Time" isRequired={true} customCSSTag="minsinput-container">
                <TextInput
                  suffixText="mins"
                  value={followFlightTurnTime.value}
                  onChange={followFlightTurnTime.onChange}
                  onBlur={followFlightTurnTime.onBlur}
                  warningType={followFlightTurnTime.warningType}
                  warningMessage={followFlightTurnTime.errorMessage}
                  dataCyTag="Irrops-flight-input-TurnTime"
                />
              </BrickContainer>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <TimeInput
                label="ETD"
                value={followFlightETD.value}
                isRequired={true}
                onChange={followFlightETD.onChange}
                onBlur={followFlightETD.onBlur}
                warningType={followFlightETD.warningType}
                warningMessage={followFlightETD.errorMessage}
                dataCyTag="Irrops-flight-input-etd"
                isAutoFocus={false}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <TimeInput
                label="ETA"
                value={followFlightETA.value}
                isRequired={true}
                onChange={followFlightETA.onChange}
                onBlur={followFlightETA.onBlur}
                warningType={followFlightETA.warningType}
                warningMessage={followFlightETA.errorMessage}
                dataCyTag="Irrops-flight-input-continuation-eta"
                isAutoFocus={false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <HorizontalRule className={classes.followFlightHzRule} />
          </Grid>
        </>
      ) : (
        <></>
      )}
      {/* No Follow Flight Section TODO: test this section above*/}
      {showCanceledFlightSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>{canceledFlightTitle}</div>
          </Grid>
          <Grid item xs={12}>
            <CurrentFlightDetails
              flightDetailBlocks={canceledFlightDetails}
              customBrickCSSTag={classes.brickMarginBottom}
              dataCyTag="irrop-cancelfd"
            />
          </Grid>
          {!canceledFlightCheckbox.hide ? (
            <Grid item xs={12} className={classes.cancelFlightCheckboxContainer}>
              <FormControlLabel
                label={canceledFlightCheckbox.label}
                control={
                  <Checkbox
                    data-cy="check-irrop-cancel-flight"
                    checked={canceledFlightCheckbox.value}
                    onChange={canceledFlightCheckbox.onChange}
                  />
                }
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <HorizontalRule className={classes.canceledFlightHzRule} />
          </Grid>
        </>
      ) : (
        <></>
      )}

      {showDocumentationSection ? (
        <>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>DOCUMENTATION</div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <BrickContainer title="Reason" isRequired={true}>
              <IrropsReasons
                onSelect={handleOnSelectReason}
                airlineCode={operatingAirlineCode}
                irropsCode={irropsCode}
                selectedReason={reason.reasonText}
                isRequired={true}
              />
            </BrickContainer>
          </Grid>
          <Grid item xs={0} sm={0} md={6}></Grid>
          <Grid item xs={12} className={classes.commentsContainer}>
            <BrickContainer title="Comments" isRequired={reason.isCommentsRequired}>
              <TextAreaInput
                value={comments.value}
                placeholder={reason.commentsTemplate}
                rows={5}
                onChange={comments.onChange}
                dataCyTag="Irrops-flight-form-comments-textarea"
              />
            </BrickContainer>
          </Grid>
        </>
      ) : (
        <></>
      )}

      {/* end container grid */}
    </Grid>
  );

  const handleOnClose = useCallback(() => {
    closeNotification();
    onClose();
  }, []);

  const formButtons = [
    <Button
      key="btnCommit"
      variant="primary"
      tabIndex="0"
      data-cy="Irrops-form-save-button"
      onClick={onCommit}
      onKeyPress={handleKeypress}
      isDisabled={!isCommitEnabled}
      className="btnDivCommit"
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <Modal
      show={showModal}
      title={modalTitle}
      body={formHTML}
      footerButtons={formButtons}
      onHide={handleOnClose}
      customCSSTag="irrops-flight-modal"
      showCloseButton={true}
    />
  );
};

IrropsFlightModal.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(IrropsFlightModal);
