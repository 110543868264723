import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import '../FlightDetailPane.css';
import MiniInfoBlock from './MiniInfoBlock/MiniInfoBlock';
import InfoBlock from './InfoBlock/InfoBlock';
import FlightTabBlockTime from './BlockTime/FlightTabBlockTime';
import FlightTabStationDetail from './StationDetail/FlightTabStationDetail';
import FlightTabValueIndex from './FlightValueIndex/FlightTabValueIndex';
import TabSkeleton from '../TabSkeleton/TabSkeleton';
import TabSkeletonSection from '../TabSkeletonSection/TabSkeletonSection';

const FlightTab = ({ flightDetails, scoreDetails, flightLoading, scoreLoading }) => {
  var outTime = 0;
  var offTime = 0;
  var onTime = 0;
  var inTime = 0;

  var scheduledOutTime = 0;
  var scheduledOffTime = 0;
  var scheduledOnTime = 0;
  var scheduledInTime = 0;

  let renderFlightDetails = !flightLoading && flightDetails && Object.keys(flightDetails).length > 0;

  if (renderFlightDetails) {
    outTime =
      flightDetails.times.find((t) => t.name === 'OUT')?.actualTimeUTC ??
      flightDetails.times.find((t) => t.name === 'OUT')?.estimatedTimeUTC;

    offTime =
      flightDetails.times.find((t) => t.name === 'OFF')?.actualTimeUTC ??
      flightDetails.times.find((t) => t.name === 'OFF')?.estimatedTimeUTC;

    onTime =
      flightDetails.times.find((t) => t.name === 'ON')?.actualTimeUTC ??
      flightDetails.times.find((t) => t.name === 'ON')?.estimatedTimeUTC;

    inTime =
      flightDetails.times.find((t) => t.name === 'IN')?.actualTimeUTC ??
      flightDetails.times.find((t) => t.name === 'IN')?.estimatedTimeUTC;

    scheduledOutTime = flightDetails.times.find((t) => t.name === 'OUT')?.scheduledTimeUTC;
    scheduledOffTime = flightDetails.times.find((t) => t.name === 'OFF')?.scheduledTimeUTC;
    scheduledOnTime = flightDetails.times.find((t) => t.name === 'ON')?.scheduledTimeUTC;
    scheduledInTime = flightDetails.times.find((t) => t.name === 'IN')?.scheduledTimeUTC;
  }

  return flightLoading ? (
    <TabSkeleton />
  ) : (
    <div className="flight-tab-container" data-cy="flight-tab-container">
      {renderFlightDetails && (
        <>
          {/* ----- SUMMARY ----- */}
          <div className="flight-tab-summary" data-cy="flight-tab-header">
            <div id="flight-tab-header-container" className="flex-row">
              <div className="flight-header">SUMMARY</div>
              {flightDetails.irregularOperationCode && (
                <div className="irrops-msg" data-cy="irrops-msg">
                  {flightDetails.irregularOperationCode}
                  {flightDetails.irregularOperationReason && <>&nbsp;-&nbsp;{flightDetails.irregularOperationReason}</>}
                </div>
              )}
            </div>
          </div>

          <div className="flex-row-fill">
            <div className="flex-col-left" data-cy="departure-block">
              <InfoBlock label="Departure">
                <MiniInfoBlock
                  station={flightDetails?.origin?.actual}
                  actualTime={outTime}
                  scheduledTime={scheduledOutTime ?? 0}
                  dataCyTag="departure-time-display"
                />
              </InfoBlock>
            </div>
            <div className="flex-col-right" data-cy="arrival-block">
              <InfoBlock label="Arrival">
                <MiniInfoBlock
                  station={flightDetails?.destination?.actual}
                  actualTime={inTime}
                  scheduledTime={scheduledInTime ?? 0}
                  dataCyTag="arrival-time-display"
                />
              </InfoBlock>
            </div>
          </div>

          <div className="flex-row-fill">
            <div className="flex-col-left" data-cy="passenger-count-block">
              <InfoBlock label="Passenger Count">N/A</InfoBlock>
            </div>
            <div className="flex-col-right" data-cy="equipment-block">
              <InfoBlock label="Equipment">
                {flightDetails?.aircraft?.aircraftRegistration}
                &nbsp; ({flightDetails?.aircraft?.fleetType})
              </InfoBlock>
            </div>
          </div>

          <div className="flex-row-fill">
            <div className="flex-col-left" data-cy="dispatcher-block">
              <InfoBlock label="Dispatcher" dataCyTag="dispatcher">
                N/A
              </InfoBlock>
            </div>
            <div className="flex-col-right" data-cy="service-type-block">
              <InfoBlock label="Service Type" dataCyTag="service-type">
                {/* leave first letter uppercase, the rest lowercase */}
                {flightDetails.flightServiceType?.charAt(0)}
                {flightDetails.flightServiceType?.slice(1).toLowerCase()}
              </InfoBlock>
            </div>
          </div>

          <div className="section-separator"></div>

          {/*----- DEPARTURE DETAILS -----*/}
          <div className="flight-tab-component" data-cy="departure-station-detail">
            <FlightTabStationDetail
              title="DEPARTURE"
              station={flightDetails?.origin?.actual}
              event1={'OUT'}
              event2={'OFF'}
              times={flightDetails.times}
              gateInfo={flightDetails?.origin?.parkingSpot ?? 'N/A'}
            />
          </div>

          <div className="section-separator"></div>

          {/*----- ARRIVAL DETAILS -----*/}
          <div className="flight-tab-component" data-cy="arrival-station-detail">
            <FlightTabStationDetail
              title="ARRIVAL"
              station={
                flightDetails.projectedDestination ??
                (!!flightDetails?.destination?.actual ? flightDetails.destination.actual : '')
              }
              event1={'ON'}
              event2={'IN'}
              times={flightDetails.times}
              gateInfo={flightDetails?.destination?.parkingSpot ?? 'N/A'}
            />
          </div>

          <div className="section-separator"></div>

          {/*----- BLOCK TIME ----- */}

          <div className="flight-header">BLOCK TIMES </div>

          <div className="flex-row-fill" data-cy="block-time">
            <div className="flex-col-left" data-cy="block-total">
              <InfoBlock label="Block Total">
                <FlightTabBlockTime
                  startTime={outTime}
                  endTime={inTime}
                  scheduledStartTime={scheduledOutTime}
                  scheduledEndTime={scheduledInTime}
                  showBlockInMinutes={true}
                />
              </InfoBlock>
            </div>
            <div className="flex-col-right" data-cy="flight-total">
              <InfoBlock label="Flight Time">
                <FlightTabBlockTime
                  startTime={offTime}
                  endTime={onTime}
                  scheduledStartTime={scheduledOffTime}
                  scheduledEndTime={scheduledOnTime}
                />
              </InfoBlock>
            </div>
          </div>

          <div className="flex-row-fill" data-cy="taxi-time">
            <div className="flex-col-left" data-cy="taxi-out-time">
              <InfoBlock label="Taxi Out">
                <FlightTabBlockTime
                  startTime={outTime}
                  endTime={offTime}
                  scheduledStartTime={scheduledOutTime}
                  scheduledEndTime={scheduledOffTime}
                  itemLabel="Taxi Out"
                />
              </InfoBlock>
            </div>
            <div className="flex-col-left" data-cy="taxi-in-time">
              <InfoBlock label="Taxi In">
                <FlightTabBlockTime
                  startTime={onTime}
                  endTime={inTime}
                  scheduledStartTime={scheduledOnTime}
                  scheduledEndTime={scheduledInTime}
                />
              </InfoBlock>
            </div>
          </div>

          <div className="section-separator"></div>

          {/*----- FLIGHT VALUE INDEX ----- */}

          {scoreLoading ? (
            <TabSkeletonSection />
          ) : (
            <div className="flight-tab-component" data-cy="flight-value-index">
              <FlightTabValueIndex scoreDetails={scoreDetails} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

FlightTab.propTypes = {
  flightDetails: PropTypes.object,
  scoreDetails: PropTypes.object,
  flightLoading: PropTypes.bool,
  scoreLoading: PropTypes.bool,
};

export default withAppInsightsTracking(FlightTab);
